import React, { Component } from 'react'
import { store } from '../../../store'
import { connect } from 'react-redux'
import Dropzone from 'react-dropzone'
import PropTypes from 'prop-types'
import Card from '@material-ui/core/Card'
import CardContent from '@material-ui/core/CardContent'
import Typography from '@material-ui/core/Typography'
import Input from '@material-ui/core/Input'
import InputLabel from '@material-ui/core/InputLabel' 
import FormControl from '@material-ui/core/FormControl'
import Select from '@material-ui/core/Select'
import Stepper from '@material-ui/core/Stepper'
import Step from '@material-ui/core/Step'
import StepLabel from '@material-ui/core/StepLabel'
import MenuItem from '@material-ui/core/MenuItem'
import { withTheme } from '@material-ui/core/styles'
import { batchTransfer, batchTransferErrorMessage, batchTransferReset } from '../../../action/batchTransfer'
import { batchFileVerification, batchFileVerificationErrorMessage, verifyBatchFile, batchFileVerificationReset } from '../../../action/batchFileVerification'
import Notification from '../../default/layout/Notification'
import BatchFile from '../batchFile/BatchFile'
import plusImage from '../../../images/agoda/dropzone.png'
import batchSample from '../../../resources/batch-transfer-sample.csv'
import DownloadSample from '../../../container/agoda/BatchSampleDownload'

const initialState = {
  activeStep: 0,
  batchFileContent: [],
  errorMsg: '',
  batchUploadResult: false,
  identifierType: 1,
  loadReference: '',
  fileName: '',
  uploadTriggered: false,
  additionalValidation: ['loadlimit','duplicateCard'],
  batchTransferOptions: ['noIdentifierValidation']
}

const batchSizeLimit = 250

class BatchTransferForm extends Component {

  constructor(props) {
    super(props)

    this.state = initialState
    this.resetComponent = this.resetComponent.bind(this)
    this.uploadBatch = this.uploadBatch.bind(this)
    this.verifyBatch = this.verifyBatch.bind(this)
    this.handleInputChange = this.handleInputChange.bind(this)
  }

  componentDidMount() {
    this.setState(this.initialState)
    store.dispatch(batchTransferReset())
    store.dispatch(batchFileVerificationReset())
  }

  componentWillReceiveProps(nextProps){
    if (nextProps.batchFileVerificationStruct.batchVerificationResult !== this.props.batchFileVerificationStruct.batchVerificationResult) {
      this.setState({
        batchFileContent : nextProps.batchFileVerificationStruct.batchVerificationResult,
        fileName: nextProps.batchFileVerificationStruct.fileName
      })
    }
  }

  handleInputChange = event => {
    const stateKey = event.target.name
    this.setState({...this.state, [stateKey]: event.target.value })
  }

  hadleCheckBoxChange = event => {
    this.setState({ [event.target.name]: event.target.checked })
  }

  verifyBatch(acceptedFiles, rejectedFiles) {
    this.setState({...this.state, uploadTriggered: true })
    if(this.state.loadReference !== '') {
      this.setState({...this.state, activeStep: 1 },verifyBatchFile(acceptedFiles, rejectedFiles, this.state.identifierType, batchSizeLimit, this.state.additionalValidation, this.state.loadReference))
    } else {
      var errorMsg = 'the load reference can\'t be blank or the file format is incorrect'
      alert(errorMsg)
    }
  }

  uploadBatch() {
    this.setState({
      ...this.state,
      activeStep: 2
    }, () => store.dispatch(batchTransfer(this.state.batchFileContent, this.state.identifierType, this.state.loadReference, this.state.fileName, this.state.batchTransferOptions)))
  }

  resetComponent() {
    this.setState({...initialState})
    store.dispatch(batchTransferReset())
    store.dispatch(batchFileVerificationReset())
  }

  render () {
    const steps = ['Batch file upload', 'Batch verification', 'Batch submit']
    const { apiCallPending, errorMsg } = this.props

    const disabled = (apiCallPending)

    const batchUploadResult = this.props.batchUploadResult && (
      <Notification type={'success'} message={'Batch transfers completed'} open={ true } closed={() => this.resetComponent()}/>
    ) 

    const batchUploadError = errorMsg && (
      <Notification type={'error'} message={ errorMsg } open={ true } closed={() => this.resetComponent()}/>
    )
    return (
      <React.Fragment>
        { batchUploadError }
        { batchUploadResult }
        <Card style={{padding:'40px'}}>
          <Typography gutterBottom variant="headline" component="h2">
            Create Batch Transfer
          </Typography>
          <CardContent>
            <Stepper activeStep={this.state.activeStep}>
              {steps.map((label, index) => {
                return (
                  <Step key={label}>
                    <StepLabel>{label}</StepLabel>
                  </Step>  
                )
              })}
            </Stepper>
          </CardContent>
        </Card><br />
        { this.state.activeStep === 0 && (
          <Card style={{padding:'40px'}}>
            <Typography gutterBottom variant="subheading" component="h3">
              Upload a CSV file containing a list of card identifiers and amounts to load. No amount means transfer the whole balance. (Maximum {batchSizeLimit} records)
            </Typography>
            <CardContent>
              <div className="batch-load-form-container"> 
                <FormControl className="form-input">
                  <InputLabel htmlFor="identifier-type">Used identifier</InputLabel>
                  <Select
                    id="identifier-type"
                    value={this.state.identifierType}
                    onChange={this.handleInputChange}
                    input={<Input name="identifierType"/>}
                    fullWidth={true}
                  >
                    <MenuItem value={1}>PAN</MenuItem>
                    <MenuItem value={2}>TrackingNumber</MenuItem>
                  </Select>
                </FormControl>
                <FormControl className="form-input" required={true}>
                  <InputLabel htmlFor="load-reference">Load reference</InputLabel>
                  <Input
                    id="load-reference"
                    value={this.state.loadReference}
                    name="loadReference"
                    onChange={this.handleInputChange}
                    error={this.state.loadReference === '' && this.state.uploadTriggered}
                  />
                </FormControl>
              </div>
              <Dropzone className="dropzone" multiple={false} disabled={disabled} onDrop={this.verifyBatch.bind(this)} accept=".csv">
                {({getRootProps, getInputProps}) => (
                  <section className="container">
                    <div {...getRootProps({className: 'dropzone'})}>
                      <input {...getInputProps()} />
                      <img className="dropzone-image" src={plusImage} alt="Try dropping a file into this box, or click on it to select the file to upload." />
                      <Typography component="p" className="dropzone-p">Try dropping a file into this box, or click on it to select the file to upload.</Typography>
                    </div>
                  </section>
                )}
              </Dropzone>
            </CardContent>
            <DownloadSample batchSample ={batchSample}/>
          </Card>
        ) }
        { (this.state.batchFileContent.length && this.state.activeStep === 1)? (
          <BatchFile
            batchFileContent = { this.state.batchFileContent }
            submitCallback = {this.uploadBatch.bind(this)}
            resetCallback = {this.resetComponent.bind(this)}
            identifierType = {this.state.identifierType}
            fileName = {this.state.fileName}
            reference = {this.state.loadReference}
            operationType = "transfer"
            handleInputChange = {this.handleInputChange}
            hadleCheckBoxChange = {this.hadleCheckBoxChange}
          />
        ): null
        }
      </React.Fragment>
    )
  }
}

BatchTransferForm.propTypes = {
  BatchFileVerification: PropTypes.func
}

const mapStateToProps = (state, props) => {
  return {
    apiCallPending: !!(state.api.BATCH_LOAD && state.api.BATCH_LOAD > 0),
    errorMsg: (state.batchTransfer.errorMsg && state.batchTransfer.errorMsg.length) ? state.batchTransfer.errorMsg
      : state.batchFileVerification.errorMsg,   
    batchUploadResult: state.batchTransfer.batchUploadResult ? JSON.parse(state.batchTransfer.batchUploadResult) : false,
    batchFileVerificationStruct: {
      batchVerificationResult: (state.batchFileVerification.batchVerificationResult || []),
      batchFileRow: (state.batchFileVerification.batchFileRow || null),
      fileName: (state.batchFileVerification.fileName || '')
    },
    batchFileVerification: state.batchFileVerification,
    fees: state.fees,
    fetched: state.batchFileVerification.fetched
  }
}

export default connect(
  mapStateToProps,
  { 
    batchTransfer,
    batchTransferErrorMessage,
    batchFileVerification,
    batchFileVerificationErrorMessage
  }
)(withTheme(BatchTransferForm))
