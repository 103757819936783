import React, { Component } from 'react'
import { connect } from 'react-redux'
import Paper from '@material-ui/core/Paper'
import TextField from '@material-ui/core/TextField'
import Button from '@material-ui/core/Button'
import { red } from '@material-ui/core/colors'
import { withStyles, withTheme } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'
import Alert from '../../component/default/layout/Alert'

const styles = theme => ({
  button: {
    margin: theme.spacing(),
  },
});

class EnrollUser extends Component {
  state = {
    isResent: false
  }

  onKeyPress = (event) => {
    if (event.key === 'Enter') {
      this.props.confirm()
    }
  }

  onResentOTP = () => {
    this.setState({isResent: true})
    this.props.resendOTP()
  }

  onSubmit = () => {
    this.props.confirm()
  }

  render() {
    const paperStyle = {
      width: '50%',
      position: 'absolute',
      top: '45%',
      left: '50%',
      padding: 20,
      marginLeft: '-120px',
      textAlign: 'center',
      display: 'flex',
      flexDirection: 'column',
      minHeight:'200px',
      justifyContent:'space-between',
      transform: 'translateY(-50%) translateX(-50%)',
      verticalAlign: 'middle',
    }
    const helperTextStyle = {
      color: red[500]
    }

    const { errorCode, errorMsg, classes, onInputChange, isSubmitDisabled } = this.props

    return (
      <Paper style={paperStyle} zdepth={4}>
        <Typography gutterBottom variant="h5">
          Two-Factor Authentication
        </Typography>
        <br/><br/>
        <Typography gutterBottom variant="subtitle1">
          <span>Enter a verification code sent to your email.</span>
        </Typography>
        <div data-testid="otpErrorMessage" style={helperTextStyle}>
          {errorCode === '-20155' && !this.state.isResent ? '' : errorMsg}
        </div>
        <br/><br/>
        <div align="center">
          <TextField
            name="challenge"
            label="Verification Code"
            inputProps={{
              maxLength: 6
            }}
            fullWidth={false}
            onChange={onInputChange}
            onKeyPress={this.onKeyPress}
            autoComplete="false"
            style={{borderBottom:'0.1px solid'}}
            autoFocus
          />
        </div>
        {
          this.state.isResent && errorCode === '-20152' && <Alert style={{
            backgroundColor: 'rgb(237, 247, 237)', 
            color: 'rgb(30, 70, 32)', 
            lineHeight: '20px', 
            margin: '20px auto 0 auto',
            verticalAlign: 'none',
            width: '250px',
            borderRadius: '5px'}}>
            The code has been resent to your email!
          </Alert>
        }
        <div style={{marginTop: 20}}>
          <Button data-testid="cancelOTPBtn" className={classes.button} size="large" variant="contained"
            onClick={this.props.reset}>Cancel
          </Button>
          <Button data-testid="resendOTPBtn" type="button" className={classes.button} size="large" variant="outlined" color="primary"
            onClick={this.onResentOTP}>Resend Code</Button>  
          <Button data-testid="submitOTPBtn" className={classes.button} size="large" variant="contained" color="primary"
            disabled={isSubmitDisabled} onClick={this.onSubmit}>Submit</Button>
        </div>
      </Paper>
    )
  }
}

const mapStateToProps = (state, props) => {
  return {
    errorMsg: state.auth.errorMsg,
    errorCode: state.auth.errorCode
  }
}

export default connect(mapStateToProps)(withStyles(styles)(withTheme(EnrollUser)))
