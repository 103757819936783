import {
  LOGIN,
  RESET_MFA,
  MFA_NOT_ENROLLED,
  MFA_REQUIRED,
  MFA_UNKNOWN,
  GET_PERMISSIONS,
  LOGOUT,
  GET_CURRENT_USER,
  ENROLL_USER,
  CONFIRM_USER_ENROLMENT,
  MFA_ENROLMENT_COMPLETE,
} from '../action'
import {TOKEN_EXPIRED} from "../action/apiResponseCodes";



const initialState = {
  token: null,
  mfaStatus: MFA_UNKNOWN,
  mfaSecret: null,
  perms: {},
  errorMsg: ''
}

export default (state = initialState, action) => {
  const { type, payload } = action
  switch(type) {
    case `${GET_CURRENT_USER}_FULFILLED`: {
      if(payload.resultCode === TOKEN_EXPIRED){
        return initialState
      } else {
        return state
      }
    }
    case `${LOGIN}_FULFILLED`:
      let defaultState = { 
        ...state, 
        errorCode: payload.resultCode
      }
      if (payload.resultCode === "0") {
        return {
          ...defaultState,
          errorMsg: '',
          token: payload.token
        }
      } else if (payload.resultCode === "-20150"){
        return {
          ...defaultState,
          mfaStatus: MFA_NOT_ENROLLED,
          errorMsg: ''
        }
      } else if (payload.resultCode === "-20151"){
        return {
          ...defaultState,
          mfaStatus: MFA_REQUIRED,
          errorMsg: 'Verification code not valid, please try again'
        }
      } else if (payload.resultCode === "-20152"){
        return {
          ...defaultState,
          mfaStatus: MFA_REQUIRED,
          errorMsg: ''
        }
      } else if (payload.resultCode === "-20155"){
        return {
          ...defaultState,
          mfaStatus: MFA_REQUIRED,
          errorMsg: payload.resendOTPLimitMessage
        }
      } else {
        return {
          ...defaultState,
          errorMsg: payload.resultText
        }
      }
    case `${LOGIN}_PENDING`:
      return {
        ...state,
        errorMsg: ''
      }
    case `${LOGIN}_REJECTED`:
      return {
        ...state,
        errorMsg: 'Cannot connect to server'
      }
    case `${ENROLL_USER}_FULFILLED`:
      if (payload.resultCode === "0") {
        return {
          ...state,
          mfaSecret: payload.secret
        }
      } else {
        return {
          ...state,
          mfaSecret: null,
          errorMsg: payload.resultText
        }
      }
    case `${ENROLL_USER}_PENDING`:
      return {
        ...state,
        mfaSecret: null,
        errorMsg: ''
      }
    case `${ENROLL_USER}_REJECTED`:
      return {
        ...state,
        mfaSecret: null,
        errorMsg: 'Cannot connect to server'
      }
    case `${CONFIRM_USER_ENROLMENT}_FULFILLED`:
      if (payload.resultCode === "0") {
        return {
          ...state,
          mfaSecret: null,
          mfaStatus: MFA_ENROLMENT_COMPLETE
        }
      } else if (payload.resultCode === "-20151"){
        return {
          ...state,
          errorMsg: 'Verification code not valid, please try again'
        }
      } else {
        return {
          ...state,
          errorMsg: payload.resultText
        }
      }
    case `${CONFIRM_USER_ENROLMENT}_PENDING`:
      return {
        ...state,
        errorMsg: ''
      }
    case `${CONFIRM_USER_ENROLMENT}_REJECTED`:
      return {
        ...state,
        errorMsg: 'Cannot connect to server'
      }
    case `${GET_PERMISSIONS}_FULFILLED`:
      if (payload.resultCode === "0") {
        return {
          ...state,
          user: payload.user,
          perms: payload.permissions
        }
      } else {
        return state
      }
    case RESET_MFA:
      return {
        ...state,
        mfaSecret: null,
        mfaStatus: MFA_UNKNOWN
      }
    case LOGOUT:
      return {
        ...initialState
      }
    default:
      return state
  }
}
