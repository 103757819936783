import React from 'react'
import TableRow from '@material-ui/core/TableRow'
import TableCell from '@material-ui/core/TableCell'
import Icon from '@material-ui/core/Icon'
import IconButton from '@material-ui/core/IconButton'
import {Link} from 'react-router-dom'
import Tooltip from '@material-ui/core/Tooltip'

const UserSearchResultsRow = (props) => {
  const id = props.searchResult.userID
  const isCurrentUser = (id === parseInt(props.currentUserId, 10))
  return (
    <TableRow key={id}>
      <TableCell>{props.searchResult.userName}</TableCell>
      <TableCell>{props.searchResult.firstName}</TableCell>
      <TableCell>{props.searchResult.lastName}</TableCell>
      <TableCell>{props.searchResult.email}</TableCell>
      <TableCell>{props.searchResult.userGroups}</TableCell>
      <TableCell>{props.searchResult.lastLogin}</TableCell>
      <TableCell>
        <Tooltip title="Edit user">
          <Link to={`/user/edituser/${props.searchResult.userID}`}><Icon>edit</Icon></Link>
        </Tooltip>
      </TableCell>
      <TableCell>
        <Tooltip title="Delete user">
          <IconButton onClick={() => {
            props.delete(id)
          }} disabled={isCurrentUser} aria-label="Delete">
            <Icon>delete</Icon>
          </IconButton>
        </Tooltip>
      </TableCell>
      <TableCell>
        <Tooltip title="Unlock user">
          <IconButton onClick={() => {
            props.unlock(id)
          }} disabled={!props.searchResult.isLocked} aria-label="Unlock">
            <Icon>lock_open</Icon>
          </IconButton>
        </Tooltip>
      </TableCell>
      <TableCell>
        <Tooltip title="Send new activation email">
          <IconButton onClick={() => {
            props.resendActivation(id)
          }} disabled={props.searchResult.isActivated} aria-label="Unlock">
            <Icon>email</Icon>
          </IconButton>
        </Tooltip>
      </TableCell>
    </TableRow>
  )
}

export default UserSearchResultsRow
