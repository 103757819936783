import React from 'react'
import Wrapper from '../../../component/default/layout/Wrapper'
import CSVReport from '../../../component/agoda/reports/CSVReport/CSVReport'
import transform from '../../../component/agoda/reports/CSVReport/transform'

const CardBalanceReport = () => <div>
  <Wrapper>
    <CSVReport 
      type="cardBalanceReport" 
      reportHeader="Card Balances Report"
      displayAllCampaigns="true"
      transform={{
        "Balance" : transform.centToMoney , 
        "Last Load Amount" : transform.centToMoney, 
        "Load Total" : transform.centToMoney, 
        "Authorisation Total" : transform.centToMoney
      }}
      previewSize={1000}
      prependRowIndex={true}
      downloadableExcel={true}
    />
  </Wrapper>
</div>

export default CardBalanceReport
