import React, { Component, Fragment } from 'react'
import Popover from '@material-ui/core/Popover'
import Button from '@material-ui/core/Button'
import IconButton from '@material-ui/core/IconButton'
import TextField from '@material-ui/core/TextField'
import Tooltip from '@material-ui/core/Tooltip'
import {red} from "@material-ui/core/colors";
import IndeterminateCheckBoxOutlined from "@material-ui/icons/IndeterminateCheckBoxOutlined"

const buttonTooltipDelay = 800
const closedCommentsCharLimit = 255

const closeButtonStyle = {
  backgroundColor: red[500],
  color:'white'
}

class BatchLoadsCloseDialog extends Component {
  state = {
    anchorEl: null,
    closedComments: ''
  };

  handleClick = event => {
    this.setState({
      anchorEl: event.currentTarget
    });
  };

  handleClose = () => {
    this.setState({
      anchorEl: null
    });
  };
  
  handleConfirm() {
    this.props.batchLoadClose(
        this.props.batchLoadFileID,
        this.state.closedComments
    )
    this.handleClose()
  }

  handleInputChange = (event) => {
    const stateKey = event.target.name
    this.setState({ [stateKey]: event.target.value })
  }

  render(){
    const { anchorEl } = this.state
    const { disabled } = this.props
    return (
      <Fragment>
        <Tooltip title="Close batch load" enterDelay={buttonTooltipDelay}>
          <span>
            <IconButton onClick={this.handleClick} disabled={ disabled } aria-label="Close" >
              <IndeterminateCheckBoxOutlined/>
            </IconButton>
          </span>
        </Tooltip>
        <Popover
          open={Boolean(anchorEl)}
          anchorEl={anchorEl}
          onClose={this.handleClose}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "center"
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "center"
          }}
        >
          <div style={{padding:'40px'}} >
            Are you sure you want to close this batch?
            <br/>
            <TextField
              name="closedComments"
              label={"Closed comments (" + closedCommentsCharLimit + " chars max)"}
              multiline
              rowsMax="4"
              onChange={this.handleInputChange}
              margin="normal"
              inputProps={{
                maxLength: closedCommentsCharLimit,
              }}
              fullWidth
            />
            <br/><br/>
            <div style={{width:'100%', display:'flex', flexFlow:'row no-wrap', justifyContent:'space-around'}}>
              <Button size='small' onClick={()=>this.handleClose()} variant="raised">
                Cancel
              </Button>
              <Button size='small' onClick={()=>this.handleConfirm()} variant="raised" style={closeButtonStyle}>
                Close
              </Button>
            </div>
          </div>
        </Popover>
      </Fragment>
    )
  }
}

export default BatchLoadsCloseDialog
