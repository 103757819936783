import React, { Component } from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import Card from '@material-ui/core/Card'
import CardContent from '@material-ui/core/CardContent'
import Typography from '@material-ui/core/Typography'
import Input from '@material-ui/core/Input'
import FormControl from '@material-ui/core/FormControl'
import Select from '@material-ui/core/Select'
import InputLabel from '@material-ui/core/InputLabel'
import MenuItem from '@material-ui/core/MenuItem'
import TextField from '@material-ui/core/TextField'
import Button from '@material-ui/core/Button'
import Icon from '@material-ui/core/Icon'
import CardSearchResults from './CardSearchResults'
import { CSVLink } from "react-csv"
import Tooltip from '@material-ui/core/Tooltip'
import Switch from "@material-ui/core/Switch"
import FormControlLabel from "@material-ui/core/FormControlLabel"
import { withTheme } from '@material-ui/core/styles'
import download from "downloadjs";
import dateFormat from "dateformat"
import IconButton from "@material-ui/core/IconButton"
import Grid from "@material-ui/core/Grid"

import { store } from '../../../store'
import { cardSearch, cardSearchReset, getCombinedStatement, resetCombinedStatement, getCardTags } from '../../../action'
import combinedStatementToCSV from '../../../util/csv/combinedStatementToCSV'
import { validateMinimumLength } from '../../../util/form'
import { TAG_VALIDATION_FAIL } from '../../../constants/errorMessages'

export const SEARCH_TYPES = Object.freeze({
  cardNumber: '1',
  customerId: '2',
  phoneNumber: '3',
  trackingNumber: '4',
  idNumbers: '5',
  customerReference: '6',
})

const TAGS_NAMES_TO_VALIDATE = ['bookingId']

const minLength = (minLength) => (val) => {
  if (val.length < minLength) {
    return `Search term should be at least ${minLength} characters`
  }
  return ''
}  

const validateTagsSearchTerm = (minLength) => (searchTerm) => {
  return !validateMinimumLength(searchTerm, minLength) ? TAG_VALIDATION_FAIL.replace("{minLength}", minLength) : ''
}

const defaultSearchOptions = {
  [SEARCH_TYPES.cardNumber]: { label: 'Card Number', placeholder: '9-19 digits' },
  [SEARCH_TYPES.customerId]: { label: 'Customer ID', placeholder: '3-20 characters' },
  [SEARCH_TYPES.phoneNumber]: { label: 'PhoneNumber', placeholder: '7-15 digits' },
  [SEARCH_TYPES.trackingNumber]: { label: 'Tracking Number', placeholder: '15digits', validations: [minLength(5)] },
  [SEARCH_TYPES.idNumbers]: { label: 'ID Number', placeholder: '5-20digits' },
  [SEARCH_TYPES.customerReference]: { label: 'Customer Reference', placeholder: 'min 12 characters', validations: [minLength(19)] },
}

export class CardSearchForm extends Component {
  constructor(props) {
    super(props)
    this.initialState = {
      search: '',
      errorMsg: '',
      searchType: SEARCH_TYPES.cardNumber,
      fieldsErrors: {},
      showExpired: false,
      searchOptions: defaultSearchOptions,
      triedToBeSubmited: false
    }
    this.state = {
      ...this.initialState
    }
  }

  componentWillUnmount() {
    store.dispatch(cardSearchReset())
  }

  handleInputChange = (event, callback) => {
    const stateKey = event.target.name
    const value = event.target.value

    this.setState({ [stateKey]: value }, () => {
      if (callback) {
        callback(value)
      }
    })
  };

  handleExpireChange = event => {
    this.setState({ showExpired: event.target.checked })
  }

  validateOptions = (value) => {
    const { searchType, fieldsErrors } = this.state
    const option = this.state.searchOptions[searchType]
    const { validations } = option

    let error = '' 
    if (validations) {
      error = validations
        .reverse()
        .reduce((_, validate) => validate(value), '') || ''
    }
    const newErrors = {
      ...fieldsErrors,
      search: error,
    }
    this.setState({ fieldsErrors: newErrors })
  };

  handleClick = () => {
    //  set validation error message for the first time when user submit, that's better for the UX
    this.setState({ triedToBeSubmited: true })
    
    const isInvalid = Object.values(this.state.fieldsErrors).some(val => val)
    if (isInvalid) {
      return
    }
    this.props.cardSearch(this.state.search, this.state.searchType, this.state.showExpired)

  }

  handleStatementClick = () => {
    this.props.getCombinedStatement(
      this.props.searchResults.map((row) => row.CARDNUMBER)
    )
  }

  boolFlag(value){
    return value ? 1 : 0
  }

  formatCsv = (data) => {
    return data.map(
      card => ({
        CampaignName: card.CAMPAIGNNAME,
        CustomerRef: card.CUSTOMERREFERENCE,
        CardNumber: card.CARDNUMBER,
        TrackingNumber: card.TRACKINGNUMBER,
        BookingID: card.BOOKINGID,
        CardExpiry: card.CARDEXPIRY,
        CardCVV: card.CARDCVV2,
        CardBalance: card.CARDBALANCE,
        FundsAvailable: card.AVAILABLEFUNDS,
        LoadedAmount: card.LOADEDAMOUNT,
        AuthorizedAmount: card.AUTHORIZEDAMOUNT,
        SettledAmount: card.SETTLEDAMOUNT,
        IssueDate: card.ISSUEDATE,
        Expired: this.boolFlag(card.EXPIRED),
        Activated: this.boolFlag(card.ACTIVATED),
        Retired: this.boolFlag(card.RETIRED),
        Stopped: this.boolFlag(card.STOPPED)
      })
    )
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (this.props.combinedStatement && !prevProps.combinedStatement){
      const csvContent = combinedStatementToCSV(this.props.combinedStatement)
      const dateStr = dateFormat(new Date(),'yyyy-mm-dd_H:M:ss')
      const type = 'text/csv'
      download(
        new Blob([csvContent], { type }),
        `CardSearch-combined-statement-${dateStr}.csv`,
        type
      )
      this.props.resetCombinedStatement()
    }
    if (this.props.cardTags !== prevProps.cardTags) {
      const searchOptions = { 
        ...defaultSearchOptions
      }
      this.props.cardTags.forEach ((item) => {
        searchOptions['voucherTag-' + item.id] = {
          label: 'Tag: '  + item.name,
          placeholder: 'comma separated list of values',
          validations: TAGS_NAMES_TO_VALIDATE.includes(item.name)? [validateTagsSearchTerm(5)]: [validateTagsSearchTerm(1)]
        }
      })
      this.setState({ searchOptions })
    }
  }

  componentDidMount() {
    this.props.getCardTags()
  }

  render() {
    const { submitting, errorMsg } = this.props
    const { searchOptions, fieldsErrors, triedToBeSubmited } = this.state
    let foundResult = false
    if (this.props.searchResults && this.props.searchResults.length > 0) {
      foundResult = true
    }
    let foundError = false
    if (errorMsg && errorMsg.length > 0) {
      foundResult = false
      foundError = true
    }

    const responseCard =
      foundResult && !foundError ? (
        <div data-testid="card-search-result">
          <Card style={{ padding: '40px' }}>
            <Grid
              justify="space-between"
              container
            >
              <Grid item>
                <Typography gutterBottom variant="h5">
                  Cards Found ({this.props.searchResults.length})
                </Typography>
              </Grid>
              <Grid item>
                <Tooltip title="CSV">
                  <CSVLink
                    data={this.formatCsv(this.props.searchResults)}
                    filename={"cardsearch.csv"}
                    target="_blank"
                  >
                    <Icon style={{ verticalAlign: 'middle' }} color="primary">cloud_download</Icon>
                  </CSVLink>
                </Tooltip>
                &nbsp;&nbsp;
                &nbsp;&nbsp;
                <Tooltip title="Combined Statement">
                  <IconButton color="inherit" onClick={this.handleStatementClick}>
                    <Icon>description</Icon>
                  </IconButton>
                </Tooltip>
              </Grid>
            </Grid>
            <CardSearchResults searchResults={this.props.searchResults} />
          </Card>
        </div>
      ) : foundError ? (
        <div data-testid="error-found-result">
          <Card style={{ padding: '40px' }}>
            <Typography variant="h5">
              No Cards Found
            </Typography>
            <CardContent>
              <Typography component="p" className="p-error">
                {errorMsg}
              </Typography>
            </CardContent>
          </Card>
        </div>
      ) : null
    return (
      <React.Fragment>
        <Card style={{ padding: '40px' }}>
          <Typography variant="h5">
            Find Card
          </Typography>
          <CardContent className="card-search-form-container">
            <FormControl className="form-input">
              <InputLabel htmlFor="search-options">Search Type</InputLabel>
              <Select
                id="search-options"
                value={this.state.searchType}
                onChange={(e)=> this.handleInputChange(e, () => this.validateOptions(this.state.search))}
                input={<Input name="searchType" />}
                data-testid="search-type"
              >
                {Object.keys(searchOptions).map(key => (
                  <MenuItem value={key} key={key} data-testid="search-type-option">
                    {searchOptions[key].label}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            <FormControl className="form-input">
              <TextField
                name="search"
                label={searchOptions[this.state.searchType].label}
                value={this.state.search}
                placeholder={searchOptions[this.state.searchType].placeholder}
                onChange={(e)=> this.handleInputChange(e, (val) => this.validateOptions(val))}
                onKeyPress={e => e.key === 'Enter' && this.handleClick()}
                error={triedToBeSubmited && !!fieldsErrors.search}
                helperText={fieldsErrors.search}
                data-testid="search-term"
              />
            </FormControl>
            <FormControl>
              <Button
                className="flattbutton-search"
                color="primary"
                size="large"
                variant="contained"
                onClick={this.handleClick}
                disabled={submitting}
                data-testid="submit-button"
              >
                <Icon>search</Icon>
              </Button>
            </FormControl>
          </CardContent>
          <FormControlLabel control={
            <Switch
              checked={this.state.showExpired}
              onChange={this.handleExpireChange}
              color="primary"
              data-testid="expired-checkbox"
            />}
          label="Show Expired"
          labelPlacement="start"
          />
        </Card>
        {responseCard}
      </React.Fragment>
    )
  }
}

CardSearchForm.propTypes = {
  CardSearch: PropTypes.func
}

const mapStateToProps = (state) => {
  return {
    submitting: !!(state.api.CARD_SEARCH && state.api.CARD_SEARCH > 0),
    errorMsg: state.cardSearch.errorMsg,
    searchResults: state.cardSearch.searchResults,
    cardTags: state.cardSearch.cardTags,
    combinedStatement: state.cardSearch.combinedStatement
  }
}

export default connect(
  mapStateToProps,
  {
    cardSearch,
    getCardTags,
    getCombinedStatement: getCombinedStatement,
    resetCombinedStatement: resetCombinedStatement
  }
)(withTheme(CardSearchForm))
