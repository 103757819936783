import { GET_FEES_FROM_TERMINAL_ID } from "../action";
import { INSERT_CARD_FEE } from '../action/insertCardFee'

const initialState = {
	resultCode: 0
}

export default (state = initialState, action) => {
	switch (action.type) {

	case `${GET_FEES_FROM_TERMINAL_ID}_FULFILLED`:
		return { ...state, ...action.payload.fee }
	case `${INSERT_CARD_FEE}_FULFILLED`:
		return { ...state, resultCode: action.payload.resultCode}
	default:
		return state
	}
}
