import { BATCH_FILE_VERIFICATION, BATCH_FILE_VERIFICATION_ERROR_MESSAGE } from '../action/batchFileVerification'

const initialState = {
  errorMsg : '',
  batchVerificationResult : [],
  batchFileRow: null,
  fileName: '',
  pending: false,
  fetched: false
}

export default (state = initialState, action) => {
  const { type, payload, meta } = action
  switch(type) {
    case BATCH_FILE_VERIFICATION_ERROR_MESSAGE:
      return {
        ...state,
        errorMsg: payload.resultText || 'An error occurred'
      }
    case `${BATCH_FILE_VERIFICATION}_FULFILLED`:
      if (payload.resultCode === "0") {

        return {
          ...state,
          batchVerificationResult: JSON.parse(payload.resultText),
          batchFileRow: meta.batchFileRow,
          fileName: meta.fileName,
          pending: false,
          fetched: true
        }
      } else {
        return {
          ...state,
          errorMsg: payload.resultText,
          pending: false,
          fetched: true
        }
      }
    case `${BATCH_FILE_VERIFICATION}_PENDING`:
      return {
        ...state,
        errorMsg: '',
        pending: true
      }
    case `${BATCH_FILE_VERIFICATION}_REJECTED`:
      return {
        ...state,
        errorMsg: 'Cannot connect to server'
      }
    case `${BATCH_FILE_VERIFICATION}_RESET`:
      return {
        ...initialState
      }

    default:
      return state
  }
}
