import React, { Component } from 'react'
import Header from '../../component/default/layout/Header'
import ProgressBar from '../../component/default/layout/ProgressBar'
import SideBar from '../../component/default/layout/SideBar'
import { createMuiTheme, MuiThemeProvider } from '@material-ui/core/styles'

const tutukaTheme = createMuiTheme({
  palette: {
    primary: {main:'#00334e'}
  },
  overrides: {
    MuiSnackbarContent: {
      root: {
        backgroundColor: 'white',
        color: 'grey'
      },
    },
  }
})

class App extends Component {
  constructor(props) {
    super(props)
    this.state = {
      showSideBar: false
    }
  }

  render() {
    return (
      <MuiThemeProvider theme={tutukaTheme}>
        <div className="container">
          <div className="class-root">
            <Header titleStyle={{ paddingLeft: '20px' }} />
            <ProgressBar />
            <SideBar />
            <div className="class-content-spaced">
              {this.props.children}
            </div>
          </div>
        </div>
      </MuiThemeProvider>
    )
  }
}

export default App
