import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { push } from 'connected-react-router'
import { connect } from 'react-redux'
import { withStyles, List } from '@material-ui/core'
import DefaultDrawer from './DefaultDrawer'
import NavItems from './NavItems'
import Config from "../../../../config"


const styles = theme => ({
  root: {
    maxWidth: 360,
    minWidth: 240
  }
})

class SideBar extends Component {
  navigate = path => {
    const { push } = this.props
    push(path)
  }

  navigationItems = () => {
    const routeUrls = [
      {displayName: 'User Management', sortOrder: 1, path: '/', depth: 0, children:
        [
          {displayName: 'Add User', sortOrder: 1, path: '/user/adduser', depth: 1},
          {displayName: 'Find User', sortOrder: 2, path: '/user/search', depth: 1}
        ]
      },   

      {displayName: 'Batch Management', sortOrder: 2, path: '/', depth: 0, children:
        [
          {displayName: 'Create Batch Load', sortOrder: 1, path: '/batchload', depth: 1},
          {displayName: 'Approve Batch Load', sortOrder: 2, path: '/batchloads', depth: 1},
          {displayName: 'Devalue Batch', sortOrder: 3, path: '/batchdevalue', depth: 1},
          {displayName: 'Batch Transfer', sortOrder: 4, path: '/batchtransfer', depth: 1}
        ]
      },

      {displayName: 'Card Management', sortOrder: 3, path: '/', depth: 0, testid: 'card-management', children:
        [
          {displayName: 'Find Card', sortOrder: 1, path: '/card/search', depth: 1, testid: 'find-card'},
          {displayName: 'Order Card', sortOrder: 2, path: '/card/order', depth: 1, hidden: true},
          {displayName: 'Order Virtual Card', sortOrder: 2, path: '/card/virtual-card-order', depth: 1, testid: 'virtual-card-order', hidden: true},
          {displayName: 'Activate Card', sortOrder: 3, path: '/activateCards', depth: 1, hidden: true},
          {displayName: 'Insert Card Fee', sortOrder: 4, path: '/card/insertfee', depth: 1, hidden: Config.hideFees},
          {displayName: 'Approve Virtual Card Orders', sortOrder: 5, path: '/card/virtual-card-orders', depth: 1, testid: 'virtual-cards-approval'}
        ]
      },

      {displayName: 'Reports', sortOrder: 4, path: '/reports', depth: 0, children: []}
    ]
    const navTree = []
    routeUrls.forEach(url => {
      if (!url.hidden) {
        navTree.push(url)
      }
    })
    return <NavItems items={navTree} navigate={this.navigate} />
  }

  render() {
    const isLoggedIn = (typeof this.props.token !== 'undefined' && this.props.token !== null)

    return (
      <DefaultDrawer navigate={this.navigate}>
        {isLoggedIn && <List component="nav">{this.navigationItems()}</List>}
      </DefaultDrawer>
    )
  }
}

const ThemedSideBar = withStyles(styles)(SideBar)

const mapStateToProps = (state, props) => {
  return {
    token: state.auth.token
  }
}

SideBar.propTypes = {
  token: PropTypes.string,
  push: PropTypes.func
}

export default connect(
  mapStateToProps,
  { push }
)(ThemedSideBar)


