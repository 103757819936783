import React, { Component, Fragment } from 'react'
import Popover from '@material-ui/core/Popover'
import Button from '@material-ui/core/Button'
import IconButton from '@material-ui/core/IconButton'
import Icon from '@material-ui/core/Icon'
import TextField from '@material-ui/core/TextField'
import Tooltip from '@material-ui/core/Tooltip'

const buttonTooltipDelay = 800

class BatchLoadsApproveDialog extends Component {
  state = {
    anchorEl: null,
    processDate: '',
    processTime: ''
  };

  handleClick = event => {
    this.setState({
      anchorEl: event.currentTarget
    });
  };

  handleClose = () => {
    this.setState({
      anchorEl: null
    });
  };
  
  handleConfirm() {
    if (
        (this.state.processDate.length === 0 && this.state.processTime.length === 0)
      ||
        (this.state.processDate.length > 0 && this.state.processTime.length === 5)
      ) {
      this.handleClose()
      const processDate = (this.state.processDate.length > 0 && this.state.processTime.length === 5) ?
        this.state.processDate + ' ' + (this.state.processTime || "00:00")  + ':00'
      :
        null
      this.props.batchLoadApprove(this.props.batchLoadFileID, processDate)
    }
  }

  handleInputChange = (event) => {
    const stateKey = event.target.name
    this.setState({ [stateKey]: event.target.value })
  }

  render(){
    const { anchorEl } = this.state
    const { approveText, cancelText, disabled, tooltip } = this.props
    const dateError = (this.state.processTime.length === 5 && this.state.processDate.length === 0) ? true : false
    const timeError =  (this.state.processDate.length > 0 && this.state.processTime.length < 5) ? true : false
    return (
      <Fragment>
        <Tooltip title={ tooltip ? tooltip : 'Approve batch load' } enterDelay={buttonTooltipDelay}>
          <span>
            <IconButton onClick={this.handleClick} disabled={ disabled } aria-label="Approve" >
              <Icon>check</Icon>
            </IconButton>
          </span>
        </Tooltip>
        <Popover
          open={Boolean(anchorEl)}
          anchorEl={anchorEl}
          onClose={this.handleClose}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "center"
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "center"
          }}
        >
          <div style={{padding:'40px'}} >
                Load immediately or load on: 
              <TextField
                name="processDate"
                type="date"
                onChange={this.handleInputChange}
                error={dateError}
              />
              <TextField
                name="processTime"
                type="time"
                onChange={this.handleInputChange}
                inputProps={{
                  step: 3600
                }}
                error={ timeError }
              />
            <br/><br />
            <div style={{width:'100%', display:'flex', flexFlow:'row no-wrap', justifyContent:'space-around'}}>
              <Button size='small' onClick={()=>this.handleClose()} variant="contained">
                {cancelText || 'Cancel'}
              </Button>
              <Button size='small' onClick={()=>this.handleConfirm()} variant="contained">
                {approveText || 'Approve Batch Load'}
              </Button>
            </div>
          </div>
        </Popover>
      </Fragment>
    )
  }
}

export default BatchLoadsApproveDialog
