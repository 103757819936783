import { apiCall } from './api'

export const CARD_DETAILS = 'CARD_DETAILS'

export function cardDetails(voucherID) {
  const payload = apiCall('cardDetails', {voucherID})
  return {
    type: CARD_DETAILS,
    payload,
    meta: {
      voucherID
    }
  }
}

export const CARD_DETAILS_ERROR_MESSAGE = 'CARD_DETAILS_ERROR_MESSAGE'

export function cardDetailsErrorMessage () {
  return {
    type: CARD_DETAILS_ERROR_MESSAGE
  }
}

export const CARD_STATEMENT = 'CARD_STATEMENT'
export const cardStatement = (token, voucherID, startDate, endDate) => ({
  type: CARD_STATEMENT,
  payload: apiCall('cardStatement',{token, voucherID, startDate, endDate})
})

export const CARD_DETAILS_CLEAR = 'CARD_DETAILS_CLEAR'
export const cardDetailsClear = () => ({
  type: CARD_DETAILS_CLEAR
})

export const GET_SETTLEMENT_CHARGEBACKS = 'GET_SETTLEMENT_CHARGEBACKS'

export function getSettlementChargebacks(authorisationId) {
  const payload = apiCall('getSettlementChargebacks', {authorisationId})
  return {
    type: GET_SETTLEMENT_CHARGEBACKS,
    payload,
    meta: {
      authorisationId
    }
  }
}

export const CLEAR_SETTLEMENT_CHARGEBACKS = 'CLEAR_SETTLEMENT_CHARGEBACKS'
export const clearSettlementChargebacks = () => ({
  type: CLEAR_SETTLEMENT_CHARGEBACKS
})
