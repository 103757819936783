import React, { Component, Fragment } from 'react'
import Popover from '@material-ui/core/Popover'
import Button from '@material-ui/core/Button'
import IconButton from '@material-ui/core/IconButton'
import Icon from '@material-ui/core/Icon'
import TextField from '@material-ui/core/TextField'

class BatchFileElementEditDialog extends Component {
  state = {
    anchorEl: null
  };

  handleClick = event => {
    this.setState({
      anchorEl: event.currentTarget,
      batchFileElementValue: this.props.batchFileElementValue
    });
  };

  handleClose = () => {
    this.setState({
      anchorEl: null
    });
  };
  
  handleConfirm(batchFileRowNumber, batchFileElementValue) {
    this.props.batchFileElementUpdate(batchFileRowNumber, batchFileElementValue, this.handleClose)
  }

  handleRemove(batchFileRowNumber) {
    this.props.batchFileElementDelete(batchFileRowNumber, this.handleClose)
  }

  handleInputChange = (event) => {
    const stateKey = event.target.name
    this.setState({ [stateKey]: event.target.value })
  }

  render(){
    const { anchorEl } = this.state
    const { approveText, removeText, disabled } = this.props

    return (
      <Fragment>
        <IconButton onClick={this.handleClick} disabled={ disabled } aria-label="Approve" >
          <Icon>edit</Icon>
        </IconButton>
        <Popover
          open={Boolean(anchorEl)}
          anchorEl={anchorEl}
          onClose={this.handleClose}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "center"
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "center"
          }}
          classes={{paper : 'popover-paper'}}
        >
          <span className="popover-close" onClick={()=>this.handleClose()}>x</span>
          <div style={{padding:'40px'}} >
            Modify identifier: 
            <TextField 
              value={this.state.batchFileElementValue}
              name="batchFileElementValue"
              placeholder=""
              onChange={this.handleInputChange}
            />
            <br/><br />
            <div style={{width:'100%', display:'flex', flexFlow:'row no-wrap', justifyContent:'space-around'}}>
              <Button size='small' onClick={()=>this.handleRemove(this.props.batchFileRowNumber)} variant="contained">
                {removeText || 'Remove'}
              </Button>
              <Button size='small' onClick={()=>this.handleConfirm(this.props.batchFileRowNumber, this.state.batchFileElementValue)} variant="contained">
                {approveText || 'Save'}
              </Button>
            </div>
          </div>
        </Popover>
      </Fragment>
    )
  }
}

export default BatchFileElementEditDialog
