import React from 'react'
import { MuiThemeProvider, createMuiTheme, withTheme } from '@material-ui/core/styles'
import Header from '../../component/default/layout/Header'
import ProgressBar from '../../component/default/layout/ProgressBar'
import SideBar from '../../component/agoda/layout/SideBar'
import DefaultApp from '../default/App'
import monoLogoImage from '../../images/agoda/logo-mono.png'
import { connect } from 'react-redux'
import { getUser } from '../../action/currentUser'
import { withRouter } from 'react-router-dom'
import malloryWoff2 from '../../fonts/Mallory-Book.woff2';
import CssBaseline from '@material-ui/core/CssBaseline';

const mallory = {
  fontFamily: 'mallory',
  fontStyle: 'normal',
  fontDisplay: 'swap',
  fontWeight: 400,
  src: `
    local('Mallory'),
    local('Mallory Book Regular'),
    url(${malloryWoff2}) format('woff2')
  `
};

const agodaTheme = theme => createMuiTheme({
  typography: {
    fontFamily: [
      'mallory',
      '"Helvetica Neue"',
      'Helvetica',
      'Arial',
      'sans-serif'
    ].join(','),
    fontSize:11,
    useNextVariants: true,
    suppressDeprecationWarnings: true
  },
  palette: {
    primary:{main:'rgb(20,169,224)'},
    secondary: { main: 'rgb(34,40,102)' },
    type: 'light',
    backgroundColor: 'rgb(255,255,255)',
    primary1Color: 'rgb(20,169,224)',   // teal
    primary2Color: 'rgb(34,40,102)',    // navy
    primary3Color: 'rgb(140,88,166)',   // purple
    accent1Color: 'rgb(255,255,255)',   // white
    accent2Color: 'rgb(255,178,2)',     // burnt yellow
    accent3Color: 'rgb(31,195,52)',     // green
    lightSilver: 'rgb(243,243,243)', // light silver */
    paperWrapper: {
      margin: '50px',
      padding:'50px'
    },
    input: {
      marginLeft: '20px'
    },
    startEndDateInput:{
      marginLeft: '20px',
      width: '30%'
    },
    campaignSelect: {
      margin: '0 20px',
      width: '20%'
    }, 
    card: {
      textAlign: 'center',
      padding: '20px'
    },
    tableWrapper: {
      display: 'block',
      overflowX: 'auto',
      whiteSpace: 'nowrap'
    },
    button: {
      color: 'white',
      marginRight: '20px'
    },
    modal: (width, height) => ({
      top: `calc(50% - ${height}px)`,
      left: `calc(50% - ${width}px)`,
      transform: `translate(50%, 50%)`,
      position: 'absolute',
      width: `${width}px`,
      height: `${height}px`,
      backgroundColor: theme.palette.background.paper,
      boxShadow: theme.shadows[5],
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center'
    })
  },
  overrides: {
    MuiSnackbarContent: {
      root: {
        backgroundColor: 'white',
        color: 'grey'
      }
    },
    MuiCssBaseline: {
      '@global': {
        '@font-face': [mallory],
      },
    },
  }
})
class App extends DefaultApp {

  componentDidUpdate = (prevProps) => {
    const loggedOutPaths = [
      '/login',
      '/forgotPassword',
      '/user/:uuid/activate',
      ''
    ]
    if(prevProps.router.location.pathname !== this.props.router.location.pathname && !loggedOutPaths.includes(this.props.router.location.pathname)){
      this.props.getUser()
    }
  }

  render() {
    return (
      <MuiThemeProvider theme={agodaTheme(this.props.theme)}>
        <CssBaseline />
        <div className="container">
          <div className="class-root">
            <Header className="header" title={<img src={monoLogoImage} alt='AGODA Portal' />} >
            </Header>
            <SideBar />
            <div className="class-content-spaced">
              <ProgressBar />
              {this.props.children}
            </div>
          </div>
        </div>
        <CssBaseline />
      </MuiThemeProvider>
    )
  }
}

const mapStateToProps = (state, props) => {
  return {
    currentUser: state.currentUser,
    router : state.router
  }
}


const setDispatchToProps = {
  getUser
}

export default connect(mapStateToProps,setDispatchToProps)(withRouter(withTheme(App)))
