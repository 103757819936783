import 'whatwg-fetch'
import Config from '../config'
import { store } from '../store'

export function apiCall(method, params, requireToken = true) {
  if (requireToken) {
    params.token = store.getState().auth.token
  }
  return fetch(Config.apiURL, {
    method: 'POST',
    headers: {
      'Accept': 'application/json',
      'Content-Type': 'application/json'
    },
    body: JSON.stringify({ method, params })
  })
  .then(response => response.json())
  .catch(error => ({error}) )
}

export const API_RESET = 'API_RESET'

export function apiReset() {
  return {
    type: API_RESET
  }
}
