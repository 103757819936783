import { GET_USER_CAMPAIGNS } from '../action/userCampaigns'

const initialState = {
	errorMsg: '',
	campaigns : []
}

const userCampaigns = (state = initialState, action) => {
	const {type, payload} = action
	switch (type) {
		case `${GET_USER_CAMPAIGNS}_FULFILLED`:
			if (payload.resultCode === "0") {
				return {
					...state,
					campaigns: payload.campaigns,
					errorMsg: ''
				}
			} else {
				return {
					...state,
					errorMsg: payload.resultText
				}
			}
		case `${GET_USER_CAMPAIGNS}_PENDING`:
			return {
				...state,
				errorMsg: ''
			}
		case `${GET_USER_CAMPAIGNS}_REJECTED`:
			return {
				...state,
				errorMsg: 'Cannot connect to server'
			}
		default:
			return state
	}


}

export default userCampaigns