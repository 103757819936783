import React, { Component } from 'react'
import { connect } from 'react-redux'
import {
  logIn,
  MFA_REQUIRED,
  MFA_NOT_ENROLLED,
  MFA_ENROLMENT_COMPLETE,
  confirmEnrolment,
  resetMFA
} from '../../action/auth'
import { Redirect } from 'react-router'
import Login from './Login'
import EnrollUser from './EnrollUser'
import VerifyCode from './VerifyCode'


const initialState = {
  username : '',
  password : '',
  challenge: '',
  isResent: false
}

class MFALogin extends Component {

  state = initialState

  onInputChange = (event) => {
    const stateKey = event.target.getAttribute("name")
    this.setState({ [stateKey]: event.target.value })
    this.setState({isResent: false})
  }

  onLogIn = () => {
    this.props.logIn(this.state.username, this.state.password, this.state.challenge)
  }

  onResendOTP = () => {
    this.props.logIn(this.state.username, this.state.password, '')
  }

  confirmEnrolment = () => {
    this.props.confirmEnrolment(this.state.username, this.state.password, this.state.challenge)
  }

  resetLogin = () => {
    this.setState(initialState)
    this.props.resetMFA()
  }

  render() {
    if ((this.props.auth) && (this.props.auth.token)) {
      return <Redirect to="/" />
    }

    if ( (this.props.auth) &&
         (this.props.auth.mfaStatus === MFA_NOT_ENROLLED || this.props.auth.mfaStatus === MFA_ENROLMENT_COMPLETE)
    ){
      return (
        <EnrollUser
          username={this.state.username}
          password={this.state.password}
          onInputChange={this.onInputChange}
          confirm={this.confirmEnrolment}
          reset={this.resetLogin}
        />)
    } else if ((this.props.auth) && (this.props.auth.mfaStatus === MFA_REQUIRED)){
      return (
        <VerifyCode
          onInputChange={this.onInputChange}
          confirm={this.onLogIn}
          resendOTP={this.onResendOTP}
          reset={this.resetLogin}
          isSubmitDisabled={this.state.challenge.length === 0}
        />)
    } else {
      return (
        <Login
          onInputChange={this.onInputChange}
          onLogIn={this.onLogIn}
          username={this.state.username}
          password={this.state.password}
        />)
    }
  }

}

const mapStateToProps = (state, props) => {
  return {
    auth: state.auth
  }
}

const mapDispatchToProps = {
  logIn: logIn,
  confirmEnrolment: confirmEnrolment,
  resetMFA: resetMFA
}


export default connect(mapStateToProps, mapDispatchToProps)(MFALogin)
