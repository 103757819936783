import { apiCall } from '../api'

export const TOGGLE_MAGSTRIPE = 'TOGGLE_MAGSTRIPE'
export function toggleMagstripe(voucherNumber, status) {
  const payload = apiCall('toggleMagstripe', { voucherNumber, status })
  return {
    type: TOGGLE_MAGSTRIPE,
    payload,
    meta: {
      voucherNumber,
      status
    }
  }
}

export const TOGGLE_MAGSTRIPE_ERROR_MESSAGE = 'TOGGLE_MAGSTRIPE_ERROR_MESSAGE'
export function toggleMagstripeErrorMessage() {
  return {
    type: TOGGLE_MAGSTRIPE_ERROR_MESSAGE
  }
}

export const TOGGLE_MAGSTRIPE_CLEAR = 'TOGGLE_MAGSTRIPE_CLEAR'
export function toggleMagstripeClear () {
  return {
    type: TOGGLE_MAGSTRIPE_CLEAR
  }
}
