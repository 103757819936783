import React, { Component, Fragment } from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import Popover from '@material-ui/core/Popover'
import Button from '@material-ui/core/Button'
import IconButton from '@material-ui/core/IconButton'
import TextField from '@material-ui/core/TextField'
import Tooltip from '@material-ui/core/Tooltip'
import InfoOutlined from "@material-ui/icons/InfoOutlined"
import Info from "@material-ui/icons/Info"
import { batchLoadUpdateClosedComments } from '../../../action/batchLoads'

const buttonTooltipDelay = 800
const closedCommentsCharLimit = 255

class BatchLoadsCloseDialog extends Component {

  state = {
    anchorEl: null,
    batchLoadClosedComments: this.props.batchLoadClosedComments
  };

  handleClick = event => {
    this.setState({
      anchorEl: event.currentTarget
    });
  };

  handleClose = () => {
    this.setState({
      anchorEl: null
    });
  };
  
  handleConfirm() {
    this.props.batchLoadUpdateClosedComments(
        this.props.batchLoadFileID,
        this.state.batchLoadClosedComments
    )
    this.handleClose()
  }

  handleInputChange = (event) => {
    const stateKey = event.target.name
    this.setState({ [stateKey]: event.target.value })
  }

  render(){
    const { anchorEl } = this.state
    return (
      <Fragment>
        <Tooltip title="Close comments" enterDelay={buttonTooltipDelay}>
          <span>
            <IconButton onClick={this.handleClick} aria-label="Comments" >
              { (!this.props.batchLoadClosedComments) ? <InfoOutlined/> : <Info/> }
            </IconButton>
          </span>
        </Tooltip>
        <Popover
          open={Boolean(anchorEl)}
          anchorEl={anchorEl}
          onClose={this.handleClose}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "center"
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "center"
          }}
        >
          <div style={{padding:'40px', width: '300px'}} >
            <TextField
              name="batchLoadClosedComments"
              label={"Closed comments (" + closedCommentsCharLimit + " chars max)"}
              multiline
              rowsMax="4"
              value={this.state.batchLoadClosedComments}
              onChange={this.handleInputChange}
              margin="normal"
              inputProps={{
                maxLength: closedCommentsCharLimit,
              }}
              fullWidth
            />
            <br/><br/>
            <div style={{width:'100%', display:'flex', flexFlow:'row no-wrap', justifyContent:'space-around'}}>
              <Button size='small' onClick={()=>this.handleConfirm()} variant="raised" >
                Save
              </Button>
            </div>
          </div>
        </Popover>
      </Fragment>
    )
  }
}



BatchLoadsCloseDialog.propTypes = {
  batchLoadUpdateClosedComments: PropTypes.func
}

export default connect(
  null,
  {
    batchLoadUpdateClosedComments
  }
)(BatchLoadsCloseDialog)
