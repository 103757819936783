import React, {Component} from 'react'
import TableRow from '@material-ui/core/TableRow'
import TableCell from '@material-ui/core/TableCell'
import CurrencyFormat from 'react-currency-format'
import moment from 'moment'
import Config from "../../../config"

export class BatchLoadDetailsResultsRow extends Component {
  render() {
    const loadDate = this.props.batchLoadDetailsResult.LOADDATE ?
      moment(this.props.batchLoadDetailsResult.LOADDATE).format('YYYY-MM-DD hh:mm:ss') :
      '';

    return (
      <TableRow>
        <TableCell>{this.props.batchLoadDetailsResult.IDENTIFIER}</TableCell>
        <TableCell><CurrencyFormat value={this.props.batchLoadDetailsResult.AMOUNT} displayType={'text'} thousandSeparator={true} prefix={'$'} /></TableCell>
        {(!Config.hideFees) ? <TableCell><CurrencyFormat value={this.props.batchLoadDetailsResult.FEEAMOUNT ? this.props.batchLoadDetailsResult.FEEAMOUNT : '0'} displayType={'text'} thousandSeparator={true} prefix={'$'} /></TableCell> : null}
        <TableCell>{this.props.batchLoadDetailsResult.RESULTTEXT}</TableCell>
        <TableCell>{this.props.batchLoadDetailsResult.LOADED}</TableCell>
        <TableCell>{loadDate}</TableCell>
      </TableRow>
    )
  }
}
