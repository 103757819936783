import { GET_FEE_TYPES, INSERT_CARD_FEE, RESET_CARD_FEE_STATUS } from '../action/cardFee'
const initialState = {
  types:[],
  status: 'none',
  pending: false,
  insertFeeResult: {}
}


export default (state = initialState, action) => {
  switch (action.type) {
    case GET_FEE_TYPES + '_FULFILLED':
      return {
        ...state,
        types:{
          ...state.types,
          ...action.payload || {}
        }
      }
    case INSERT_CARD_FEE + '_PENDING':
      return {
        ...state,
        pending:true
      }
    case INSERT_CARD_FEE + '_FULFILLED':
      return {
        ...state,
        status: action.payload.resultCode === '0' ? 'success': 'failed',
        insertFeeResult: action.payload.insertCardFeeResult,
        pending: false
      }
    case RESET_CARD_FEE_STATUS:
      return {
        ...state,
        status: initialState.success,
        pending: initialState.pending
      }
    default:
      return state
  }
}
