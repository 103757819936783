import React from 'react'
import Wrapper from '../../../component/default/layout/Wrapper'
import CSVReport from '../../../component/agoda/reports/CSVReport/CSVReport'
import transform from '../../../component/agoda/reports/CSVReport/transform'

const DailySettlementDetail = () => <div>
  <Wrapper>
    <CSVReport 
      type="dailySettlementDetail" 
      reportHeader="Daily Settlement Detail"
      transform={{
        "Transaction Amount (SettlementAmount)": transform.fixDecimalPlaces,
        "TransactionAmount(CardholderCurrency)": transform.centToMoney
      }}
    />
  </Wrapper>
</div>

export default DailySettlementDetail
