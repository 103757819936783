import { REVERSE_TRANSACTION, RESET_REVERSE_TRANSACTION} from "../action/transaction"

const initialState = {
  success: false,
  errorMsg: ''
}

export default  (state = initialState, action) => {
  const { type, payload } = action
  switch (type) {
    case `${REVERSE_TRANSACTION}_FULFILLED`:
      if (payload.resultCode === "0") {
        return {
          ...state,
          success: true
        }
      } else {
        return {
          ...state,
          success: false,
          errorMsg: payload.resultText
        }
      }

    case `${REVERSE_TRANSACTION}_PENDING`:
      return {
        ...state
      }
    case `${REVERSE_TRANSACTION}_REJECTED`:
      return {
        ...state,
        errorMsg: 'Cannot connect to server'
      }
    case RESET_REVERSE_TRANSACTION:
      return {
        initialState
      }
    default:
      return state
  }

}
