export default
{
  centToMoney: (cell) => {
    const decimalPlaces = 2 
    const divider = Math.pow(10,decimalPlaces)
    return cell.includes('.') ? parseFloat(cell).toFixed(decimalPlaces) : (parseFloat(cell)/divider).toFixed(decimalPlaces)
  },
  
  fixDecimalPlaces: (cell) => {
    const decimalPlaces = 2
    if (isNaN(cell) || cell === 'NaN' || cell === '') {
      return ''
    } else {
      return parseFloat(cell).toFixed(decimalPlaces)
    }
  }
}
