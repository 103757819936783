import React, { Component } from 'react'
import Icon from '@material-ui/core/Icon'
import Card from '@material-ui/core/Card'
import CardHeader from '@material-ui/core/CardHeader'
import Typography from '@material-ui/core/Typography'
import Avatar from '@material-ui/core/Avatar'
import { push } from 'connected-react-router'
import { connect } from 'react-redux'

class ReportGridList extends Component {

	navigate = (path) => {
		const { push } = this.props
		push(path)
	}

	render() {

		const reportData = [
			{
				name: 'Card Balance Report',
				route: '/reports/cardBalanceReport',
				description: 'View detailed card balances',
				icon: 'payment'
			},
			{
				name: 'Cardholder Statement',
				route: 'reports/cardholderStatement',
				description: 'View detailed card statements',
				icon: 'person_outline'
			},
			{
				name: 'Detailed Daily Settlement Report',
				route: '/reports/dailySettlementDetail',
				description: 'View settlements on a daily basis',
				icon: 'done'
			},
			{
				name: 'Summary Daily Settlement Report',
				route: '/reports/dailySettlementSummary',
				description: 'View summary settlements on a daily basis',
				icon: 'account_balance'
			},
			{
				name: 'Quarterly MasterCard Report',
				route: '/reports/qmr',
				description: 'View a detailed breakdown of MasterCard transactions',
				icon: 'payment'
			},
			{
				name: 'Unsettled Transaction Report',
				route: 'reports/unsettledTransactions',
				description: 'View a breakdown of daily unsettled transactions',
				icon: 'alarm'
			},
			{
				name: 'Chargeback Report',
				route: 'reports/chargebackReport',
				description: 'View chargeback status and complete date',
				icon: 'money_off'
			},
			{
				name: 'Card Spend Report',
				route: 'reports/cardSpendReport',
				description: 'View Detailed Card Spend report',
				icon: 'money'
			},
			{
				name: 'Spend Summary Report',
				route: 'reports/spendSummaryReport',
				description: 'Spend Summary report',
				icon: 'money'
			},
			{
				name: 'Detailed Top up Report',
				route: 'reports/detailedTopupReport',
				description: 'Detailed Top up report',
				icon: 'money'
			},
			{
				name: 'Detailed Devalue Report',
				route: 'reports/devalueReport',
				description: 'Detailed devalue report',
				icon: 'money'
			},
			{
				name: 'Negative Balance Report',
				route: 'reports/negativeBalanceReport',
				description: 'Contains all cards with a negative balance',
				icon: 'trending_down'
			},
			{
				name: 'Settlements Without Authorisations Report',
				route: 'reports/settlementsWithoutAuthorisationsReport',
				description: 'View Settlements without Authorisations',
				icon: 'receipt'
			}
		]

		//sort the reports alphabetically
		reportData.sort((a,b) => {
			return a.name > b.name
		})

		return (
			<div>
				<Card style={{padding:'40px'}}>
					<Typography gutterBottom variant="h5">Available Reports</Typography>
					<Typography component="p">Please see below for the list of available reports.  Currently there are {reportData.length} reports available, please scroll down to see the entire list.</Typography>
	          	</Card><br />
				{reportData.map(report => (
					<Card className="reportcard" key={report.route} onClick={()=> this.navigate(report.route)}>
						<CardHeader
				            avatar={
				              <Avatar aria-label={report.name}>
				                <Icon className="reportcardicon" color="primary">{report.icon}</Icon>
				              </Avatar>
				            }
							classes={{
								title: 'reportcardheadertext',
								subheader: 'reportcardheadertext',
							}}
				            title={report.name}
				            subheader={report.description}
				          />
					</Card>
				))}
			</div>
		)
	}

}

const mapStateToProps = (state, props) => {
  return {}
}

export default connect(mapStateToProps, { push })(ReportGridList)
