import { CARD_NOTE, CARD_NOTE_RESET } from '../action/cardNote'
const initialState = {
  pending: false,
  errorMsg : '',
}


export default (state = initialState, action) => {
  const { type, payload } = action
  switch (type) {
    case CARD_NOTE + '_PENDING':
      return {
        ...state,
        pending: true
      }
    case CARD_NOTE + '_FULFILLED':
      if (payload.resultCode === '0') {
        return {
          ...state,
          savedNotes: JSON.parse(payload.resultText),
          pending: false,
        }
      } else {
        return {
          ...state,
          errorMsg: payload.resultText,
          pending: false,
        }
      }
    case `${CARD_NOTE}_REJECTED`:
      return {
        ...state,
        pending: false,
        errorMsg: 'Cannot connect to server'
      }
    case CARD_NOTE_RESET:
      return {
        ...initialState,
      }
    default:
      return state
  }
}
