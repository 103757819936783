import { USER_DELETE, USER_SEARCH, USER_UNLOCK, RESEND_ACTIVATION, RESET_OTP, RESET_SUCCESS, RESET_ERROR } from "../action/userManagement"

const initialState = {
	errorMsg: '',
	searchResults: [],
	successMessage: ''
}

const actionsMap = {
	[USER_SEARCH + '_FULFILLED']: (state, payload) => {
		if (payload.resultCode === "0") {
			return {
				...state,
				searchResults: payload.users,
				errorMsg: ''
			}
		} else {
			return {
				...state,
				errorMsg: payload.resultText
			}
		}
	},
	[USER_SEARCH + '_PENDING']: state => ({
		...state,
		errorMsg: ''
	}),
	[USER_SEARCH + '_REJECTED']: state => ({
		...state,
		errorMsg: 'Cannot connect to server'
	}),
	[USER_DELETE + '_FULFILLED']: (state, payload, action) => {
		if (payload.resultCode === "0") {
			return {
				...state,
				searchResults: state.searchResults.filter((item) => item.userID !== action.meta.id),
				errorMsg: '',
				successMessage: 'User successfully removed'
			}
		} else {
			return {
				...state,
				errorMsg: payload.resultText
			}
		}
	},
	[USER_DELETE + '_PENDING']: state => ({
		...state,
		errorMsg: ''
	}),
	[USER_DELETE + '_REJECTED']: state => ({
		...state,
		errorMsg: 'Cannot connect to server'
	}),
	[USER_UNLOCK + '_FULFILLED']: (state, payload) => {
		if (payload.resultCode === "0") {
			return {
				...state,
				errorMsg: '',
				successMessage: 'User successfully unlocked'
			}
		} else {
			return {
				...state,
				errorMsg: payload.resultText
			}
		}
	},
	[USER_UNLOCK + '_PENDING']: state => ({
		...state,
		errorMsg: ''
	}),
	[USER_UNLOCK + '_REJECTED']: state => ({
		...state,
		errorMsg: 'Cannot connect to server'
	}),
	[RESEND_ACTIVATION + '_FULFILLED']: (state, payload) => {
		if (payload.resultCode === "0") {
			return {
				...state,
				errorMsg: '',
				successMessage: 'Activation email successfully sent'
			}
		} else {
			return {
				...state,
				errorMsg: payload.resultText
			}
		}
	},
	[RESEND_ACTIVATION + '_PENDING']: state => ({
		...state,
		errorMsg: ''
	}),
	[RESEND_ACTIVATION + '_REJECTED']: state => ({
		...state,
		errorMsg: 'Cannot connect to server'
	}),
	[RESET_OTP + '_FULFILLED']: (state, payload) => {
		if (payload.resultCode === "0") {
			return {
				...state,
				errorMsg: '',
				successMessage: 'OTP successfully reset'
			}
		} else {
			return {
				...state,
				errorMsg: payload.resultText
			}
		}
	},
	[RESET_OTP + '_PENDING']: state => ({
		...state,
		errorMsg: ''
	}),
	[RESET_OTP + '_REJECTED']: state => ({
		...state,
		errorMsg: 'Cannot connect to server'
	}),
	[RESET_SUCCESS]: state => ({
		...state,
		successMessage: ''
	}),
	[RESET_ERROR]: state => ({
		...state,
		errorMsg: ''
	})
}

export default function reducer(state = initialState, action = {}) {
	const fn = actionsMap[action.type]
	return fn ? fn(state, action.payload, action) : state
}
