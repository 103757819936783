import React, { Component } from 'react'
import blue from '@material-ui/core/colors/blue'
import red from '@material-ui/core/colors/red'
import green from '@material-ui/core/colors/green'
import Snackbar from '@material-ui/core/Snackbar'
import IconButton from '@material-ui/core/IconButton'
import Icon from '@material-ui/core/Icon'


const style = {
  normal: { color : blue[500] },
  error: { color : red[500] },
  success: { color : green[500] }
}

class Notification extends Component {

  constructor(props) {
    super(props)
    this.initialState = {
      vertical: 'top',
      horizontal: 'center',
      type: 'normal',
      autoClose: false,
      autoCloseTime: 5000
    }
    this.state = {
      ...this.initialState,
      open: this.props.open || this.initialState.open,
      type: this.props.type || this.initialState.type,
      autoClose: this.props.autoClose || this.initialState.autoClose,
      autoCloseTime: this.props.autoCloseTime || this.initialState.autoCloseTime
    }
  }

  handleClose = () => {
    this.props.closed ? this.props.closed() : (() => {})()
  }

  render() {
    const  { vertical, horizontal, type } = this.state
    const action = this.state.autoClose ?
        null
      :
        [
          <IconButton
            key="close"
            aria-label="Close"
            color="inherit"
            onClick={this.handleClose}
          >
            <Icon>close</Icon>
          </IconButton>,
        ]

    if (this.state.autoClose && this.props.open) {
      setTimeout(() => this.handleClose.apply(this), this.state.autoCloseTime);
    }

    return (
      <Snackbar
        anchorOrigin={{ vertical, horizontal }}
        open={this.props.open}
        message={<span id="notification-message" style={ style[type] }>{ this.props.message }</span>}
        action={action}
      />
    )
  }
}

export default Notification
