import React, { Component } from 'react'
import PropTypes from 'prop-types'
import Table from '@material-ui/core/Table'
import TableHead from '@material-ui/core/TableHead'
import TableFooter from '@material-ui/core/TableFooter'
import TableBody from '@material-ui/core/TableBody'
import TableRow from '@material-ui/core/TableRow'
import TableCell from '@material-ui/core/TableCell'
import TablePagination from '@material-ui/core/TablePagination'
import VirtualCardOrdersDetailsTableBody from './VirtualCardOrdersDetailsTableBody'

class VirtualCardOrdersDetailsTable extends Component {
  state = {
    page: 0,
    rowsPerPage: 10,
  }

  handleChangePage = (event, page) => {
    this.setState({ ...this.state, page })
  }

  handleChangeRowsPerPage = (event) => {
    this.setState({ ...this.state, rowsPerPage: event.target.value })
  }

  render() {
    const { virtualCardsOrders } = this.props
    const { rowsPerPage, page } = this.state

    const count = virtualCardsOrders.length
    const totalPages = Math.ceil(count / rowsPerPage)
    const currentPage = totalPages > 0 ? page + 1 : 0

    return (
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>Customer Reference</TableCell>
            <TableCell>Card Label</TableCell>
            <TableCell>Notification Number</TableCell>
            <TableCell>Expiry Date</TableCell>
            <TableCell>Load Amount</TableCell>
            <TableCell>Tag Name</TableCell>
            <TableCell>Tag Value</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          <VirtualCardOrdersDetailsTableBody
            virtualCardsOrders={virtualCardsOrders}
          />
        </TableBody>
        <TableFooter>
          <TableRow>
            <TablePagination
              colSpan={12}
              count={count}
              rowsPerPage={rowsPerPage}
              page={page}
              labelDisplayedRows={() => currentPage + ' of ' + totalPages}
              onChangePage={this.handleChangePage}
              onChangeRowsPerPage={this.handleChangeRowsPerPage}
            />
          </TableRow>
        </TableFooter>
      </Table>
    )
  }
}

VirtualCardOrdersDetailsTable.propTypes = {
  virtualCardsOrders: PropTypes.array,
}

export default VirtualCardOrdersDetailsTable
