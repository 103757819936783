import { apiCall } from './api'
import Config from '../config'

export const LOGIN = 'LOGIN'
export const ENROLL_USER = 'ENROLL_USER'
export const CONFIRM_USER_ENROLMENT = 'CONFIRM_USER_ENROLMENT'
export const RESET_MFA = 'RESET_MFA'


export const MFA_UNKNOWN = 'UNKNOWN'
export const MFA_REQUIRED = 'REQUIRED'
export const MFA_NOT_ENROLLED = 'NOT_ENROLLED'
export const MFA_ENROLMENT_COMPLETE = 'ENROLMENT_COMPLETE'

export function logIn(username, password, challenge) {
  const ownerID = Config.ownerID
  return async function(dispatch) {
    const { value } = await dispatch({
      type: LOGIN,
      payload: apiCall('logIn', { username, password, challenge, ownerID }, false)
    })
    if (value.token) {
      dispatch(getPermissions())
    }
  }
}

export function enrollUser(username, password) {
  const ownerID = Config.ownerID

  const payload = apiCall('enrollUser', { username, password, ownerID })
  return {
    type: ENROLL_USER,
    payload,
    meta: {}
  }
}

export function confirmEnrolment(username, password, challenge) {
  const ownerID = Config.ownerID

  const payload = apiCall('confirm2FAEnrolment', { username, password, challenge, ownerID })
  return {
    type: CONFIRM_USER_ENROLMENT,
    payload,
    meta: {}
  }
}

export function resetMFA() {
  return {
    type: RESET_MFA
  }
}

export const GET_PERMISSIONS = 'GET_PERMISSIONS'

export function getPermissions() {
  return {
    type: GET_PERMISSIONS,
    payload: apiCall('userInfo', {})
  }
}

export const LOGOUT = 'LOGOUT'

export function logOut() {
  // TODO: call backend api
  //const payload = apiCall('logOut', {})
  return {
    type: LOGOUT
  }
}
