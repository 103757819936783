import React, {Component} from 'react'
import Wrapper from '../../component/default/layout/Wrapper'
import BatchLoadForm from '../../component/agoda/batchLoad/BatchLoadForm'

class BatchLoad extends Component {
  render () {
    return (
      <div>
        <Wrapper>
          <BatchLoadForm />
        </Wrapper>
      </div>
    )
  }
}

export default BatchLoad
