import {apiCall} from './api'

export const GET_USER_CAMPAIGNS = 'GET_USER_CAMPAIGNS'

export function getUserCampaigns() {
	const payload = apiCall('GetCampaigns',{})
	return {
		type: GET_USER_CAMPAIGNS,
		payload
	}
}
