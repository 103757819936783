import React from 'react'
import PropTypes from 'prop-types'
import { Table, TableBody, TableCell, TableHead, TableRow } from '@material-ui/core'

import ResultTableRow from './ResultTableRow'

const ResultTable = ({
  virtualCardOrdersBatchLoads,
  onShowDetails,
  onApprove,
  onReject,
  currentUser,
  showApprovedBy,
  showRejectedBy,
}) => {
  return (
    <Table className="approve-batch-loads-table">
      <TableHead>
        <TableRow>
          <TableCell>Campaign</TableCell>
          <TableCell>Date Uploaded</TableCell>
          <TableCell>Uploaded By</TableCell>
          {showApprovedBy && (
            <>
              <TableCell>Approved By</TableCell>
              <TableCell>Approved Date</TableCell>
            </>
          )}
          {showRejectedBy && (
            <>
              <TableCell>Rejected By</TableCell>
              <TableCell>Rejected Date</TableCell>
            </>
          )}
          {!showApprovedBy && !showRejectedBy && <TableCell>Approved</TableCell>}
          <TableCell>No of Cards</TableCell>
          <TableCell>Actions</TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {virtualCardOrdersBatchLoads.map((virtualCardOrder) => (
          <ResultTableRow
            key={virtualCardOrder.virtualCardOrdersBatchLoadId}
            virtualCardOrder={virtualCardOrder}
            showApprovedBy={showApprovedBy}
            showRejectedBy={showRejectedBy}
            approvable={currentUser.userId !== virtualCardOrder.uploadUserId}
            onApprove={() => onApprove(virtualCardOrder.virtualCardOrdersBatchLoadId)}
            onReject={() => onReject(virtualCardOrder.virtualCardOrdersBatchLoadId)}
            onShowDetails={() => onShowDetails(virtualCardOrder.virtualCardOrdersBatchLoadId)}
          />
        ))}
      </TableBody>
    </Table>
  )
}

ResultTable.propTypes = {
  virtualCardOrdersBatchLoads: PropTypes.array,
  showApprovedBy: PropTypes.bool,
  showRejectedBy: PropTypes.bool,
  onShowDetails: PropTypes.func,
  onApprove: PropTypes.func,
  onReject: PropTypes.func,
  currentUser: PropTypes.object,
}

export default ResultTable
