import React, { Component } from 'react'
import PropTypes from 'prop-types'
import Wrapper from '../../../component/default/layout/Wrapper'
import { withTheme } from '@material-ui/core/styles'
import CardVirtualCardOrderForm from '../../../component/agoda/card/CardVirtualCardOrderForm'

class CardVirtualCardOrder extends Component {
  render() {
    return (
      <div>
        <Wrapper style={this.props.theme.palette.paperWrapper}>
          <CardVirtualCardOrderForm />
        </Wrapper>
      </div>
    )
  }
}

CardVirtualCardOrder.propTypes = {
  theme: PropTypes.shape({
    palette: PropTypes.shape({
      paperWrapper: PropTypes.object.isRequired,
    }).isRequired,
  }).isRequired,
}

export default withTheme(CardVirtualCardOrder)
