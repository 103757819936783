import { apiCall } from './api'

export const BATCH_LOAD = 'BATCH_LOAD'

export function batchLoad(batchElements, identifierType, loadReference, fileName, batchLoadOptions) {
  const payload = apiCall('batchLoad', {batchElements, identifierType, loadReference, fileName, batchLoadOptions})
  return {
    type: BATCH_LOAD,
    payload,
    meta: {
      batchElements,
      identifierType,
      loadReference,
      fileName,
      batchLoadOptions
    }
  }
}

export const BATCH_LOAD_ERROR_MESSAGE = 'BATCH_LOAD_ERROR_MESSAGE'

export function batchLoadErrorMessage (errorMessage = null) {
  return {
    type: BATCH_LOAD_ERROR_MESSAGE,
    payload: { resultText: errorMessage }
  }
}

export const BATCH_LOAD_RESET = 'BATCH_LOAD_RESET'

export function batchLoadReset () {
  return {
    type: BATCH_LOAD_RESET
  }
}
