import React from 'react'
import PropTypes from 'prop-types'
import { Input, MenuItem, Select } from '@material-ui/core'
import { FILTER_STATE } from '../../../constants/virtualCardOrder'

export const approvalStatuses = [
  { label: 'Awaiting approval', value: FILTER_STATE.AWAITING_APPROVAL },
  { label: 'Approved', value: FILTER_STATE.APPROVED },
  { label: 'Rejected', value: FILTER_STATE.REJECTED },
]

const StatusSelector = (props) => {
  const { value, onChange } = props

  return (
    <Select
      id="virtual-card-approval-selector"
      className="batch-loads-filter"
      value={value}
      onChange={(e) => {
        onChange(e.target.value)
      }}
      input={<Input name="approve-virtual-card-status" />}
      fullWidth={false}
      data-testid="vco-status-selector"
    >
      {approvalStatuses.map(({ label, value }) => (
        <MenuItem key={value} value={value}>
          {label}
        </MenuItem>
      ))}
    </Select>
  )
}

StatusSelector.propTypes = {
  value: PropTypes.number,
  onChange: PropTypes.func,
}

export default StatusSelector
