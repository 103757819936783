import { SET_USER_PASSWORD,SET_USER_PASSWORD_RESULT,SET_USER_PASSWORD_ERROR_MESSAGE } from '../action/setPassword'


const initialState = {
  values :{
    password: '',
	confirmPassword: ''
  },
  errorMsg:'',
  success: false
}

export default (state = initialState, action) => {
  const { type, payload } = action
  switch(type) {
    case SET_USER_PASSWORD_ERROR_MESSAGE:
      return {
        ...state,
        errorMsg: 'An error occurred'
      }
    case `${SET_USER_PASSWORD}_FULFILLED`:
      if (payload.resultCode === "0") {
        return {
          ...state,
          errorMsg:'',
          success: true
        }
      } else {
        return {
          ...state,
          errorMsg: payload.resultText
        }
      }
    case `${SET_USER_PASSWORD}_PENDING`:
      return {
        ...state,
        errorMsg: '',
        success: false
      }
    case `${SET_USER_PASSWORD}_REJECTED`:
      return {
        ...state,
        errorMsg: 'Cannot connect to server'
      }
    case SET_USER_PASSWORD_RESULT:
      return {
        initialState
      }
    default:
      return state
  }
}
