import React from 'react'
import { Select, MenuItem, InputLabel, FormControl } from '@material-ui/core'

const styles = {
  select:{
    width:'160px'
  }
}

export const QuarterSelect = props => <FormControl style={styles.select}>
  <InputLabel htmlFor="quarter-readonly">Quarter</InputLabel>
  <Select name={props.name} onChange={props.handleChange} value={props.value}>
    <MenuItem value='1'>Quarter1</MenuItem>
    <MenuItem value='2'>Quarter2</MenuItem>
    <MenuItem value='3'>Quarter3</MenuItem>
    <MenuItem value='4'>Quarter4</MenuItem>
  </Select>
</FormControl>

export const YearSelect = props => {
  const yearStartFrom = 1990
  const yearEndAt = 2100
  let yearsArray = []
  for(let yearInc = yearStartFrom ; yearInc < yearEndAt; yearInc++) yearsArray.push(yearInc)
  return <FormControl style={styles.select}>
    <InputLabel htmlFor="year-readonly">Year</InputLabel>
    <Select name={props.name} onChange={props.handleChange} value={props.value}>
      {
        yearsArray.length > 0 && yearsArray.map( year => <MenuItem key={year} value={year.toString()}>{year.toString()}</MenuItem>)
      }
    </Select>
  </FormControl>
}
