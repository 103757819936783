import React, { Component } from 'react'
import { connect } from 'react-redux'
import Card from '@material-ui/core/Card'
import CardContent from '@material-ui/core/CardContent'
import CardActions from '@material-ui/core/CardActions'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'
import TableCell from '@material-ui/core/TableCell'
import Dialog from '@material-ui/core/Dialog'
import Button from '@material-ui/core/Button'
import Typography from '@material-ui/core/Typography'
import download from 'downloadjs'
import merchantListToCSV from '../../../util/csv/merchantListToCSV'
import PrivateSection from '../../../component/default/route/PrivateSection'
import { merchantClearSignUpList } from '../../../action/merchant'
import dateFormat from "dateformat";

class DownloadForm extends Component {
  constructor(props) {
    super(props)
    this.initState = {
      showClearListDialog: false,
      dialogConfirmButtonText: 'Clear',
      dialogText: '',
      removingMerchantIndex: null
    }
    this.state = this.initState
  }

  onDownload = () => {
    // https://stackoverflow.com/questions/14964035/how-to-export-javascript-array-info-to-csv-on-client-side
    const csvContent = merchantListToCSV(this.props.merchantList)
    const dateStr = dateFormat(new Date(),'yyyy-mm-dd_H:M:ss')
    const type = 'text/csv'
    download(
      new Blob([csvContent], { type }),
      `merchant-sign-up-${dateStr}.csv`,
      type
    )
  }

  onShowClearListDialog = (position) => {
    const all = (position === 'all')
    this.setState({
      showClearListDialog: true,
      dialogConfirmButtonText: (all ? 'Clear' : 'Remove'),
      dialogText: `Are you sure you want to remove ${all ? 'these items' : 'this item'}?`,
      removingMerchantIndex: position
    })
  }

  onCloseClearListDialog = () => {
    this.setState(this.initState)
  }

  onClearMerchantList = () => {
    const index = this.state.removingMerchantIndex
    this.onCloseClearListDialog()
    this.props.merchantClearSignUpList(index)
  }


  render() {
    const { merchantList } = this.props
    const { dialogConfirmButtonText, dialogText } = this.state
    const merchantListEmpty = (merchantList.length === 0)
    return (
      <React.Fragment>
        <Card style={{padding:'15px'}}>
          <Typography gutterBottom variant="h5">
            Download Merchant Information
          </Typography>
          <Typography gutterBottom variant="subtitle1">
            Download the information as CSV
          </Typography>
          <CardContent>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>Merchant ID</TableCell>
                  <TableCell>Merchant Name</TableCell>
                  <TableCell></TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                { 
                  merchantList.map((m, i) => (
                    <TableRow selectable={false} key={m.mastercardMerchantID}>
                      <TableCell>{m.mastercardMerchantID}</TableCell>
                      <TableCell>{m.merchantName}</TableCell>
                      <TableCell>
                        <Button label="Remove" onClick={() => this.onShowClearListDialog(i)} />
                      </TableCell>
                    </TableRow>
                  ))
                }
              </TableBody>
            </Table>
          </CardContent>
          <PrivateSection permissions={["MerchantSignUp.write"]}>
            <CardActions>
              <Button children="Download" onClick={this.onDownload} disabled={merchantListEmpty}/>
              <Button children="Clear" onClick={() => this.onShowClearListDialog('all')} disabled={merchantListEmpty}/>
            </CardActions>
          </PrivateSection>
        </Card>
        <Dialog
          title="Clear Merchant Information"
          actions={[
            <Button children={dialogConfirmButtonText} primary={true} onClick={this.onClearMerchantList} />,
            <Button children="Cancel" primary={true} onClick={this.onCloseClearListDialog} />,
          ]} 
          modal={false}
          open={this.state.showClearListDialog}
          onRequestClose={this.onCloseClearListDialog}
        >
          {dialogText}
          </Dialog>
      </React.Fragment>
    )
  }
}

const mapStateToProps = (state, props) => {
  return {
    merchantList: state.merchant.signUpList
  }
}

export default connect(mapStateToProps, { merchantClearSignUpList })(DownloadForm)
