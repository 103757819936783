import { SET_CARD_STATUS, SET_CARD_STATUS_ERROR_MESSAGE, SET_CARD_STATUS_CLEAR } from '../action/card/setCardStatus'

const initialState = {
  errorMsg : '',
  statusResult : false
}

export default (state = initialState, action) => {
  const { type, payload } = action
  switch(type) {
    case SET_CARD_STATUS_ERROR_MESSAGE:
      return {
        ...state,
        errorMsg: 'An error occurred'
      }
    case `${SET_CARD_STATUS}_FULFILLED`:
      if (payload.resultCode === "0") {

        return {
          ...state,
          statusResult: payload.resultText,
          resultStruct: payload.resultStruct
        }
      } else {
        return {
          ...state,
          errorMsg: payload.resultText
        }
      }
    case `${SET_CARD_STATUS}_PENDING`:
      return {
        ...state,
        errorMsg: ''
      }
    case `${SET_CARD_STATUS}_REJECTED`:
      return {
        ...state,
        errorMsg: 'Cannot connect to server'
      }
    case SET_CARD_STATUS_CLEAR:
      return {
        initialState
      }
    default:
      return state
  }
}
