import React from 'react'
import { Component } from 'react'
import Wrapper from '../../component/default/layout/Wrapper'

class Home extends Component {
  render() {
    return (
      <div>
        <Wrapper>
          <div className="card-home-wrapper">            
          </div>
        </Wrapper>
      </div>
    )
  }
}

export default Home
