import React from 'react'
import Wrapper from '../../component/default/layout/Wrapper'
import UserSearchForm from '../../component/agoda/userSearch/UserSearchForm'

const UserSearch = () => {
    return (
      <div>
        <Wrapper>
          <UserSearchForm />
        </Wrapper>
      </div>
    )
}

export default UserSearch
