import React, {Component} from 'react'
import TableRow from '@material-ui/core/TableRow'
import TableCell from '@material-ui/core/TableCell'
import dateFormat from "dateformat"
import CurrencyFormat from 'react-currency-format'

export class FailedTransactionResultsRow extends Component {
  render() {

    return (
      <TableRow>
        <TableCell>{dateFormat(new Date(this.props.failedTransactionResult.FAILEDTRANSACTIONDATE), "dd mmmm yyyy")}</TableCell>
        <TableCell><CurrencyFormat value={this.props.failedTransactionResult.FAILEDTRANSACTIONAMOUNT} displayType={'text'} thousandSeparator={true} prefix={this.props.failedTransactionResult.CAMPAIGNCURRENCYSYMBOLHTML} /></TableCell>
        <TableCell><CurrencyFormat value={this.props.failedTransactionResult.FAILEDTRANSACTIONVOUCHERVALUE ? this.props.failedTransactionResult.FAILEDTRANSACTIONVOUCHERVALUE : '0'} displayType={'text'} thousandSeparator={true} prefix={this.props.failedTransactionResult.CAMPAIGNCURRENCYSYMBOLHTML} /></TableCell>
        <TableCell>{this.props.failedTransactionResult.FAILEDTRANSACTIONDESCRIPTION}</TableCell>
        <TableCell>{this.props.failedTransactionResult.FAILEDTRANSACTIONMETHOD}</TableCell>
        <TableCell>{this.props.failedTransactionResult.FAILEDTRANSACTIONMANAGER}</TableCell>
        <TableCell>{this.props.failedTransactionResult.FAILEDTRANSACTIONERRORCODE}</TableCell>
      </TableRow>
    )
  }
}
