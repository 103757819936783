import React, { Fragment } from 'react'
import { store } from '../../store'
import { connect } from 'react-redux'
import Wrapper from '../../component/default/layout/Wrapper'
import Card from '@material-ui/core/Card'
import CardContent from '@material-ui/core/CardContent'
import CardActions from '@material-ui/core/CardActions'
import Button from '@material-ui/core/Button'
import Typography from '@material-ui/core/Typography'
import TextField from '@material-ui/core/TextField'
import Input from '@material-ui/core/Input'
import FormControl from '@material-ui/core/FormControl'
import Select from '@material-ui/core/Select'
import InputLabel from '@material-ui/core/InputLabel'
import MenuItem from '@material-ui/core/MenuItem'
import { getFeeTypes, resetCardFeeStatus } from '../../action/cardFee'
import DialogBox from '../../component/default/layout/DialogBox'
import Table from '@material-ui/core/Table'
import TableHead from '@material-ui/core/TableHead'
import TableBody from '@material-ui/core/TableBody'
import TableRow from '@material-ui/core/TableRow'
import TableCell from '@material-ui/core/TableCell'
import {insertCardFee} from '../../action/insertCardFee'

const identifierTypes = {
  1: { label: 'PAN' },
  2: { label: 'Tracking Number' }
}

class InsertCardOrderFee extends React.Component {
  constructor(props) {
    super(props)
    this.initialState = {
      identifier:'',
      feeId:0,
      amount:0,
      identifierType: '1'
    }
    this.state = {
      ...this.initialState
    }
  }

  componentDidMount() {
    this.setState(this.initialState)
    store.dispatch(getFeeTypes())
  }

  handleInputChange = (event) => {
    const stateKey = event.target.name
    this.setState({ [stateKey]: event.target.value })
  }

  handleClick = () => {
    this.props.insertCardFee(this.state.identifierType, this.state.identifier, this.state.amount, this.state.feeId)
  }

  render(){
    const { feeTypes } = this.props
    return <Fragment>
      <div>
        <Wrapper>
          <DialogBox
            open={this.props.status === 'success' || this.props.status === 'failed'}
            title="Insert Card Order Fee Status"
            onClick={()=>{
              this.props.resetCardFeeStatus()
              this.setState({ ...this.initialState})
            }}
          >
            <div>
              {this.props.status === 'success' ?
                <div>
                  <Table>
                    <TableHead>
                      <TableRow>
                        <TableCell>Property</TableCell>
                        <TableCell>Value</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {this.props.insertFeeResult&&
                      Object.keys(this.props.insertFeeResult).map(key=>
                        <TableRow key={key}>
                          <TableCell>{key}</TableCell>
                          <TableCell>{this.props.insertFeeResult[key]}</TableCell>
                        </TableRow>
                      )}
                    </TableBody>
                  </Table>
                </div>:
                <span>Failed! The fee has not been inserted</span>}
            </div>
          </DialogBox>
          <Card style={{padding:'20px'}}>
            <Typography gutterBottom variant="h5">
              Insert Card Fee
            </Typography>
            <CardContent className="insert-card-fee-form-container">
              <FormControl className="form-input">
                <InputLabel htmlFor="identifier-type">Identifier type</InputLabel>
                <Select
                  id="identifier-type"
                  value={this.state.identifierType}
                  onChange={this.handleInputChange}
                  input={<Input name="identifierType"/>}
                >
                  {Object.keys(identifierTypes).map(key => (
                    <MenuItem value={key} key={key}>
                      {identifierTypes[key].label}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
              <FormControl className="form-input">
                <TextField
                  id="identifier"
                  name="identifier"
                  label={identifierTypes[this.state.identifierType].label}
                  value={this.state.identifier}
                  onChange={this.handleInputChange}
                />
              </FormControl>
              <FormControl className="form-input">
                <InputLabel htmlFor="fee-type">Fee Type</InputLabel>
                <Select
                  id="fee-type"
                  value={this.state.feeId}
                  onChange={this.handleInputChange}
                  input={<Input name="feeId"/>}
                >
                  {
                    Object.keys(feeTypes).map( key => <MenuItem value={feeTypes[key].id} key={key}>{feeTypes[key].name}</MenuItem> )
                  }
                </Select>
              </FormControl>
              <FormControl className="form-input">
                <TextField
                  id="amount"
                  name="amount"
                  label="Request Amount"
                  value={this.state.amount}
                  onChange={this.handleInputChange}
                />
              </FormControl>
            </CardContent>
            <CardActions className="align-right">
              <Button className="flattbutton-search" color="primary"size="large" variant="contained" onClick={this.handleClick}>Submit</Button>
            </CardActions>
          </Card>
        </Wrapper>
      </div>
    </Fragment>
  }
}

const mapStateToProps = (state,props) => {
  return {
    token: state.auth.token,
    feeTypes: state.cardFee.types,
    status: state.cardFee.status,
    insertFeeResult: state.cardFee.insertFeeResult
  }
}

const mapDispatchToProps = {
  getFeeTypes,
  insertCardFee,
  resetCardFeeStatus
}

export default connect(mapStateToProps, mapDispatchToProps)(InsertCardOrderFee)
