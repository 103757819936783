import React, { Component } from 'react'
import { store } from '../../../store'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import Card from '@material-ui/core/Card'
import CardContent from '@material-ui/core/CardContent'
import CardActions from '@material-ui/core/CardActions'
import Typography from '@material-ui/core/Typography'
import Button from '@material-ui/core/Button'
import TextField from '@material-ui/core/TextField'
import Alert from '../../../component/default/layout/Alert'
import PrivateSection from '../../../component/default/route/PrivateSection'
import Notification from '../../default/layout/Notification'
import { 
  merchantSignUp,
  merchantClearErrorMessage
} from '../../../action/merchant'
import Config from '../../../config'

class SignUpForm extends Component {
  constructor(props) {
    super(props)
    this.initialState = {
      values: {
        mastercardMerchantID: '',
        merchantName: '',
        merchantCity: '',
        merchantCategoryCode: '',
        currencyCode: '',
        countryCode: ''
      },
      errors: {
        mastercardMerchantID: '',
        merchantName: '',
        merchantCity: '',
        merchantCategoryCode: '',
        currencyCode: '',
        countryCode: ''
      },
      submitDisabled: true
    }
    this.state = {
      ...this.initialState
    }
    this.validateRegex = {
      mastercardMerchantID: /^\d{8}$|^\d{15}$/,
      merchantCategoryCode: /^\d{4}$/,
      merchantName: /^.{1,25}$/,
      currencyCode: /^\d{3}$/,
      countryCode: /^[a-zA-Z]{3}$/,
    }
  }

  onClearForm = () => {
    this.setState({ ...this.initialState })
  }

  onGenerate = () => {
    this.props.merchantSignUp(this.state.values)
  }

  onInputChange = (event) => {
    const stateKey = event.target.getAttribute("name")
    const value = event.target.value
    const error = this.validateInput(stateKey, value)
    const newState = {
      values: {
        ...this.state.values,
        [stateKey]: value
      },
      errors: {
        ...this.state.errors,
        [stateKey]: error
      }
    }
    this.updateSubmitDisabled(newState)
    this.setState(newState)
  }

  onCloseDialog = () => {
    this.props.merchantClearErrorMessage()
  }

  validateInput(key, value) {
    const regex = this.validateRegex[key]
    // assume all fields are required
    if (value === "") {
      return "This field is required"
    } else if (regex && !regex.test(value)) {
      return "Invalid input format"
    } else {
      return ""
    }
  }

  updateSubmitDisabled(newState) {
    const { values, errors } = newState
    let submitDisabled = false
    // assume all fields are required
    for (let field of Object.keys(values)) {
      if (values[field] === '' || errors[field] !== '') {
        submitDisabled = true
        break 
      }
    }

    newState.submitDisabled = submitDisabled
  }

  resetComponent() {
    this.setState({ ...this.initialState })
    store.dispatch(merchantClearErrorMessage())
  }

  render() {
    const { values, errors, submitDisabled } = this.state
    const { submitting, merchantListFull, errorMsg } = this.props

    const signupError = errorMsg ? (
      <Notification type={'error'} message={ errorMsg } open={ true } closed={() => this.resetComponent()}/>
    ) : (
      null
    )

    return (
      <React.Fragment>
        { signupError }
        <Card style={{padding:'15px'}}>
          <Typography gutterBottom variant="h5">
            Merchant Sign Up
          </Typography>
          <Typography gutterBottom variant="subtitle1">
            Fill in this form to get QR code string for merchant
          </Typography>
          <Alert style={{display: merchantListFull ? 'block' : 'none'}}>
            You cannot submit Merchant Sign Up because Merchant Information list below is full. Please clear the list to continue.
          </Alert>
           <CardContent>
            <TextField 
              name="mastercardMerchantID"
              label="Merchant Number" 
              placeholder="8 or 15 digits long" 
              helperText={errors.mastercardMerchantID}
              maxLength={15} 
              fullWidth={true}
              onChange={this.onInputChange}
              value={values.mastercardMerchantID}
            />
            <TextField 
              name="merchantName"
              label="Merchant Name" 
              placeholder="25 characters maximum" 
              helperText={errors.merchantName}
              maxLength={25} 
              fullWidth={true} 
              onChange={this.onInputChange}
              value={values.merchantName}
            />
            <TextField 
              name="merchantCity"
              label="Merchant City" 
              placeholder="Ex. Johannesburg" 
              helperText={errors.merchantCity}
              maxLength={20} 
              fullWidth={true} 
              onChange={this.onInputChange}
              value={values.merchantCity}
            />
            <TextField 
              name="merchantCategoryCode"
              label="Merchant Category Code" 
              placeholder="4 digits long Ex. 5411" 
              maxLength={4} 
              helperText={errors.merchantCategoryCode}
              fullWidth={true} 
              onChange={this.onInputChange}
              value={values.merchantCategoryCode}
            />
            <TextField 
              name="currencyCode"
              label="Currency Code" 
              placeholder="3 digits long Ex. 840" 
              helperText={errors.currencyCode}
              maxLength={3} 
              fullWidth={true} 
              onChange={this.onInputChange}
              value={values.currencyCode}
            />
            <TextField 
              name="countryCode"
              label="Country Code" 
              placeholder="3 characters long Ex. ZAF" 
              helperText={errors.countryCode}
              maxLength={3} 
              fullWidth={true} 
              onChange={this.onInputChange}
              value={values.countryCode}
            />
          </CardContent>
          <PrivateSection permissions={["MerchantSignUp.write"]}>
            <CardActions>
              <Button
                children="Generate"
                onClick={this.onGenerate}
                disabled={submitDisabled || submitting || merchantListFull} 
              />
              <Button children="Clear" onClick={this.onClearForm} />
            </CardActions>
          </PrivateSection>
        </Card>
      </React.Fragment>
    )
  }
}

SignUpForm.propTypes = {
  merchantSignUp: PropTypes.func
}

const mapStateToProps = (state, props) => {
  return {
    submitting: !!(state.api.MERCHANT_SIGNUP && state.api.MERCHANT_SIGNUP > 0),
    error: !!(state.merchant.errorMsg && state.merchant.errorMsg !== ''),
    errorMsg: state.merchant.errorMsg,
    merchantListFull: state.merchant.signUpList.length >= Config.maxMerchantSignUp
  }
}

export default connect(
  mapStateToProps,
  { 
    merchantSignUp, 
    merchantClearErrorMessage
  }
)(SignUpForm)
