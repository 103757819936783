import React, { Component, Fragment } from 'react'

import Single from './Single'

class NavItems extends Component {
  renderItems = subItems => {
    const { navigate } = this.props
    return subItems.map((route, index) => {
        return (
          <Single key={index} index={index} route={route} navigate={navigate} />
        )
    })
  }

  render() {
    const { items } = this.props
    return <Fragment>{this.renderItems(items)}</Fragment>
  }
}

export default NavItems
