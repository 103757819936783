import React from 'react'
import { TableBody, Table, TableCell, TableRow, Paper, withStyles } from '@material-ui/core'

const headRowArray = [6, 65, 87]
const subHeadRowArray = [7, 18 , 35, 46, 66, 70, 74, 78, 82]
const subSubHeadRowArray = [13, 24, 27, 32, 41, 59]
const totalRow = 94 
const totalRowArray = []
for(let rowCount = 6 ; rowCount < totalRow ; rowCount ++ )
  totalRowArray.push(rowCount)
const iterateColumns = ['A','B','C','D']

export const HeaderTableCell = withStyles(theme => ({
  body: {
    backgroundColor: theme.palette.primary.main,
    fontSize: 20,
    color: theme.palette.common.white,
  },
}))(TableCell)

export const SubHeaderTableCell = withStyles(theme => ({
  body: {
    backgroundColor:theme.palette.lightSilver,
    fontWeight:'bold'
  },
}))(TableCell)

export const SubSubHeaderTableCell = withStyles(theme => ({
  body: {
    backgroundColor:theme.palette.lightSilver,
  },
}))(TableCell)

export default ({report}) => <Paper>
  <Table>
    <TableBody>
      {report && totalRowArray.map(rowNum =>
        <TableRow key={rowNum}>
          {
            iterateColumns.map(
              columnLetter => {
                const cell = `${columnLetter}${rowNum}`
                if(headRowArray.filter( headerRow => headerRow === rowNum ).length > 0){
                  return <HeaderTableCell key={cell}>{report[cell] && report[cell].v.toString()}</HeaderTableCell>
                } else if(subHeadRowArray.filter( subHeaderRow => subHeaderRow === rowNum ).length > 0){
                  return <SubHeaderTableCell key={cell}>{report[cell] && report[cell].v.toString()}</SubHeaderTableCell>
                } else if(subSubHeadRowArray.filter( subSubheaderRow => subSubheaderRow === rowNum ).length > 0){
                  return <SubSubHeaderTableCell key={cell}>{report[cell] && report[cell].v.toString()}</SubSubHeaderTableCell>
                } else {
                  return <TableCell key={cell}>{report[cell] && report[cell].v.toString()}</TableCell>
                }
              }
            )
          }
        </TableRow>
      )}
    </TableBody>
  </Table>
</Paper>
