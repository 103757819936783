
export const LOAD_TRANSACTION = 1
export const SETTLEMENT_TRANSACTION = 2
export const AUTHORIZATION_TRANSACTION = 3
export const DEVALUE_TRANSACTION = 4
export const FEE_TRANSACTION = 5
export const REVERSED_FEE_TRANSACTION = 6
export const REVERSED_REDEMPTION = 7
export const REFUND = 8
export const CHARGEBACK = 9
export const MONEYSEND = 10
