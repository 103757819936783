import React, {Component} from 'react'
import { connect } from 'react-redux'
import dateFormat from "dateformat"
import { Typography, Paper, Button, Table, TableRow, TableBody, TableCell  } from '@material-ui/core'
import XLSX from 'xlsx'
import DateFromDateTo from '../../../component/agoda/transactionDetails/DateFromDateTo'
import QmrReportTable, {HeaderTableCell, SubHeaderTableCell} from '../../../component/agoda/reports/QmrReportTable'
import { QuarterSelect, YearSelect } from '../../../component/agoda/reports/DateAndTime'
import Wrapper from '../../../component/default/layout/Wrapper'
import UserCampaigns from '../../../component/agoda/userCampaigns/UserCampaigns'

const today = new Date()
const getQuarterStartMonth = month => month < 4 ? 1 : month < 7 ? 4 : month < 10 ? 7 : month < 13 ? 10 : null
const getQuarterEndMonth = month => month < 10 ? getQuarterStartMonth(month) + 3 : 1 
const getInitialQuarter = month => month < 4 ? '1' : month < 7 ? '2' : month < 10 ? '3' : month < 13 ? '4' : null
const todayDate = today.getFullYear() + '-' + (getQuarterStartMonth(parseInt(today.getMonth(),10))) + '-' + 1
const nextDate = today.getFullYear() + '-' + (getQuarterEndMonth(today.getMonth())) + '-' + 1

class QmrReport extends Component {
  state = {
    startDate:dateFormat(new Date(todayDate), "yyyy-mm-dd"),
    endDate:dateFormat(new Date(nextDate), "yyyy-mm-dd"),
    report: {},
    sheetNames:[],
    value:'',
    url:'',
    quarter:getInitialQuarter(parseInt(today.getMonth(),10)),
    year: today.getFullYear().toString(),
    campaignID: '',
    error: '',
    fileName:''
  }
  componentDidUpdate(prevProps, prevState){
    if (prevState.campaignID !== this.state.campaignID || prevState.quarter !== this.state.quarter || prevState.year !== this.state.year) {
      var campaignDetail = this.getCampaign(this.state.campaignID)
      if(campaignDetail){
        const fileName = `QMR_${campaignDetail.CampaignCardICA}_${this.state.year}Q${this.state.quarter}.xls`
        let url = `${process.env.REACT_APP_DOWNLOAD_URL}mastercardquarterlyreports/${fileName}`
        this.setState({url, fileName})
        var oReq = new XMLHttpRequest()
        oReq.open("GET", url, true)
        oReq.responseType = "arraybuffer"
        oReq.onload = () => {
          var arraybuffer = oReq.response
         
          var data = new Uint8Array(arraybuffer)
          var arr = []
          for(var i = 0; i !== data.length; ++i) arr[i] = String.fromCharCode(data[i])
          var bstr = arr.join("")
          
          var workbook = XLSX.read(bstr, {type:"binary"})
          this.setState({report:workbook.Sheets, sheetNames: workbook.SheetNames, value:workbook.SheetNames[0]})
        }
        oReq.send()
      }
    }

    if (prevState.quarter !== this.state.quarter || prevState.year !== this.state.year) {
      const startMonth = parseInt(this.state.quarter, 10)*3 -2 
      this.setState({
        startDate: dateFormat(new Date(`${this.state.year}-${startMonth}-01`), "yyyy-mm-dd"),
        endDate: dateFormat(new Date(this.state.quarter !== '4'? `${this.state.year}-${startMonth+3}-01` :`${parseInt(this.state.year, 10)+1}-01-01`), "yyyy-mm-dd")
      })
    }
  }

  getCampaign(campaignID) {
    return this.props.campaigns.reduce(this.returnCampaignDetail(campaignID), {})
  }

  returnCampaignDetail = campaignID => (value, campaign) =>campaign.CampaignID === campaignID ? campaign : value

  handleInputChange = (event) => {
    const stateKey = event.target.name
    this.setState({ [stateKey]: event.target.value })
  }

  handleCampaignChange = campaignID => this.setState({campaignID})

  render(){
    const report = this.state.report[this.state.sheetNames[0]]
    let reportDescriptionsRowArray = [] 
    const campaignDetail = this.getCampaign(this.state.campaignID)
    const reportDescriptionsRow = 6 
    if(report &&  report.length > 0){
      for(let rowNum = 1 ; rowNum < reportDescriptionsRow ; rowNum++) {
        reportDescriptionsRowArray.push(report[`A${rowNum}`].v)
      }
    }
    return(
      <Wrapper>
        <Paper style={{padding:'20px', marginBottom:'20px'}}>
          <Typography variant="h6" gutterBottom>
            Mastercard Quarterly Report
          </Typography>
          <div style={{margin:'10px 0'}}>
            <UserCampaigns campaignSelected={this.handleCampaignChange} />
          </div>
          <div style={{margin:'10px 0'}}>
            <QuarterSelect name="quarter" value={this.state.quarter} handleChange = {this.handleInputChange}/>
            <span style={{padding:'20px'}}>-</span>
            <YearSelect name="year" value={this.state.year} handleChange = {this.handleInputChange} />
          </div>
          <DateFromDateTo startDate={this.state.startDate} endDate={this.state.endDate} disabled={true}/>
          {reportDescriptionsRowArray.map( desc => 
            <Typography variant="body1" gutterBottom>
              {desc}
            </Typography>
          )}
          <br/>
          <a href={this.state.url} target="_blank" rel="noopener noreferrer"><Button style={{margin:'10px 0'}} variant='contained'> Download</Button></a>
        </Paper>
        {report && Object.keys(report).length > 0 ? 
          <QmrReportTable report={report} /> : 
          <Paper style={{padding:'20px'}}>
            <Table>
              <TableBody>
                { Object.keys(campaignDetail).length > 0 && !campaignDetail.CampaignCardICA ? <TableRow style={{background:'yellow'}} >
                  <TableCell colSpan={4}>
                    <Typography variant='heading'>warning: no campaignICA</Typography>
                  </TableCell>
                </TableRow> : null
                }
                <TableRow>
                  <HeaderTableCell colSpan={4}>
                    I. Cardholder Activity
                  </HeaderTableCell>
                </TableRow>
                <TableRow>
                  <SubHeaderTableCell>
                    IA.Purchase
                  </SubHeaderTableCell>
                  <SubHeaderTableCell>
                  </SubHeaderTableCell>
                  <SubHeaderTableCell>
                    Transactions
                  </SubHeaderTableCell>
                  <SubHeaderTableCell>
                    Volume
                  </SubHeaderTableCell>
                </TableRow>
                <TableRow>
                  <TableCell>1. Domestic On-us</TableCell>
                  <TableCell></TableCell>
                  <TableCell>NO DATA</TableCell>
                  <TableCell>NO DATA</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>2. Domestic Other Brand / Non-MasterCard Processed</TableCell>
                  <TableCell></TableCell>
                  <TableCell>NO DATA</TableCell>
                  <TableCell>NO DATA</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>3. Domestic Interchange</TableCell>
                  <TableCell></TableCell>
                  <TableCell>NO DATA</TableCell>
                  <TableCell>NO DATA</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>4. International</TableCell>
                  <TableCell></TableCell>
                  <TableCell>NO DATA</TableCell>
                  <TableCell>NO DATA</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>5. Total (A1+A2+A3+A4)</TableCell>
                  <TableCell></TableCell>
                  <TableCell>NO DATA</TableCell>
                  <TableCell>NO DATA</TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </Paper>
        }
      </Wrapper>
    )
  }
}

const mapStateToProps = (state, props) => ({
  campaigns: state.userCampaigns.campaigns
})

export default connect(mapStateToProps)(QmrReport)
