import React, {Component} from 'react'
import {connect} from 'react-redux'
import PropTypes from 'prop-types'
import TextField from '@material-ui/core/TextField'
import Card from '@material-ui/core/Card'
import CardContent from '@material-ui/core/CardContent'
import Typography from '@material-ui/core/Typography'
import Button from '@material-ui/core/Button'

import {
  getUser,
  dismissError
} from '../../action/currentUser'
import Notification from '../../component/default/layout/Notification'

const styles = {
  card: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    padding: '50px',
    maxWidth: '500px',
    width: '100%'
  },
  select: {
    width: '200px'
  },
  textField: {
    maxWidth: '500px',
    width: '100%'
  },
  button: {
    width: '150px',
    color: 'white'
  },
  form: {
    display: 'flex',
    flexFlow: 'row nowrap',
    justifyContent: 'center'
  },
  link: {
    display: 'flex',
    paddingTop: '20px'
  }
}

class UserSettings extends Component
{

  state = {
    username: '',
    firstname: '',
    lastname: '',
    email: ''
  }

  componentDidMount() {
    this.props.fetch()
  }

  componentWillReceiveProps(nextProps) {
    this.setState({...this.state, ...nextProps.user})
  }

  render() {
    return <React.Fragment>
      {this.props.user &&
      <form style={styles.form}>
        <Card style={styles.card}>
          <CardContent>
            <Typography variant="h5" gutterBottom>
              My Settings
            </Typography>
            <TextField
              autoFocus
              id="firstName"
              label="First Name"
              value={this.state.firstname}
              style={styles.textField}
              disabled={true}
            />
            <TextField
              id="lastName"
              label="Last Name"
              value={this.state.lastname}
              style={styles.textField}
              disabled={true}
            />
            <TextField
              required
              id="email"
              label="Email"
              type="email"
              value={this.state.email}
              style={styles.textField}
              disabled={true}
            />
            <TextField
              required
              id="username"
              label="Username"
              value={this.state.username}
              style={styles.textField}
              disabled={true}
            />
            <br/>
            <br/>
            <Button variant="contained" href="/user/changepassword" color="primary">Change Password</Button>
          </CardContent>

          <Notification
            type={'error'}
            message={this.props.errorMsg}
            open={this.props.errorMsg !== ''}
            closed={this.props.dismissError}
          />

        </Card>
      </form>
      }
    </React.Fragment>
  }
}

UserSettings.propTypes = {
  UserSettings: PropTypes.func
}

const mapStateToProps = (state, props) => {
  return {
    submitting: !!(state.api.GET_CURRENT_USER && state.api.GET_CURRENT_USER > 0),
    error: !!(state.currentUser.errorMsg && state.currentUser.errorMsg !== ''),
    errorMsg: state.currentUser.errorMsg,
    user: state.currentUser.userResults
  }
}

const mapDispatchToProps = {
  fetch: getUser,
  dismissError : dismissError
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(UserSettings)
