import React, {Component} from "react"
import {Document, Page, Text, View, StyleSheet, Image} from "@react-pdf/renderer"
import monoLogoImage from '../../../images/agoda/logo-mono.png'


const styles = StyleSheet.create({
  body: {
    padding: 10,
    paddingLeft: '40px',
    fontStyle: 'normal'
  },
  headerLogo: {
    width: '20%',
    paddingTop: '10px',
    paddingLeft: '0px'
  },
  headerTitle: {
    width: '80%',
    fontSize: 20,
    textAlign: 'center',
    paddingTop: '25px',
    // paddingLeft: '25px'
  },
  sectionTitle: {
    fontSize: 15,
    textAlign: 'left',
    paddingTop: '35px',
    paddingBottom: '15px'
  },
  table: {
    display: "table",
    width: "auto",
    borderRightWidth: 0,
    borderBottomWidth: 0
  },
  tableRow: {
    margin: "auto",
    flexDirection: "row"
  },
  tableColHeader: {
    width: "20%",
  },
  tableCol: {
    width: "30%",
  },
  tableCellHeader: {
    margin: 5,
    fontSize: 10,
    fontWeight: 'bold'

  },
  tableCell: {
    margin: 5,
    fontSize: 10
  }
});

class ChargebackReportPrintDetails extends Component {
  render() {
    const chargebackDateLabel = this.props.chargebackDetails['CHARGEBACKDATE'] ? 'Chargeback Date' : 'Chargeback Process Date'
    const chargebackDateValue = this.props.chargebackDetails['CHARGEBACKDATE'] ? this.props.chargebackDetails['CHARGEBACKDATE'] : this.props.chargebackDetails['CHARGEBACKPROCESSDATE']

    return (<Document>
      <Page style={styles.body}>

        <View style={styles.table}>
          <View style={styles.tableRow}>
            <View style={styles.headerLogo}>
              <Image src={monoLogoImage} style={{width:'82px', height:'50px'}}/>
            </View>
            <Text style={styles.headerTitle}>Chargeback Details</Text>
          </View>
        </View>

        <Text style={styles.sectionTitle}>Card</Text>
        <View style={styles.table}>
          <View style={styles.tableRow}>
            <View style={styles.tableColHeader}>
              <Text style={styles.tableCellHeader}>Campaign Name</Text>
            </View>
            <View style={styles.tableCol}>
              <Text style={styles.tableCell}>{this.props.chargebackDetails['CAMPAIGNNAME']}</Text>
            </View>
            <View style={styles.tableColHeader}>
              <Text style={styles.tableCellHeader}>Card Number</Text>
            </View>
            <View style={styles.tableCol}>
              <Text style={styles.tableCell}>{this.props.chargebackDetails['VOUCHERNUMBER']}</Text>
            </View>
          </View>
          <View style={styles.tableRow}>
            <View style={styles.tableColHeader}>
              <Text style={styles.tableCellHeader}>Tracking Number</Text>
            </View>
            <View style={styles.tableCol}>
              <Text style={styles.tableCell}>{this.props.chargebackDetails['TRACKINGNUMBER']}</Text>
            </View>
            <View style={styles.tableColHeader}>
              <Text style={styles.tableCellHeader}>Name On Card</Text>
            </View>
            <View style={styles.tableCol}>
              <Text style={styles.tableCell}>{this.props.chargebackDetails['NAMEONCARD']}</Text>
            </View>
          </View>
          <View style={styles.tableRow}>
            <View style={{width: "20%"}}>
              <Text style={styles.tableCellHeader}>Wallet Reference</Text>
            </View>
            <View style={{width: "80%"}}>
              <Text style={styles.tableCell}>{this.props.chargebackDetails['WALLETREFERENCE']}</Text>
            </View>
          </View>
        </View>

        <Text style={styles.sectionTitle}>Transaction</Text>
        <View style={styles.table}>
          <View style={styles.tableRow}>
            <View style={styles.tableColHeader}>
              <Text style={styles.tableCellHeader}>Transaction ID</Text>
            </View>
            <View style={styles.tableCol}>
              <Text style={styles.tableCell}>{this.props.chargebackDetails['TRANSACTIONID']}</Text>
            </View>
            <View style={styles.tableColHeader}>
              <Text style={styles.tableCellHeader}>POS Entry Mode</Text>
            </View>
            <View style={styles.tableCol}>
              <Text style={styles.tableCell}>{this.props.chargebackDetails['POSENTRYMODE']}</Text>
            </View>
          </View>
          <View style={styles.tableRow}>
            <View style={styles.tableColHeader}>
              <Text style={styles.tableCellHeader}>Transaction Amount Local Currency</Text>
            </View>
            <View style={styles.tableCol}>
              <Text style={styles.tableCell}>{this.props.chargebackDetails['TRANSACTIONAMOUNTLOCALCURRENCY']}</Text>
            </View>
            <View style={styles.tableColHeader}>
              <Text style={styles.tableCellHeader}>Transaction Amount USD</Text>
            </View>
            <View style={styles.tableCol}>
              <Text style={styles.tableCell}>{this.props.chargebackDetails['TRANSACTIONAMOUNTUSD']}</Text>
            </View>
          </View>
          <View style={styles.tableRow}>
            <View style={{width: "20%"}}>
              <Text style={styles.tableCellHeader}>Transaction Description</Text>
            </View>
            <View style={{width: "80%"}}>
              <Text style={styles.tableCell}>{this.props.chargebackDetails['TRANSACTIONDESCRIPTION']}</Text>
            </View>
          </View>
          <View style={styles.tableRow}>
            <View style={{width: "20%"}}>
              <Text style={styles.tableCellHeader}>Original Transaction Date</Text>
            </View>
            <View style={{width: "80%"}}>
              <Text style={styles.tableCell}>{this.props.chargebackDetails['ORIGINALTRANSACTIONDATE']}</Text>
            </View>
          </View>
        </View>

        <Text style={styles.sectionTitle}>Chargeback</Text>
        <View style={styles.table}>
          <View style={styles.tableRow}>
            <View style={styles.tableColHeader}>
              <Text style={styles.tableCellHeader}>{chargebackDateLabel}</Text>
            </View>
            <View style={styles.tableCol}>
              <Text style={styles.tableCell}>{chargebackDateValue}</Text>
            </View>
            <View style={styles.tableColHeader}>
              <Text style={styles.tableCellHeader}>Chargeback Reason Code</Text>
            </View>
            <View style={styles.tableCol}>
              <Text style={styles.tableCell}>{this.props.chargebackDetails['CHARGEBACKREASONCODE']}</Text>
            </View>
          </View>
          <View style={styles.tableRow}>
            <View style={styles.tableColHeader}>
              <Text style={styles.tableCellHeader}>Chargeback Amount Local Currency</Text>
            </View>
            <View style={styles.tableCol}>
              <Text style={styles.tableCell}>{this.props.chargebackDetails['CHARGEBACKAMOUNTLOCALCURRENCY']}</Text>
            </View>
            <View style={styles.tableColHeader}>
              <Text style={styles.tableCellHeader}>Chargeback Amount USD</Text>
            </View>
            <View style={styles.tableCol}>
              <Text style={styles.tableCell}>{this.props.chargebackDetails['CHARGEBACKAMOUNTUSD']}</Text>
            </View>
          </View>
          <View style={styles.tableRow}>
            <View style={styles.tableColHeader}>
              <Text style={styles.tableCellHeader}>Chargeback Rejected</Text>
            </View>
            <View style={styles.tableCol}>
              <Text style={styles.tableCell}>{this.props.chargebackDetails['CHARGEBACKREJECTED']}</Text>
            </View>
            <View style={styles.tableColHeader}>
              <Text style={styles.tableCellHeader}>Chargeback Reversed</Text>
            </View>
            <View style={styles.tableCol}>
              <Text style={styles.tableCell}>{this.props.chargebackDetails['CHARGEBACKREVERSED']}</Text>
            </View>
          </View>
          <View style={styles.tableRow}>
            <View style={styles.tableColHeader}>
              <Text style={styles.tableCellHeader}>Mastercom Chargeback Reference</Text>
            </View>
            <View style={styles.tableCol}>
              <Text style={styles.tableCell}>{this.props.chargebackDetails['MASTERCOMCHARGEBACKREFERENCE']}</Text>
            </View>
            <View style={styles.tableColHeader}>
              <Text style={styles.tableCellHeader}>Mastercom Claim ID</Text>
            </View>
            <View style={styles.tableCol}>
              <Text style={styles.tableCell}>{this.props.chargebackDetails['MASTERCOMCLAIMID']}</Text>
            </View>
          </View>
          <View style={styles.tableRow}>
            <View style={{width: "20%"}}>
              <Text style={styles.tableCellHeader}>Chargeback Notes</Text>
            </View>
            <View style={{width: "80%"}}>
              <Text style={styles.tableCell}>{this.props.chargebackDetails['CHARGEBACKNOTES']}</Text>
            </View>
          </View>
          <View style={styles.tableRow}>
            <View style={styles.tableColHeader}>
              <Text style={styles.tableCellHeader}>Second Presentment Date</Text>
            </View>
            <View style={styles.tableCol}>
              <Text style={styles.tableCell}>{this.props.chargebackDetails['SECONDPRESENTMENTDATE']}</Text>
            </View>
            <View style={styles.tableColHeader}>
              <Text style={styles.tableCellHeader}>Second Presentment Amount Local Currency</Text>
            </View>
            <View style={styles.tableCol}>
              <Text style={styles.tableCell}>{this.props.chargebackDetails['SECONDPRESENTMENTAMOUNTLOCALCURRENCY']}</Text>
            </View>
          </View>
          <View style={styles.tableRow}>
            <View style={styles.tableColHeader}>
              <Text style={styles.tableCellHeader}>Second Presentment Amount USD</Text>
            </View>
            <View style={styles.tableCol}>
              <Text style={styles.tableCell}>{this.props.chargebackDetails['SECONDPRESENTMENTAMOUNTUSD']}</Text>
            </View>
            <View style={styles.tableColHeader}>
              <Text style={styles.tableCellHeader}>Second Presentment Reason Code</Text>
            </View>
            <View style={styles.tableCol}>
              <Text style={styles.tableCell}>{this.props.chargebackDetails['SECONDPRESENTMENTREASONCODE']}</Text>
            </View>
          </View>

        </View>
      </Page>
    </Document>
    )
  }
}

export default ChargebackReportPrintDetails
