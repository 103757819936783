import { DEVALUE_CARD, DEVALUE_CARD_ERROR_MESSAGE, DEVALUE_CARD_CLEAR } from '../action/card/devalueCard'

const initialState = {
  errorMsg : '',
  devalueResult: false,
  devalueSuccess: false
}

export default (state = initialState, action) => {
  const { type, payload } = action
  switch(type) {
    case DEVALUE_CARD_ERROR_MESSAGE:
      return {
        ...state,
        errorMsg: 'An error occurred'
      }
    case `${DEVALUE_CARD}_FULFILLED`:
      if (payload.resultCode === "1") {
        return {
          ...state,
          devalueResult: payload.resultText,
          devalueSuccess: true
        }
      } else {
        return {
          ...state,
          errorMsg: payload.resultText,
          devalueSuccess: false
        }
      }
    case `${DEVALUE_CARD}_PENDING`:
      return {
        ...state,
        errorMsg: '',
        devalueSuccess: false
      }
    case `${DEVALUE_CARD}_REJECTED`:
      return {
        ...state,
        errorMsg: 'Cannot connect to server'
      }
    case DEVALUE_CARD_CLEAR:
      return {
        initialState
      }
    default:
      return state
  }
}
