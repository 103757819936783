import React, { Component } from 'react'
import Wrapper from '../../../component/default/layout/Wrapper'
import CardOrderForm from '../../../component/agoda/card/CardOrderForm'
import { withTheme } from '@material-ui/core/styles'

class CardOrder extends Component {
  render() {
    return (
      <div>
        <Wrapper style={this.props.theme.palette.paperWrapper}>
          <CardOrderForm />
        </Wrapper>
      </div>
    )
  }
}

export default withTheme(CardOrder)
