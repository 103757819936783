import { apiCall } from './api' 

export const CHARGEBACK_REPORT = 'CHARGEBACK_REPORT'

export function chargebackReport(token, startDate, endDate, campaigns) {
  const payload = apiCall('chargebackReport', {token, startDate, endDate, campaigns})
  return {
    type: CHARGEBACK_REPORT,
    payload
  }
}
