import { GET_STOP_REASONS, GET_STOP_REASONS_ERROR_MESSAGE, GET_STOP_REASONS_CLEAR } from '../action/card/getStopReasons'

const initialState = {
  errorMsg : '',
  getStopReasonsResult: []
}

export default (state = initialState, action) => {
  const { type, payload } = action
  switch(type) {
    case GET_STOP_REASONS_ERROR_MESSAGE:
      return {
        ...state,
        errorMsg: 'An error occurred'
      }
    case `${GET_STOP_REASONS}_FULFILLED`:
      if (payload.resultCode === "0") {
        return {
          ...state,
          getStopReasonsResult: JSON.parse(payload.resultText)
        }
      } else {
        return {
          ...state,
          errorMsg: payload.resultText
        }
      }
    case `${GET_STOP_REASONS}_PENDING`:
      return {
        ...state,
        errorMsg: ''
      }
    case `${GET_STOP_REASONS}_REJECTED`:
      return {
        ...state,
        errorMsg: 'Cannot connect to server'
      }
    case GET_STOP_REASONS_CLEAR:
      return {
        initialState
      }
    default:
      return state
  }
}
