import React from 'react'
import Wrapper from '../../../component/default/layout/Wrapper'
import CSVReport from '../../../component/agoda/reports/CSVReport/CSVReport'
import transform from '../../../component/agoda/reports/CSVReport/transform'


const UnsettledTransactions = () =><div>
  <Wrapper>
    <CSVReport 
      type = "unsettledTransactions" 
      reportHeader = "Unsettled Transactions Report" 
      transform = {{ "TransactionAmount": transform.centToMoney }}
    />
  </Wrapper>
</div>

export default UnsettledTransactions
