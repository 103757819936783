import React, { Component } from 'react'
import { connect } from 'react-redux'
import Paper from '@material-ui/core/Paper'
import TextField from '@material-ui/core/TextField'
import Button from '@material-ui/core/Button'
import { red } from '@material-ui/core/colors'
import {Link} from 'react-router-dom'
import Typography from '@material-ui/core/Typography'

class Login extends Component {

  onKeyPress = (event) => {
    if (event.key === 'Enter') {
      this.props.onLogIn()
    }
  }

  render() {
    const paperStyle = {
      width: '50%',
      position: 'absolute',
      top: '45%',
      left: '50%',
      padding: 20,
      marginLeft: '-120px',
      textAlign: 'center',
      display: 'flex',
      flexDirection: 'column',
      minHeight:'200px',
      justifyContent:'space-between',
      transform: 'translateY(-50%) translateX(-50%)',
      verticalAlign: 'middle',
    }
    const helperTextStyle = {
      color: red[500]
    }
    const { submitting, errorCode, errorMsg, username, password } = this.props
    return (
      <Paper style={paperStyle} zdepth={4}>
        { errorCode !== '-20155' && <div style={helperTextStyle}>{errorMsg}</div> }
        <TextField 
          name="username"
          label="Username"
          inputProps={{
            maxLength: 100
          }}
          fullWidth={true}
          onChange={this.props.onInputChange}
          value={username}
          autoComplete="false"
          style={{borderBottom:'0.1px solid'}}
          autoFocus
        />
        <TextField 
          name="password"
          type="password"
          label="Password"
          inputProps={{
            maxLength: 100
          }}
          fullWidth={true}
          onChange={this.props.onInputChange}
          onKeyPress={this.onKeyPress}
          value={password}
          autoComplete="false"
          style={{borderBottom:'0.1px solid'}}
        />

        <div style={{marginTop: 25}}>
          <Button data-testid="loginSubmitBtn" className="flattbutton-login" size="large" variant="contained"  color="primary" onClick={this.props.onLogIn} disabled={submitting}>Log in</Button>
        </div>
        <Link data-testid="loginForgotPassword" style={{textDecoration: 'none', marginTop: 15}} to={`/forgotPassword`}>
          <Typography  variant="caption" gutterBottom>Forgot your password?</Typography>
        </Link>
      </Paper>
    )
  }
}

const mapStateToProps = (state, props) => {
  return {
    submitting: !!(state.api.LOGIN && state.api.MERCHANT_LOGIN > 0),
    errorCode: state.auth.errorCode,
    errorMsg: state.auth.errorMsg,
    userPerms: state.auth.perms,
  }
}



export default connect(mapStateToProps)(Login)
