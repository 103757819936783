import {
  FETCH_VIRTUAL_CARD_ORDERS_BATCH_LOADS,
  FETCH_VIRTUAL_CARD_ORDERS_BATCH_LOAD_DETAILS,
  VIRTUAL_CARD_ORDERS_BATCH_LOAD_APPROVE,
  VIRTUAL_CARD_ORDERS_BATCH_LOAD_REJECT,
  DOWNLOAD_VIRTUAL_CARD_ORDERS_BATCH_LOAD_DETAILS_REPORT,
  VIRTUAL_CARD_ORDERS_BATCH_LOAD_CLEAR_ERROR,
  VIRTUAL_CARD_ORDERS_BATCH_LOAD_SET_ERROR,
} from '../action/virtualCardOrdersBatchLoads'

const initialState = {
  errorMsg: '',
  virtualCardOrdersBatchLoads: [],
  virtualCardOrdersBatchLoadDetails: [],
  approvedSuccessfully: false,
  rejectedSuccessfully: false,
  virtualCardOrdersBatchLoadDetailsReport: null,
}

export default (state = initialState, action) => {
  const { type, payload } = action
  switch (type) {
  case `${FETCH_VIRTUAL_CARD_ORDERS_BATCH_LOADS}_FULFILLED`:
    if (payload.resultCode === '0') {
      return {
        ...state,
        virtualCardOrdersBatchLoads: payload.data || [],
        errorMsg: (payload.data || []).length ? '' : 'No virtual card orders found'
      }
    } else {
      return {
        ...state,
        errorMsg: payload.data.resultText,
        errorCode: payload.data.resultCode,
      }
    }

  case `${VIRTUAL_CARD_ORDERS_BATCH_LOAD_APPROVE}_FULFILLED`:
    if (payload.resultCode === '0') {
      return {
        ...state,
        virtualCardOrdersBatchLoads: state.virtualCardOrdersBatchLoads.filter(
          ({ virtualCardOrdersBatchLoadId }) =>
            virtualCardOrdersBatchLoadId !==
            payload.virtualCardOrdersBatchLoadId
        ),
        approvedSuccessfully: true,
      }
    } else {
      return {
        ...state,
        errorMsg: payload.data,
        errorCode: payload.resultCode,
      }
    }

  case `${VIRTUAL_CARD_ORDERS_BATCH_LOAD_REJECT}_FULFILLED`:
    if (payload.resultCode === '0') {
      return {
        ...state,
        virtualCardOrdersBatchLoads: state.virtualCardOrdersBatchLoads.filter(
          ({ virtualCardOrdersBatchLoadId }) =>
            virtualCardOrdersBatchLoadId !==
            payload.virtualCardOrdersBatchLoadId
        ),
        rejectedSuccessfully: true,
      }
    } else {
      return {
        ...state,
        errorMsg: payload.data,
        errorCode: payload.resultCode,
      }
    }

  case `${FETCH_VIRTUAL_CARD_ORDERS_BATCH_LOAD_DETAILS}_FULFILLED`:
    if (payload.resultCode === '0') {
      return {
        ...state,
        virtualCardOrdersBatchLoadDetails: payload.data ?? null,

      }
    } else {
      return {
        ...state,
        errorMsg: payload.data,
        errorCode: payload.resultCode,
      }
    }

  case `${DOWNLOAD_VIRTUAL_CARD_ORDERS_BATCH_LOAD_DETAILS_REPORT}_PENDING`:
    return {
      ...state,
      errorMsg: '',
      virtualCardOrdersBatchLoadDetailsReport: null,
    }    
  case `${DOWNLOAD_VIRTUAL_CARD_ORDERS_BATCH_LOAD_DETAILS_REPORT}_FULFILLED`:
    if (payload.resultCode === "0") {
      return {
        ...state,
        virtualCardOrdersBatchLoadDetailsReport: payload.resultExcel
      }
    } else {
      return {
        ...state,
        errorMsg: payload.resultText
      }
    }

  case `${FETCH_VIRTUAL_CARD_ORDERS_BATCH_LOADS}_REJECTED`:
  case `${VIRTUAL_CARD_ORDERS_BATCH_LOAD_APPROVE}_REJECTED`:
  case `${VIRTUAL_CARD_ORDERS_BATCH_LOAD_REJECT}_REJECTED`:
  case `${FETCH_VIRTUAL_CARD_ORDERS_BATCH_LOAD_DETAILS}_REJECTED`:
    return {
      ...state,
      errorMsg: 'Cannot connect to server',
    }

  case `${FETCH_VIRTUAL_CARD_ORDERS_BATCH_LOADS}_PENDING`:
  case `${VIRTUAL_CARD_ORDERS_BATCH_LOAD_APPROVE}_PENDING`:
  case VIRTUAL_CARD_ORDERS_BATCH_LOAD_CLEAR_ERROR:
    return {
      ...state,
      approvedSuccessfully: false,
      rejectedSuccessfully: false,
      errorMsg: '',
    }

  case VIRTUAL_CARD_ORDERS_BATCH_LOAD_SET_ERROR:
    return {
      ...state,
      errorMsg: payload,
    }
  default:
    return state
  }
}
