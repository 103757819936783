import React, {Component} from 'react'
import Table from '@material-ui/core/Table'
import TableHead from '@material-ui/core/TableHead'
import TableFooter from '@material-ui/core/TableFooter'
import TableBody from '@material-ui/core/TableBody'
import TableRow from '@material-ui/core/TableRow'
import TableCell from '@material-ui/core/TableCell'
import { BatchLoadDetailsResultsRow } from './BatchLoadDetailsResultsRow'
import TablePagination from '@material-ui/core/TablePagination';
import Config from "../../../config"

export class BatchLoadDetailsResults extends Component {
  state = {
    page: 0,
    rowsPerPage: 10
  };

  handleChangePage = (event, page) => {
    this.setState({ ...this.state, page });
  };

  handleChangeRowsPerPage = event => {
    this.setState({ ...this.state, rowsPerPage: event.target.value });
  };

  render() {
    const rowsPerPage = this.state.rowsPerPage;
    const totalPages = Math.ceil(this.props.batchLoadDetailsResults.length / rowsPerPage );
    const currentPage = totalPages > 0 ? (this.state.page + 1) : 0;
    const tableRows = this.props.batchLoadDetailsResults.map((row,i) =>
      <BatchLoadDetailsResultsRow key={i} batchLoadDetailsResult={row} />
    ).slice(this.state.page * rowsPerPage, this.state.page  * rowsPerPage + rowsPerPage);

    return (
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>Identifier</TableCell>
            <TableCell>Load Amount</TableCell>
            {(!Config.hideFees) ? <TableCell>Load Fee</TableCell> : null }
            <TableCell>Result</TableCell>
            <TableCell>Loaded</TableCell>
            <TableCell>Load Date</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {tableRows}     
        </TableBody>
          <TableFooter>
            <TableRow>
              <TablePagination
                  colSpan={5}
                  count={this.props.batchLoadDetailsResults.length}
                  rowsPerPage={this.state.rowsPerPage}
                  page={this.state.page}
                  labelDisplayedRows={() => currentPage + ' of ' + totalPages}
                  onChangePage={this.handleChangePage}
                  onChangeRowsPerPage={this.handleChangeRowsPerPage}
              />
            </TableRow>
          </TableFooter>
      </Table>
    )
  }
}
