import React, { Component } from 'react'
import Paper from '@material-ui/core/Paper'

class Home extends Component {
  render() {
    const style = {
      width: '50%',
      height: 300,
      position: 'absolute',
      top: '50%',
      left: '50%',
      padding: 20,
      textAlign: 'center',
      display: 'block',
      transform: 'translateY(-50%) translateX(-50%)',
      lineHeight: '270px',
      verticalAlign: 'middle',
    }

    return (
      <Paper style={style} zdepth={2}>
        {this.props.children || 'Welcome to Tutuka Client Portal'}
      </Paper>
    )
  }
}

export default Home
