import { apiCall } from './api'

export const BATCH_DEVALUE = 'BATCH_DEVALUE'

export function batchDevalue(batchElements, identifierType, batchDescription, fileName, retire = false) {
  const payload = apiCall('batchDevalue', {batchElements, identifierType, batchDescription, fileName, retire});

  return {
    type: BATCH_DEVALUE,
    payload,
    meta: {
      
    }
  }
}

export const BATCH_DEVALUE_ERROR_MESSAGE = 'BATCH_DEVALUE_ERROR_MESSAGE'

export function batchDevalueErrorMessage (errorMessage = null) {
  return {
    type: BATCH_DEVALUE_ERROR_MESSAGE,
    payload: { resultText: errorMessage }
  }
}

export const BATCH_DEVALUE_RESET = 'BATCH_DEVALUE_RESET'

export function batchDevalueReset () {
  return {
    type: BATCH_DEVALUE_RESET
  }
}
