import { apiCall } from './api' 

export const GET_CAMPAIGNS = 'GET_CAMPAIGNS'
export const GET_VIRTUAL_CAMPAIGNS = 'GET_VIRTUAL_CAMPAIGNS'

export function getCampaigns () {
  const payload = apiCall('getCampaigns', {cardType: '2,4,8,16,32,64'})
  return {
    type: GET_CAMPAIGNS,
    payload
  }
}

export function getVirtualCampaigns () {
  const payload = apiCall('getCampaigns', {cardType: '4,16,64'})
  return {
    type: GET_VIRTUAL_CAMPAIGNS,
    payload
  }
}
