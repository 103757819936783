import React, { Component, Fragment } from 'react'
import { store } from '../../store'
import PropTypes from 'prop-types'
import Stepper from '@material-ui/core/Stepper'
import Step from '@material-ui/core/Step'
import StepLabel from '@material-ui/core/StepLabel'
import Card from '@material-ui/core/Card'
import CardContent from '@material-ui/core/CardContent'
import Button from '@material-ui/core/Button'
import Typography from '@material-ui/core/Typography'
import Input from '@material-ui/core/Input'
import InputLabel from '@material-ui/core/InputLabel'
import FormControl from '@material-ui/core/FormControl'
import Select from '@material-ui/core/Select'
import MenuItem from '@material-ui/core/MenuItem'
import Dropzone from 'react-dropzone'
import Table from '@material-ui/core/Table'
import TableHead from '@material-ui/core/TableHead'
import TableBody from '@material-ui/core/TableBody'
import TableRow from '@material-ui/core/TableRow'
import TableCell from '@material-ui/core/TableCell'
import { connect } from 'react-redux'
import { setCardStatus, setCardStatusClear } from '../../action/card/setCardStatus'
import plusImage from '../../images/agoda/dropzone.png'
import { batchFileVerification, batchFileVerificationErrorMessage, verifyBatchFile, batchFileVerificationReset } from '../../action/batchFileVerification'
import BatchFile from '../../component/agoda/batchFile/BatchFile'
import Notification from '../../component/default/layout/Notification'
import batchSample from '../../resources/activate-card-sample.csv'
import DownloadSample from './BatchSampleDownload'

const steps = ['File upload', 'Verification', 'Submit']

const batchSizeLimit = 250

// We can use inline-style
const svgStyle = { 
  color: '#06a9e0'
};
class ActivateCards extends Component{

  constructor(props) {
    super(props)
    this.initialState={
      activeStep: 1,
      batchFileContent: [],
      identifierType: 1,
      cardsToActivate: [],
      additionalValidation: []
    }
    this.state = {
      ...this.initialState
    }
  }

  handleInputChange = event => this.setState({ [event.target.name]: event.target.value })

  hadleCheckBoxChange = event => this.setState({ [event.target.name]: event.target.checked })

  componentDidMount() {
    this.setState(this.initialState)
    store.dispatch(setCardStatusClear())
    store.dispatch(batchFileVerificationReset())
  }

  componentWillReceiveProps(nextProps){
    if (nextProps.batchFileVerificationStruct.batchVerificationResult) {
      this.setState({
        batchFileContent : nextProps.batchFileVerificationStruct.batchVerificationResult,
        fileName: nextProps.batchFileVerificationStruct.fileName
      })
    }
  }

  resetComponent() {
    this.setState(this.initialState)
    store.dispatch(setCardStatusClear())
    store.dispatch(batchFileVerificationReset())
  }

  verifyBatch(acceptedFiles, rejectedFiles) {
    this.setState({...this.state, activeStep: 2 }, verifyBatchFile(acceptedFiles, rejectedFiles, this.state.identifierType, batchSizeLimit, this.state.additionalValidation))
  }

  StageOne = () =><Card style={{padding:'20px'}}>
    <CardContent>
      <Typography gutterBottom variant="subtitle1">
        Upload a CSV file containing a list of card identifiers and amounts to activate. (Maximum {batchSizeLimit} records)
      </Typography>
      <div className="batch-devalue-form-container"> 
        <FormControl>
          <InputLabel htmlFor="identifier-type">Used identifier</InputLabel>
          <Select
            id="identifier-type"
            value={this.state.identifierType}
            onChange={this.handleInputChange}
            input={<Input name="identifierType"/>}
            fullWidth={true}
          >
            <MenuItem value={1}>PAN</MenuItem>
            <MenuItem value={2}>Tracking Number</MenuItem>
          </Select>
        </FormControl>
      </div>
      <br />
      
      <Dropzone className="dropzone" multiple={false} onDrop={this.verifyBatch.bind(this)} disabled={false} accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel, .xlsx">
        {({getRootProps, getInputProps}) => (
          <section className="container">
            <div {...getRootProps({className: 'dropzone'})}>
              <input {...getInputProps()} />
              <img className="dropzone-image" src={plusImage} alt="Try dropping a file into this box, or click on it to select the file to upload." />
              <Typography component="p" className="dropzone-p">Try dropping a file into this box, or click on it to select the file to upload.</Typography>
            </div>
          </section>
        )}
      </Dropzone>
    </CardContent>
    <DownloadSample batchSample ={batchSample}/>
  </Card>

  StageTwo = () => <BatchFile
    batchFileContent = { this.state.batchFileContent }
    submitCallback = {this.activateCards.bind(this)}
    resetCallback = {this.resetComponent.bind(this)}
    identifierType = {this.state.identifierType}
    fileName = {this.state.fileName}
    reference = {this.state.loadReference}
    operationType = "activate"
    batchFeeAmount = "0"
    handleInputChange = {this.handleInputChange}
    hadleCheckBoxChange = {this.hadleCheckBoxChange}
  />

  activateCards = () => {
    var cardNumbers = this.state.batchFileContent.map(function(batchElement) {
      return batchElement.identifier
    })
    this.setState({...this.state, cardsToActivate: cardNumbers, activeStep:3}, () => this.props.setCardStatus(this.state.cardsToActivate, this.state.identifierType))
  }

  StageThree = () => <Card style={{padding:'20px'}}>
    <Typography variant="h5">Activation Result</Typography>
    {
      this.props.cardStatus.statusResult === "Success" ? <Table>
        <TableHead>
          <TableRow>
            <TableCell>CardNumber</TableCell>
            <TableCell>Activation Result</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {
            Object.keys(this.props.cardStatus.resultStruct).map( resultKey =>
              <TableRow key={resultKey}>
                <TableCell>
                  {resultKey}
                </TableCell>
                <TableCell>
                  {this.props.cardStatus.resultStruct[resultKey]? 'activated':'not activated'}
                </TableCell>
              </TableRow>
            )
          }
        </TableBody>
      </Table>:null
    }<br />
    <Button variant='contained' color='primary' onClick={()=>this.setState({activeStep:1})}>Back</Button>
  </Card>

  verified = (data, type) =>  type === 'voucherNumber'? (data.length === 16) :
    type === 'trackingNumber'? data.length === 15 :
      type === 'sequenceNumber' && true

  render(){
    const { errorMsg } = this.props

    const batchUploadError = errorMsg ? (
      <Notification type={'error'} message={ errorMsg } open={ true } closed={() => this.resetComponent()}/>
    ) : (
      null
    )

    return(
      <Fragment>
        { batchUploadError }
        <div style={{padding:'40px'}}>
          <Card style={{padding:'40px'}}>
            <Typography gutterBottom variant="h5">
              Activate Card
            </Typography>
            <CardContent>
              <Stepper activeStep={this.state.activeStep}>
                {steps.map((label, index) => {
                  return (
                    <Step key={label}>
                      <StepLabel style={svgStyle}>{label}</StepLabel>
                    </Step>
                  )
                })}
              </Stepper>
            </CardContent>
          </Card><br />
          {
            this.state.activeStep === 1 ? <this.StageOne/>:
              (this.state.activeStep === 2 && this.state.batchFileContent.length) ? <this.StageTwo/>:
                this.state.activeStep === 3 ? <this.StageThree/> : null
          }
        </div>
      </Fragment>	
    )
  }
}

ActivateCards.propTypes = {
  SetCardStatus: PropTypes.func,
  BatchFileVerification: PropTypes.func
}

const mapStateToProps = (state, props) => {
  return {
    errorMsg: state.batchFileVerification.errorMsg,  
    cardStatus: state.setCardStatus,
    batchFileVerificationStruct: {
      batchVerificationResult: (state.batchFileVerification.batchVerificationResult || []),
      batchFileRow: (state.batchFileVerification.batchFileRow || null),
      fileName: (state.batchFileVerification.fileName || '')
    },
    batchFileVerification: state.batchFileVerification,
  }
}

const setDispatchToProps = {
  setCardStatus,
  batchFileVerification,
  batchFileVerificationErrorMessage
}

export default connect(
  mapStateToProps,
  setDispatchToProps
)(ActivateCards)
