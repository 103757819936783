import React from 'react'
import {connect} from 'react-redux'
import Wrapper from '../../component/default/layout/Wrapper'
import TextField from '@material-ui/core/TextField'
import Button from '@material-ui/core/Button'
import Tooltip from '@material-ui/core/Tooltip'
import Card from '@material-ui/core/Card'
import Typography from '@material-ui/core/Typography'
import {saveUser, getUserInfo, resetUserCreated, dismissError, dismissConfirmation} from '../../action/userAdmin'
import {getUserGroups} from '../../action/generalUserDetails'
import DialogBox from '../../component/default/layout/DialogBox'
import Notification from '../../component/default/layout/Notification'
import config from '../../config'
import {Link} from "react-router-dom"

const AGODA_OWNER_ID = config.ownerId

const styles = {
  card: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    padding: '50px',
    maxWidth: '500px',
    width: '100%'
  },
  select: {
    width: '200px'
  },
  textField: {
    maxWidth: '500px',
    width: '100%'
  },
  button: {
    color: 'white',
    margin: '20px'
  },
  form: {
    display: 'flex',
    flexFlow: 'row nowrap',
    justifyContent: 'center'
  }
}

const validateNoEmptyFields = validateFieldArray => validateFieldArray.filter(field => field === '').length === 0

const initialState = {
  username: '',
  firstname: '',
  lastname: '',
  email: '',
  ownerId: AGODA_OWNER_ID,
  groupId: 0,
  isNew: true
}


class UserManagement extends React.Component
{

  constructor(props) {
    super(props)
    this.state = {
      ...initialState,
      isNew: (props.new)
    }
  }

  componentDidMount() {
    if ((this.props.match.params.userid) && (this.state.userId !== this.props.match.params.userid)) {
      this.setState({...this.state, isNew: false})
      this.props.getUserInfo(this.props.match.params.userid)
    } else {
      this.setState(initialState)
    }
    if ((!this.props.generalUserDetails) || ( (this.props.generalUserDetails.userGroups) && Array.isArray(this.props.generalUserDetails.userGroups) && this.props.generalUserDetails.userGroups.length === 0)) {
      this.props.getUserGroups(AGODA_OWNER_ID)
    }
  }

  componentWillReceiveProps(nextProps) {
    if (!this.state.isNew) {
      this.setState({...this.state, ...nextProps.user})
    }

    //Initialize the groupId if we are just loading the groups and there is no group defined yet
    if (
      ((!this.props.generalUserDetails) || (this.props.generalUserDetails.userGroups.length === 0)) &&
        ((nextProps.generalUserDetails.userGroups) && Array.isArray(nextProps.generalUserDetails.userGroups) && nextProps.generalUserDetails.userGroups.length !== 0)
    ) {
      let defaultGroupId = nextProps.generalUserDetails.userGroups[0].GROUPID
      initialState.groupId = defaultGroupId
      if (this.state.groupId === 0){
        this.setState({groupId: defaultGroupId})
      }
    }
  }

  resetCreation = () => {
    this.setState(initialState)
    this.props.resetUserCreated()
  }

  render() {
    return <React.Fragment>
      <div>
        <Wrapper>

          {this.props.userCreated &&
          <DialogBox open={this.props.userCreated} onClick={this.resetCreation}>The user has been created!</DialogBox>}
          <form style={styles.form}>
            <Card style={styles.card}>
              <Typography variant="h5" gutterBottom>
                {this.state.isNew ? "Add User" : "Edit User"}
              </Typography>
              <TextField
                autoFocus
                id="firstName"
                label="First Name"
                value={this.state.firstname}
                style={styles.textField}
                onChange={e => this.setState({...this.state, firstname: e.target.value})}
              />
              <TextField
                id="lastName"
                label="Last Name"
                value={this.state.lastname}
                style={styles.textField}
                onChange={e => this.setState({...this.state, lastname: e.target.value})}
              />
              <TextField
                required
                id="email"
                label="Email"
                type="email"
                value={this.state.email}
                style={styles.textField}
                onChange={e => this.setState({...this.state, email: e.target.value})}
              />
              <TextField
                required
                id="username"
                label="Username"
                value={this.state.username}
                style={styles.textField}
                onChange={e => this.setState({...this.state, username: e.target.value})}
                onKeyPress={e => e.key === 'Enter' && this.props.saveUser(this.state)}
              />
              <TextField
                id="select-currency-native"
                select
                label="User Group"
                SelectProps={{
                  native: true,
                }}
                value={this.state.groupId}
                helperText="Please select user group"
                margin="normal"
                style={styles.select}
                onChange={e => this.setState({...this.state, groupId: e.target.value})}
              >
                { this.props.generalUserDetails &&
                  this.props.generalUserDetails.userGroups.map(group => <option key={group.GROUPID} value={group.GROUPID}>{group.GROUPDESCRIPTION}</option>)
                }
              </TextField>
              <Notification
                type={'error'}
                message={this.props.errorMsg}
                open={this.props.errorMsg !== ''}
                closed={this.props.dismissError}
              />

              <Notification
                type={'success'}
                message={'User saved successfully'}
                open={this.props.confirmationOpen}
                closed={this.props.dismissConfirmation}
              />

              <Tooltip
                title="please fill in all required fields"
                disableTouchListener={true}
                disableFocusListener={true}
                disableHoverListener={validateNoEmptyFields([this.state.username, this.state.email])}>
                <div style={{width: 'fit-content'}}>
                  { (!this.state.isNew) ? (
                    <Link to='/user/search' style={{textDecoration: 'none'}}>
                      <Button className={styles.button} variant="contained">Cancel</Button>
                    </Link>
                  ) :
                    null
                  }
                  <Button
                    onClick={() => {this.props.saveUser(this.state)}}
                    style={styles.button} variant="contained" color='primary'
                    disabled={!validateNoEmptyFields([this.state.username, this.state.email])}
                  >
                    Save
                  </Button>
                </div>
              </Tooltip>
            </Card>
          </form>
        </Wrapper>
      </div>
    </React.Fragment>
  }
}

const mapStateToProps = (state, props) => ({
  user: state.userAdmin.user,
  errorMsg: state.userAdmin.errorMsg,
  confirmationOpen: state.userAdmin.confirmationOpen,
  userCreated: state.userAdmin.created,
  generalUserDetails: state.generalUserDetails
})

const mapDispatchToProps = {
  saveUser, getUserInfo, resetUserCreated, dismissError, getUserGroups, dismissConfirmation
}

export default connect(mapStateToProps, mapDispatchToProps)(UserManagement)
