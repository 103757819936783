import 'whatwg-fetch'
import {apiCall} from './api'
import {FEE_TRANSACTION} from "../util/transaction";

export const REVERSE_TRANSACTION = 'REVERSE_TRANSACTION'

export function reverseTransaction(voucherNumber, transactionType, transactionId, reason) {
  let reverseMethod = ''

  let params = {
    voucherNumber,
    requesterName: reason.requesterName,
    storeName: reason.storeName,
    contactPhone: reason.contactPhone,
    declineReason: reason.declineReason,
    comments: reason.comments
  }

  switch (transactionType) {
    case FEE_TRANSACTION:
      reverseMethod = 'reverseTransactionFee'
      params.authorisationId = transactionId
      break
    default:
      //No other methods are implemented yet they may need a different method name and different params (eg: loadId = transactionId)
      reverseMethod = ''
  }

  const payload = apiCall(reverseMethod, params)

  return {
    type: REVERSE_TRANSACTION,
    payload,
    meta: {
      transactionId
    }
  }
}

export const RESET_REVERSE_TRANSACTION = 'RESET_REVERSE_TRANSACTION'

export function resetReverseTransaction() {
  return {
    type: RESET_REVERSE_TRANSACTION
  }
}


export const RESET_SUCCESS = 'RESET_SUCCESS'

export const resetSuccess = () => ({type: RESET_SUCCESS})
