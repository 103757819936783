import {GET_CURRENT_USER, CURRENT_USER_SAVE, CHANGE_USER_PASSWORD, CHANGE_PASSWORD_RESULT_AS_DEFAULT, DISMISS_CURRENT_USER_ERROR} from '../action/currentUser'

const initialState = {
  errorMsg: '',
  changePasswordSuccess: false,
  userResults: {},
  resultCode: 0,
  resultText: ''
}

export default (state = initialState, action) => {
  const {type, payload} = action
  switch (type) {
    case `${GET_CURRENT_USER}_FULFILLED`:
      if (payload.resultCode === "0") {
        return {
          ...state,
          errorMsg: '',
          userResults: payload.resultText
        }
      } else {
        return {
          ...state,
          errorMsg: payload.resultText
        }
      }
    case `${GET_CURRENT_USER}_REJECTED`:
      return {
        ...state,
        errorMsg: 'Cannot connect to server'
      }
    case `${CURRENT_USER_SAVE}_FULFILLED`:
      if (payload.resultCode === "0") {
        return {
          ...state,
          userResults: action.meta.user,
          errorMsg: ''
        }
      } else {
        return {
          ...state,
          userResults: action.meta.user,
          errorMsg: payload.resultText
        }
      }
  case `${CHANGE_USER_PASSWORD}_FULFILLED`:
    if(payload.resultCode === "0") {
      return {
        ...state,
        changePasswordSuccess: true
      }
    } else {
      return {
        ...state,
        resultCode: payload.resultCode,
        resultText: payload.resultText
      }
    }
  case CHANGE_PASSWORD_RESULT_AS_DEFAULT:
    return {
      ...state,
      changePasswordSuccess: false,
      resultCode: 0,
      resultText: ''
    }
  case DISMISS_CURRENT_USER_ERROR:
    return {
      ...state,
      errorMsg: ''
    }
  default:
    return state
  }
}
