import {CARD_DETAILS, CARD_DETAILS_ERROR_MESSAGE, CARD_STATEMENT, CARD_DETAILS_CLEAR, GET_SETTLEMENT_CHARGEBACKS, CLEAR_SETTLEMENT_CHARGEBACKS} from '../action/cardDetails'
import { SET_CARD_STATUS } from '../action/card/setCardStatus';

const initialState = {
  errorMsg: '',
  transactions:[],
  visibleColumns:[],
  exportColumns:[],
  dateFrom:'',
  dateTo:'',
  cardDetailsResponse:{},
  excelBase64Data: '',
  resultCode: 0,
  pending:false,
  chargebackInfo: []
}

export default (state = initialState, action) => {
  const {type, payload} = action
  switch (type) {
    case CARD_DETAILS_ERROR_MESSAGE:
      return {
        ...state,
        errorMsg: 'An error occurred',
        cardDetailsResponse: '',
        pending:false
      }
    case `${CARD_DETAILS}_FULFILLED`:
      if (payload.resultCode === "0") {

        return {
          ...state,
          errorMsg: '',
          cardDetailsResponse: JSON.parse(payload.resultText.cardDetails)[0],
          pending:false
        }
      } else {
        return {
          ...state,
          errorMsg: payload.resultText,
          cardDetailsResponse: '',
          pending:false
        }
      }
    case `${CARD_DETAILS}_PENDING`:
      return {
        ...state,
        errorMsg: '',
        pending:true
      }
    case `${CARD_DETAILS}_REJECTED`:
      return {
        ...state,
        errorMsg: 'Cannot connect to server',
        cardDetailsResponse:'',
        pending:false
      }

    case `${CARD_STATEMENT}_FULFILLED`:
      return {
        ...state,
        transactions: payload.statement? [
          ...payload.statement
        ] : [],
        visibleColumns: payload.visibleColumns ? [
          ...payload.visibleColumns
        ] : [],
        exportColumns: payload.exportColumns ? [
          ...payload.exportColumns
        ] : [],
        excelBase64Data: payload.excelData,
        resultCode: payload.resultCode,
        pending:false
      }

    case `${SET_CARD_STATUS}_FULFILLED`:
      return {
        ...state,
        cardDetailsResponse: {
          ...state.cardDetailsResponse,
          STATUS: payload.resultCode === "0"
        },
        pending:false
      }

    case CARD_DETAILS_CLEAR:
      return initialState

    case `${GET_SETTLEMENT_CHARGEBACKS}_FULFILLED`:
      if (payload.resultCode === "0") {
        return {
          ...state,
          errorMsg: '',
          chargebackInfo: payload.chargebacks,
          pending:false
        }
      } else {
        return {
          ...state,
          errorMsg: payload.resultText,
          chargebackInfo: [],
          pending:false
        }
      }
    case `${GET_SETTLEMENT_CHARGEBACKS}_PENDING`:
      return {
        ...state,
        errorMsg: '',
        chargebackInfo: [],
        pending: true
      }
    case `${GET_SETTLEMENT_CHARGEBACKS}_REJECTED`:
      return {
        ...state,
        errorMsg: 'Cannot connect to server',
        chargebackInfo: [],
        pending: false
      }
    case CLEAR_SETTLEMENT_CHARGEBACKS:
      return {
        ...state,
        errorMsg: '',
        chargebackInfo: [],
        pending: false
      }
    default:
      return state
  }
}
