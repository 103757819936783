import React from 'react'
import BaseRoute from './BaseRoute'
import { Route } from 'react-router'
import PrivateRoute from '../component/default/route/PrivateRoute'
import App from '../container/agoda/App'
import Home from '../container/agoda/Home'
import Reports from '../container/agoda/Reports'
import CardSearch from '../container/agoda/CardSearch'
import CardDetails from '../container/agoda/CardDetails'
import InsertCardFee from '../container/agoda/InsertCardFee'
import ActivateCards from '../container/agoda/ActivateCards'
import CardOrder from '../container/agoda/card/CardOrder'
import CardOrderReport from '../container/agoda/reports/CardOrderReport'
import CardholderStatement from '../container/agoda/reports/CardholderStatement'
import DailySettlementDetailReport from '../container/agoda/reports/DailySettlementDetail'
import ExpiredCardsReport from '../container/agoda/reports/ExpiredCardsReport'
import ExpiredCardsSummaryReport from '../container/agoda/reports/ExpiredCardsSummaryReport'
import DevalueReport from '../container/agoda/reports/DevalueReport'
import SpendSummaryReport from '../container/agoda/reports/SpendSummaryReport'
import CardBalanceReport from '../container/agoda/reports/CardBalanceReport'
import DetailedTopupReport from '../container/agoda/reports/DetailedTopupReport'
import DailySettlementSummary from '../container/agoda/reports/DailySettlementSummary'
import FeeReport from '../container/agoda/reports/Fee'
import UnsettledTransactions from '../container/agoda/reports/UnsettledTransactions'
import TopUpSummaryReport from '../container/agoda/reports/TopUpSummaryReport'
import LiabilityReport from '../container/agoda/reports/Liability'
import UserManagement from '../container/agoda/UserManagement'
import UserSearch from '../container/agoda/UserSearch'
import BatchLoad from '../container/agoda/BatchLoad'
import BatchLoads from '../container/agoda/BatchLoads'
import BatchDevalue from '../container/agoda/BatchDevalue'
import BatchTransfer from '../container/agoda/BatchTransfer'
import ChargebackReport from '../container/agoda/ChargebackReport'
import QmrReport from '../container/agoda/reports/QmrReport'
import ActivateUser from '../container/agoda/ActivateUser'
import CardSpendReport from '../container/agoda/reports/CardSpendReport'
import FundMovementReport from '../container/agoda/reports/FundMovementReport'
import MonthlyComplianceReport from '../container/agoda/reports/MonthlyComplianceReport'
import NegativeBalanceReport from '../container/agoda/reports/NegativeBalanceReport'
import SettlementsWithoutAuthorisationsReport from '../container/agoda/reports/SettlementsWithoutAuthorisationsReport'
import VirtualCardOrdersBatchLoad from '../container/agoda/virtualCardOrdersBatchLoads'
import CardVirtualCardOrder from '../container/agoda/card/CardVirtualCardOrder'

export default (
  <BaseRoute App={App} Home={Home}>
    <Route exact path="/user/:uuid/activate" component={ActivateUser} />
    <PrivateRoute
      exact
      path="/batchload"
      component={BatchLoad}
      permissions={['Batch.load']}
    />
    <PrivateRoute
      exact
      path="/batchloads"
      component={BatchLoads}
      permissions={['Batch.approve']}
    />
    <PrivateRoute
      exact
      path="/batchdevalue"
      component={BatchDevalue}
      permissions={['Batch.redeem']}
    />
    <PrivateRoute
      exact
      path="/batchtransfer"
      component={BatchTransfer}
      permissions={['Batch.redeem']}
    />
    <PrivateRoute
      exact
      path="/activateCards"
      component={ActivateCards}
      permissions={['Card.write']}
    />
    <PrivateRoute
      exact
      path="/card/insertfee"
      component={InsertCardFee}
      permissions={['Card.write']}
    />
    <PrivateRoute
      exact
      path="/card/order"
      component={CardOrder}
      permissions={['Card.write']}
    />
    <PrivateRoute
      exact
      path="/card/virtual-card-order"
      component={CardVirtualCardOrder}
      permissions={['Card.order']}
    />
    <PrivateRoute
      exact
      path="/card/search"
      component={CardSearch}
      permissions={['CardStatement.read']}
    />
    <PrivateRoute
      exact
      path="/card/details/:voucherID"
      component={CardDetails}
      permissions={['CardStatement.read']}
    />
    <PrivateRoute
      exact
      path="/card/virtual-card-orders"
      component={VirtualCardOrdersBatchLoad}
      permissions={['Card.approve']}
    />
    <PrivateRoute
      exact
      path="/reports"
      component={Reports}
      permissions={['Report.read']}
    />
    <PrivateRoute
      exact
      path="/reports/liability"
      component={LiabilityReport}
      permissions={['Report.read']}
    />
    <PrivateRoute
      exact
      path="/reports/expiredCardsReport"
      component={ExpiredCardsReport}
      permissions={['Report.read']}
    />
    <PrivateRoute
      exact
      path="/reports/devalueReport"
      component={DevalueReport}
      permissions={['Report.read']}
    />
    <PrivateRoute
      exact
      path="/reports/expiredCardsSummaryReport"
      component={ExpiredCardsSummaryReport}
      permissions={['Report.read']}
    />
    <PrivateRoute
      exact
      path="/reports/cardSpendReport"
      component={CardSpendReport}
      permissions={['Report.read']}
    />
    <PrivateRoute
      exact
      path="/reports/spendSummaryReport"
      component={SpendSummaryReport}
      permissions={['Report.read']}
    />
    <PrivateRoute
      exact
      path="/reports/cardBalanceReport"
      component={CardBalanceReport}
      permissions={['Report.read']}
    />
    <PrivateRoute
      exact
      path="/reports/detailedTopupReport"
      component={DetailedTopupReport}
      permissions={['Report.read']}
    />
    <PrivateRoute
      path="/reports/fundMovementReport" 
      component={FundMovementReport} 
      permissions={["Report.read"]}
    />
    <PrivateRoute
      exact
      path="/reports/cardholderStatement"
      component={CardholderStatement}
      permissions={['Report.read']}
    />
    <PrivateRoute
      exact
      path="/reports/cardorders"
      component={CardOrderReport}
      permissions={['Report.read']}
    />
    <PrivateRoute
      exact
      path="/reports/dailySettlementDetail"
      component={DailySettlementDetailReport}
      permissions={['Report.read']}
    />
    <PrivateRoute
      exact
      path="/reports/dailySettlementSummary"
      component={DailySettlementSummary}
      permissions={['Report.read']}
    />
    <PrivateRoute
      exact
      path="/reports/forexFee"
      component={FeeReport}
      permissions={['Report.read']}
    />
    <PrivateRoute
      exact
      path="/reports/qmr"
      component={QmrReport}
      permissions={['Report.read']}
    />
    <PrivateRoute
      exact
      path="/reports/unsettledTransactions"
      component={UnsettledTransactions}
      permissions={['Report.read']}
    />
    <PrivateRoute
      exact
      path="/reports/fee"
      component={FeeReport}
      permissions={['Report.read']}
    />
    <PrivateRoute
      exact
      path="/reports/unsettledTransactions"
      component={UnsettledTransactions}
      permissions={['Report.read']}
    />
    <PrivateRoute
      exact
      path="/reports/topUp"
      component={TopUpSummaryReport}
      permissions={['Report.read']}
    />
    <PrivateRoute
      exact
      path="/reports/liability"
      component={LiabilityReport}
      permissions={['Report.read']}
    />
    <PrivateRoute
      exact
      path="/reports/chargebackReport"
      component={ChargebackReport}
      permissions={['Report.read']}
    />
    <PrivateRoute
      exact
      path="/reports/settlementsWithoutAuthorisationsReport"
      component={SettlementsWithoutAuthorisationsReport}
      permissions={['Report.read']}
    />
    <PrivateRoute
      exact
      path="/user/adduser"
      component={props => <UserManagement {...props} add={true} />}
      permissions={['User.write']}
    />
    <PrivateRoute
      exact
      path="/user/edituser/:userid"
      component={props => <UserManagement {...props} add={false} />}
      permissions={['User.write']}
    />
    <PrivateRoute
      exact
      path="/user/search"
      component={UserSearch}
      permissions={['User.write']}
    />
    <PrivateRoute
      exact
      path="/reports/monthlyComplianceReport"
      component={MonthlyComplianceReport}
      permissions={['Report.read']}
    />
    <PrivateRoute
      exact
      path="/reports/negativeBalanceReport"
      component={NegativeBalanceReport}
      permissions={['Report.read']}
    />
  </BaseRoute>
)
