import {apiCall} from './api'
import Config from "../config";

export const REMEMBER_PASSWORD = 'REMEMBER_PASSWORD'
export const rememberPassword = (account) => {
  const ownerId = Config.ownerID
  const payload = apiCall('rememberPassword', {account, ownerId})
  return {
    type: REMEMBER_PASSWORD,
    payload,
    meta: {}
  }
}

export const RESET_REMEMBER_PASSWORD = 'RESET_REMEMBER_PASSWORD'
export const reset = () => {
  return {
    type: RESET_REMEMBER_PASSWORD
  }
}
