import React, {Component} from 'react'
import StartAndEndDate from '../../../component/agoda/reports/StartAndEndDate'
import Paper from '@material-ui/core/Paper'
import Typography from '@material-ui/core/Typography'
import { withStyles, withTheme } from '@material-ui/core/styles'
import Button from '@material-ui/core/Button'
import Icon from '@material-ui/core/Icon'
import { getDateRangeReport } from '../../../action'
import Table from '@material-ui/core/Table'
import TableHead from '@material-ui/core/TableHead'
import TableCell from '@material-ui/core/TableCell'
import TableRow from '@material-ui/core/TableRow'
import TableBody from '@material-ui/core/TableBody'
import { connect } from 'react-redux'
import dateFormat from 'dateformat'
import CampaignSelect, { combineCampaigns } from './CampaignSelect'
import b64toBlob from '../../../util/b64toBlob'
import download from 'downloadjs'
import { getCampaigns, getDateRangeLimit } from '../../../action'
import Grid from "@material-ui/core/Grid";
import Tooltip from "@material-ui/core/Tooltip";
import IconButton from "@material-ui/core/IconButton";

var now = new Date()

class BaseDateRangeReport extends Component {
  state = {
    startDate : dateFormat(now, "yyyy-mm-dd"),
    endDate : dateFormat(now, "yyyy-mm-dd"),
    campaign : 0
  }

  componentDidMount = () => {
    this.props.getCampaigns()
    this.props.getDateRangeLimit(this.props.apiReportName)
  }

  componentDidUpdate = (prevProps, prevState) => {
    if (prevProps.campaigns !== this.props.campaigns){
      this.setState({campaign: combineCampaigns(this.props.campaigns)})
    }

    if (this.props.report.dateRangeLimit && (prevState.startDate !== this.state.startDate || prevState.endDate !== this.state.endDate) ) {
      const dateInSecond = 60e3 * 60 * 24 
      let endDate = new Date(dateFormat(this.state.endDate, "isoDateTime"))
      let startDate = new Date(dateFormat(this.state.startDate, "isoDateTime"))
      let dateDiffInSecond = endDate - startDate
      let dateDiffInDay  = Math.floor(dateDiffInSecond / dateInSecond)
      if (dateDiffInDay > this.props.report.dateRangeLimit) {
        let dateLimitInSecond = this.props.report.dateRangeLimit * dateInSecond
        let newEndDate = startDate.getTime() + dateLimitInSecond
        this.setState({endDate:dateFormat(newEndDate, 'yyyy-mm-dd')})
        alert(`date limit is ${this.props.report.dateRangeLimit} days, end date is set to ${dateFormat(newEndDate, 'yyyy-mm-dd')}`)
      }
    }
  }
  

  handleInputChange = e => this.setState({[e.target.name]:e.target.value})

  handleClick = () => this.props.getDateRangeReport(
    this.props.apiReportName, 
    {
      startDate: this.state.startDate, 
      endDate: this.state.endDate,
      campaign: this.state.campaign
    }
  )

  createCsvString = () => {
    var CsvString = ""
    var results = [
      this.props.report.columns,
      ...this.props.report.data
    ]
    results.forEach((rowItem) => {
      rowItem.forEach((ColItem, colIndex) => {
        let insertString = this.replaceNullByBlank(ColItem)
        insertString = this.replaceCommaByBlank(insertString)
        CsvString += insertString
        CsvString += colIndex < rowItem.length -1 ? ',' : ''
      })
      CsvString += "\r\n"
    })
    CsvString = "data:application/csv," + encodeURIComponent(CsvString)
    return CsvString
  }

  replaceNullByBlank = data => data === null ? '' : data
  replaceCommaByBlank = data => data && data.replace ? data.replace(',', '') :   data

  createExcelData = () => {
    const columns = this.props.report.columns
    return this.props.report.data.map(row=> this.constructRowObject(row, columns))
  }

  constructRowObject = (row, columns) => columns.reduce((object,column, index) => ({...object, [column]: row[index]}), {})

  downloadExcelData = () => {
    const mimeType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
    const fileExtension = 'xlsx'
    const binaryExcelData = b64toBlob(this.props.report.excelBase64)
    const fileName = `${this.props.reportDownloadName}_from${this.state.startDate}_to${this.state.endDate}.${fileExtension}`
    download(binaryExcelData, fileName, mimeType)
  }

  alignRight = columnData => columnData ? 'right': 'left'

  getCurrencySymbol = () => this.props.campaigns.reduce( (campaign, symbol) => campaign.CampaignID === this.state.campaign ? campaign.CampaignCurrencySymbol : symbol, '').CampaignCurrencySymbol
  
  applyDecimal = ( data, columnKey ) => data && (this.props.report.meta && this.props.report.meta[columnKey]) && (this.props.report.meta[columnKey].TypeName === 'decimal' || 
    this.props.report.meta[columnKey].TypeName ===  'money' )? 
      data.toFixed(2) : data

  render() {
    const { classes, report, theme, chooseCampaignDisabled, downloadOnly } = this.props
    return (
      <div>
        <Paper className={classes.paper} style={theme.palette.paperWrapper}>
          <Typography  variant="h5" gutterBottom>{this.props.reportHeader}</Typography>
          <StartAndEndDate startValue={this.state.startDate} endValue={this.state.endDate} onStartChange={this.handleInputChange} onEndChange={this.handleInputChange} />
          {
            !chooseCampaignDisabled &&
              <CampaignSelect
                classes={classes}
                campaigns={this.props.campaigns}
                campaign = {this.state.campaign}
                handleInputChange={this.handleInputChange}
              />
          }

          <Button variant = 'contained' color='primary' onClick={this.handleClick}>
            <Icon>search</Icon>
          </Button>
        </Paper>
        <Paper className={classes.paper} style={theme.palette.paperWrapper}>
          <Grid
            justify="space-between"
            container
          >
            <Grid item>
              <Typography gutterBottom variant="h5">
                Result
              </Typography>
            </Grid>
            <Grid item>
              {
                (report.data.length > 0) ?
                  (
                    <a href={this.createCsvString()}
                       download={`${this.props.reportDownloadName}_${this.state.startDate}-${this.state.endDate}.csv`}>
                      <Tooltip title="CSV"><Icon style={{verticalAlign: 'middle', cursor: 'pointer'}}
                                                 color="primary">cloud_download</Icon></Tooltip>
                    </a>
                  ) : null
              }
              &nbsp;&nbsp;
              &nbsp;&nbsp;
              {
                (report.data.length > 0) ?
                  (
                    <IconButton disabled={!(report.data.length > 0)} style={{verticalAlign: 'middle', cursor: 'pointer'}}
                                onClick={this.downloadExcelData}>
                      <Tooltip title="Excel"><Icon color="primary">receipt</Icon></Tooltip>
                    </IconButton>
                  ) : null
              }
            </Grid>
          </Grid>
              { !downloadOnly ? (
                  <Table>
                    <TableHead>
                      <TableRow>
                        {
                          report.columns.length > 0 &&
                          report.columns.map(
                            (column, index) => <TableCell style={{textAlign:this.alignRight(report.meta[index].TypeName === 'decimal' || report.meta[index].TypeName === 'money')}}  key={index}>{column}</TableCell>
                          )
                        }
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {
                        report.data.length > 0 &&
                        report.data.map(
                          (row, rowKey) => <TableRow key={`row-${rowKey}`}>
                            {row.length > 0 && row.map(
                              (column, columnKey) => <TableCell style={{textAlign:this.alignRight( (report.meta && report.meta[columnKey]) && (report.meta[columnKey].TypeName === 'decimal' || report.meta[columnKey].TypeName === 'money'))}} key={`row-${rowKey}-column-${columnKey}`} >{this.applyDecimal(column, columnKey)}</TableCell>
                            )}
                          </TableRow>
                        )
                      }
                    </TableBody>
                  </Table>) :
                (
                  ( report.notFound) ?
                    (
                      <div style={{marginTop: 25}}>
                        No records found.
                      </div>
                    )
                    : null
                )
              }
              { downloadOnly  && report.data.length ? (
                  (
                    <div style={{marginTop: 25}}>
                      Press the download buttons to get the report.
                    </div>
                  )
                ) : null
              }
        </Paper>
      </div>
    )
  }
}

const styles = theme => ({
  root: {
    ...theme.mixins.gutters(),
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
  },
  button: {
    color: 'white',
    marginRight: '20px'
  },
  formControl: {
    marginLeft:'30px',
    width: '170px'
  },
  paper: {
    overflowX: 'auto',
    marginTop:'20px',
    padding:'20px'
  },
  table: {
    display: 'block',
    overflowX: 'auto',
    whiteSpace: 'nowrap'
    },
  select: {
    width: '170px'
  },
  search: {
    marginLeft: '100px'
  }
})

const mapStateToProps = state => ({
  report: state.report,
  campaigns: state.campaign.campaigns
})

const mapDispatchToProps = {
  getDateRangeReport,
  getCampaigns,
  getDateRangeLimit
}

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(withTheme(BaseDateRangeReport)))
