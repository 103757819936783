import React from 'react'
import PropTypes from 'prop-types'
import Dialog from '@material-ui/core/Dialog'
import DialogContent from '@material-ui/core/DialogContent'
import AppBar from '@material-ui/core/AppBar'
import Toolbar from '@material-ui/core/Toolbar'
import IconButton from '@material-ui/core/IconButton'
import Tooltip from '@material-ui/core/Tooltip'
import Icon from '@material-ui/core/Icon'
import Typography from '@material-ui/core/Typography'
import Card from '@material-ui/core/Card'
import CardContent from '@material-ui/core/CardContent'
import { makeStyles } from '@material-ui/core'

import VirtualCardOrdersDetailsTable from './VirtualCardOrdersDetailsTable'
import { BUTTON_TOOLTIP_DELAY } from '../../../constants/virtualCardOrder'

const useStyles = makeStyles(() => ({
  paper: {
    minWidth: 'calc(100% - 380px)',
    minHeight: '80vh',
    position: 'fixed',
    top: '30px',
  },
}))

const VirtualCardOrdersDetails = ({ virtualCardsOrders, onDownloadReport, onClose }) => {
  const classes = useStyles()
  return (
    <Dialog
      open
      scroll="paper"
      data-testid="vco-detsils-container"
      classes={{ paper: classes.paper }}
    >
      <AppBar style={{ position: 'relative' }}>
        <Toolbar>
          <Typography variant="h6" style={{ flex: '1' }}>
            Virtual Card Order Details
          </Typography>
          <Tooltip title="Download Report" enterDelay={BUTTON_TOOLTIP_DELAY}>
            <IconButton
              color="inherit"
              onClick={onDownloadReport}
              aria-label="Download Report"
              data-testid="vco-download-report-button"
            >
              <Icon>assignment_returned</Icon>
            </IconButton>
          </Tooltip>
          <IconButton
            className="batch-load-details-close"
            color="inherit"
            onClick={onClose}
            aria-label="Close"
            data-testid="vco-close-details-button"
          >
            <Icon>close</Icon>
          </IconButton>
        </Toolbar>
      </AppBar>
      <DialogContent>
        {virtualCardsOrders ? (
          <Card>
            <CardContent>
              <VirtualCardOrdersDetailsTable virtualCardsOrders={virtualCardsOrders} />
            </CardContent>
          </Card>
        ) : null}
      </DialogContent>
    </Dialog>
  )
}

VirtualCardOrdersDetails.propTypes = {
  virtualCardsOrders: PropTypes.array,
  onDownloadReport: PropTypes.func,
  onClose: PropTypes.func,
}

export default VirtualCardOrdersDetails
