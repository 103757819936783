export function parse(strData, strDelimiter = ",") {

  // Create a regular expression to parse the CSV values.
  let objPattern = new RegExp(
    (
      // Delimiters.
      "(\\" + strDelimiter + "|\\r?\\n|\\r|^)" +

      // Quoted fields.
      "(?:\"([^\"]*(?:\"\"[^\"]*)*)\"|" +

      // Standard fields.
      "([^\"\\" + strDelimiter + "\\r\\n]*))"
    ),
    "gi"
  )


  // Create an array to hold our data. Give the array
  // a default empty first row.
  let arrData = [[]]

  // Create an array to hold our individual pattern
  // matching groups.

  // Keep looping over the regular expression matches
  // until we can no longer find a match.
  let arrMatches = objPattern.exec(strData)

  //To prevent an infinite loop
  let previousIndex = -1

  while (arrMatches) {
    if (arrMatches.index === previousIndex) {
      break
    }

    // Get the delimiter that was found.
    let strMatchedDelimiter = arrMatches[1]

    // Check to see if the given delimiter has a length
    // (is not the start of string) and if it matches
    // field delimiter. If id does not, then we know
    // that this delimiter is a row delimiter.
    if (
      strMatchedDelimiter.length &&
      strMatchedDelimiter !== strDelimiter
    )
    {

      // Since we have reached a new row of data,
      // add an empty row to our data array.
      arrData.push([])

    }

    let strMatchedValue

    // Now that we have our delimiter out of the way,
    // let's check to see which kind of value we
    // captured (quoted or unquoted).
    if (arrMatches[2]) {

      // We found a quoted value. When we capture
      // this value, unescape any double quotes.
      strMatchedValue = arrMatches[2].replace(
        new RegExp("\"\"", "g"),
        "\""
      )

    } else {

      // We found a non-quoted value.
      strMatchedValue = arrMatches[3]

    }


    // Now that we have our value string, let's add
    // it to the data array.
    previousIndex = arrMatches.index
    arrData[arrData.length - 1].push(strMatchedValue)
    arrMatches = objPattern.exec(strData)
  }

  //Remove empty line if needed
  if ((arrData[arrData.length - 1].length === 1) && (arrData[arrData.length - 1][0] === "")) {
    arrData.splice(-1, 1)
  }

  // Return the parsed data.
  return (arrData)
}
