import { apiCall } from './../api'

export const SET_CARD_STATUS = 'SET_CARD_STATUS'

export function setCardStatus(cardArray,type) {
  const payload = apiCall('SetCardActive', {cardArray, type})
  return {
    type: SET_CARD_STATUS,
    payload,
    meta: {
		cardArray
    }
  }
}

export const SET_CARD_STATUS_ERROR_MESSAGE = 'SET_CARD_STATUS_ERROR_MESSAGE'

export function setCardStatusErrorMessage() {
  return {
    type: SET_CARD_STATUS_ERROR_MESSAGE
  }
}

export const SET_CARD_STATUS_CLEAR = 'SET_CARD_STATUS_CLEAR'

export function setCardStatusClear () {
  return {
    type: SET_CARD_STATUS_CLEAR
  }
}
