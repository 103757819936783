import { LOAD_REPORT, GET_DATE_RANGE_REPORT, GET_DAILY_REPORT, GET_DATE_RANGE_LIMIT, GET_REPORT } from "../action/report"
import { LOCATION_CHANGE } from 'connected-react-router'

const initialState = {
  data: [],
  reportURL: '',
  errorMsg: '',
  notFound: false,
  pending: false,
  columns: [],
  excelBase64:'',
  dateRangeLimit: 0,
  meta: []
}

const reportInfo = (state = initialState, action) => {
  const { type, payload } = action

  switch (type) {
    case `${LOCATION_CHANGE}`:
      return initialState
    case `${LOAD_REPORT}_FULFILLED`:
      return {
        ...state,
        data: payload.reports,
        reportURL: payload.reportURL,
        errorMsg: ''
      }
    case `${LOAD_REPORT}_PENDING`:
      return {
        ...state,
        data: null
      }
    case `${LOAD_REPORT}_REJECTED`:
      return {
        ...state,
        data: null,
        errorMsg: action.error.message || 'Cannot connect to server'
      }
    case `${GET_DATE_RANGE_REPORT}_PENDING`:
      return {
        ...state,
        data: [],
        reportURL: '',
        errorMsg: '',
        columns: [],
        excelBase64:'',
        meta: [],
        pending: true,
        notFound: false
      }
    case `${GET_DATE_RANGE_REPORT}_FULFILLED`:
      let parsedData = []
      let parsedColumns = []
      if (payload.reportData) {
        const parsed = JSON.parse(payload.reportData)
        if (parsed.DATA) {
          parsedData = parsed.DATA
        }
        if (parsed.COLUMNS) {
          parsedColumns = parsed.COLUMNS
        }
      }
      return {
        ...state,
        data: parsedData,
        columns: parsedColumns,
        meta: payload.reportMeta && JSON.parse(payload.reportMeta),
        excelBase64: payload.reportExcel ? payload.reportExcel : null,
        pending: false,
        notFound: parsedData.length === 0
      }
    case `${GET_DATE_RANGE_LIMIT}_FULFILLED`:
      return {
        ...state,
        dateRangeLimit: parseInt(payload.limit, 10)
      }
    case `${GET_DAILY_REPORT}_PENDING`:
      return {
        ...state,
        pending: true
      }
    case `${GET_DAILY_REPORT}_FULFILLED`:
      return {
        ...state,
        pending: false,
        reportURL: payload.reportURL
      }
    case `${GET_REPORT}_PENDING`:
      return {
        ...state,
        pending: true
      }
    case `${GET_REPORT}_FULFILLED`:
      return {
        ...state,
        pending: false,
        reportURL: payload.reportURL
      }
    default:
      return state
  }

}


export default reportInfo
