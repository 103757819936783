import React, {Component} from 'react'
import Paper from '@material-ui/core/Paper'
import Typography from '@material-ui/core/Typography'
import { withStyles, withTheme } from '@material-ui/core/styles'
import Button from '@material-ui/core/Button'
import Icon from '@material-ui/core/Icon'
import { getMonthlyReport, getReport } from '../../../action/report'
import Table from '@material-ui/core/Table'
import TableHead from '@material-ui/core/TableHead'
import TableCell from '@material-ui/core/TableCell'
import TableRow from '@material-ui/core/TableRow'
import TableBody from '@material-ui/core/TableBody'
import { connect } from 'react-redux'
import dateFormat from 'dateformat'
import CampaignSelect, { combineCampaigns } from './CampaignSelect'
import b64toBlob from '../../../util/b64toBlob'
import download from 'downloadjs'
import { getCampaigns } from '../../../action'
import { MuiPickersUtilsProvider, DatePicker } from 'material-ui-pickers'
import MomentUtils from '@date-io/moment'
import Notification from '../../../component/default/layout/Notification'

var now = new Date()

class BaseMonthlyReport extends Component {
  state = {
    reportMonth : dateFormat(now, "yyyy-mm"),
    campaign : 0,
    showNotFound : false
  }

  componentDidMount = () => {
    this.props.getCampaigns()
  }

  componentDidUpdate = (prevProps, prevState) => {
    if (prevProps.campaigns !== this.props.campaigns){
      this.setState({campaign: combineCampaigns(this.props.campaigns)})
    }
  }

  handleInputChange = e => this.setState({[e.target.name]:e.target.value})

  handleMonthChange = e => {
    this.setState({reportMonth:e._d})
  }

  handleClick = () => this.props.getMonthlyReport(
    this.props.apiReportName, 
    {
      reportMonth: this.state.reportMonth,
      campaign: this.state.campaign
    }
  )

  reportExists = (url) => {
    var http = new XMLHttpRequest();
    http.open('HEAD', url, false);
    http.send();
    return http.status !== 404;
  }

  downloadReportClickHandler = () => {
    this.props.loadReport(this.props.apiReportName, 0, this.state.reportMonth);
  }

  componentWillReceiveProps(nextProps) {
    this.setState({showNotFound: false})
    if ((nextProps.url !== '') && ( typeof(nextProps.url) !== 'undefined' )) {
      if (this.reportExists(nextProps.url)){
        download(nextProps.url)
      } else {
        if(nextProps.url !== this.props.url) {
          this.setState({showNotFound: true})
        }
      }
    }
  }

  createCsvString = () => {
    var CsvString = ""
    var results = [
      this.props.report.columns,
      ...this.props.report.data
    ]
    results.forEach((rowItem, rowIndex) => {
      rowItem.forEach((ColItem, colIndex) => {
        let insertString = this.replaceNullByBlank(ColItem)
        insertString = this.replaceCommaByBlank(insertString)
        CsvString += insertString
        CsvString += colIndex < rowItem.length -1 ? ',' : ''
      })
      CsvString += "\r\n"
    })
    CsvString = "data:application/csv," + encodeURIComponent(CsvString)
    return CsvString
  }

  replaceNullByBlank = data => data === null ? '' : data
  replaceCommaByBlank = data => data && data.replace ? data.replace(',', '') :   data

  createExcelData = () => {
    const columns = this.props.report.columns
    const excelData = this.props.report.data.map(row=> this.constructRowObject(row, columns))
    return excelData
  }

  constructRowObject = (row, columns) => columns.reduce((object,column, index) => ({...object, [column]: row[index]}), {})

  downloadExcelData = () => {
    const mimeType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
    const fileExtension = 'xlsx'
    const binaryExcelData = b64toBlob(this.props.report.excelBase64)
    const fileName = `${this.props.reportDownloadName}_for${this.state.reportMonth}.${fileExtension}`
    download(binaryExcelData, fileName, mimeType)
  }

  alignRight = columnData => columnData ? 'right': 'left'

  getCurrencySymbol = () => this.props.campaigns.reduce( (campaign, symbol) => campaign.CampaignID === this.state.campaign ? campaign.CampaignCurrencySymbol : symbol, '').CampaignCurrencySymbol
  
  applyDecimal = ( data, columnKey ) => data && (this.props.report.meta && this.props.report.meta[columnKey]) && (this.props.report.meta[columnKey].TypeName === 'decimal' || 
    this.props.report.meta[columnKey].TypeName ===  'money' )? 
      data.toFixed(2) : data

  render() {
    const { classes, report, theme, chooseCampaignDisabled, downloadOnly, getExistingReport } = this.props
    return (
      <div>

        <Notification
          type={'error'}
          message={'Report not found'}
          open={this.state.showNotFound}
          closed={() => this.setState({showNotFound: false})}
        />

        <Paper className={classes.paper} style={theme.palette.paperWrapper}>
          <Typography variant="h5" gutterBottom>{this.props.reportHeader}</Typography>
          <MuiPickersUtilsProvider utils={MomentUtils}>
             <DatePicker
              views={["year", "month"]}
              name="reportMonth"
              label="Date picker"
              value={this.state.reportMonth}
              onChange={this.handleMonthChange}
            />
          </MuiPickersUtilsProvider>
          {
            !chooseCampaignDisabled &&
              <CampaignSelect
                classes={classes}
                campaigns={this.props.campaigns}
                campaign = {this.state.campaign}
                handleInputChange={this.handleInputChange}
              />
          }

          { !getExistingReport ? (
            <Button variant = 'contained' color='primary' onClick={this.handleClick}>
              <Icon>search</Icon>
            </Button>
            ) : (
            <Button className="flattbutton-login" color="primary" size="medium" variant="contained" onClick={this.downloadReportClickHandler} disabled={this.state.reportMonth === ''}>
              Download
            </Button>
            )
          }
        </Paper>

        { !getExistingReport ? (
          <Paper className={classes.paper} style={theme.palette.paperWrapper}>
            <Typography variant="h5" gutterBottom>Result</Typography>
            <Button disabled={!(report.data.length >0)} className={classes.button} variant='contained' color='primary' href={this.createCsvString()} download={`${this.props.reportDownloadName}_${this.state.reportMonth}.csv`} >Download CSV</Button>
            <Button disabled={!(report.data.length >0)} className={classes.search} variant='contained' color='primary' onClick={this.downloadExcelData}>Download Excel</Button>
            { !downloadOnly ? (
              <Table>
                <TableHead>
                  <TableRow>
                    {
                      report.columns.length > 0 && 
                        report.columns.map(
                          (column, index) => <TableCell style={{textAlign:this.alignRight(report.meta[index].TypeName === 'decimal' || report.meta[index].TypeName === 'money')}}  key={index}>{column}</TableCell>
                        )
                    }
                  </TableRow>
                </TableHead>
                <TableBody>
                  {
                    report.data.length > 0 && 
                      report.data.map(
                        (row, rowKey) => <TableRow key={`row-${rowKey}`}>
                          {row.length > 0 && row.map(
                            (column, columnKey) => <TableCell style={{textAlign:this.alignRight( (report.meta && report.meta[columnKey]) && (report.meta[columnKey].TypeName === 'decimal' || report.meta[columnKey].TypeName === 'money'))}} key={`row-${rowKey}-column-${columnKey}`} >{this.applyDecimal(column, columnKey)}</TableCell>
                          )}
                        </TableRow>
                      )
                  }
                </TableBody>
              </Table>) :
                (
                  ( report.notFound) ?
                    (
                      <div style={{marginTop: 25}}>
                        No records found.
                      </div>
                    )
                  : null
                )
            }
          </Paper>
          ): null
        }
      </div>
    )
  }
}

const styles = theme => ({
  root: {
    ...theme.mixins.gutters(),
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
  },
  button: {
    color: 'white',
    marginRight: '20px'
  },
  formControl: {
    marginLeft:'30px',
    width: '170px'
  },
  paper: {
    overflowX: 'auto',
    marginTop:'20px',
    padding:'20px'
  },
  table: {
    display: 'block',
    overflowX: 'auto',
    whiteSpace: 'nowrap'
    },
  select: {
    width: '170px'
  },
  search: {
    marginLeft: '100px'
  }
})

const mapStateToProps = state => ({
  report: state.report,
  campaigns: state.campaign.campaigns,
  url: state.reportInfo.reportURL
})

const mapDispatchToProps = {
  getMonthlyReport,
  getCampaigns,
  loadReport: getReport
}

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(withTheme(BaseMonthlyReport)))
