import React, { Component } from 'react'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableRow from '@material-ui/core/TableRow'
import TableCell from '@material-ui/core/TableCell'
import TableHead from '@material-ui/core/TableHead'
import Card from '@material-ui/core/Card'
import CardHeader from '@material-ui/core/CardHeader'
import CardContent from '@material-ui/core/CardContent'
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import { addCardNote, cardNoteErrorMessage, cardNoteReset } from '../../../action/cardNote'
import {connect} from 'react-redux'
import { withTheme } from '@material-ui/core/styles'
import Notification from '../../default/layout/Notification'
import {getUserId} from '../../../util/user'

class Notes extends Component {
  constructor(props) {
    super(props)
    this.initialState = {
      showModal: false,
      notes: [],
      newNoteText: '',
      errorMsg: '',
      errorNotificationOpen: false,
    }
    this.state = {
      ...this.initialState,
    }
  }

  addNewNote = e => {
    const currentUserId = getUserId(this.props.token)
    this.props
      .addCardNote(this.state.newNoteText, this.props.cardID, currentUserId)
      .then(this.handleNewNoteAdded())
  }

  handleNewNoteAdded = () => {
    this.setState({
      newNoteText: '', 
      showModal: false,
    })
  }

  onNoteTextChange = e => {
    this.setState({newNoteText: e.target.value})
  }

  openNoteModal() {
    this.setState({showModal: true})
  }
  
  handleClose = e => {
    this.setState({showModal: false})
  }

  componentDidMount = () => {
    this.props.cardNoteReset()
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.notes) {
      this.setState({notes: JSON.parse(nextProps.notes)})
    }
    if (nextProps.savedNotes) {
      this.setState({notes: nextProps.savedNotes})
    }
    if (nextProps.errorMsg) {
      this.setState({
        errorNotificationOpen: true
      })
    }
  }

  notificationCloseHandler = () => {
    this.setState({
      errorNotificationOpen: false
    })
  }


  render() {
    const { errorMsg } = this.props
    return <React.Fragment>
      <Card style={{margin: '2px', marginTop: '4px', padding: '40px'}}>
        <CardHeader title="Voucher notes" action={<Button variant="contained" color="primary" onClick={this.openNoteModal.bind(this)}>Create note</Button>}/>
        <CardContent>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Note</TableCell>
                <TableCell align="right">Date</TableCell>
                <TableCell align="right">User</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {this.state.notes.map(row => (
                <TableRow key={row.VOUCHERNOTEDATE}>
                  <TableCell component="th" scope="row">
                    {row.VOUCHERNOTETEXT}
                  </TableCell>
                  <TableCell align="right">{row.VOUCHERNOTEDATE}</TableCell>
                  <TableCell align="right">{row.USERNAME}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </CardContent>
      </Card>
    

      <Dialog open={this.state.showModal} onClose={this.handleClose} aria-labelledby="form-dialog-title">
        <DialogTitle id="form-dialog-title">New card note</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Add new note for the card.
          </DialogContentText>
          <TextField
            autoFocus
            margin="dense"
            id="note"
            label="Note text"
            type="text"
            value={this.state.newNoteText}
            onChange={this.onNoteTextChange}
            fullWidth
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={this.handleClose} color="primary">
            Cancel
          </Button>
          <Button onClick={this.addNewNote} color="primary">
            Add note
          </Button>
        </DialogActions>
      </Dialog>
      <Notification
        type={'error'}
        message={errorMsg}
        open={this.state.errorNotificationOpen}
        closed={this.notificationCloseHandler}
      />
    </React.Fragment>
    }
}

const mapStateToProps = (state, props) => {
  return {
		token: state.auth.token,
    errorMsg: state.cardNote.errorMsg,
    savedNotes: state.cardNote.savedNotes
  }
}

export default connect(
  mapStateToProps,
  { 
    addCardNote,
    cardNoteErrorMessage,
    cardNoteReset,
  }
)(withTheme(Notes))