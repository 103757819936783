import React from 'react'
import { connect } from 'react-redux'
import { activateUser, getActivationInfo,resetActivateUserResult } from '../../action/activateUser'
import SetPasswordForm from '../../component/agoda/user/SetPasswordForm'
import CardContent from '@material-ui/core/Card'
import Card from '@material-ui/core/Card'
import Typography from '@material-ui/core/Typography'
import config from '../../config'

const USER_PERMISSION_GROUP_ID = 3
const AGODA_OWNER_ID = config.ownerId

const initialState = {	
	groupId: USER_PERMISSION_GROUP_ID,
	ownerId: AGODA_OWNER_ID,
	isValid:false,
	loading:true,
	error:null
}

class ActivateUser extends React.Component{

	constructor(props) {
		super(props)
		this.state = {
		  ...initialState
		}
	  }	

	componentWillMount()
	{
		this.props.getActivationInfo(this.props.match.params.uuid)
	}

	componentWillReceiveProps(nextProps){
		nextProps.result !== this.props.result && this.setState(initialState)
	}
	getError() { return {__html: this.props.error}; };
	ActivationNotValid = () => <Card>
			<CardContent>
				<Typography gutterBottom variant="h5">				
				<div dangerouslySetInnerHTML={this.getError()} />
      			</Typography>
			</CardContent>
			</Card>


	render(){
		const  { loading,error } = this.props
		return (
			<React.Fragment>
			{
				loading && (
					<div>Loading..</div>
				)
			}
			{
				error && (
					<this.ActivationNotValid/>
				)
			}
			{
				(!loading && !error) && (
					<SetPasswordForm user={this.props.actData} userUUID={this.props.match.params.uuid}>
						</SetPasswordForm>
				)
			}
			</React.Fragment>			
		)
		
}}

const mapStateToProps = (state,props)=> ({
	token: state.auth.token,	
	actData:state.activateUser.activationInfoResponse,
	error:state.activateUser.errorMsg,
	loading:state.activateUser.loading	
})

const mapDispatchToProps = {
	activateUser,
	getActivationInfo,
	resetActivateUserResult
}

export default connect(mapStateToProps, mapDispatchToProps)(ActivateUser)
