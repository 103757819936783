import React, {Component} from 'react'
import CSVReportHeader from './CSVReportHeader'
import CSVReportDetails from './CSVReportDetails'
import {connect} from "react-redux"
import PropTypes from "prop-types"
import {loadReport} from '../../../../action/report'
import {getUserCampaigns} from "../../../../action/userCampaigns"
import Notification from "../../../default/layout/Notification"

class CSVReport extends Component {

  state = {
    showDate: '',
    selectedCampaign: -1,
    missingDataOpen: false
  }

  onDateChangeHandler = (event) => {
    this.setState({
      ...this.state,
      showDate: event.target.value
    })
  }

  onCampaignSelected = (campaignID) => {
    this.setState({
      ...this.state,
      selectedCampaign: campaignID
    })
  }

  notificationCloseHandler = () => {
    this.setState({
      ...this.state,
      missingDataOpen: false
    })
  }

  onShowClickHandler = () => {
    if (this.state.showDate === undefined || this.state.showDate === '' || this.state.selectedCampaign === -1) {
      this.setState({
        ...this.state,
        missingDataOpen: true
      })
      return
    }

    var campaignIDs = this.state.selectedCampaign === 0 ?
      this.props.campaigns.map((campaign) => campaign.CampaignID) :
      [this.state.selectedCampaign];

    this.props.loadReport(this.props.type, campaignIDs, this.state.showDate)
  }

  getReportName() {
    const url = this.props.reportURL
    return url ? url.substring(url.lastIndexOf('/')+1) : 'Report.csv'
  }

  componentDidMount = () => {
    this.props.getCampaigns()
  }

  render() {
    return (
      <div className="CSVReport">
        <CSVReportHeader 
          showDate={this.state.showDate}
          show={this.onShowClickHandler}
          dateChanged={this.onDateChangeHandler}
          campaignSelected={this.onCampaignSelected}
          reportHeader={this.props.reportHeader}
          displayAllCampaigns={this.props.displayAllCampaigns}
        />
        <br/>
        <div style={{marginLeft: '40px'}}>{this.props.errorMsg}</div>
        <CSVReportDetails
          date={this.state.showDate}
          reportHeader={this.props.reportHeader}
          data={this.props.data}
          transform={this.props.transform}
          reportName={this.getReportName()}
          previewSize={this.props.previewSize}
          prependRowIndex={this.props.prependRowIndex}
          downloadableExcel={this.props.downloadableExcel}
        />
        <Notification message={'Campaign and date are required'} open={this.state.missingDataOpen}
                      closed={this.notificationCloseHandler}/>
      </div>
    )
  }
}

CSVReport.propTypes = {
  loadReport: PropTypes.func,
  campaigns : PropTypes.array
}

const mapStateToProps = (state) => {
  return {
    reportURL: state.reportInfo.reportURL,
    data: state.reportInfo.data,
    errorMsg: state.reportInfo.errorMsg,
    campaigns : state.userCampaigns.campaigns,
  }
}

const mapDispatchToProps = {
  loadReport: loadReport,
  getCampaigns: getUserCampaigns
}


export default connect(
  mapStateToProps, mapDispatchToProps
)(CSVReport)
