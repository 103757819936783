import React from 'react'
import BaseDateRangedReport from './BaseDateRangedReport'

const NegativeBalanceReport = () => {
    return (
        <BaseDateRangedReport
            reportHeader="Negative Balance Report"
            reportDownloadName="negativeBalanceReport"
            apiReportName="negativeBalanceReport"
            chooseCampaign={true}
        />
    )
}

export default NegativeBalanceReport
