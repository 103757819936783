import React, {Component} from 'react'
import {connect} from 'react-redux'
import {getResetPasswordInfo} from '../../action/resetPassword'
import SetPasswordForm from '../../component/agoda/user/SetPasswordForm'
import CardContent from '@material-ui/core/Card'
import Card from '@material-ui/core/Card'
import Typography from '@material-ui/core/Typography'
import {Redirect} from 'react-router'

class ResetPassword extends Component
{
  state = {
    isValid: false,
    loading: true,
    error: null
  }

  componentDidMount() {
    this.props.getResetPasswordInfo(this.props.match.params.uuid)
  }

  getError() {
    return {__html: this.props.error};
  };

  ActivationNotValid = () => <Card>
    <CardContent>
      <Typography gutterBottom variant="h5">
        <div dangerouslySetInnerHTML={this.getError()}/>
      </Typography>
    </CardContent>
  </Card>


  render() {
    if (this.props.token !== null) {
      return (<Redirect to="/"/>)
    }

    const {loading, error} = this.props
    return (
      <React.Fragment>
        {
          loading && (
            <div>Loading...</div>
          )
        }
        {
          error && (
            <this.ActivationNotValid/>
          )
        }
        {
          (!loading && !error) && (
            <SetPasswordForm user={this.props.actData}
              userUUID={this.props.match.params.uuid}
              reset={true} />
          )
        }
      </React.Fragment>
    )

  }
}

const mapStateToProps = (state, props) => ({
  token: state.auth.token,
  actData: state.resetPassword.activationInfoResponse,
  error: state.resetPassword.errorMsg,
  loading: state.resetPassword.loading
})

const mapDispatchToProps = {
  getResetPasswordInfo
}

export default connect(mapStateToProps, mapDispatchToProps)(ResetPassword)
