import { apiCall } from './api'

export const CARD_NOTE = 'CARD_NOTE'

export function addCardNote(noteText, voucherId, userId) {
  const payload = apiCall('addCardNote', {noteText, voucherId, userId})
  return {
    type: CARD_NOTE,
    payload,
    meta: {
      noteText,
      voucherId,
      userId
    }
  }
}

export const CARD_NOTE_ERROR_MESSAGE = 'CARD_NOTE_ERROR_MESSAGE'

export function cardNoteErrorMessage (errorMessage = null) {
  return {
    type: CARD_NOTE_ERROR_MESSAGE,
    payload: { resultText: errorMessage }
  }
}

export const CARD_NOTE_RESET = 'CARD_NOTE_RESET'

export function cardNoteReset () {
  return {
    type: CARD_NOTE_RESET
  }
}
