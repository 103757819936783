import React, {Component} from 'react'
import Typography from '@material-ui/core/Typography'
import Table from '@material-ui/core/Table'
import TableHead from '@material-ui/core/TableHead'
import TableBody from '@material-ui/core/TableBody'
import TableRow from '@material-ui/core/TableRow'
import TableCell from '@material-ui/core/TableCell'
import {BatchLoadsResultsRow} from './BatchLoadsResultsRow'
import Select from '@material-ui/core/Select'
import Input from '@material-ui/core/Input'
import MenuItem from '@material-ui/core/MenuItem'
import TextField from '@material-ui/core/TextField'
import Icon from '@material-ui/core/Icon'
import IconButton from '@material-ui/core/IconButton'
import Tooltip from '@material-ui/core/Tooltip'

const buttonTooltipDelay = 800

export class BatchLoadsResults extends Component {
  render() {
    const tableRows = this.props.batchLoadsResults.map((row,i) =>
      <BatchLoadsResultsRow
        key={i}
        batchLoadsResult={row}
        batchLoadReject = { this.props.batchLoadReject }
        batchLoadClose = { this.props.batchLoadClose }
        batchLoadApprove = { this.props.batchLoadApprove }
        batchLoadRetry = { this.props.batchLoadRetry }
        batchLoadDetails = { this.props.batchLoadDetails }
        currentUserId = { this.props.currentUserId }
        appliedBatchLoadsFilter = {this.props.batchLoadsFilter}
      />
    )

    return (
      <div>
        <Typography gutterBottom variant="h5">
          Batch Loads
        </Typography>
        <Select
          id="batch-loads-filter"
          className="batch-loads-filter"
          value={this.props.batchLoadsFilter}
          onChange={this.props.handleInputChange}
          input={<Input name="batchLoadsFilter"/>}
          fullWidth={false}
        >
          <MenuItem value={1}>Awaiting approval</MenuItem>
          <MenuItem value={5}>Processing</MenuItem>
          <MenuItem value={2}>Approved</MenuItem>
          <MenuItem value={3}>Rejected</MenuItem>
          <MenuItem value={4}>Closed</MenuItem>
        </Select>
        <Tooltip title="Refresh results" enterDelay={buttonTooltipDelay} className="batch-loads-results-refresh">
          <span>
            <IconButton onClick={() => {this.props.batchLoadsRefresh()}} aria-label="Refresh" >
              <Icon>autorenew</Icon>
            </IconButton>
          </span>
        </Tooltip>
        { (!this.props.dateFilterHidden) ?
          <div className="batch-loads-date-filter">
            <TextField
              name="startDate"
              helperText = "Uploaded from"
              type="date"
              onChange={this.props.handleInputChange}
              value={this.props.startDate}
              disabled={this.props.dateFilterDisabled}
              error={this.props.dateFilterValidationError}
            />
            <div className="batch-loads-date-filter-separator">-</div>
            <TextField
              name="endDate"
              helperText="Uploaded to"
              type="date"
              onChange={this.props.handleInputChange}
              value={this.props.endDate}
              disabled={this.props.dateFilterDisabled}
              error={this.props.dateFilterValidationError}
            />
          </div> : null
        }
        <Table className="approve-batch-loads-table">
          <TableHead>
            <TableRow>
              <TableCell>Reference</TableCell>
              <TableCell>Date Uploaded</TableCell>
              <TableCell>Uploaded By</TableCell>
              <TableCell>Approved</TableCell>
              <TableCell>Rejected</TableCell>
              <TableCell>Cards Loaded</TableCell>
              <TableCell>Loaded Amount</TableCell>
              <TableCell>Actions</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {tableRows}     
          </TableBody>
        </Table>
      </div>
    )
  }
}
