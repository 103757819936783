import { apiCall } from '../api'

export const STOP_CARD = 'STOP_CARD'

export function stopCard(trackingNumber, activationData, stopReasonID) {
  const payload = apiCall('stopCard', { trackingNumber, activationData, stopReasonID: parseInt(stopReasonID, 10)})
  return {
    type: STOP_CARD,
    payload,
    meta: {
      trackingNumber,
      activationData,
      stopReasonID:parseInt(stopReasonID, 10)
    }
  }
}

export const STOP_CARD_ERROR_MESSAGE = 'STOP_CARD_ERROR_MESSAGE'

export function stopCardErrorMessage() {
  return {
    type: STOP_CARD_ERROR_MESSAGE
  }
}

export const STOP_CARD_CLEAR = 'STOP_CARD_CLEAR'

export function stopCardClear () {
  return {
    type: STOP_CARD_CLEAR
  }
}
