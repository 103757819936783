import { GET_USER_GROUPS } from '../action/generalUserDetails'

const initialState = {
  userGroups:[]
}

export default (state = initialState, action) => {
  switch (action.type) {
  case GET_USER_GROUPS + '_FULFILLED':
    if(action.payload.resultCode === "0"){
      return { ...state, userGroups:constructObjectArray(JSON.parse(action.payload.userGroups))}
    } else {
      return state
    }
  default:
    return state
  }
}

const constructObjectArray = query => query.DATA.map( row => row.reduce(arrayToObject(query), {}))
const arrayToObject = query => (accu, column, index) => ({...accu, [query.COLUMNS[index]]:column})
