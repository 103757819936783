import React, { Component } from 'react'
import { store } from '../../../store'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import Dialog from '@material-ui/core/Dialog'
import DialogContent from '@material-ui/core/DialogContent'
import AppBar from '@material-ui/core/AppBar'
import Toolbar from '@material-ui/core/Toolbar'
import IconButton from '@material-ui/core/IconButton'
import Tooltip from '@material-ui/core/Tooltip'
import Icon from '@material-ui/core/Icon'
import Typography from '@material-ui/core/Typography'
import Card from '@material-ui/core/Card'
import CardContent from '@material-ui/core/CardContent'
import { BatchLoadDetailsResults } from './BatchLoadDetailsResults'
import Chip from '@material-ui/core/Chip'
import {
  downloadBatchLoadFile,
  downloadBatchLoadFileReset,
  downloadBatchLoadReport,
  downloadBatchLoadReportReset
} from '../../../action/batchLoads'
import b64toBlob from '../../../util/b64toBlob'
import download from 'downloadjs'
import batchLoadFileToCSV from '../../../util/csv/batchLoadFileToCSV'

const buttonTooltipDelay = 800

class BatchLoadDetails extends Component {

  constructor(props) {
    super(props)

    this.state = {}
  }

  handleDownloadBatchLoadFile(batchLoadFileID) {
    store.dispatch(downloadBatchLoadFile(batchLoadFileID))
  }

  handleDownloadBatchLoadReport(batchLoadFileID) {
    store.dispatch(downloadBatchLoadReport(batchLoadFileID))
  }

  downloadExcelData(excelBase64) {
    const mimeType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
    const fileExtension = 'xlsx'
    const binaryExcelData = b64toBlob(excelBase64)
    const fileName = `${this.props.batchLoadDetailsMetadata.batchLoadFile.BATCHLOADFILEREFERENCE}_report.${fileExtension}`
    download(binaryExcelData, fileName, mimeType)
  }

  downloadCsvData(content, reference, originalFileName) {
    const csvContent = batchLoadFileToCSV(content)
    const mimeType = 'text/csv'
    download(
      new Blob([csvContent], { mimeType }),
      originalFileName,
      mimeType
    )
  }

  render() {
    const batchLoadFile = this.props.batchLoadDetailsMetadata.batchLoadFile || {};

    if(this.props.downloadBatchLoadReportResult) {
      this.downloadExcelData(this.props.downloadBatchLoadReportResult);
      store.dispatch(downloadBatchLoadReportReset())
    }
    if(this.props.downloadBatchLoadFileResult) {
      this.downloadCsvData(
        this.props.downloadBatchLoadFileResult,
        batchLoadFile.BATCHLOADFILEREFERENCE,
        batchLoadFile.BATCHLOADFILEORIGINALNAME
      );
      store.dispatch(downloadBatchLoadFileReset())
    }

    const batchLoadDetailsCard = this.props.batchLoadDetailsResults ? (
    <Card>
      <CardContent>
        <BatchLoadDetailsResults batchLoadDetailsResults={this.props.batchLoadDetailsResults} />
      </CardContent>
    </Card>
    ) : (null)

    return (
      <Dialog
        fullScreen
        open={this.props.open}
        scroll='paper'
      >
        <AppBar style={{position:'relative'}}>
          <Toolbar>
            <Typography variant="h6" style={{flex:'1'}}>
              Batch Details { batchLoadFile.BATCHLOADREFERENCE ? <Chip label={ batchLoadFile.BATCHLOADREFERENCE } className="batch-load-details-header-chip"/> : null }
            </Typography>
            <Tooltip title="Download Report" enterDelay={buttonTooltipDelay}>
              <IconButton color="inherit" onClick={() => this.handleDownloadBatchLoadReport(batchLoadFile.BATCHLOADFILEID)} aria-label="Close">
                <Icon>assignment_returned</Icon>
              </IconButton>
            </Tooltip>
            <Tooltip title="Download Batch File" enterDelay={buttonTooltipDelay}>
              <IconButton color="inherit" onClick={() => this.handleDownloadBatchLoadFile(batchLoadFile.BATCHLOADFILEID)} aria-label="Close">
                <Icon>archive</Icon>
              </IconButton>
            </Tooltip>
            <IconButton className="batch-load-details-close" color="inherit" onClick={this.props.close} aria-label="Close">
              <Icon>close</Icon>
            </IconButton>
          </Toolbar>
        </AppBar>
        <DialogContent>
          {batchLoadDetailsCard}
        </DialogContent>
      </Dialog>
    )
  }
}


BatchLoadDetails.propTypes = {
  downloadBatchLoadReport: PropTypes.func,
  downloadBatchLoadFile: PropTypes.func
}

const mapStateToProps = (state, props) => {
  return {
    errorMsg: state.batchLoads.errorMsg,
    downloadBatchLoadReportResult: state.batchLoads.downloadBatchLoadReportResult || null,
    downloadBatchLoadFileResult: state.batchLoads.downloadBatchLoadFileResult || null
  }
}

export default connect(
  mapStateToProps,
  {
    downloadBatchLoadReport,
    downloadBatchLoadFile
  }
)(BatchLoadDetails)
