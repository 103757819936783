import React, { Component } from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import Card from '@material-ui/core/Card'
import { red, green } from '@material-ui/core/colors'
import BottomNavigation from '@material-ui/core/BottomNavigation'
import BottomNavigationAction from '@material-ui/core/BottomNavigationAction'
import Redeem from '@material-ui/icons/Redeem'
import Block from '@material-ui/icons/Block'
import MoneyOff from '@material-ui/icons/MoneyOff'
import CheckCircleOutline from '@material-ui/icons/CheckCircleOutline'
import CardHeader from '@material-ui/core/CardHeader'
import CardContent from '@material-ui/core/CardContent'
import { GridList, GridListTile } from '@material-ui/core'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableRow from '@material-ui/core/TableRow'
import TableCell from '@material-ui/core/TableCell'
import Typography from '@material-ui/core/Typography'
import dateFormat from 'dateformat'
import CurrencyFormat from 'react-currency-format'
import FailedTransactions from '../transactionDetails/FailedTransactions'
import { failedTransactionSearchClear } from '../../../action/failedTransactionSearch'
import RefreshIcon from '@material-ui/icons/Refresh'
import { TextField,  FormControl } from '@material-ui/core'

import {
  devalueCard,
  devalueCardErrorMessage,
  devalueCardClear
} from '../../../action/card/devalueCard'
import {
  stopCard,
  stopCardErrorMessage,
  stopCardClear
} from '../../../action/card/stopCard'
import {
  unstopCard,
  unstopCardErrorMessage,
  unstopCardClear
} from '../../../action/card/unstopCard'
import {
  toggleMagstripe,
  toggleMagstripeClear
} from '../../../action/card/toggleMagstripe'
import {
  getStopReasons,
  getStopReasonsErrorMessage,
  getStopReasonsClear
} from '../../../action/card/getStopReasons'
import {
  retireCard,
  retireCardErrorMessage,
  retireCardClear
} from '../../../action/card/retireCard'
import {
  changePin,
  changePinErrorMessage,
  changePinClear
} from '../../../action/card/changePin'
import {
  setCardStatus,
  setCardStatusErrorMessage
} from '../../../action/card/setCardStatus'
import {
  cardDetailsClear
} from '../../../action/cardDetails'
import DialogBox from '../../../component/default/layout/DialogBox'
import Notification from '../../default/layout/Notification'
import Select from '@material-ui/core/Select'
import { MenuItem } from '@material-ui/core'
import Input from '@material-ui/core/Input'
import { withStyles } from '@material-ui/core/styles'

import { store } from '../../../store'
import config from '../../../config'

const SHOW_DEVALUE = config.showStatementDevalue
const SHOW_RETIRE = config.showStatementRetire
const SHOW_STOP = config.showStatementStop
const SHOW_ACTIVATE = config.showStatementActivate
const SHOW_CHANGEPIN = config.showChangePIN

const styles = theme => ({
  statusRoot: {
    height: 0
  },
  statusIcon: {
    width: 12,
    height: 12
  },
  statusText: {}
})

class CardDetails extends Component {
  constructor(props) {
    super(props)

    this.initialState = {
      devalueDialogOpen: false,
      devalueResult: false,
      disableDevalue: false,
      devalueNotificationOpen: false,
      errorMsg: '',
      open: false,
      stopDialogOpen: false,
      errorNotificationOpen: false,
      stopReasonID: 0,
      stopNotificationOpen: false,
      isStopped: false,
      retireDialogOpen: false,
      retireNotificationOpen: false,
      voucherIsRetired: false,
      magstripeEnabled: false,
      statusFailureMessage: false,
      toggleMagstripeDisabled: false,
      changePinDialogueOpen: false,
      changePinNotificationOpen: false,
      newCardPIN:''
    }

    this.state = {
      ...this.initialState,
      open: false
    }

    this.handleClose = this.handleClose.bind(this)
  }

  handleClick = event => {
    this.setState({
      open: true
    })
  }

  handleDevalueClick = event => {
    store.dispatch(devalueCardClear())
    this.setState({
      devalueDialogOpen: true
    })
  }

  handleDevalueClose = () => {
    this.setState({
      devalueDialogOpen: false
    })
  }

  handleDevalueCard = () => {
    this.props
      .devalueCard(
        this.props.cardDetails.CARDNUMBER
      )
      .then(this.handleDevalueClose())
  }

  handleStopCardClick = event => {
    this.setState({
      stopDialogOpen: true
    })
  }

  handleStopClose = () => {
    this.setState({
      stopDialogOpen: false
    })
  }

  handleStopCard = () => {
    this.props
      .stopCard(
        this.props.cardDetails.TRACKINGNUMBER,
        this.props.cardDetails.ACTIVATIONDATA,
        this.state.stopReasonID
      )
      .then(this.handleStopClose())
  }

  handleUnstopCardClick = event => {
    store.dispatch(unstopCardClear())
    this.setState({
      unstopDialogOpen: true
    })
  }

  handleUnstopClose = () => {
    this.setState({
      unstopDialogOpen: false
    })
  }

  handleUnstopCard = () => {
    this.props
      .unstopCard(
        this.props.cardDetails.CARDNUMBER
      )
      .then(this.handleUnstopClose())
  }

  handleInputChange = event => {    
    const stateKey = event.target.name
    this.setState({ [stateKey]: event.target.value })
  }

  handleInputPINChange = event => {       
    if (event.target.value.length === this.props.cardDetails.CAMPAIGNCARDPINLENGTH) {
      this.setState({ helperText: '', error: false });
      const stateKey = event.target.name
      this.setState({ [stateKey]: event.target.value })
    } else {
      this.setState({ helperText: 'Please enter '+ this.props.cardDetails.CAMPAIGNCARDPINLENGTH + ' digit new PIN', error: true });
    }    
  }

  handleToggleMagstripeChange = event => {
    this.props
      .toggleMagstripe(this.props.cardDetails.CARDNUMBER, !this.state.magstripeEnabled)
  }

  handleRetireClick = event => {
    store.dispatch(retireCardClear())
    this.setState({
      retireDialogOpen: true
    })
  }

  handleChangePinClose = () => {
    this.setState({
      changePinDialogueOpen: false
    })
  }

  handleChangePinClick = event => {
    store.dispatch(changePinClear())
    this.setState({
      changePinDialogueOpen: true
    })
  }

  handleRetireClose = () => {
    this.setState({
      retireDialogOpen: false
    })
  }
  handleChangePin = () => {
    this.props
      .changePin(
        this.props.cardDetails.CARDNUMBER,
        this.props.cardDetails.TRACKINGNUMBER,
        this.state.newCardPIN
      )
      .then(this.setState({
        changePinDialogueOpen: false
      }))
  }
  handleRetireCard = () => {
    this.props
      .retireCard(
        this.props.cardDetails.TRACKINGNUMBER,
        this.props.cardDetails.ACTIVATIONDATA || ''
      )
      .then(this.setState({
        retireDialogOpen: false
      }))
  }

  handleClose = () => {
    store.dispatch(failedTransactionSearchClear())
    this.setState({
      open: false
    })
    this.notificationCloseHandler()
  }

  notificationCloseHandler = () => {
    this.setState({
      devalueNotificationOpen: false,
      devalueNotificationErrorOpen: false,
      stopNotificationOpen: false,
      unstopNotificationOpen: false,
      retireNotificationOpen: false,
      errorNotificationOpen: false,
      statusFailureMessage: false,
      changePinNotificationOpen: false
    })
  }

  handleStatusChange = event => {
    let cardNumbers = [this.props.cardDetails.CARDNUMBER]
    this.props.setCardStatus(cardNumbers, 'voucherNumber')
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.cardDetails.ISSTOPPED === 1) {
      this.setState({
        isStopped: true
      })
    }
    this.setState({
      voucherIsRetired: nextProps.cardDetails.RETIRED,
      toggleMagstripeDisabled: nextProps.toggleMagstripeDisabled,
      magstripeEnabled: nextProps.toggleMagstripeApiCalled ? nextProps.toggleMagstripeResult : this.state.magstripeEnabled
    })
    if (nextProps.statusErrorMessage) {
      this.setState({
        statusFailureMessage: nextProps.statusErrorMessage || false
      })
    }
    if (nextProps.devalueResult) {
      this.setState({
        devalueNotificationOpen: true
      })
      store.dispatch(devalueCardClear())
    } else if (nextProps.stopResult) {
      this.setState({
        stopNotificationOpen: true,
        isStopped: true
      })
      store.dispatch(stopCardClear())
    } else if (nextProps.unstopResult) {
      this.setState({
        unstopNotificationOpen: true,
        isStopped: false
      })
      store.dispatch(unstopCardClear())
    } else if (nextProps.retireResult) {
      this.setState({
        ...this.state,
        retireNotificationOpen: true,
        voucherIsRetired: true
      })
    } else if (nextProps.changePinResult) {
      this.setState({
        ...this.state,
        changePinNotificationOpen: true,
        voucherPinChanged: true
      })
    } else if (nextProps.errorMsg) {
      this.setState({
        errorNotificationOpen: true
      })
    }
  }

  componentWillMount() {
    this.props.toggleMagstripeClear()
    this.props.cardDetailsClear()
    this.props.getStopReasons()
    this.setState({
      ...this.state,
      isStopped: this.props.cardDetails.ISSTOPPED,
      voucherIsRetired: this.props.cardDetails.RETIRED
    })
  }

  componentDidMount() {
    this.props.devalueCardClear()
    this.props.stopCardClear()
    this.props.unstopCardClear()
    this.props.getStopReasonsClear()
    this.props.retireCardClear()
    this.props.changePinClear()
  }

  componentDidUpdate(prevProps) {
    if (this.props.cardDetails.MAGSTRIPEENABLED !== prevProps.cardDetails.MAGSTRIPEENABLED || this.props.cardDetails.CARDNUMBER !== prevProps.cardDetails.CARDNUMBER) {
      this.setState({
        magstripeEnabled: !!this.props.cardDetails.MAGSTRIPEENABLED
      })
    }
  }

  componentWillUnmount() {
    this.props.toggleMagstripeClear()
    this.props.cardDetailsClear()
    this.setState({
      ...this.initialState
    })
  }


  concatenateWithSpace() {
    let result = arguments[0]
    for (var i = 1; i < arguments.length; i++) {
      result += ' ' + arguments[i]
    }
    return result
  }

  render() {
    const { cardDetails } = this.props
    var date = cardDetails.EXPIRYDATE
      ? dateFormat(new Date(cardDetails.EXPIRYDATE), 'dd mmmm yyyy')
      : null

    const { submittingDevalue, errorMsg, devalueResult } = this.props
    const actualCardbalance = devalueResult ? 0 : cardDetails.CARDBALANCE
    const disableDevalue = submittingDevalue || parseInt(actualCardbalance, 10) === 0
    const disableActivate = cardDetails.STATUS ? true : false
    const enableChangePin = cardDetails.CAMPAIGNCARDREQUIREPIN 
    let realStatus = cardDetails.STATUS ? 'Activated' : 'Not Activated'
    if (new Date() > new Date(cardDetails.EXPIRYDATE)) {
      realStatus = 'Expired'
    }
    if (cardDetails.RETIRED) {
      realStatus = 'Retired'
    }
    if (this.state.isStopped) {
      realStatus = 'Stopped'
    }

    const cardStyle = {
      position: 'absolute',
      padding: '40px',
      margin: '2px',
      top: '0px',
      bottom: '0px',
      left: '0px',
      right: '0px'
    }
    
    return (
      <div>
        <Notification
          type={'success'}
          message={'Card devalued successfully'}
          open={this.state.devalueNotificationOpen}
          closed={this.notificationCloseHandler}
        />
        <Notification
          type={'error'}
          message={this.state.statusFailureMessage}
          open={this.state.statusFailureMessage !== false}
          closed={this.notificationCloseHandler}
        />
        <Notification
          type={'success'}
          message={'Card stopped successfully'}
          open={this.state.stopNotificationOpen}
          closed={this.notificationCloseHandler}
        />
        <Notification
          type={'success'}
          message={'Card enabled successfully'}
          open={this.state.unstopNotificationOpen}
          closed={this.notificationCloseHandler}
        />
        <Notification
          type={'success'}
          message={'Card retired successfully'}
          open={this.state.retireNotificationOpen}
          closed={this.notificationCloseHandler}
        />
        <Notification
          type={'success'}
          message={'CARD PIN changed successfully'}
          open={this.state.changePinNotificationOpen}
          closed={this.notificationCloseHandler}
        />
        <Notification
          type={'error'}
          message={errorMsg}
          open={this.state.errorNotificationOpen}
          closed={this.notificationCloseHandler}
        />

        <GridList cols={2} cellHeight={750} style={{ height: 'auto' }}>
          <GridListTile cols={1} rows={1}>
            <Card style={cardStyle}>
              <CardHeader title="Card Details" />
              <CardContent>
                <GridList cols={1}>
                  <Table>
                    <TableBody>
                      <TableRow>
                        <TableCell><Typography gutterBottom={true}>Card Number</Typography></TableCell>
                        <TableCell><Typography gutterBottom={true}><b>{cardDetails.CARDNUMBER}</b></Typography>
                        </TableCell>
                      </TableRow>

                      <TableRow>
                        <TableCell><Typography gutterBottom={true}>Card Expiry Date</Typography></TableCell>
                        <TableCell><Typography gutterBottom={true}><b>{date}</b></Typography></TableCell>
                      </TableRow>

                      <TableRow>
                        <TableCell><Typography gutterBottom={true}>Card CVV</Typography></TableCell>
                        <TableCell><Typography gutterBottom={true}><b>{cardDetails.CARDCVV2}</b></Typography></TableCell>
                      </TableRow>

                      <TableRow>
                        <TableCell><Typography gutterBottom={true}>Card Balance</Typography></TableCell>
                        <TableCell>
                          <Typography gutterBottom={true}>
                            <b>
                              <CurrencyFormat
                                value={cardDetails.CARDBALANCE}
                                displayType={'text'}
                                thousandSeparator={true}
                                prefix={cardDetails.CURRENCY}
                              />
                            </b>
                          </Typography>
                        </TableCell>
                      </TableRow>

                      <TableRow>
                        <TableCell><Typography gutterBottom={true}>Funds Available</Typography></TableCell>
                        <TableCell>
                          <Typography gutterBottom={true}>
                            <b>
                              <CurrencyFormat
                                value={cardDetails.FUNDSAVAILABLE}
                                displayType={'text'}
                                thousandSeparator={true}
                                prefix={cardDetails.CURRENCY}
                              />
                            </b>
                          </Typography>
                        </TableCell>
                      </TableRow>

                      <TableRow>
                        <TableCell><Typography gutterBottom={true}>Loaded Amount</Typography></TableCell>
                        <TableCell>
                          <Typography gutterBottom={true}>
                            <b>
                              <CurrencyFormat
                                value={cardDetails.LOADEDAMOUNT}
                                displayType={'text'}
                                thousandSeparator={true}
                                prefix={cardDetails.CURRENCY}
                              />
                            </b>
                          </Typography>
                        </TableCell>
                      </TableRow>

                      <TableRow>
                        <TableCell><Typography gutterBottom={true}>Authorized Amount</Typography></TableCell>
                        <TableCell>
                          <Typography gutterBottom={true}>
                            <b>
                              <CurrencyFormat
                                value={cardDetails.AUTHORIZEDAMOUNT}
                                displayType={'text'}
                                thousandSeparator={true}
                                prefix={cardDetails.CURRENCY}
                              />
                            </b>
                          </Typography>
                        </TableCell>
                      </TableRow>

                      <TableRow>
                        <TableCell><Typography gutterBottom={true}>Settled Amount</Typography></TableCell>
                        <TableCell>
                          <Typography gutterBottom={true}>
                            <b>
                              <CurrencyFormat
                                value={cardDetails.SETTLEDAMOUNT}
                                displayType={'text'}
                                thousandSeparator={true}
                                prefix={cardDetails.CURRENCY}
                              />
                            </b>
                          </Typography>
                        </TableCell>
                      </TableRow>
                    </TableBody>
                  </Table>
                </GridList>
              </CardContent>

              <BottomNavigation showLabels>
                {!disableDevalue && SHOW_DEVALUE ? (
                  <BottomNavigationAction label="Devalue" icon={<MoneyOff color="primary" fontSize="large"/>} onClick={this.handleDevalueClick}/>
                ) : (
                  null
                )}

                {SHOW_RETIRE ?
                  this.state.voucherIsRetired ? (
                    <BottomNavigationAction label="Retired" icon={<Redeem color="disabled" fontSize="large"/>} disabled="true"/>
                  ) : (
                    <BottomNavigationAction label="Retire" icon={<Redeem color="primary" fontSize="large"/>} onClick={this.handleRetireClick}/>
                  ): null
                }
                
                {SHOW_STOP ?
                  this.state.isStopped ? (
                    <BottomNavigationAction label="Unstop" icon={<Block style={{ color: green[500] }} fontSize="large" />} onClick={this.handleUnstopCardClick}/>
                  ) : (
                    <BottomNavigationAction label="Stop" icon={<Block style={{ color: red[500] }} fontSize="large" />} onClick={this.handleStopCardClick}/>
                  ) : null
                }

                {SHOW_ACTIVATE ? 
                  disableActivate ? (
                    <BottomNavigationAction label="Activated" icon={<CheckCircleOutline color="disabled" fontSize="large" />} disabled="true" />
                  ) : (
                    <BottomNavigationAction label="Activate" icon={<CheckCircleOutline color="primary" fontSize="large" />} onClick={() =>
                      this.props.setCardStatus(
                        [cardDetails.CARDNUMBER],
                        'voucherNumber'
                      )
                    }/>
                  ) : null
                }
                {enableChangePin && SHOW_CHANGEPIN && !this.state.voucherPinChanged ? 
                   (
                    <BottomNavigationAction label="ChangePIN" icon={<RefreshIcon color="primary" fontSize="large" />} onClick={this.handleChangePinClick}/>
                  ) : (
                    <BottomNavigationAction label="ChangePIN" icon={<RefreshIcon color="disabled" fontSize="large" />} disabled="true" />
                  ) 
                }
              </BottomNavigation>
            </Card>
          </GridListTile>       
          <GridListTile cols={1} rows={1}>
            <Card style={cardStyle}>
              <CardHeader title="&nbsp;" />
              <CardContent>
                <GridList cols={1}>
                  <Table>
                    <TableBody>
                      <TableRow>
                        <TableCell><Typography gutterBottom={true}>Campaign Name</Typography></TableCell>
                        <TableCell><Typography gutterBottom={true}><b>{cardDetails.CAMPAIGNNAME}</b></Typography></TableCell>
                      </TableRow>

                      <TableRow>
                        <TableCell><Typography gutterBottom={true}>Card Status</Typography></TableCell>
                        <TableCell><Typography gutterBottom={true}><b>{realStatus}</b></Typography></TableCell>
                      </TableRow>

                      <TableRow>
                        <TableCell><Typography gutterBottom={true}>Tracking Number</Typography></TableCell>
                        <TableCell><Typography gutterBottom={true}><b>{cardDetails.TRACKINGNUMBER}</b></Typography></TableCell>
                      </TableRow>

                      <TableRow>
                        <TableCell><Typography gutterBottom={true}>Customer Reference</Typography></TableCell>
                        <TableCell><Typography gutterBottom={true}><b>{cardDetails.ACTIVATIONDATA}</b></Typography></TableCell>
                      </TableRow>

                      <TableRow>
                        <TableCell><Typography gutterBottom={true}>Sequence Number</Typography></TableCell>
                        <TableCell><Typography gutterBottom={true}><b>{cardDetails.SEQUENCENUMBER}</b></Typography></TableCell>
                      </TableRow>
                   
                      {(cardDetails.VOUCHERTAGS) && cardDetails.VOUCHERTAGS.map(row => (
                        <TableRow key={row.TAGNAME}>
                          <TableCell><Typography gutterBottom={true}>{row.TAGNAME}</Typography></TableCell>
                          <TableCell><Typography gutterBottom={true}><b>{row.VOUCHERTAGVALUE}</b></Typography></TableCell>
                        </TableRow>
                      ))}
                     
                    </TableBody>
                  </Table>
                </GridList>
              </CardContent>
            </Card>
          </GridListTile>
        </GridList>

        <FailedTransactions
          open={this.state.open}
          close={this.handleClose}
          voucherNumber={cardDetails.CARDNUMBER}
        />

        <DialogBox
          open={this.state.devalueDialogOpen}
          title="Devalue card?"
          actionLabel="Yes"
          onClick={this.handleDevalueCard}
          cancelLabel="No"
          onClose={this.handleDevalueClose}
        >
          Are you sure you want to devalue this card? This will remove all funds
          from the card.
        </DialogBox>

        <DialogBox
          open={this.state.stopDialogOpen}
          title="Stop card?"
          actionLabel="Yes"
          onClick={this.handleStopCard}
          cancelLabel="No"
          onClose={this.handleStopClose}
        >
          Are you sure you want to stop this card? If so, please select a stop
          reason.
          <Select
            value={this.state.stopReasonID}
            onChange={this.handleInputChange}
            input={<Input name="stopReasonID" />}
            fullWidth
          >
            {this.props.getStopReasonsResult
              ? this.props.getStopReasonsResult.map(stopReason => (
                <MenuItem
                  value={stopReason.STOPREASONID}
                  key={stopReason.STOPREASONID}
                >
                  {stopReason.STOPREASONSHORT}
                </MenuItem>
              ))
              : null}
          </Select>
        </DialogBox>

        <DialogBox
          open={this.state.unstopDialogOpen}
          title="Unstop card?"
          actionLabel="Yes"
          onClick={this.handleUnstopCard}
          cancelLabel="No"
          onClose={this.handleUnstopClose}
        >
          Are you sure you want to enable this card?
        </DialogBox>

        <DialogBox
          open={this.state.retireDialogOpen}
          title="Retire card?"
          actionLabel="Yes"
          onClick={this.handleRetireCard}
          cancelLabel="No"
          onClose={this.handleRetireClose}
        >
          Are you sure you want to retire this card?
        </DialogBox>

        <DialogBox
          open={this.state.changePinDialogueOpen}
          title="Change PIN ?"
          actionLabel="Update"
          onClick={this.handleChangePin}
          cancelLabel="Cancel"
          onClose={this.handleChangePinClose}
        >          
          <FormControl className="form-input">
          Are you sure you want to change pin of this card ?            
                <TextField
                  id="newCardPIN"
                  name="newCardPIN"                                    
                  onChange={this.handleInputChange}
                  helperText={'Please enter '+ this.props.cardDetails.CAMPAIGNCARDPINLENGTH + ' digit new PIN'}
                  error ={this.state.newCardPIN.length === this.props.cardDetails.CAMPAIGNCARDPINLENGTH  ? false : true }                                    
                />
          </FormControl>
        </DialogBox>
        
      </div>
    )
  }
}

CardDetails.propTypes = {
  devalueCard: PropTypes.func,
  stopCard: PropTypes.func,
  unstopCard: PropTypes.func,
  getStopReasons: PropTypes.func,
  retireCard: PropTypes.func,
  setCardStatus: PropTypes.func,
  toggleMagstripe: PropTypes.func,
  changePin:PropTypes.func
}

const mapStateToProps = (state, props) => {
  return {
    submittingDevalue: !!(state.api.DEVALUE_CARD && state.api.DEVALUE_CARD > 0),
    errorMsg:
      state.devalueCard.errorMsg && state.devalueCard.errorMsg.length
        ? state.devalueCard.errorMsg
        : state.stopCard.errorMsg && state.stopCard.errorMsg.length
          ? state.stopCard.errorMsg
          : state.unstopCard.errorMsg && state.unstopCard.errorMsg.length
            ? state.unstopCard.errorMsg
            : state.retireCard.errorMsg && state.retireCard.errorMsg.length
             ? state.retireCard.errorMsg
             : state.changePin.errorMsg,
    cardBalance:
    state.devalueCard.cardBalance === '0'
      ? state.devalueCard.cardBalance
      : false,
    devalueResult: state.devalueCard.devalueResult || false,
    getStopReasonsResult: state.getStopReasons.getStopReasonsResult,
    stopResult: state.stopCard.stopResult || false,
    unstopResult: state.unstopCard.unstopResult || false,
    toggleMagstripeResult: state.toggleMagstripe.toggleMagstripeResult || false,
    toggleMagstripeDisabled: state.toggleMagstripe.toggleMagstripePending || false,
    toggleMagstripeApiCalled: state.toggleMagstripe.toggleMagstripeApiCalled || false,
    retireResult: state.retireCard.retireResult
      ? JSON.parse(state.retireCard.retireResult)
      : false,
    changePinResult: state.changePin.changePinSuccess,
    statusResult: state.setCardStatus.statusResult || false,
    statusErrorMessage: state.setCardStatus.errorMsg || ''
  }
}

export default connect(
  mapStateToProps,
  {
    devalueCard,
    devalueCardErrorMessage,
    stopCard,
    stopCardErrorMessage,
    unstopCard,
    unstopCardErrorMessage,
    getStopReasons,
    getStopReasonsErrorMessage,
    retireCard,
    retireCardErrorMessage,
    setCardStatus,
    setCardStatusErrorMessage,
    devalueCardClear,
    stopCardClear,
    unstopCardClear,
    getStopReasonsClear,
    retireCardClear,
    toggleMagstripe,
    toggleMagstripeClear,
    cardDetailsClear,
    changePin,
    changePinErrorMessage,
    changePinClear
  }
)(withStyles(styles)(CardDetails))
