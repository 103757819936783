import React from 'react'
import { ConnectedRouter } from 'connected-react-router'
import { Route, Switch } from 'react-router'
import { Provider } from 'react-redux'
import { PersistGate } from 'redux-persist/integration/react'
import { store, persistor, browserHistory } from '../store'
import LoginFlow from '../container/default/MFALogin'
import PrivateRoute from '../component/default/route/PrivateRoute'
import ForgotPassword from '../container/default/ForgotPassword'
import ResetPassword from '../container/agoda/ResetPassword'
import Logout from '../container/default/Logout'
import UserSettings from '../container/agoda/UserSettings'
import UserChangePassword from '../container/agoda/UserChangePassword'
import NotFound from '../container/default/NotFound'

export default props => (
  <Provider store={store}>
    <PersistGate loading={null} persistor={persistor}>
      <ConnectedRouter history={browserHistory}>
        <props.App>
          <Switch>
            <PrivateRoute exact path="/" component={props.Home} />
            <Route exact path="/login" component={LoginFlow}/>
            <Route exact path="/forgotPassword" component={ForgotPassword} />
            <Route exact path="/password/:uuid/reset" component={ResetPassword} />
            <PrivateRoute exact path="/logout" component={Logout}/>
            <PrivateRoute
              exact
              path="/user/detail"
              component={UserSettings}
              permissions={['User.settings']}
            />
            <PrivateRoute
              exact
              path="/user/changepassword"
              component={UserChangePassword}
              permissions={['User.settings']}
            />
            {props.children}
            <Route path="*" component={NotFound}/>
          </Switch>
        </props.App>
      </ConnectedRouter>
    </PersistGate>
  </Provider>
)
