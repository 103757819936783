import {apiCall} from './api'

export const RESET_USER_CREATED = 'RESET_USER_CREATED '
export const resetUserCreated = () => (
  {
    type: RESET_USER_CREATED
  }
)

export const DISMISS_USER_CREATED_ERROR = 'DISMISS_USER_CREATED_ERROR'
export const dismissError = () => (
  {
    type: DISMISS_USER_CREATED_ERROR
  }
)

export const DISMISS_USER_CREATED_CONFIRMATION = 'DISMISS_USER_CREATED_CONFIRMATION'
export const dismissConfirmation = () => (
  {
    type: DISMISS_USER_CREATED_CONFIRMATION
  }
)

export const USER_INFO = 'USER_INFO'
export const getUserInfo = (id) => {
  const payload = apiCall('userInfo', {id})
  return {
    type: USER_INFO,
    payload,
    meta: {}
  }
}

export const ADD_USER = 'ADD_USER'
export const USER_ADMIN_SAVE = 'USER_ADMIN_SAVE'
export const saveUser = (userDetail) => {
  const action = (userDetail.userId) ? 'saveUser' : 'addUser'
  const payload = apiCall(action, {userData: userDetail})
  const type = (userDetail.userId) ? USER_ADMIN_SAVE : ADD_USER

  return {
    type: type,
    payload,
    meta: {
      user: userDetail
    }
  }
}
