import React, {Component} from 'react'
import Table from '@material-ui/core/Table'
import TableHead from '@material-ui/core/TableHead'
import TableBody from '@material-ui/core/TableBody'
import TableRow from '@material-ui/core/TableRow'
import TableCell from '@material-ui/core/TableCell'
import {FailedTransactionResultsRow} from './FailedTransactionResultsRow'

export class FailedTransactionResults extends Component {
  render() {
    const tableRows = this.props.failedTransactionResults.map((row,i) =>
      <FailedTransactionResultsRow key={i} failedTransactionResult={row} />
    )

    return (
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>Transaction Date</TableCell>
            <TableCell>Transaction Amount</TableCell>
            <TableCell>Voucher Value</TableCell>
            <TableCell>Transaction Description</TableCell>
            <TableCell>Transaction Method</TableCell>
            <TableCell>Transaction Manager</TableCell>
            <TableCell>Error Code</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {tableRows}     
        </TableBody>
      </Table>
    )
  }
}
