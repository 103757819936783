import React, {Component} from 'react'
import Card from '@material-ui/core/Card';
import Typography from '@material-ui/core/Typography'
import {parse} from '../../../../util/csv'
import Table from '@material-ui/core/Table'
import TableHead from '@material-ui/core/TableHead'
import TableBody from '@material-ui/core/TableBody'
import TableRow from '@material-ui/core/TableRow'
import TableCell from '@material-ui/core/TableCell'
import Icon from '@material-ui/core/Icon'
import {toCsvBlob} from './csvFunctions'
import Tooltip from '@material-ui/core/Tooltip'
import XLSX from 'xlsx'
import download from 'downloadjs'
import { connect } from 'react-redux'
import Grid from "@material-ui/core/Grid";
import IconButton from "@material-ui/core/IconButton";
import {s2ab} from "../../../../util/misc"

export class CSVReportDetails extends Component {

  parseReportsAndMerge(data) {
    let parsedCsvReports = data.map(csvReport => parse(csvReport))

    let headerRow = parsedCsvReports[0][0];
    parsedCsvReports.forEach(reportRows => reportRows.shift(1))

    let mergedReport = Array.prototype.concat(...parsedCsvReports) // Flatten.

    return {
      header: headerRow,
      rows: mergedReport
    }
  }

  applyTransformations(csvReport) {
    let transformations = csvReport.header.map(columnName => this.props.transform[columnName])

    csvReport.rows.forEach(row =>
      row.forEach((cell, cellIndex) => {
        row[cellIndex] = transformations[cellIndex] ? transformations[cellIndex](cell) : cell
      })
    )
  }

  prependRowIndex(csvReport) {
    csvReport.header.unshift("Item Number")

    csvReport.rows.forEach((row, index) => {
      const indexStr = (index + 1) + ''
      row.unshift(indexStr)
    })
  }

  getCsvReport(data) {
    let csvReport = this.parseReportsAndMerge(data)

    if (this.props.prependRowIndex) {
      this.prependRowIndex(csvReport)
    }

    this.applyTransformations(csvReport)
    return csvReport
  }

  downloadExcelData = () => {
    const mimeType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
    const fileExtension = 'xlsx'
    let data = this.transformReportsData(this.props.data)
    const csvReport = this.getCsvReport(data)
    let reportFilename = `cardbalances_${this.props.date}.${fileExtension}`;
    
    const excelWorkbook = XLSX.utils.book_new()

    // put the whole report data into an array of arrays, with the first row the headers
    let excelData = []
    excelData.push(csvReport.header)
    csvReport.rows.forEach(function(row) {
      excelData.push(row)
    })

    excelWorkbook.Props = {
      Title: "Report",
      Author: "Tutuka",
      CreatedDate: new Date()
    }
    excelWorkbook.SheetNames.push("Sheet1")
    var excelSheet = XLSX.utils.aoa_to_sheet(excelData)
    excelWorkbook.Sheets["Sheet1"] = excelSheet
    
    var wbout = XLSX.write(excelWorkbook, {bookType:'xlsx',  type: 'binary'});
    download(new Blob([s2ab(wbout)],{type:"application/octet-stream"}), reportFilename, mimeType)
  }

  transformReportsData = (dataToTransform) => {
    let data = dataToTransform || [];
    data = data.filter(report => report.length > 0); // Exclude empty reports (empty files).
    return data
  }

  render() {
    let data = this.transformReportsData(this.props.data)
    if (data.length === 0) {
      return null;
    }

    const csvReport = this.getCsvReport(data);
    const header = csvReport.header;
    const rows = csvReport.rows;

    const tableHeader = header.map(
      (element, index) => {
        return <TableCell key={index}>{element}</TableCell>
      }
    )

    const visibleRows = this.props.previewSize ? rows.slice(0, this.props.previewSize) : rows
    const tableBody = visibleRows.map((row, index) => (
      <TableRow key={index}>
        {row.map((cell, cellIndex) => <TableCell key={cellIndex}>{cell}</TableCell>)}
      </TableRow>
    ))

    const csvBlob = toCsvBlob([header, ...rows])
    const csvDownloadLink = URL.createObjectURL(csvBlob)
    const link = <a href={csvDownloadLink} download={this.props.reportName}>
      <Tooltip title="CSV"><Icon style={{ verticalAlign: 'middle',cursor: 'pointer' }} color="primary">cloud_download</Icon></Tooltip>
    </a>
    const excelDownload = this.props.downloadableExcel ? <IconButton style={{verticalAlign: 'middle',cursor: 'pointer'}} onClick={this.downloadExcelData}>
       <Tooltip title="Excel"><Icon color="primary">receipt</Icon></Tooltip>
     </IconButton> : null

    const report = (
      <Table>
        <TableHead>
          <TableRow>
            {tableHeader}
          </TableRow>
        </TableHead>
        <TableBody>
          {tableBody}
        </TableBody>
      </Table>
    )

    return (
      <Card style={{padding: '40px'}}>
        <Grid
          justify="space-between"
          container
        >
          <Grid item>
            <Typography gutterBottom variant="h5">
              {this.props.reportHeader}
            </Typography>
          </Grid>
          <Grid item>
            {this.props.previewSize && `(${this.props.previewSize} rows previewed)`} &nbsp; {link} &nbsp; {excelDownload}
          </Grid>
          <Grid item style={{ padding: '20px', overflow: 'overlay' }}>
            {report}
          </Grid>
        </Grid>
      </Card>
    )
  }
}

const mapStateToProps = state => ({
  reportUrl: state.reportInfo.reportURL
})

export default connect(mapStateToProps)(CSVReportDetails)
