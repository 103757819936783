import React, {Component, Fragment} from 'react'
import Button from '@material-ui/core/Button'
import FailedTransactionsPage from '../transactionDetails/FailedTransactions'
import { failedTransactionSearchClear } from 
'../../../action/failedTransactionSearch'
import { store } from '../../../store'

export class FailedTransactions extends Component {
  constructor(props){
    super(props)

    this.state = {
      open: false
    }

    this.handleClose = this.handleClose.bind(this)
  }

  handleClick = event => {
    this.setState({
        open: true
    })
  }

  handleClose = () => {
    store.dispatch(failedTransactionSearchClear())
    this.setState({
      open: false
    })
  }

  render(){
    return(
      <Fragment>
        <Button variant="contained" color="primary" onClick={this.handleClick}>Failed</Button>
        <FailedTransactionsPage open={this.state.open} close={this.handleClose} voucherNumber={this.props.cardDetails ? this.props.cardDetails.CARDNUMBER : null}/>
      </Fragment>
    )
  }
}

export default FailedTransactions
