import { 
  MERCHANT_SIGNUP,
  MERCHANT_CLEAR_ERROR_MESSAGE,
  MERCHANT_CLEAR_SIGN_UP_LIST
} from '../action'

const initialState = {
  signUpList: [],
  errorMsg: ''
}

export default (state = initialState, action) => {
  const { type, payload, meta } = action
  switch(type) {
    case MERCHANT_CLEAR_ERROR_MESSAGE:
      return {
        ...state,
        errorMsg: ''
      }
    case MERCHANT_CLEAR_SIGN_UP_LIST:
      const { position = 'all' } = action
      const { signUpList : oldList } = state
      return {
        ...state,
        signUpList: (
          position === 'all' ?
          [] :
          [
            ...oldList.slice(0, position),
            ...oldList.slice(position + 1, oldList.length)
          ]
        )
      }

    case `${MERCHANT_SIGNUP}_FULFILLED`:
      if (payload.resultCode === "0") {
        const registeredMerchant = {
          ...meta.merchantInfo,
          qr: payload.qrCode
        }
        return {
          ...state,
          signUpList: [...state.signUpList, registeredMerchant]
        }
      } else {
        return {
          ...state,
          errorMsg: payload.resultText
        }
      }
    case `${MERCHANT_SIGNUP}_REJECTED`:
      return {
        ...state,
        errorMsg: 'Cannot connect to server'
      }

    default:
      return state
  }
}
