import React from 'react'
import Table from '@material-ui/core/Table'
import TableHead from '@material-ui/core/TableHead'
import TableBody from '@material-ui/core/TableBody'
import TableRow from '@material-ui/core/TableRow'
import TableCell from '@material-ui/core/TableCell'
import UserSearchResultsRow from './UserSearchResultsRow'
import Card from "@material-ui/core/Card"
import Grid from "@material-ui/core/Grid"
import Typography from "@material-ui/core/Typography"
import Tooltip from "@material-ui/core/Tooltip"
import {CSVLink} from "react-csv"
import Icon from "@material-ui/core/Icon"
import dateFormat from "dateformat"


const couldNotLoginBefore = new Date('1950-01-01');
//The last login is 1900-01-01 in the DB initially
const formatDate = dateValue => dateValue <  couldNotLoginBefore ? '' : dateFormat(dateValue,'yyyy-mm-dd HH:MM:ss')

const boolFlag = value => value ? 'Yes' : 'No'

const formatCsv = (data) => {
  return data.map(
    user => ({
      Username: user.userName,
      FirstName: user.firstName,
      LastName: user.lastName,
      Email: user.email,
      Groups: user.userGroups,
      LastLogin: formatDate(user.lastLogin),
      Activated: boolFlag(user.isActivated),
      Locked: boolFlag(user.isLocked)
    })
  )
}
const transformData = (data) => {
  return data && data.map((item) => {
    const newItem = {...item}
    newItem.lastLogin = formatDate(item.lastLogin)
    return newItem
  })
}

const UserSearchResults = (props) => {
    return (
      <Card>
        <Grid style={{ paddingLeft: '50px',paddingTop: '50px',paddingBottom: '20px', paddingRight: '90px' }}
          justify="space-between"
          container
        >
          <Grid item>
            <Typography gutterBottom variant="h5">
              Search results
            </Typography>
          </Grid>
          <Grid style={{ paddingTop: '20px' }} item>
            <Tooltip title="CSV">
              <CSVLink
                data={formatCsv(props.searchResults)}
                filename={`Users_${dateFormat(new Date(),'yyyy-mm-dd_H:M:ss')}.csv`}
                target="_blank"
              >
                <Icon style={{ verticalAlign: 'middle' }} color="primary">cloud_download</Icon>
              </CSVLink>
            </Tooltip>
          </Grid>
        </Grid>
        <Grid item style={{ padding: '20px', overflow: 'overlay' }}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>User</TableCell>
                <TableCell>First Name</TableCell>
                <TableCell>Last Name</TableCell>
                <TableCell>email</TableCell>
                <TableCell>Groups</TableCell>
                <TableCell>Last Login</TableCell>
                <TableCell></TableCell>
                <TableCell></TableCell>
                <TableCell></TableCell>
                <TableCell></TableCell>
                <TableCell></TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {
                transformData(props.searchResults).map((row, i) =>
                  <UserSearchResultsRow 
                    currentUserId={props.currentUserId} 
                    delete={props.delete} 
                    unlock={props.unlock} 
                    resendActivation={props.resendActivation} 
                    key={i} 
                    searchResult={row}
                    resetOTP={props.resetOTP}
                  />
                )
              }
            </TableBody>
          </Table>
        </Grid>
      </Card>

    )
}

export default UserSearchResults
