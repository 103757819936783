import React, {Fragment} from 'react'
import TextField from '@material-ui/core/TextField'
import { withTheme } from '@material-ui/core/styles'

const StartAndEndDate = props => <Fragment><TextField
  style={props.theme.palette.startEndDateInput}
  name="startDate"
  label="Start date"
  type="date"
  onChange={props.onStartChange}
  value={props.startValue}
/>
<span style={{padding:'20px'}}>-</span>
<TextField
  style={props.theme.palette.startEndDateInput}
  name="endDate"
  label="End date"
  type="date"
  onChange={props.onEndChange}
  value={props.endValue}
/></Fragment>

export default withTheme(StartAndEndDate)
