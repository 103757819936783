import React from 'react'
import BaseDateRangedReport from './BaseDateRangedReport'

const TopUpSummaryReport = () => {
    return (
      <BaseDateRangedReport
        reportHeader = "Top Up Summary Report"
        reportDownloadName = "topUpSummaryReport"
        apiReportName = "topUpSummaryReport"
        chooseCampaignDisabled
      />
    )
}

export default TopUpSummaryReport
