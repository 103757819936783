import { apiCall } from './api'
import { store } from '../store'

export const SUBMIT_VIRTUAL_CARD_ORDER = 'SUBMIT_VIRTUAL_CARD_ORDER'
export const submitVirtualCardOrder = ({campaignId, virtualCardOrders, fileName}) => {
  const payload = apiCall('batchVirtualCardOrder', {
    campaignId,
    batchElements: constructBatchElement(virtualCardOrders),
    fileName
  })
  return {
    type: SUBMIT_VIRTUAL_CARD_ORDER,
    payload,
    meta: {
      campaignId, virtualCardOrders, fileName
    }
  }
}

const constructBatchElement = (virtualCardOrders) => virtualCardOrders.map( card => {
  return ({
    customerReference: card.customerReference,
    cardLabel: card.cardLabel,
    notificationNumber: card.notificationNumber,
    expiryDate: card.expiryDate,
    loadAmount: card.loadAmount,
    tagName: card.tagName,
    tagValue: card.tagValue
  })
})

export const RESET_VIRTUAL_SUCCESS = 'RESET_VIRTUAL_SUCCESS'

export const resetVirtualSuccess = () => ({type: RESET_VIRTUAL_SUCCESS})

export const RESET_VIRTUAL_CARD_ORDER = 'RESET_VIRTUAL_CARD_ORDER'
export const resetVirtualCardOrder = () => ({type: RESET_VIRTUAL_CARD_ORDER})

export const verifyVirtualCardOrderFile = (files, campaignID) => {

  const fileBlob = files[0]
  
  const reader = new FileReader()

  reader.onload = function() {

    const fileContent = reader.result
    let orderBatch = []

    let virtualCardOrders = fileContent.split(/\r\n|\n/)

    virtualCardOrders.forEach(function (row) {
      //Only process lines that aren't empty
      if (row.length > 0) {
        let virtualCardOrder = row.split(',')
        let virtualCardOrderObject = {
          "customerReference": virtualCardOrder[0],
          "cardLabel": virtualCardOrder[1],
          "notificationNumber": virtualCardOrder[2],
          "expiryDate": virtualCardOrder[3],
          "loadAmount": virtualCardOrder[4],
          "tagName": virtualCardOrder[5],
          "tagValue": virtualCardOrder[6],
        }

        orderBatch.push(virtualCardOrderObject)
      }
    })

    store.dispatch(virtualCardOrderVerification(orderBatch, campaignID))
  }

  reader.onabort = () => store.dispatch(virtualCardOrderVerificationErrorMessage('File upload aborted'))
  reader.onerror = () => store.dispatch(virtualCardOrderVerificationErrorMessage('File upload failed'))

  reader.readAsBinaryString(fileBlob) 
}

export const VIRTUAL_CARD_ORDER_VERIFICATION = 'VIRTUAL_CARD_ORDER_VERIFICATION'
export const virtualCardOrderVerification = (virtualCardOrders, campaignID) => {
  virtualCardOrders = virtualCardOrders.map((order) => {
    delete order.ERROR
    return order
  })
  return {
    type: VIRTUAL_CARD_ORDER_VERIFICATION,
    payload: apiCall('verifyVirtualCardOrder', {virtualCardOrders, campaignID}),
    meta: {
      virtualCardOrders,
      campaignID
    }
  }
}

export const VIRTUAL_CARD_ORDER_VERIFICATION_ERROR_MESSAGE = 'VIRTUAL_CARD_ORDER_VERIFICATION_ERROR_MESSAGE'

export const virtualCardOrderVerificationErrorMessage = (errorMessage = null) => {
  return {
    type: VIRTUAL_CARD_ORDER_VERIFICATION_ERROR_MESSAGE,
    payload: {resultText: errorMessage}
  }
}
