import Paper from '@material-ui/core/Paper'
import Button from '@material-ui/core/Button'
import {Link} from 'react-router-dom'
import Typography from '@material-ui/core/Typography'
import React from 'react'


const ForgotPasswordConfirmation = (props) => {

  return (
      <Paper style={props.paperStyle} zdepth={4}>
        <Typography style={{marginTop: 20, textAlign: 'left'}} variant="subtitle1" gutterBottom>A reset password link has been sent to your e-mail address:
          <div style={{fontWeight: "bold", textAlign: 'center'}}> {props.email}</div>
          <br/>
          Please check your inbox.</Typography>
        <div style={{marginTop: 10}}>
          <Link to='/login' style={{textDecoration: 'none'}} >
            <Button size="large" variant="contained" color="primary" onClick={props.accept}>Accept</Button>
          </Link>
        </div>
      </Paper>
    )
}

export default (ForgotPasswordConfirmation)
