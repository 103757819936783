import React from 'react'
import BaseMonthlyReport from './BaseMonthlyReport'

export default () => <BaseMonthlyReport
  reportHeader = "Monthly Compliance Report"
  reportDownloadName = "monthlyComplianceReport"
  apiReportName = "monthlyComplianceReport"
  chooseCampaignDisabled = {true}
  downloadOnly = {true}
  getExistingReport = {true}
/>
