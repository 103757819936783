import { apiCall } from './api'

import { convertCapitalCaseToCamelCase, convertStringToBoolean } from '../util/misc'

const LIST_VIRTUAL_CARD_ORDERS_BATCH_LOADS_URL = 'fetchVirtualCardOrdersBatchLoads'
const FETCH_VIRTUAL_CARD_ORDERS_BATCH_LOAD_DETAILS_URL = 'fetchVirtualCardOrdersBatchLoadDetails'
export const FETCH_VIRTUAL_CARD_ORDERS_BATCH_LOADS = 'FETCH_VIRTUAL_CARD_ORDERS_BATCH_LOADS'
export const VIRTUAL_CARD_ORDERS_BATCH_LOAD_APPROVE = 'VIRTUAL_CARD_ORDERS_BATCH_LOAD_APPROVE'
export const VIRTUAL_CARD_ORDERS_BATCH_LOAD_REJECT = 'VIRTUAL_CARD_ORDERS_BATCH_LOAD_REJECT'
export const FETCH_VIRTUAL_CARD_ORDERS_BATCH_LOAD_DETAILS = 'FETCH_VIRTUAL_CARD_ORDERS_BATCH_LOAD_DETAILS'
export const DOWNLOAD_VIRTUAL_CARD_ORDERS_BATCH_LOAD_DETAILS_REPORT =
  'DOWNLOAD_VIRTUAL_CARD_ORDERS_BATCH_LOAD_DETAILS_REPORT'
export const VIRTUAL_CARD_ORDERS_BATCH_LOAD_SET_ERROR = 'VIRTUAL_CARD_ORDERS_BATCH_LOAD_SET_ERROR'
export const VIRTUAL_CARD_ORDERS_BATCH_LOAD_CLEAR_ERROR = 'VIRTUAL_CARD_ORDERS_BATCH_LOAD_CLEAR_ERROR'

export const prepareVirtualCardOrdersResultToCamelCase = (result) =>
  convertStringToBoolean(convertCapitalCaseToCamelCase(result))

export const fetchVirtualCardOrdersBatchLoads = ({ filter, startDate = null, endDate = null }) => {
  const payload = apiCall(LIST_VIRTUAL_CARD_ORDERS_BATCH_LOADS_URL, {
    filter,
    startDate,
    endDate,
  }).then(({ resultText, resultCode }) => ({
    resultCode,
    data:
      resultCode === '0'
        ? prepareVirtualCardOrdersResultToCamelCase(JSON.parse(resultText))
        : { resultCode, resultText },
  }))

  return {
    type: FETCH_VIRTUAL_CARD_ORDERS_BATCH_LOADS,
    payload,
  }
}

export const approveVirtualCardOrdersBatchLoad = (virtualCardOrdersBatchLoadId) => {
  const payload = apiCall('approveVirtualCardOrdersBatchLoad', {
    batchLoadFileID: virtualCardOrdersBatchLoadId,
  }).then(({ resultCode }) => ({ resultCode, virtualCardOrdersBatchLoadId }))

  return {
    type: VIRTUAL_CARD_ORDERS_BATCH_LOAD_APPROVE,
    payload,
  }
}

export const rejectVirtualCardOrdersBatchLoad = (virtualCardOrdersBatchLoadId) => {
  const payload = apiCall('rejectVirtualCardOrdersBatchLoad', {
    batchLoadFileID: virtualCardOrdersBatchLoadId,
  }).then(({ resultCode }) => ({ resultCode, virtualCardOrdersBatchLoadId }))

  return {
    type: VIRTUAL_CARD_ORDERS_BATCH_LOAD_REJECT,
    payload,
  }
}

export const fetchVirtualCardOrdersBatchLoadDetails = (virtualCardOrdersBatchLoadId) => {
  const payload = apiCall(FETCH_VIRTUAL_CARD_ORDERS_BATCH_LOAD_DETAILS_URL, {
    batchLoadFileID: virtualCardOrdersBatchLoadId,
  }).then(({ resultText, resultCode }) => ({
    resultCode,
    data:
      resultCode === '0'
        ? prepareVirtualCardOrdersResultToCamelCase(JSON.parse(resultText))
        : { resultCode, resultText },
  }))

  return {
    type: FETCH_VIRTUAL_CARD_ORDERS_BATCH_LOAD_DETAILS,
    payload,
  }
}

export const downloadVirtualCardOrdersBatchLoadDetailsReport = (batchLoadFileID) => {
  const payload = apiCall('downloadVirtualCardOrdersBatchLoadDetailsReport', {
    batchLoadFileID,
  })
  return {
    type: DOWNLOAD_VIRTUAL_CARD_ORDERS_BATCH_LOAD_DETAILS_REPORT,
    payload,
  }
}

export const setVirtualCardOrdersBatchLoadError = (errorMsg) => {
  return {
    type: VIRTUAL_CARD_ORDERS_BATCH_LOAD_SET_ERROR,
    payload: errorMsg,
  }
}

export const clearVirtualCardOrdersBatchLoadError = () => {
  return {
    type: VIRTUAL_CARD_ORDERS_BATCH_LOAD_CLEAR_ERROR,
  }
}
