import jwt_decode from 'jwt-decode'

export function userDetails(token){
  return jwt_decode(token)
}

export function getUserId(token){
  return userDetails(token).uid
}

export function validatePasswordPolicy(password,username){
  return password.length >= 10 && 
  	password.search(username) === -1 &&
  	[/[\d]/, /[a-z]/, /[A-Z]/, /[!@#$%^\\'"&*()`~,./<>;:]/].every(regex => regex.test(password))
}
