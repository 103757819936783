import { CARD_SEARCH, COMBINED_CARD_STATEMENT, COMBINED_CARD_STATEMENT_RESET, GET_CARD_TAGS } from '../action/cardSearch'

const initialState = {
  errorMsg : '',
  cardTags : [],
  combinedStatement: null
}

export default (state = initialState, action) => {
  const { type, payload } = action
  switch(type) {
    case `${CARD_SEARCH}_FULFILLED`:
      if (payload.resultCode === "0") {

        return {
          ...state,
          errorMsg: '',
          searchResults: JSON.parse(payload.resultText)
        }
      } else {
        return {
          ...state,
          errorMsg: payload.resultText,
          searchResults: ''
        }
      }
    case `${CARD_SEARCH}_PENDING`:
      return {
        ...state,
        errorMsg: ''
      }
    case `${CARD_SEARCH}_REJECTED`:
      return {
        ...state,
        errorMsg: 'Cannot connect to server',
        searchResults: ''
      }
    case `${CARD_SEARCH}_RESET`:
      return {
        ...initialState
      }
    case `${COMBINED_CARD_STATEMENT}_FULFILLED`:
      if (payload.resultCode === "0") {
        return {
          ...state,
          errorMsg: '',
          combinedStatement: JSON.parse(payload.statement)
        }
      } else {
        return {
          ...state,
          errorMsg: payload.resultText,
          combinedStatement: null
        }
      }
    case `${COMBINED_CARD_STATEMENT}_PENDING`:
      return {
        ...state,
        errorMsg: '',
        combinedStatement: null
      }
    case `${COMBINED_CARD_STATEMENT}_REJECTED`:
      return {
        ...state,
        errorMsg: 'Cannot connect to server',
        combinedStatement: null
      }
    case `${COMBINED_CARD_STATEMENT_RESET}`:
      return {
        ...state,
        combinedStatement: null
      }
    case `${GET_CARD_TAGS}_FULFILLED`:
      if (payload.resultCode === "0") {
        return {
          ...state,
          errorMsg: '',
          cardTags: JSON.parse(payload.resultText)
        }
      } else {
        return {
          ...state,
          errorMsg: payload.resultText,
          cardTags: []
        }
      }
    case `${GET_CARD_TAGS}_PENDING`:
      return {
        ...state,
        cardTags: []
      }
    case `${GET_CARD_TAGS}_REJECTED`:
      return {
        ...state,
        errorMsg: 'Cannot connect to server',
        cardTags: []
      }

    default:
      return state
  }
}
