import {SUBMIT_VIRTUAL_CARD_ORDER, RESET_VIRTUAL_SUCCESS, RESET_VIRTUAL_CARD_ORDER, VIRTUAL_CARD_ORDER_VERIFICATION} from "../action/virtualCardOrder"

const initialState = {
  resultCode: '',
  resultText: '',
  errorMsg: '',
  complete: false,
  virtualCardOrders: []
}

export default (state = initialState, action) => {

  const { type, payload} = action

  switch (type) {
  case SUBMIT_VIRTUAL_CARD_ORDER + '_FULFILLED':
    return {
      ...state,
      resultCode: payload.resultCode,
      complete: payload.resultCode === 0 || payload.resultCode === "0",
      resultText: payload.resultText
    }
  case RESET_VIRTUAL_SUCCESS:
  case RESET_VIRTUAL_CARD_ORDER:
    return {
      ...initialState,
      virtualCardOrders: []
    }
  case VIRTUAL_CARD_ORDER_VERIFICATION + '_FULFILLED':
    if (payload.resultCode === 0 || payload.resultCode === "0") {
      return {
        ...state,
        resultCode: payload.resultCode,
        virtualCardOrders: payload.resultText
      }
    } else {
      return {
        ...state,
        errorMsg: payload.resultText
      }
    }
  default:
    return state
  }
}
