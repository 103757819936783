import React, {Component} from 'react'
import Wrapper from '../../component/default/layout/Wrapper'
import TextField from '@material-ui/core/TextField'
import {connect} from 'react-redux'
import { Button, Typography, Card, Modal } from '@material-ui/core'
import { withStyles } from '@material-ui/core/styles'
import { changeUserPassword, setPasswordResultAsDefault } from '../../action/currentUser'
import { withRouter } from "react-router"
import { validatePasswordPolicy } from '../../util/user'

function getModalStyle() {
  const top = 50
  const left = 50

  return {
    top: `${top}%`,
    left: `${left}%`,
    transform: `translate(-${top}%, -${left}%)`,
  }
}

const styles = theme => ({
  paper: {
    position: 'absolute',
    width: theme.spacing(50),
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    padding: theme.spacing(4),
  },
})

class UserChangePassword extends Component {
  state = {
    oldPassword:'',
    password: '',
    passwordConfirmation: ''
  }

  componentWillUnmount = () => {
    this.props.setPasswordResultAsDefault()
  }

  handleChange = e => this.setState({...this.state, [e.target.name]: e.target.value})

  handleClose = () => {
    this.props.setPasswordResultAsDefault()
    this.props.history.push('/')
  }

  render() {
    return (
      <Card style={{padding:'20px'}}>
        <Wrapper>
          <form>
            <Typography variant='h5'>Change Password</Typography>
            <TextField
              required
              id="oldPassword"
              label="Old Password"
              type="password"
              name="oldPassword"
              autoComplete="old-password"
              value={this.state.oldPassword}
              onChange={this.handleChange}
            />
            <br/>
            <TextField
              required
              id="password"
              label="New Password"
              type="password"
              name="password"
              autoComplete="new-password"
              value={this.state.password}
              onChange={this.handleChange}
            />
            <br/>
            <TextField
              required
              id="password-confirm"
              label="Password Confirmation"
              type="password"
              name="passwordConfirmation"
              autoComplete="new-password"
              value={this.state.passwordConfirmation}
              onChange={this.handleChange}
            />
            {
              this.state.passwordConfirmation.length > 3 && (!validatePasswordPolicy(this.state.password, this.props.user.userId)  || this.state.password !== this.state.passwordConfirmation)?
                <Typography color="error">password and password confirmation donot match and/or password quality did not match the spec. 1.password should be more than 10 characters 2. it should have atleast 1 small and 1 capital letters 3. the password must contain atleast on of the following symbol: {"!@#$%^'\"&*()`~,./<>;:"}</Typography> : null
            }
            <br/>
            <br/>
            <Button color='primary' variant="contained" disabled={this.state.password !== this.state.passwordConfirmation || this.state.password.length < 3} onClick={()=>this.props.changeUserPassword(this.state.oldPassword, this.state.password)}>Change Password</Button>
          </form>
        </Wrapper>
        <Modal
          aria-labelledby="simple-modal-title"
          aria-describedby="simple-modal-description"
          open={this.props.currentUser.changePasswordSuccess}
          onClose={this.handleClose}
        >
          <div style={getModalStyle()} className={this.props.classes.paper}>
            <Typography variant="h5" id="modal-title">
              {
                this.props.currentUser.resultCode === 0 ? 
                  'Your password has changed!': this.props.currentUser.resultText
              }
            </Typography>
            <br/>
            <Button onClick={this.handleClose} color='primary' variant='contained'>OK</Button>
          </div>
        </Modal>
      </Card>
    )
  }
}

const mapStateToProps = (state, props) => ({
  user: state.auth.user,
  currentUser: state.currentUser
})

const mapDispatchToProps = {
  changeUserPassword,
  setPasswordResultAsDefault
}

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(withRouter(UserChangePassword)))
