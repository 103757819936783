import React, {Component} from 'react'
import {TableRow, TableCell} from '@material-ui/core'
import Icon from '@material-ui/core/Icon'
import IconButton from '@material-ui/core/IconButton'
import dateFormat from "dateformat"
import CurrencyFormat from 'react-currency-format'
import BatchLoadsStatusPopover from './BatchLoadsStatusPopover'
import BatchLoadsApproveDialog from './BatchLoadsApproveDialog'
import BatchLoadsCloseDialog from './BatchLoadsCloseDialog'
import BatchLoadsCloseCommentsDialog from './BatchLoadsCloseCommentsDialog'
import Tooltip from '@material-ui/core/Tooltip'

const buttonTooltipDelay = 800

export class BatchLoadsResultsRow extends Component {

  render() {
    const isSameUser = this.props.batchLoadsResult.BATCHLOADFILEUPLOADEDUSERID === this.props.currentUserId
    const approveDeclineDisabled = (this.props.batchLoadsResult.BATCHLOADFILEAPPROVED || this.props.batchLoadsResult.BATCHLOADFILEREJECTED) ? true : false
    const loadRetryDisabled = (this.props.batchLoadsResult.BATCHLOADFILEAPPROVED && !this.props.batchLoadsResult.PROCESSED && !this.props.batchLoadsResult.BATCHLOADFILECLOSED && this.props.appliedBatchLoadsFilter !== 5) ? false : true
    const closeDisabled = (this.props.batchLoadsResult.BATCHLOADFILEAPPROVED && !this.props.batchLoadsResult.BATCHLOADFILEREJECTED && !this.props.batchLoadsResult.BATCHLOADFILECLOSED) ? false : true
    const showClosedCommentsButton = this.props.batchLoadsResult.BATCHLOADFILECLOSED;
    const showClosedButton = !loadRetryDisabled;

    return (
      <TableRow>
        <TableCell>{this.props.batchLoadsResult.BATCHLOADFILEREFERENCE}</TableCell>
        <TableCell>
          {dateFormat(new Date(this.props.batchLoadsResult.BATCHLOADFILEDATEUPLOADED), "yyyy-mm-dd HH:MM:ss")}
        </TableCell>
        <TableCell>{this.props.batchLoadsResult.UPLOADUSERFIRSTNAME} {this.props.batchLoadsResult.UPLOADUSERLASTNAME}</TableCell>
        <TableCell>
          <BatchLoadsStatusPopover
            type="Approved"
            status={this.props.batchLoadsResult.BATCHLOADFILEAPPROVED}
            firstName={this.props.batchLoadsResult.APPROVALUSERFIRSTNAME}
            lastName={this.props.batchLoadsResult.APPROVALUSERLASTNAME}
            statusDate={dateFormat(new Date(this.props.batchLoadsResult.BATCHLOADFILEAPPROVEDDATE), "yyyy-mm-dd HH:MM:ss")}
            scheduledDate={this.props.batchLoadsResult.SCHEDULEDDATE ? dateFormat(new Date(this.props.batchLoadsResult.SCHEDULEDDATE), "yyyy-mm-dd HH:MM:ss") : null}
          />
        </TableCell>
        <TableCell>
          <BatchLoadsStatusPopover
            type="Rejected"
            status={this.props.batchLoadsResult.BATCHLOADFILEREJECTED}
            firstName={this.props.batchLoadsResult.REJECTIONUSERFIRSTNAME}
            lastName={this.props.batchLoadsResult.REJECTIONUSERLASTNAME}
            statusDate={dateFormat(new Date(this.props.batchLoadsResult.BATCHLOADFILEREJECTEDDATE), "yyyy-mm-dd HH:MM:ss")}
          />
        </TableCell>
        <TableCell>{this.props.batchLoadsResult.LOADEDCOUNT}/{this.props.batchLoadsResult.LOADSCOUNT}</TableCell>
        <TableCell>
          <CurrencyFormat value={this.props.batchLoadsResult.LOADEDAMOUNT} displayType={'text'} thousandSeparator={true} fixedDecimalScale={true} decimalScale={2}/>
          /
          <CurrencyFormat value={this.props.batchLoadsResult.LOADSAMOUNT} displayType={'text'} thousandSeparator={true} fixedDecimalScale={true} decimalScale={2}/>
        </TableCell>
        <TableCell>
          <BatchLoadsApproveDialog
            batchLoadFileID={this.props.batchLoadsResult.BATCHLOADFILEID}
            batchLoadApprove={this.props.batchLoadApprove}
            disabled={approveDeclineDisabled || isSameUser}
            tooltip = {isSameUser ? 'A user cannot approve their own load' : null}
          />
          { showClosedButton ?
            <BatchLoadsCloseDialog
              batchLoadFileID={this.props.batchLoadsResult.BATCHLOADFILEID}
              batchLoadClose={this.props.batchLoadClose}
              disabled={closeDisabled}
            />
          :
            <Tooltip title="Reject batch load" enterDelay={buttonTooltipDelay}>
              <span>
                <IconButton onClick={() => {this.props.batchLoadReject(this.props.batchLoadsResult.BATCHLOADFILEID)}} disabled={approveDeclineDisabled} aria-label="Reject" >
                  <Icon>close</Icon>
                </IconButton>
              </span>
            </Tooltip>
          }
          <Tooltip title="Retry batch load or process scheduled batch load" enterDelay={buttonTooltipDelay}>
            <span>
              <IconButton onClick={() => {this.props.batchLoadRetry(this.props.batchLoadsResult.BATCHLOADFILEID)}} disabled={loadRetryDisabled} aria-label="Reject" >
                <Icon>autorenew</Icon>
              </IconButton>
            </span>
          </Tooltip>
          <Tooltip title="Show batch load details" enterDelay={buttonTooltipDelay}>
            <IconButton onClick={() => {this.props.batchLoadDetails(this.props.batchLoadsResult)}} aria-label="Reject" >
              <Icon>list</Icon>
            </IconButton>
          </Tooltip>
          { showClosedCommentsButton ?
          <BatchLoadsCloseCommentsDialog
            batchLoadFileID={this.props.batchLoadsResult.BATCHLOADFILEID}
            batchLoadClosedComments={this.props.batchLoadsResult.BATCHLOADFILECLOSEDCOMMENTS}
          /> : null }
        </TableCell>
      </TableRow>
    )
  }
}
