import dateFormat from 'dateformat'

const BOOLEAN_STRING_VALUES = {
  TRUE: true,
  FALSE: false
}

export function getFieldName(text){
  return text.toUpperCase().replace(/\s/g, '')
}

export function maskedCardNumber (number){
  return 'xxxxxxxxxxxx' + number.substr(number.length - 4);
}

/**
 * String to array buffer
 */
export function s2ab (s){
  var buf = new ArrayBuffer(s.length); //convert s to arrayBuffer
  var view = new Uint8Array(buf);  //create uint8array as viewer
  for (var i=0; i<s.length; i++) view[i] = s.charCodeAt(i) & 0xFF; //convert to octet
  return buf;
}

export const doSafeDateFormat = (date, format) =>
  date
    ? dateFormat(new Date(date), format)
    : null
    
export const camelize = (text) => {
  if (!text) {
    return ''
  }
  const preparedText = text
    .toLowerCase()
    .replace(/[-_\s.]+(.)?/g, (_, c) => (c ? c.toUpperCase() : ''))
  return preparedText.substring(0, 1).toLowerCase() + preparedText.substring(1)
}

export const convertStringToBoolean = (value) => {
  if (value === null || value === undefined) {
    return value
  }
  if (Array.isArray(value)) {
    return value.map((val) => convertStringToBoolean(val))
  }
  if (typeof value === 'object') {
    return Object.entries(value).reduce(
      (acc, [key, value]) => ({
        ...acc,
        [key]: convertStringToBoolean(value),
      }),
      {}
    )
  }
  
  const convertedValue = BOOLEAN_STRING_VALUES[value]
  if (typeof value === 'string' && convertedValue !== null && convertedValue !== undefined ) {
    return convertedValue
  }
  return value
}

export const convertCapitalCaseToCamelCase = (
  value,
  mappingKeys = {},
  isValue = false
) => {
  if (value === null || value === undefined) {
    return value
  }
  if (Array.isArray(value)) {
    return value.map((val) =>
      convertCapitalCaseToCamelCase(val, mappingKeys, true)
    )
  }
  if (typeof value === 'object') {
    return Object.entries(value).reduce(
      (acc, [key, value]) => ({
        ...acc,
        [convertCapitalCaseToCamelCase(key, mappingKeys, false)]:
          convertCapitalCaseToCamelCase(value, mappingKeys, true),
      }),
      {}
    )
  }
  if (isValue) {
    return value
  }
  return mappingKeys[value] || camelize(value)
}

export const calculateDifferenceInDays = (dateStr1 = new Date(), dateStr2 = new Date()) => {
  const date1 = new Date(dateStr1)
  const date2 = new Date(dateStr2)

  const timeDifference = date2 - date1

  const differenceInDays = Math.floor(timeDifference / (24 * 60 * 60 * 1000))

  return differenceInDays
}

