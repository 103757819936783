import React from 'react'
import PropTypes from 'prop-types'
import { TextField } from '@material-ui/core'
import moment from 'moment'

const DateFilter = (props) => {
  const { startDate, endDate, hasError, onChange } = props

  const handleOnChange = (field) => (event) => {
    if (!moment(event.target.value).isValid()){
      return
    }
    onChange({ startDate, endDate, [field]: event.target.value })
  }

  return (
    <div className="batch-loads-date-filter">
      <TextField
        name="startDate"
        helperText="Uploaded from"
        type="date"
        onChange={handleOnChange('startDate')}
        value={startDate}
        error={hasError}
        InputProps={{ inputProps: { 'data-testid': 'vco-start-date' } }}
      />
      <div className="batch-loads-date-filter-separator">-</div>
      <TextField
        name="endDate"
        helperText="Uploaded to"
        type="date"
        onChange={handleOnChange('endDate')}
        value={endDate}
        error={hasError}
        InputProps={{ inputProps: { 'data-testid': 'vco-end-date' } }}
      />
    </div>
  )
}

DateFilter.propTypes = {
  startDate: PropTypes.string,
  endDate: PropTypes.string,
  hasError: PropTypes.bool,
  onChange: PropTypes.func,
}

export default DateFilter
