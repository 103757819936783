import React, {Component} from 'react'
import {connect} from 'react-redux'
import PropTypes from 'prop-types'
import red from '@material-ui/core/colors/red'
import {cardDetails, cardDetailsErrorMessage} from '../../../action/cardDetails'
import FailedTransactions from './FailedTransactions'
import CardTransactions from '../transactionDetails/CardTransactions'
import CardDetails from './CardDetails'
import { devalueCardClear } from '../../../action/card/devalueCard'
import Notes from './Notes'

class CardDetailsForm extends Component {
  constructor(props) {
    super(props)
    
    this.initialState = {
      errorMsg: ''
    }
    
    this.state = {
      ...this.initialState
    }
  }

  refresh = () =>{
    this.props.cardDetails(this.props.voucherID)
  }

  componentDidMount(){
    this.refresh()
  }
  
  componentDidUpdate(prevProps, prevState){
    if( this.props.devalueSuccess && prevProps.devalueSuccess !== this.props.devalueSuccess){
      this.props.cardDetails(this.props.voucherID)
      this.props.devalueCardClear()
    }
  }

  render() {
    const errorTextStyle = {
      color: red[500]
    }
    const {errorMsg} = this.props
    const cardDetails = this.props.cardDetailsResponse ?
      (<CardDetails cardDetails={this.props.cardDetailsResponse}/>) : (null)
    return (
      <React.Fragment>
        <div style={errorTextStyle}>{errorMsg}</div>
        {cardDetails}
        <CardTransactions
          cardID={this.props.voucherID}
          cardDetails={this.props.cardDetailsResponse}
          failedTransaction={<FailedTransactions cardDetails={this.props.cardDetailsResponse}/>}
          refresh={this.refresh}
        />
        <Notes 
          notes={this.props.cardDetailsResponse.VOUCHERNOTES}
          cardID={this.props.voucherID}
        />
      </React.Fragment>
    )
  }
}

CardDetailsForm.propTypes = {
  CardDetails: PropTypes.func
}

const mapStateToProps = (state, props) => {
  return {
    submitting: !!(state.api.CARD_DETAILS && state.api.CARD_DETAILS > 0),
    errorMsg: state.cardDetails.errorMsg,
    cardDetailsResponse: state.cardDetails.cardDetailsResponse,
    devalueSuccess: state.devalueCard.devalueSuccess
  }
}

export default connect(
  mapStateToProps,
  {
    cardDetails,
    cardDetailsErrorMessage,
    devalueCardClear
  }
)(CardDetailsForm)
