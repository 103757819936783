import { apiCall } from './api'

export const FAILED_TRANSACTION_SEARCH = 'FAILED_TRANSACTION_SEARCH'

export function failedTransactionSearch(voucherNumber, startDate, endDate) {
  const payload = apiCall('failedTransactionSearch', {voucherNumber, startDate, endDate})
  return {
    type: FAILED_TRANSACTION_SEARCH,
    payload,
    meta: {
      voucherNumber,
      startDate,
      endDate
    }
  }
}

export const FAILED_TRANSACTION_SEARCH_ERROR_MESSAGE = 'FAILED_TRANSACTION_SEARCH_ERROR_MESSAGE'

export function failedTransactionSearchErrorMessage () {
  return {
    type: FAILED_TRANSACTION_SEARCH_ERROR_MESSAGE
  }
}

export const FAILED_TRANSACTION_SEARCH_CLEAR = 'FAILED_TRANSACTION_SEARCH_CLEAR'

export function failedTransactionSearchClear () {
  return {
    type: FAILED_TRANSACTION_SEARCH_CLEAR
  }
}
