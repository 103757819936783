import { apiCall } from '../api'

export const CHANGE_PIN = 'CHANGE_PIN'

export function changePin(voucherNumber,trackingNumber, newCardPin) {
  const payload = apiCall('ChangePin', { voucherNumber, trackingNumber, newCardPin })
  return {
    type: CHANGE_PIN,
    payload,
    meta: {
      voucherNumber,
      trackingNumber,
      newCardPin
    }
  }
}

export const CHANGE_PIN_ERROR_MESSAGE = 'CHANGE_PIN_ERROR_MESSAGE'

export function changePinErrorMessage() {
  return {
    type: CHANGE_PIN_ERROR_MESSAGE
  }
}

export const CHANGE_PIN_CLEAR = 'CHANGE_PIN_CLEAR'

export function changePinClear () {
  return {
    type: CHANGE_PIN_CLEAR
  }
}
