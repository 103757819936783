import {apiCall} from './api'

export const GET_RESET_PASSWORD_INFO = 'GET_RESET_PASSWORD_INFO'
export const getResetPasswordInfo = (uuid) => {
  const payload = apiCall('getResetPasswordInfo',{userUUID:uuid})
  return {
    type: GET_RESET_PASSWORD_INFO,
    payload,
    meta: {
    }
  }
}
