import { apiCall } from '../api'

export const DEVALUE_CARD = 'DEVALUE_CARD'

export function devalueCard(voucherNumber) {
  const payload = apiCall('devalueCard', { voucherNumber })
  return {
    type: DEVALUE_CARD,
    payload,
    meta: {
      voucherNumber
    }
  }
}

export const DEVALUE_CARD_ERROR_MESSAGE = 'DEVALUE_CARD_ERROR_MESSAGE'

export function devalueCardErrorMessage() {
  return {
    type: DEVALUE_CARD_ERROR_MESSAGE
  }
}

export const DEVALUE_CARD_CLEAR = 'DEVALUE_CARD_CLEAR'

export function devalueCardClear () {
  return {
    type: DEVALUE_CARD_CLEAR
  }
}
