import React from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogTitle from '@material-ui/core/DialogTitle'

class AlertDialog extends React.Component {
  state = {
    open: false,
  };

  handleClickOpen = () => {
    this.setState({ open: true });
  };

  handleClose = () => {
    this.setState({ open: false });
	};

  render() {
    return (
      <div>
        <Dialog
          open={this.props.open || this.state.open}
          onClose={this.handleClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle className='card-header'  id="alert-dialog-title"><span className='card-header-text'>{this.props.title || ''}</span></DialogTitle>
          <DialogContent>
            {this.props.children || ''}
          </DialogContent>
          <DialogActions>
            <Button onClick={this.props.onClick || this.handleClose} color="primary" autoFocus>
              {this.props.actionLabel || 'OK' }
            </Button>
            {this.props.cancelLabel &&
              <Button onClick={this.props.onClose || this.handleClose} color="primary" autoFocus>
                {this.props.cancelLabel || 'Cancel' }
              </Button>
            }
          </DialogActions>
        </Dialog>
      </div>
    );
  }
}

export default AlertDialog;