import React from 'react'
import TextField from '@material-ui/core/TextField'
import Button from '@material-ui/core/Button'
import Card from '@material-ui/core/Card'
import CardContent from '@material-ui/core/CardContent'
import CardActions from '@material-ui/core/CardActions'
import Typography from '@material-ui/core/Typography'
import UserCampaigns from '../../userCampaigns/UserCampaigns'

const CSVReportHeader = (props) => {

	const defaultMargin = {marginTop:'10px'}

	return (
		<Card style={{padding: '40px'}}>
			<Typography gutterBottom variant="h5">
			</Typography>
				{props.reportHeader ? props.reportHeader : 'Report Details'}
			<CardContent>
				<UserCampaigns style={defaultMargin} campaignSelected={props.campaignSelected} displayAllCampaigns={props.displayAllCampaigns}/>

				<TextField id="date" type="date"
					InputLabelProps={{shrink: true}} style={{width:'100%',...defaultMargin}} onChange={props.dateChanged}/>
			</CardContent>
			<CardActions className="align-right">
				{
					props.manualAction?
						props.manualAction :
					<Button className="flattbutton-login" color="primary" size="medium" variant="contained"
						style={{float:'right',marginTop: '25px'}} onClick={props.show}>Show</Button>
				}
			</CardActions>
		</Card>
	)
}

export default CSVReportHeader
