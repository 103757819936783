import React from 'react'
import Modal from '@material-ui/core/Modal'
import Button from '@material-ui/core/Button'
import Typography from '@material-ui/core/Typography'
import { withStyles } from '@material-ui/core/styles'

const SuccessModal = props =>         <Modal
  aria-labelledby="simple-modal-title"
  aria-describedby="simple-modal-description"
  open={props.open}
  onClose={props.onClose}
>
  <div style={getModalStyle()} className='paper'>
    <Typography variant="h6" id="modal-title">
      Success!
    </Typography>
    <Typography variant="subtitle1" id="simple-modal-description">
      Your Card Order has been added
    </Typography>
    <Button color="primary" variant='contained' style={{color:'white'}} onClick={props.onClose}>OK</Button>
  </div>
</Modal>

function getModalStyle() {
  const top = 50
  const left = 50

  return {
    transform: `translate(${top}%, ${left}%)`,
    backgroundColor:'white',
    padding: '40px',
    left: 'calc(50% - 400px)',
    top:'calc(50% + 80px)',
    width: '320px',
    height:'80px',
    position: 'abs,olute',

  }
}

const styles = theme => ({
  paper: {
    position: 'absolute',
    width: theme.spacing(50),
    backgroundColor: 'white',
    boxShadow: theme.shadows[5],
    padding: theme.spacing(4),
  }
})

export default withStyles(styles)(SuccessModal)
