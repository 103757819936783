import React, { Component } from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import TextField from '@material-ui/core/TextField'
import Button from '@material-ui/core/Button'
import { setUserPassword, setPasswordErrorMessage } from '../../../action/setPassword'
import Card  from '@material-ui/core/Card'
import CardActions from '@material-ui/core/CardActions'
import CardContent from '@material-ui/core/CardContent'
import Typography from '@material-ui/core/Typography'
import Tooltip from '@material-ui/core/Tooltip'
import { red } from '@material-ui/core/colors'
import { validatePasswordPolicy } from '../../../util/user'

const styles = {
	card: {
		display:'flex',
		flexDirection:'column',
		justifyContent:'center',
		padding:'50px',
		maxWidth:'500px',
		width:'100%' 
	},
	select: {
		width:'200px'
},
  textField:{
    maxWidth:'500px',
    width:'100%'
  },
  button:{
    width:'150px',
    color:'white'
  },
  form:{
    display:'flex',
    flexFlow:'row nowrap',
    justifyContent:'center'
  }
}
const helperTextStyle = {
	color: red[500]
  }


const validateNoEmptyFields = validateFieldArray => validateFieldArray.filter(field => field === '').length === 0

const initialState = {
	values :{
		password: '',
		confirmPassword: ''
	},	
	success: false,
	errorMsg:'',
	validationError:''
}
class SetPasswordForm extends Component {

	constructor(props) {
		super(props)
		this.state = {
			...initialState
		}
	}

	onInputChange = (event) => {
		const stateKey = event.target.getAttribute("id")
		const value = event.target.value			
		const newState = {
		  values: {
			...this.state.values,
			[stateKey]: value
		  },
		  validationError: this.validateInput()
		}
		this.setState(newState)		
	}

	validateInput = () => {
		let userName=this.props.user.USER.USERNAME
		let errorMessage = ""
		let pass=document.getElementById("password").value
		let confPass=document.getElementById("confirmPassword").value
		if(pass && confPass) {
			if(pass === confPass) {
				if(validatePasswordPolicy(pass, userName) !== true) {
					errorMessage = "Invalid Password: ensure password includes lower and upper case letter, is 10 characters long or more, has a numeric and a special character"
				}
			}
			else {
				errorMessage = "Passwords do not match!"
			}
		}
   	return errorMessage
	}

	SubmitForm = (event) => {
		if (event.type==='click' || event.key==='Enter') {	
			const error = this.validateInput()		
			if(error){
				event.stopPropagation();
				this.setState({validationError:error})
				return false
			}
			else {
				const userData=this.props.user;
				var userDetail={
				"userId":userData.USER.USERID,
				"ownerId":userData.USER.OWNERID,
				"userUUID":this.props.userUUID,
				"password":this.state.values.password,
				"confirmPassword":this.state.values.confirmPassword,
				"reset" : this.props.reset
				}		
			this.props.setPassword(userDetail);
			}
		}		
	}
	ShowError = ({errorMsg}) => <Card>
			<CardContent>
				<Typography gutterBottom variant="h5">
				{ errorMsg }
      			</Typography>
			</CardContent>
			</Card>

	render() {
		const {success ,errorMsg } = this.props;
		const { values ,validationError } =this.state
		return 	<div>
				{
					success && (
						<Card>
							<CardContent>
							<Typography gutterBottom variant="h5">
								Password set succesfully!<br/>Please try to <a href="/login">login</a> with your username and password
							</Typography>
						</CardContent>
						</Card>
					)
				}
				{
					errorMsg && (
						<this.ShowError errorMsg={ errorMsg } />
					)
				}
				{
					(!success && !errorMsg) && (
						<React.Fragment>
						<form style={styles.form}>
						<Card style={styles.card}>
							<CardContent>
								<Typography variant="h5" gutterBottom>
									Set Password
								</Typography>								
								<TextField
									autoFocus
									id="password"
									label="Password"
									type="password"
									value={values.password}
									style={styles.textField}
									onChange={this.onInputChange}
								/>
								<TextField
									id="confirmPassword"
									label="Confirm Password"
									type="password"
									value={values.confirmPassword}
									style={styles.textField}
									onChange={this.onInputChange}
									onKeyPress={this.SubmitForm}
								/>
								{
									validationError && (
										<div style={helperTextStyle}>{validationError}</div>
									)
								}								
							</CardContent>
							<CardActions>
								<Tooltip
									title="Please fill in the required fields"
									disableTouchListener={true}
									disableFocusListener={true}
									disableHoverListener={validateNoEmptyFields([values.password, values.confirmPassword])}>
									<div>
              <Button
                onClick={this.SubmitForm}
                style={styles.button} variant="contained" color='primary'
								disabled={validationError !== '' || !validateNoEmptyFields([values.password, values.confirmPassword])}>
                Submit
              </Button>
									</div>
								</Tooltip>
							</CardActions>
						</Card>
						</form>
					</React.Fragment>
					)
				}
				</div>								
							
		
	}
}

SetPasswordForm.propTypes = {
	setPassword: PropTypes.func
}

const mapStateToProps = (state) => {
	return {
		token: state.auth.token,
		success: state.setPassword.success,
		errorMsg:state.setPassword.errorMsg
	}
}

const mapDispatchToProps = {
	setPassword: setUserPassword,
	setPasswordErrorMessage: setPasswordErrorMessage
}


export default connect(
	mapStateToProps, mapDispatchToProps
)(SetPasswordForm)

SetPasswordForm.defaultProps = {
  reset: false
};
