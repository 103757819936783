import React from 'react'

import { Typography } from '@material-ui/core'

const Header = () => {
  return (
    <Typography gutterBottom variant="h5">
      Approve Virtual Card Orders
    </Typography>
  )
}

export default Header
