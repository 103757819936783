import { CHARGEBACK_REPORT } from '../action/chargebackReport'
const initialState = {
	chargebackReport: {}
}

export default (state = initialState, action) => {
	const { type, payload } = action
	switch (type) {
		case `${CHARGEBACK_REPORT}_FULFILLED`:
			return ({
				...state,
				chargebackReport: payload.chargebackReports
			})
		default:
			return state
	}
}
