import React, { Component } from 'react'
import { ListItem, ListItemText } from '@material-ui/core'

class Single extends Component {
  render() {
    const { route, index, navigate } = this.props
    const classes = [
      `menuitem-pad-${route.depth}`,
      route.inCurrentPath ? 'menuitem-active' : 'menuitem'
    ]
    return (
      <ListItem
        className={classes.join(' ')}
        button
        key={route.depth * 10 + index}
        onClick={() => navigate(route.path)}
      >
        <ListItemText primary={route.displayName} disableTypography={false}/>
      </ListItem>
    )
  }
}

export default Single
