import React, { Component } from 'react'
import Button from '@material-ui/core/Button'
import GetAppIcon from '@material-ui/icons/GetApp';
import CardActions from '@material-ui/core/CardActions';

class BatchSampleDownload extends Component {

  render() {
    const styles = {
        alignright: {
          marginLeft: 'auto'
        }
      }
    return (
        <CardActions>
            <Button style={styles.alignright} color="primary" size="small" href={this.props.batchSample} >Sample Format <GetAppIcon/> </Button>
        </CardActions>   
    )
  }
}

export default BatchSampleDownload;
