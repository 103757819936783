import React,{Component} from 'react'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableRow from '@material-ui/core/TableRow'
import TableFooter from '@material-ui/core/TableFooter'
import TableCell from '@material-ui/core/TableCell'
import TablePagination from '@material-ui/core/TablePagination';
import EnhancedTableHead from '../../default/EnhancedTableHead'
import {stableSort} from "../../../util/numberUtil";
import {withStyles, withTheme} from "@material-ui/core/styles/index";
import Icon from '@material-ui/core/Icon'
import IconButton from '@material-ui/core/IconButton'
import {FEE_TRANSACTION} from "../../../util/transaction";
import Tooltip from '@material-ui/core/Tooltip'
import Typography from "@material-ui/core/Typography"

const colWidth = {
  width: '1rem'
}

class TransactionTable extends  Component{

  state = {
    page: 0,
    rowsPerPage: 10,
    order: 'desc',
    orderBy: 'SHOWDATE'
  }

  handleChangePage = (event, page) => {
    this.setState({ ...this.state, page });
  }

  handleChangeRowsPerPage = event => {
    this.setState({ ...this.state, rowsPerPage: event.target.value });
  }

  getSortColumn(property, columns){
    const col = columns.find( column => column.VALUE === property )
    return col && col.RAWVALUE ? col.RAWVALUE : property
  }

  handleRequestSort = (event, property) => {
    let order = 'desc';
    if (this.state.orderBy === property && this.state.order === 'desc') {
      order = 'asc';
    }

    this.setState({ order: order, orderBy: property, page : 0 });
  }

  desc = (a, b, orderBy) => {
    if (b[orderBy] < a[orderBy]) {
      return -1;
    }
    if (b[orderBy] > a[orderBy]) {
      return 1;
    }
    return 0;
  }

  getSorting = (order, orderBy) => {
    return order === 'desc' ? (a, b) => this.desc(a, b, orderBy) : (a, b) => - this.desc(a, b, orderBy);
  }

  render(){

    const { transactions, columns } = this.props
    const { rowsPerPage, page, order, orderBy } = this.state;
    const totalPages = Math.ceil(this.props.transactions.length / rowsPerPage )
    const currentPage = totalPages > 0 ? (this.state.page + 1) : 0

    const allColumns = [ ...columns,
      { VALUE: 'ACTION', NUMERIC: 'false', LABEL: '' }
    ];

    const sortColumn = this.getSortColumn(orderBy,columns)

    return (
      <Table>
        <EnhancedTableHead
          order={order}
          orderBy={orderBy}
          onRequestSort={this.handleRequestSort}
          columns={allColumns} />

        <TableBody>
          {
            transactions &&
            stableSort(transactions, this.getSorting(order,sortColumn)).slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map( (transaction, key)=> {
              const hint = transaction.REVERSIBLE !== 1 ? 'The ' + transaction.TRANSACTIONTYPELABEL.toLocaleLowerCase() +' was reversed' : 'Reverse '+ transaction.TRANSACTIONTYPELABEL
              return <TableRow key={key}>
                  <TableCell>{transaction.SHOWDATE}</TableCell>
                  <TableCell>{transaction.TRANSACTIONTYPELABEL}</TableCell>
                  <TableCell>{transaction.TRANSACTIONDESCRIPTION}</TableCell>
                  <TableCell>{transaction.AUTHNUMBER}</TableCell>
                  <TableCell>{transaction.MERCHANTID}</TableCell>
                  <TableCell numeric={true}>{transaction.MONEYAMOUNT}</TableCell>
                  <TableCell style={colWidth}>
                    { (transaction.TRANSACTIONTYPE === FEE_TRANSACTION) ?
                        <Tooltip title={hint}>
                          <span data-tip="" data-tip-disable={false}>
                            <IconButton component="div" disabled={transaction.REVERSIBLE !== 1} onClick={() => {
                              this.props.reverse(transaction)
                              }} aria-label="Revert">
                                <Icon>undo</Icon>
                            </IconButton>
                          </span>
                        </Tooltip>
                      :
                        null
                    }
                  </TableCell>
                  <TableCell style={colWidth}>
                    { (transaction.HASCHARGEBACK === 1) ?
                      <IconButton component="div" onClick={() => {
                          this.props.showChargebackInfo(transaction.TRANSACTIONID)
                        }}>
                         <Icon>info</Icon>
                        <Typography variant="caption">Chargeback</Typography>
                      </IconButton>
                      :
                        null
                    }
                  </TableCell>
                </TableRow>
              }
            )
          }
        </TableBody>

        <TableFooter>
          <TableRow>
            <TablePagination
              colSpan={7}
              count={transactions.length}
              rowsPerPage={this.state.rowsPerPage}
              page={this.state.page }
              labelDisplayedRows={() =>  currentPage + ' of ' + totalPages }
              onChangePage={this.handleChangePage}
              onChangeRowsPerPage={this.handleChangeRowsPerPage}
            />
          </TableRow>
        </TableFooter>
      </Table>
    )
  }
}
const styles = theme => ({
  button: {
    color: 'white',
    marginRight: '20px'
  },
  search: {
    marginLeft: '100px'
  }
})


export default withStyles(styles)(withTheme(TransactionTable))
