import React, {Component, Fragment} from 'react'
import {reverseTransaction} from '../../../action/transaction'
import PropTypes from 'prop-types'
import {connect} from 'react-redux'
import TextField from '@material-ui/core/TextField'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogTitle from '@material-ui/core/DialogTitle'
import Button from '@material-ui/core/Button'
import {validateNoEmptyFields} from '../../../util/form'
import {red} from '@material-ui/core/colors/index'

const initialState = {
  requesterName: '',
  storeName: '',
  contactPhone: '',
  declineReason: '',
  comments: ''
}

const helperTextStyle = {
  color: red[500]
}

class ReverseTransactionForm extends Component
{
  state = initialState

  componentDidUpdate = (prevProps) =>{
    if ((((typeof prevProps.success === 'undefined') ) || (prevProps.success !== this.props.success )) && (this.props.success)){
      this.setState(initialState)
      this.props.close(true)
    }
  }

  onInputChange = (event) => {
    const stateKey = event.target.getAttribute('name')
    this.setState({ [stateKey]: event.target.value })
  }

  render() {
    const { transaction, open, cardNumber, submitting } = this.props
    const { requesterName, declineReason } = this.state

    return <Fragment>
      {
        transaction &&
        <Dialog
          open={open}
          onClose={() => {this.props.close(false)}}
          aria-labelledby='alert-dialog-title'
          aria-describedby='alert-dialog-description'
        >
          <DialogTitle className='card-header'  id='alert-dialog-title'><span className='card-header-text'>{'Reverse ' + transaction.TRANSACTIONTYPELABEL}</span></DialogTitle>
          <DialogContent>

            <div style={helperTextStyle}>{this.props.errorMsg}</div>

            <TextField
              name='requesterName'
              label='Name of Requester'
              inputProps={{
                maxLength: 255
              }}
              fullWidth={true}
              onChange={this.onInputChange}
              value={requesterName}
              required
            />
            <TextField
              name='declineReason'
              label='Reason?'
              inputProps={{
                maxLength: 255
              }}
              fullWidth={true}
              onChange={this.onInputChange}
              value={declineReason}
              required
            />

          </DialogContent>
          <DialogActions>
            <Button onClick={ () => this.props.reverseTransaction(cardNumber, transaction.TRANSACTIONTYPE, transaction.TRANSACTIONID, this.state)} color='primary'
                disabled={!validateNoEmptyFields([requesterName, declineReason]) || submitting} autoFocus>
              {'Reverse'}
            </Button>
            <Button onClick={() => {this.props.close(false)}} color='primary' autoFocus>
              {'Cancel'}
            </Button>
          </DialogActions>

        </Dialog>
      }
      </Fragment>
  }
}
ReverseTransactionForm.propTypes = {
  close: PropTypes.func,
  transactionType: PropTypes.string,
  open: PropTypes.bool
}

const mapStateToProps = (state) => {
  return {
    submitting: !!(state.api.REVERSE_TRANSACTION && state.api.REVERSE_TRANSACTION > 0),
    success: state.reverseTransaction.success,
    errorMsg: state.reverseTransaction.errorMsg
  }
}

const mapDispatchToProps = {
  reverseTransaction: reverseTransaction
}


export default connect(mapStateToProps, mapDispatchToProps)(ReverseTransactionForm)
