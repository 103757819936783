import React,{Component} from 'react'
import {connect} from "react-redux"
import {getUserCampaigns} from "../../../action/userCampaigns"
import { Select, MenuItem, FormControl } from '@material-ui/core'
import PropTypes from "prop-types"


class UserCampaigns extends Component{

  state = {
    selectedCampaign : -1
  }

  componentDidMount = () => {
    this.props.getCampaigns()
  }

  onSelectionChangeHandler = (value) =>{
    this.setState({
      selectedCampaign : value
    })
    this.props.campaignSelected(value)
  }

  render(){
    let campaigns = ''

    const noSelectionMessage = (this.props.noSelectionMessage) ? this.props.noSelectionMessage : 'Select a campaign'
    const showAllCampaigns = this.props.displayAllCampaigns
    let allCampaignsOption = ''

    if (this.props.campaigns.length > 0){
      campaigns = this.props.campaigns.map( (element) => {
        return (<MenuItem key={element.CampaignID} value={element.CampaignID}>{element.CampaignName}</MenuItem>)
      })
    }

    if(showAllCampaigns) {
      allCampaignsOption = <MenuItem value={0}>All Campaigns</MenuItem>
    }

    return (<FormControl>
      <Select 
        value={this.state.selectedCampaign} 
        className="select"
        name="campaign"
        onChange={
          (event) => {this.onSelectionChangeHandler(event.target.value) }
        }
      >
        <MenuItem value={-1}>{noSelectionMessage}</MenuItem>
        {allCampaignsOption}
        
        {campaigns}
      </Select>
    </FormControl>)

  }

}

UserCampaigns.propTypes = {
  campaigns : PropTypes.array,
  campaignSelected : PropTypes.func
}


const mapStateToProps = (state) => {
  return {
    campaigns : state.userCampaigns.campaigns,
    submitting: !!(state.api.USER_CAMPAIGNS && state.api.USER_CAMPAIGNS > 0)

  }
}

const mapDispatchToProps = {
  getCampaigns: getUserCampaigns
}


export default connect(	mapStateToProps, mapDispatchToProps)(UserCampaigns)
