import React, { Component } from 'react'
import Table from '@material-ui/core/Table'
import TableHead from '@material-ui/core/TableHead'
import TableBody from '@material-ui/core/TableBody'
import TableRow from '@material-ui/core/TableRow'
import TableCell from '@material-ui/core/TableCell'
import { CardSearchResultsRow } from './CardSearchResultsRow'
import { withStyles } from '@material-ui/core/styles'
import TableFooter from '@material-ui/core/TableFooter'
import TablePagination from '@material-ui/core/TablePagination';

class CardSearchResults extends Component {
  state = {
    page: 0,
    rowsPerPage: 10
  };

  handleChangePage = (event, page) => {
    this.setState({ ...this.state, page });
  };

  handleChangeRowsPerPage = event => {
    this.setState({ ...this.state, rowsPerPage: event.target.value });
  };
  render() {
    const rowsPerPage = this.state.rowsPerPage;
    const totalPages = Math.ceil(this.props.searchResults.length / rowsPerPage );
    const currentPage = totalPages > 0 ? (this.state.page + 1) : 0;   
    const tableRows = this.props.searchResults.map((row,i) =>
    <CardSearchResultsRow key={i} searchResult={row} />
    ).slice(this.state.page * rowsPerPage, this.state.page  * rowsPerPage + rowsPerPage);
    const { classes } = this.props
    return (
      <div className={classes.tableWrapper}>
        <Table className={classes.table} >
          <TableHead>
            <TableRow>
              <TableCell />
              <TableCell>Campaign Name</TableCell>
              <TableCell>Customer Ref</TableCell>
              <TableCell>Card Number</TableCell>
              <TableCell>Tracking Number</TableCell>
              <TableCell>Booking ID</TableCell>
              <TableCell>Card Expiry</TableCell>
              <TableCell>Card CVV</TableCell>
              <TableCell>Card Balance</TableCell>
              <TableCell>Funds Available</TableCell>
              <TableCell>Loaded Amount</TableCell>
              <TableCell>Authorized Amount</TableCell>
              <TableCell>Settled Amount</TableCell>
              <TableCell>Issue Date</TableCell>
              <TableCell>Status</TableCell>
            </TableRow>
          </TableHead>
          <TableBody className='card-search-results'>{tableRows}</TableBody>
          <TableFooter>
            <TableRow>
              <TablePagination
                  colSpan={13}
                  count={this.props.searchResults.length}
                  rowsPerPage={this.state.rowsPerPage}
                  page={this.state.page}
                  labelDisplayedRows={() => currentPage + ' of ' + totalPages}
                  onChangePage={this.handleChangePage}
                  onChangeRowsPerPage={this.handleChangeRowsPerPage}
              />
            </TableRow>
          </TableFooter>
        </Table>
      </div>
    )
  }
}

const styles = theme => ({
  root: {
    width: '100%',
    marginTop: theme.spacing(3),
  },
  table: {
    minWidth: 1020,
  },
  tableWrapper: {
    overflowX: 'auto',
  },
})


export default withStyles(styles)(CardSearchResults)
