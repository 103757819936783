import React, { Component } from 'react'
import { connect } from 'react-redux'
import LinearProgress from '@material-ui/core/LinearProgress'
import { store } from '../../../store'
import { Redirect } from 'react-router'
import { withRouter } from 'react-router-dom'
import { apiReset } from '../../../action/api'

const initialState = {
  redirect: false
}

export class ProgressBar extends Component {

  constructor(props) {
    super(props)

    this.state = initialState
  }

  componentWillReceiveProps(nextProps){
    const redirectLocation = nextProps.redirect || ''
    const currentPath = this.props.location.pathname
    if(redirectLocation.length > 0 && currentPath !== redirectLocation) {
      this.setState({redirect: true}, this.resetApi)
    }
  }

  resetApi() {
    this.setState({redirect: false}, function(){store.dispatch(apiReset())})
  }

  render() {
    const redirectLocation = store.getState().api.redirect || ''
    const currentPath = this.props.location.pathname
    let redirect = false

    if(this.state.redirect && redirectLocation.length > 0 && currentPath !== redirectLocation) {
      redirect = true
    }
    return redirect ? <Redirect to={redirectLocation}/> :
        this.props.pending ? 
            <LinearProgress
              mode="indeterminate"
              style={{ height: '10px', marginTop: '1px' }}
              color="primary"
            /> :
            <div style={{height:'10px', marginTop: '1px'}}></div>
  }
}

const mapStateToProps = (state, props) => {
  return {
    pending: state.api.pending,
    redirect: state.api.redirect
  }
}

export default withRouter(connect(mapStateToProps)(ProgressBar))
