import React, { Component } from 'react'
import { connect } from 'react-redux'
import Paper from '@material-ui/core/Paper'
import TextField from '@material-ui/core/TextField'
import Button from '@material-ui/core/Button'
import { red } from '@material-ui/core/colors'
import {enrollUser, MFA_ENROLMENT_COMPLETE} from '../../action/auth'
import {withStyles, withTheme} from "@material-ui/core/styles";
import QRCode from 'qrcode.react'
import Typography from "@material-ui/core/Typography";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";

const styles = theme => ({
  button: {
    margin: theme.spacing(),
  },
});

class EnrollUser extends Component {

  componentDidMount() {
    this.props.fetchEnrollment(this.props.username,this.props.password)
  }

  onKeyPress = (event) => {
    if (event.key === 'Enter') {
      this.props.confirm()
    }
  }

  render() {
    const paperStyle = {
      width: '50%',
      position: 'absolute',
      top: '45%',
      left: '50%',
      padding: 20,
      marginLeft: '-120px',
      textAlign: 'center',
      display: 'flex',
      flexDirection: 'column',
      minHeight:'200px',
      justifyContent:'space-between',
      transform: 'translateY(-50%) translateX(-50%)',
      verticalAlign: 'middle',
    }
    const helperTextStyle = {
      color: red[500]
    }

    const { errorMsg, classes, onInputChange, mfaStatus } = this.props

    const qrCode = this.props.secret ? <QRCode value={this.props.secret}/> : null

    return (
      <Paper style={paperStyle} zdepth={4}>
        <Typography gutterBottom variant="h5">
          Two-Factor Authentication Required <br/>
        </Typography>
        <Typography gutterBottom variant="subtitle1" align="left">
          Two-Factor Authentication (2FA) is required to access your account. <br/><br/>

          With 2FA, Client Portal will request a One Time Password (also called verification code) on each login. <br/>
          This password is a TOTP (Time-based One-time Password): a unique code that is valid for a short time and is generated using a software application of your choice.
          Some popular examples are: <b>Google Authenticator</b> available for <a href="https://play.google.com/store/apps/details?id=com.google.android.apps.authenticator2&hl=en">Android</a>
          &nbsp; and &nbsp;<a href="https://apps.apple.com/us/app/google-authenticator/id388497605">iOS</a> or&nbsp;
          <b><a href="https://1password.com/">1Password</a></b>. <br/><br/>

          Please set up your preferred application scanning the <b>QR Code</b> and
          enter <b>a code from your application</b> below, then press <b>Accept</b> to complete the enrolment process.
        </Typography>
        <div style={helperTextStyle}>{errorMsg}</div>
        <br/><br/>
        <div align="center">
          {qrCode}
          <br/>
          <br/>
          <TextField
            name="challenge"
            label="Verification Code"
            inputProps={{
              maxLength: 6
            }}
            fullWidth={false}
            onChange={onInputChange}
            onKeyPress={this.onKeyPress}
            autoComplete="false"
            style={{borderBottom:'0.1px solid'}}
          />
        </div>
        <div style={{marginTop: 20}}>
          <Button className={classes.button} size="large" variant="contained"
                  onClick={this.props.reset}>Cancel</Button>
          <Button className={classes.button} size="large" variant="contained" color="primary"
                  onClick={this.props.confirm}>Accept</Button>
        </div>

        <Dialog
          open={mfaStatus === MFA_ENROLMENT_COMPLETE }
          aria-labelledby="success-dialog-title"
          aria-describedby="success-dialog-description"
        >
          <DialogTitle className="card-header" id="success-dialog-title">Enrolment Successful</DialogTitle>
          <DialogContent>
            <br/>
            Two-Factor Authentication activated successfully
            <br/>
            <br/>
          </DialogContent>
          <DialogActions>
            <Button onClick={this.props.reset} variant="contained" color="primary" autoFocus>OK</Button>
          </DialogActions>
        </Dialog>

      </Paper>
    )
  }
}

const mapStateToProps = (state, props) => {
  return {
    errorMsg: state.auth.errorMsg,
    secret: state.auth.mfaSecret,
    mfaStatus: state.auth.mfaStatus
  }
}

const mapDispatchToProps = {
  fetchEnrollment: enrollUser
}


export default connect(mapStateToProps,mapDispatchToProps)(withStyles(styles)(withTheme(EnrollUser)))
