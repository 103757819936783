import React, { Component } from 'react'
import Wrapper from '../../component/default/layout/Wrapper'
import SignUpForm from '../../component/default/merchantSignUp/SignUpForm'
import DownloadForm from '../../component/default/merchantSignUp/DownloadForm'

class MerchantSignUp extends Component {
  render() {
    return (
      <div>
        <Wrapper>
          <SignUpForm />
        </Wrapper>
        <Wrapper>
          <DownloadForm />
        </Wrapper>
      </div>
    )
  }
}


export default MerchantSignUp
