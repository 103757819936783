const theme = process.env.REACT_APP_THEME || 'default'
const apiURL = process.env.REACT_APP_API_URL || 'http://localhost:8500/api/clientportal/json.cfm'

const themeConfig = require(`./${theme}`).default

export default {
  apiURL,
  maxMerchantSignUp: 10,
  ...themeConfig
}
