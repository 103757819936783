import {
  BATCH_LOADS,
  BATCH_LOADS_ERROR_MESSAGE,
  BATCH_LOAD_REJECT,
  BATCH_LOAD_CLOSE,
  BATCH_LOAD_UPDATE_CLOSED_COMMENTS,
  BATCH_LOAD_APPROVE,
  BATCH_LOAD_RETRY,
  BATCH_LOAD_DETAILS,
  DOWNLOAD_BATCH_LOAD_REPORT,
  DOWNLOAD_BATCH_LOAD_FILE,
  BATCH_LOAD_RESET_ERROR
} from '../action/batchLoads'

const initialState = {
  errorMsg : '',
  batchLoadsResults : []
}

export default (state = initialState, action) => {
  const { type, payload, meta } = action
  switch(type) {
    case BATCH_LOADS_ERROR_MESSAGE:
      return {
        ...state,
        errorMsg: payload.resultText || 'An error occurred'
      }
    case `${BATCH_LOADS}_FULFILLED`:
      if (payload.resultCode === "0") {
        return {
          ...state,
          batchLoadsResults: JSON.parse(payload.resultText)
        }
      } else {
        return {
          ...state,
          errorMsg: payload.resultText,
          errorCode: payload.resultCode
        }
      }
    case `${BATCH_LOADS}_PENDING`:
      return {
        ...state,
        errorMsg: ''
      }
    case `${BATCH_LOADS}_REJECTED`:
      return {
        ...state,
        errorMsg: 'Cannot connect to server'
      }
    case `${BATCH_LOAD_REJECT}_FULFILLED`:
      if (payload.resultCode === "0") {
        return {
          ...state,
          batchLoadRejectResult: JSON.parse(payload.resultText)
        }
      } else {
        return {
          ...state,
          errorMsg: payload.resultText
        }
      }
    case `${BATCH_LOAD_REJECT}_PENDING`:
      return {
        ...state,
        errorMsg: ''
      }
    case `${BATCH_LOAD_REJECT}_REJECTED`:
      return {
        ...state,
        errorMsg: 'Cannot connect to server'
      }
    case `${BATCH_LOAD_CLOSE}_FULFILLED`:
      if (payload.resultCode === "0") {
        return {
          ...state,
          batchLoadCloseResult: payload.resultText
        }
      } else {
        return {
          ...state,
          errorMsg: payload.resultText
        }
      }
    case `${BATCH_LOAD_CLOSE}_PENDING`:
      return {
        ...state,
        errorMsg: ''
      }
    case `${BATCH_LOAD_CLOSE}_REJECTED`:
      return {
        ...state,
        errorMsg: 'Cannot connect to server'
      }
    case `${BATCH_LOAD_UPDATE_CLOSED_COMMENTS}_FULFILLED`:
      if (payload.resultCode === "0") {
        return {
          ...state
        }
      } else {
        return {
          ...state,
          errorMsg: payload.resultText
        }
      }
    case `${BATCH_LOAD_UPDATE_CLOSED_COMMENTS}_PENDING`:
      return {
        ...state,
        errorMsg: ''
      }
    case `${BATCH_LOAD_UPDATE_CLOSED_COMMENTS}_REJECTED`:
      return {
        ...state,
        errorMsg: 'Cannot connect to server'
      }

    case `${BATCH_LOAD_APPROVE}_FULFILLED`:
      if (payload.resultCode === "0") {
        return {
          ...state,
          batchLoadApproveResult: JSON.parse(payload.resultText)
        }
      } else {
        return {
          ...state,
          errorMsg: payload.resultText
        }
      }
    case `${BATCH_LOAD_APPROVE}_PENDING`:
      return {
        ...state,
        errorMsg: ''
      }
    case `${BATCH_LOAD_APPROVE}_REJECTED`:
      return {
        ...state,
        errorMsg: 'Cannot connect to server'
      }
    case `${BATCH_LOAD_RETRY}_FULFILLED`:
      if (payload.resultCode === "0") {
        return {
          ...state,
          batchLoadRetryResult: JSON.parse(payload.resultText)
        }
      } else {
        return {
          ...state,
          errorMsg: payload.resultText
        }
      }
    case `${BATCH_LOAD_RETRY}_PENDING`:
      return {
        ...state,
        errorMsg: ''
      }
    case `${BATCH_LOAD_RETRY}_REJECTED`:
      return {
        ...state,
        errorMsg: 'Cannot connect to server'
      }
    case `${BATCH_LOAD_DETAILS}_FULFILLED`:
      if (payload.resultCode === "0") {
        return {
          ...state,
          batchLoadDetailsResult: JSON.parse(payload.resultText),
          batchLoadDetailsMetadata: meta
        }
      } else {
        return {
          ...state,
          errorMsg: payload.resultText
        }
      }
    case `${BATCH_LOAD_DETAILS}_PENDING`:
      return {
        ...state,
        errorMsg: ''
      }
    case `${BATCH_LOAD_DETAILS}_REJECTED`:
      return {
        ...state,
        errorMsg: 'Cannot connect to server'
      }
    case `${BATCH_LOAD_DETAILS}_RESET`:
      return {
          ...state,
          batchLoadDetailsResult: [],
          batchLoadDetailsMetadata: {}
        }
    case `${DOWNLOAD_BATCH_LOAD_REPORT}_FULFILLED`:
      if (payload.resultCode === "0") {
        return {
          ...state,
          downloadBatchLoadReportResult: payload.resultExcel
        }
      } else {
        return {
          ...state,
          errorMsg: payload.resultText
        }
      }
    case `${DOWNLOAD_BATCH_LOAD_REPORT}_PENDING`:
      return {
        ...state,
        errorMsg: ''
      }
    case `${DOWNLOAD_BATCH_LOAD_REPORT}_REJECTED`:
      return {
        ...state,
        errorMsg: 'Cannot connect to server'
      }
    case `${DOWNLOAD_BATCH_LOAD_REPORT}_RESET`:
      return {
          ...state,
          downloadBatchLoadReportResult: null
        }
    case `${DOWNLOAD_BATCH_LOAD_FILE}_FULFILLED`:
      if (payload.resultCode === "0") {
        return {
          ...state,
          downloadBatchLoadFileResult: JSON.parse(payload.resultText).DATA
        }
      } else {
        return {
          ...state,
          errorMsg: payload.resultText
        }
      }
    case `${DOWNLOAD_BATCH_LOAD_FILE}_PENDING`:
      return {
        ...state,
        errorMsg: ''
      }
    case `${DOWNLOAD_BATCH_LOAD_FILE}_REJECTED`:
      return {
        ...state,
        errorMsg: 'Cannot connect to server'
      }
    case `${DOWNLOAD_BATCH_LOAD_FILE}_RESET`:
      return {
          ...state,
          downloadBatchLoadFileResult: null
        }
    case `${BATCH_LOADS}_RESET`:
      return {
        ...initialState
      }
    case `${BATCH_LOAD_RESET_ERROR}`:
      return {
        ...state,
        errorMsg: ''
      }
    default:
      return state
  }
}
