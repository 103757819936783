import React, {Component} from 'react'
import {connect} from 'react-redux'
import PropTypes from 'prop-types'
import Card from '@material-ui/core/Card'
import CardContent from '@material-ui/core/CardContent'
import TextField from '@material-ui/core/TextField'
import Button from '@material-ui/core/Button'
import Icon from '@material-ui/core/Icon'
import {userDelete, userSearch, userUnlock, resetSuccess, resetError, resendActivation, resetOTP} from '../../../action/userManagement'
import UserSearchResults from './UserSearchResults'
import {getUserId} from '../../../util/user'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogContentText from '@material-ui/core/DialogContentText'
import DialogTitle from '@material-ui/core/DialogTitle'
import Typography from '@material-ui/core/Typography'
import Notification from "../../default/layout/Notification"

class UserSearchForm extends Component {

	state = {
		searchText: '',
		deleteConfirmationOpen: false,
		unlockConfirmationOpen: false,
		resetOTPConfirmationOpen: false,
		userToDeleteId: null,
		userToUnlockId: null,
		userToResetOTP: {}
	}

	handleInputChange = (event) => {
		this.setState({
			...this.state,
			searchText: event.target.value
		})
	}

	handleConfirmationClose = (confirmed) => {
		const userToDeleteId = this.state.userToDeleteId
		this.setState({
			...this.state,
			deleteConfirmationOpen: false,
			userToDeleteId: null
		})

		if ((confirmed) && (userToDeleteId)) {
			this.props.delete(userToDeleteId)
		}
	}

	deleteUser = (id) => {
		this.setState({
			...this.state,
			deleteConfirmationOpen: true,
			userToDeleteId: id
		})
	}

	handleConfirmationCloseUnlock = (confirmed) => {
		const userToUnlockId = this.state.userToUnlockId
		this.setState({
			...this.state,
			unlockConfirmationOpen: false,
			userToUnlockId: null
		})

		if ((confirmed) && (userToUnlockId)) {
			this.props.unlock(userToUnlockId)
		}
	}

	unlockUser = (id) => {
		this.setState({
			...this.state,
			unlockConfirmationOpen: true,
			userToUnlockId: id
		})
	}

	resendActivation = (id) => {
		this.props.resendActivation(id)
	}

	resetOTP = (seletedUser) => {
		this.setState({
			...this.state,
			resetOTPConfirmationOpen: true,
			userToResetOTP: seletedUser
		})
	}

	handleConfirmationCloseResetOTP = (confirmed) => {
		const { userID } = this.state.userToResetOTP
		this.setState({
			...this.state,
			resetOTPConfirmationOpen: false,
			userToResetOTP: {}
		})

		confirmed && userID && this.props.resetOTP({reset2FAUserID: userID})
	}

	reloadAfterAction(){
		this.props.resetSuccess()
		this.props.search(this.state.searchText)
	}

	render() {
		const {submitting, errorMsg, successMessage} = this.props
		const responseCard = this.props.searchResults ? (
			<Card>
				<UserSearchResults currentUserId={getUserId(this.props.token)}
								   delete={this.deleteUser}
								   unlock={this.unlockUser}
								   resendActivation={this.resendActivation}
								   searchResults={this.props.searchResults}
								   resetOTP={this.resetOTP}/>
			</Card>
		) : null

		return (
			<React.Fragment>
				<Card style={{padding:'40px'}}>
					<Typography variant="h5">
	                    Find User
	                </Typography>
					<CardContent>
						<TextField
							className="input-search"
							name="search"
							placeholder="User name"
							value={this.state.searchText}
							onChange={this.handleInputChange}
						/>
						<Button
							className="flattbutton-search" color="primary" size="large" variant="contained"
							onClick={() => this.props.search(this.state.searchText)}
							disabled={submitting}
						>
							<Icon>search</Icon>
						</Button>
					</CardContent>
				</Card>
				{responseCard}

				{
					(successMessage !== '') &&
						<Notification type={'success'} message={successMessage} open={true} closed={() => this.reloadAfterAction()}/>
				}
				{
					(errorMsg !== '') &&
						<Notification type={'error'} message={errorMsg} open={true} closed={() => this.props.resetError()}/>
				}

				<Dialog open={this.state.deleteConfirmationOpen} onClose={() => this.handleConfirmationClose(false)}>
					<DialogTitle>
						{"Confirm Deletion"}
					</DialogTitle>
					<DialogContent>
						<DialogContentText>
							Are you sure you want to delete the user ?
						</DialogContentText>
					</DialogContent>
					<DialogActions>
						<Button onClick={() => this.handleConfirmationClose(true)}>
							Yes
						</Button>
						<Button onClick={() => this.handleConfirmationClose(false)}>
							No
						</Button>
					</DialogActions>
				</Dialog>

				<Dialog open={this.state.unlockConfirmationOpen} onClose={() => this.handleConfirmationCloseUnlock(false)}>
					<DialogTitle>
						{"Confirm Unlock"}
					</DialogTitle>
					<DialogContent>
						<DialogContentText>
							Are you sure you want to unlock the user ?
						</DialogContentText>
					</DialogContent>
					<DialogActions>
						<Button onClick={() => this.handleConfirmationCloseUnlock(true)}>
							Yes
						</Button>
						<Button onClick={() => this.handleConfirmationCloseUnlock(false)}>
							No
						</Button>
					</DialogActions>
				</Dialog>

				<Dialog open={this.state.resetOTPConfirmationOpen} onClose={() => this.handleConfirmationCloseResetOTP(false)}>
					<DialogTitle>
						{"Confirm Reset OTP"}
					</DialogTitle>
					<DialogContent>
						<DialogContentText>
							Are you sure you want to reset the OTP for <b>{this.state.userToResetOTP.userName}</b>?
						</DialogContentText>
					</DialogContent>
					<DialogActions>
						<Button onClick={() => this.handleConfirmationCloseResetOTP(true)}>
							Yes
						</Button>
						<Button onClick={() => this.handleConfirmationCloseResetOTP(false)}>
							No
						</Button>
					</DialogActions>
				</Dialog>
			</React.Fragment>
		)
	}
}

UserSearchForm.propTypes = {
	userSearch: PropTypes.func
}

const mapStateToProps = (state) => {
	return {
		token: state.auth.token,
		submitting: !!(state.api.USER_SEARCH && state.api.USER_SEARCH > 0),
		errorMsg: state.userSearch.errorMsg,
		successMessage: state.userSearch.successMessage,
		searchResults: state.userSearch.searchResults
	}
}

const mapDispatchToProps = {
	search: userSearch,
	delete: userDelete,
	unlock: userUnlock,
	resendActivation: resendActivation,
	resetSuccess: resetSuccess,
	resetError: resetError,
	resetOTP: resetOTP
}


export default connect(
	mapStateToProps, mapDispatchToProps
)(UserSearchForm)
