import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { push } from 'connected-react-router'
import { connect } from 'react-redux'
import { withStyles, List } from '@material-ui/core'
import DefaultDrawer from './DefaultDrawer'
import NavItems from './NavItems'

const styles = theme => ({
  root: {
    maxWidth: 360,
    minWidth: 240
  }
})

class SideBar extends Component {
  navigate = path => {
    const { push } = this.props
    push(path)
  }

  navigationItems = () => {
    const routeUrls = [{displayName: 'Merchant Sign Up', sortOrder: 1, path: '/merchant/signup', depth: 0}]
    const navTree = []
    routeUrls.forEach(url => {
      navTree.push(url)
    })
    return <NavItems items={navTree} navigate={this.navigate} />
  }

  render() {
   const isLoggedIn = (typeof this.props.token !== 'undefined' && this.props.token !== null)

    return (
      <DefaultDrawer {...this.props} navigate={this.navigate}>
          {isLoggedIn && <List component="nav">{this.navigationItems()}</List>}
      </DefaultDrawer>
    )
  }
}

const ThemedSideBar = withStyles(styles)(SideBar)

const mapStateToProps = (state, props) => {
  return {
    token: state.auth.token
  }
}

SideBar.propTypes = {
  showSideBar: PropTypes.bool,
  toggleSideBar: PropTypes.func
}

export default connect(
  mapStateToProps,
  { push }
)(ThemedSideBar)
