import React, { Component } from 'react'
import Wrapper from '../../component/default/layout/Wrapper'
import ReportGridList from '../../component/agoda/reports/ReportGridList'

class Reports extends Component {
  render() {
    return (
      <div>
        <Wrapper>
          <ReportGridList />
        </Wrapper>
      </div>
    )
  }
}

export default Reports
