import React from 'react'
import PrivateRoute from '../component/default/route/PrivateRoute'
import Home from '../container/default/Home'
import MerchantSignUp from '../container/default/MerchantSignUp'
import BaseRoute from './BaseRoute'
import App from '../container/default/App'

export default (  
  <BaseRoute App={App} Home={Home}>
    <PrivateRoute exact path="/merchant/signup" component={MerchantSignUp} permissions={["MerchantSignUp.read"]}/>
  </BaseRoute>
)
