export default (merchantList) => {
  let csvContent = "MerchantNumber,MerchantName,City,CategoryCode,Currency,Country,QRCode\r\n"
  for (let m of merchantList) {
    let row = [
      m.mastercardMerchantID,
      m.merchantName,
      m.merchantCity,
      m.merchantCategoryCode,
      m.currencyCode,
      m.countryCode,
      m.qr
    ].join(',')
    csvContent += row + "\r\n"
  }
  return csvContent
}
