import {GET_RESET_PASSWORD_INFO} from '../action/resetPassword'
import Config from "../config";

const initialState = { 
	isValid:false,
	loading:true,
  errorMsg:'',
  activationInfoResponse: null
}

export default (state = initialState, action) => {
  const {type, payload} = action
  switch (type) {
    case `${GET_RESET_PASSWORD_INFO}_FULFILLED`:
      if (payload.resultCode === "0") {
        const activationData=JSON.parse(payload.resultText.userActivationData);

        let errorMessage = '';
        if (!activationData.ISVALID) {
          if (activationData.ISEXPIRED) {
            errorMessage ='Your reset password link is expired, please request a new one';
          } else {
            errorMessage ='The link is invalid';
          }
        } else if ((activationData.ISVALID) && (activationData.USER.OWNERID !== Config.ownerID)){
          errorMessage ='The link is invalid';
        }
        return {
          ...state,
          errorMsg: errorMessage ,
          loading: false,
          activationInfoResponse: activationData
        }
      } else {
        return {
          ...state,
          errorMsg: payload.resultText,
          activationInfoResponse: null,
          loading:false          
        }
      }
    case `${GET_RESET_PASSWORD_INFO}_PENDING`:
      return {
        ...state,
        errorMsg: '',
        loading: true,
        activationInfoResponse: null
      }
    case `${GET_RESET_PASSWORD_INFO}_REJECTED`:
      return {
        ...state,
        errorMsg: 'Cannot connect to server',
        activationInfoResponse:null
      }
    default:
      return state
  }
}
