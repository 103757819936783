import { apiCall } from '../api'

export const GET_STOP_REASONS = 'GET_STOP_REASONS'

export function getStopReasons() {
  const payload = apiCall('getStopReasons', {})
  return {
    type: GET_STOP_REASONS,
    payload,
    meta: {}
  }
}

export const GET_STOP_REASONS_ERROR_MESSAGE = 'GET_STOP_REASONS_ERROR_MESSAGE'

export function getStopReasonsErrorMessage() {
  return {
    type: GET_STOP_REASONS_ERROR_MESSAGE
  }
}

export const GET_STOP_REASONS_CLEAR = 'GET_STOP_REASONS_CLEAR'

export function getStopReasonsClear () {
  return {
    type: GET_STOP_REASONS_CLEAR
  }
}
