import React, { Component } from 'react'
import { ListItem, ListItemText } from '@material-ui/core'

class NavChildren extends Component {
  
  render() {
    const { route, navigate } = this.props

    const renderChildren = (subItems) => {
      return subItems.map((route, index) => {
        const itemClasses = [
          `menuitem-pad-${route.depth}`,
          `menuitem`
        ]
        
        return (
          <ListItem
            className={itemClasses.join(' ')}
            activeclassname="menuitem-active"
            button
            key={route.depth * 10 + index}
            onClick={() => { navigate(route.path) }}
            data-testid={route.testid ?? null}
          >
            <ListItemText primary={route.displayName} disableTypography={true} />
          </ListItem>
        ) 
      })
    }

    return (
      <div>
        {renderChildren(route)}
      </div>
      
    )
  }
}

export default NavChildren
