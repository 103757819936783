import React, {Component} from 'react'
import Wrapper from '../../component/default/layout/Wrapper'
import BatchDevalueForm from '../../component/agoda/batchDevalue/BatchDevalueForm'

class BatchDevalue extends Component {
  render () {
    return (
      <div>
        <Wrapper>
          <BatchDevalueForm />
        </Wrapper>
      </div>
    )
  }
}

export default BatchDevalue
