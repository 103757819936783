
export function validateNoEmptyFields (validateFieldArray){
  return validateFieldArray.filter(field => field === '').length === 0
}

/**
 * 
 * @param {String} input String, each value is separated by a comma
 * @param {Number} minLength Minimum length of each value
 * @returns False if any value have less than [minLength] characters | True if all value pass
 */
export const validateMinimumLength = (input, minLength) => {
  const values = input.split(',')

  for (const value of values) {
    const trimmedValue = value.trim()
    if (trimmedValue.length < minLength) {
      return false
    }
  }

  return true
}
