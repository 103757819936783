import React, {Component} from 'react'
import { store } from '../../../store'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import Table from '@material-ui/core/Table'
import TableHead from '@material-ui/core/TableHead'
import TableBody from '@material-ui/core/TableBody'
import TableRow from '@material-ui/core/TableRow'
import TableCell from '@material-ui/core/TableCell'
import CurrencyFormat from 'react-currency-format'
import Card from '@material-ui/core/Card'
import CardContent from '@material-ui/core/CardContent'
import CardActions from '@material-ui/core/CardActions'
import Typography from '@material-ui/core/Typography'
import Input from '@material-ui/core/Input'
import FormControl from '@material-ui/core/FormControl'
import Button from '@material-ui/core/Button'
import Radio from '@material-ui/core/Radio'
import red from '@material-ui/core/colors/red'
import green from '@material-ui/core/colors/green'
import { withTheme } from '@material-ui/core/styles'
import BatchFileElementEditDialog from './BatchFileElementEditDialog'
import Tooltip from '@material-ui/core/Tooltip'
import Chip from '@material-ui/core/Chip'
import { batchFileVerification, batchFileVerificationErrorMessage } from '../../../action/batchFileVerification'
import { countDecimals } from "../../../util/numberUtil";
import Config from "../../../config"

export class BatchFile extends Component {

  constructor(props) {
    super(props)
    this.initialState = {
      validCards: 0,
      invalidCards: 0,
      validAmounts: 0,
      invalidAmounts: 0
    }
    this.state = {
      ...this.initialState,
      batchFileContent: props.batchFileContent,
      identifierType: props.identifierType,
      operationType: props.operationType,
      batchElementsFilter: '',
      fileName: props.fileName,
      reference: props.reference,
      submitDisabled: true
    }
  }

  componentWillUnmount() {
    this.setState(this.initialState)
  }

  checkBatch(batchFileContent, operationType) {
    if(batchFileContent.length < 1) {
      this.props.resetCallback()
    }
    this.setState({ ...this.initialState }, function() {
      let batchStatus = {
        validCards: 0,
        invalidCards: 0,
        validAmounts: 0,
        invalidAmounts: 0
      }
      
      const voucherMaxLimitsByVoucher = this.getVoucherLimitsByVoucher(batchFileContent, operationType)
      batchFileContent.map((row,i) => {
        const batchElementCheckResult = this.checkBatchElement(row, operationType)
        this.validateTransferLimits(operationType, voucherMaxLimitsByVoucher, row, batchElementCheckResult)
        if (batchElementCheckResult.validCard) {
          batchStatus.validCards ++
        } else {
          batchStatus.invalidCards ++
        }
        if (batchElementCheckResult.validAmount) {
          batchStatus.validAmounts ++
        } else {
          batchStatus.invalidAmounts ++
        }

        return null
      })
      const submitDisabled = (operationType !== 'load' && (batchStatus.invalidCards > 0 || batchStatus.invalidAmounts > 0)) ? true : false
      this.setState({ ...this.state, submitDisabled: submitDisabled, ...batchStatus})
    })
  }

  checkBatchElement(batchElement, operationType) {
    let checkResult = {
      validCard: false,
      validAmount: false,
      resultBalance: 0,
      operationType: operationType,
      indicateStopped: false,
      indicateInactive: false,
      indicateActive: false,
      indicateRetired: false,
      indicateDuplicate: false,
      indicateInsufficientBalance: false,
      exists: batchElement.source.exists
    }

    let operationCheckHandler = 'check' + operationType.charAt(0).toUpperCase() + operationType.slice(1) + 'BatchElement'

    if (typeof this[operationCheckHandler] !== 'undefined' && typeof this[operationCheckHandler] === 'function') {
      // batchTransfer requires 2 vouchers, a source and a destination
      if (operationType !== 'transfer') {
        this[operationCheckHandler](batchElement.source, checkResult, batchElement.amount)
      }
      else {
        if (batchElement.amount.toString().trim() === '') {
          batchElement.amount = batchElement.source.fundsavailable
        }
        this[operationCheckHandler](batchElement.source, batchElement.destination, checkResult, batchElement.amount)
      }
    }

    return checkResult
  }

  checkLoadBatchElement(batchElement, checkResult, amount) {
    checkResult.indicateStopped = true
    checkResult.indicateInactive = true
    checkResult.indicateRetired = true
    checkResult.indicateLoadLimit = true
    checkResult.indicateDuplicate = true
    checkResult.allowDelete = false
    if (checkResult.exists && !batchElement.stopped && batchElement.active && !batchElement.retired) {
      checkResult.validCard = true

      checkResult.resultBalance = (isNaN(amount) || amount === '') ? parseFloat(batchElement.balance, 10) : (parseFloat(batchElement.balance, 10) + parseFloat(amount, 10))

      if (checkResult.resultBalance > batchElement.balance) {
        if (isNaN(amount, 10) || amount === '' || checkResult.resultBalance < 0 || countDecimals(amount) > 2) {
          checkResult.validAmount = false
        } else if ('loadlimit' in batchElement) {
          checkResult.validAmount = true
          checkResult.loadLimitsReached = []
          if ('loadMin' in batchElement.loadlimit && batchElement.loadlimit.loadMin > 0 && amount < batchElement.loadlimit.loadMin) {
            checkResult.loadLimitsReached.push('Minimum load amount not reached')
          }
          if ('loadMax' in batchElement.loadlimit && batchElement.loadlimit.loadMax > 0 && amount > batchElement.loadlimit.loadMax) {
            checkResult.loadLimitsReached.push('Maximum load amount exceeded')
          }
          if ('voucherMax' in batchElement.loadlimit && batchElement.loadlimit.voucherMax > 0 && checkResult.resultBalance > batchElement.loadlimit.voucherMax) {
            checkResult.loadLimitsReached.push('Maximum voucher balance exceeded')
          }
          if(checkResult.loadLimitsReached.length > 0) {
            checkResult.validAmount = false
            checkResult.allowDelete = true
          }
        } else {
          checkResult.validAmount = true
        }
      } else {
        //result balance after a load should not be lower than current balance
        checkResult.validAmount = false
      }
      if ('kyc' in batchElement && !batchElement.kyc) {
        checkResult.indicateKyc = true
        checkResult.validCard = false
      }
      if (batchElement.duplicate) {
        checkResult.validCard = false
      }
    } else {
      checkResult.validCard = false
    }
  }

  checkTransferBatchElement(sourceVoucher, destinationVoucher, checkResult, amount) {
    checkResult.indicateDuplicate = true
    checkResult.indicateStopped = false
    checkResult.indicateDestinationStopped = true
    checkResult.indicateInactive = true
    checkResult.indicateDestinationInactive = true
    checkResult.indicateRetired = true
    checkResult.indicateDestinationRetired = true
    checkResult.indicateLoadLimit = true
    checkResult.validAmount = true
    checkResult.validCard = true
    checkResult.sameVoucher = false
    checkResult.indicateDestinationExpired = true

    checkResult.resultBalance = parseFloat(sourceVoucher.fundsavailable, 10) - parseFloat(amount, 10)
    
    //check for the same cards
    if(checkResult.indicateDuplicate && (sourceVoucher.cardnumber === destinationVoucher.cardnumber)) {
      checkResult.validCard = false
      checkResult.sameVoucher = true
    }

    //if both cards don't exist
    checkResult.exists = (destinationVoucher.exists && sourceVoucher.exists)

    //check for non-existant or retired cards
    if (!checkResult.exists || sourceVoucher.retired || destinationVoucher.retired || destinationVoucher.stopped || destinationVoucher.expired || !sourceVoucher.active || !destinationVoucher.active) {
      checkResult.validCard = false
      checkResult.indicateInactive = !sourceVoucher.active
      checkResult.indicateDestinationInactive = !destinationVoucher.active
      checkResult.indicateRetired = !!sourceVoucher.retired
      checkResult.indicateDestinationRetired = !!destinationVoucher.retired
    }

    if(checkResult.validCard) {
      if (sourceVoucher.fundsavailable > 0) {
        if ('loadlimit' in destinationVoucher) {
          checkResult.validAmount = true
          checkResult.loadLimitsReached = []
          if ('loadMax' in destinationVoucher.loadlimit && destinationVoucher.loadlimit.loadMax > 0 && amount > destinationVoucher.loadlimit.loadMax) {
            checkResult.loadLimitsReached.push('Maximum load amount exceeded')
          }
          if ('voucherMax' in destinationVoucher.loadlimit && destinationVoucher.loadlimit.voucherMax > 0 && destinationVoucher.balance + parseFloat(amount) > destinationVoucher.loadlimit.voucherMax) {
            checkResult.loadLimitsReached.push('Maximum voucher balance exceeded')
          }
          if(checkResult.loadLimitsReached.length > 0) {
            checkResult.validAmount = false
            checkResult.allowDelete = true
            return;
          }
        }
        
        if (isNaN(amount, 10) || countDecimals(amount) > 2) {
          checkResult.validAmount = false
        } else if (checkResult.resultBalance < 0) {
          checkResult.validAmount = false
          checkResult.indicateInsufficientBalance = true
        } else {
          //result balance after a devalue should not be higher than current balance
          checkResult.validAmount = (checkResult.resultBalance <= sourceVoucher.fundsavailable)
        } 
        if (sourceVoucher.duplicate) {
          checkResult.validCard = false
        }
      } else {
        //if no balance the card should not be redeemed
        checkResult.validAmount = false
        checkResult.validCard = false
      }
    }

    checkResult.allowDelete = false

    if (!checkResult.validCard) {
      checkResult.resultBalance = 0
    }    
  }

  checkDevalueBatchElement(batchElement, checkResult, amount) {
    checkResult.indicateDuplicate = true
    if (checkResult.exists && !batchElement.retired) {
      checkResult.validCard = true

      checkResult.resultBalance = (isNaN(amount) || amount === '') ? parseFloat(batchElement.fundsavailable, 10) : (parseFloat(batchElement.fundsavailable, 10) - parseFloat(amount, 10))

      if (batchElement.fundsavailable >= 0) {
        //if there's balance to deduct
        if (!isNaN(amount, 10) && parseFloat(amount, 10) === 0) {
          //0 amount means deduct the balance
          checkResult.validAmount = true
          checkResult.resultBalance = 0
        } else {
          if (isNaN(amount, 10) || amount === '' || checkResult.resultBalance < 0 || countDecimals(amount) > 2) {
            checkResult.validAmount = false
          } else {
            //result balance after a devalue should not be higher than current balance
            checkResult.validAmount = (checkResult.resultBalance <= batchElement.fundsavailable)
          } 
        }
        if (batchElement.duplicate) {
          checkResult.validCard = false
        }
      } else {
        //if no balance the card should not be redeemed
        checkResult.validAmount = false
      }
    } else {
      checkResult.validCard = false
    }
  }

  checkActivateBatchElement(batchElement, checkResult) {
    checkResult.indicateActive = true
    if (checkResult.exists) {
      checkResult.validCard = true
      checkResult.validAmount = true
    } else {
      checkResult.validCard = false
      checkResult.validAmount = false
    }
  }

  componentWillReceiveProps(nextProps){
    this.setState({
      ...this.initialState,
      batchFileContent: nextProps.batchFileContent,
      operationType: nextProps.operationType,
      fileName: nextProps.fileName
    }, this.checkBatch(nextProps.batchFileContent, nextProps.operationType))
    if (nextProps.batchFileVerificationStruct.batchFileRow) {
      const batchFileContent = this.state.batchFileContent
      batchFileContent[nextProps.batchFileVerificationStruct.batchFileRow] = nextProps.batchFileVerificationStruct.batchVerificationResult[0]
      this.setState({ ...this.state, batchFileContent: batchFileContent }, this.checkBatch(this.state.batchFileContent, this.state.operationType))
    }
  }

  componentWillMount() {
    this.checkBatch(this.state.batchFileContent, this.state.operationType)
  }

  handleCheck = (event) => {
    const stateKey = event.target.name
    this.setState({ [stateKey]: event.target.checked }, this.setState(this.state))
  }

  handleFilterChange =(event) => {
    this.setState({ batchElementsFilter: event.target.value }, this.setState(this.state))
  }

  handleBatchChangeCallback(event, row, key) {
    const newBatchFileContent = this.state.batchFileContent
    newBatchFileContent[row][key] = this.validateNumber(event.target.value)
    this.setState( { batchFileContent : newBatchFileContent }, this.checkBatch(this.state.batchFileContent, this.state.operationType) )
  }

  validateNumber = value => value.substring(0,1) === '0' && value.length > 1 ? value.substring(1,value.length).replace(/[^(\d.)]/g, '') : value.replace(/[^(\d.)]/g, '')

  batchFileElementUpdate(batchFileRow, batchFileElementValue, callback) {
    callback()
    const batchElements = [
      {
        identifier: batchFileElementValue,
        amount: this.state.batchFileContent[batchFileRow].amount
      }
    ]
    store.dispatch(batchFileVerification(batchElements, this.state.identifierType, batchFileRow))
  }

  batchFileElementDelete(batchFileRow, callback) {
    callback()
    this.state.batchFileContent.splice(batchFileRow, 1)
    this.checkBatch(this.state.batchFileContent, this.state.operationType)
  }

  applyBatchFee(event, batchFeeAmount) {
    this.state.batchFileContent.map((row) => {
      row.batchFeeAmount = batchFeeAmount
      return row
    })
    this.setState(this.state)
  }

  resetComponent() {
    this.setState(this.initialState)
    this.props.resetCallback()
  }

  /**
   * Aggregates by voucher all the amounts transfers to the destinations. The reason of it is to verify the total amounts
   * transfered to a destination vouchers aren't exceeding the Voucher Max limit
   * @param {*} batchFileContent 
   * @param {*} operationType 
   */
  getVoucherLimitsByVoucher(batchFileContent, operationType) {
    const voucherMaxLimitsByVoucher = {}
    if (operationType === 'transfer') {
      batchFileContent.map((batchElement, i) => {
        voucherMaxLimitsByVoucher[batchElement.identifierTo] = batchElement.destination.balance
        return null
      })
      batchFileContent.map((batchElement, i) => {
        voucherMaxLimitsByVoucher[batchElement.identifierTo] += parseFloat(batchElement.amount)
        return null
      })
    }

    return voucherMaxLimitsByVoucher
  }

  validateTransferLimits(operationType, voucherMaxLimitsByVoucher, row, batchElementCheckResult) {
    //skip the check if the transfer voucher does not exist
    if (operationType === 'transfer' && row.destination.exists && (voucherMaxLimitsByVoucher[row.identifierTo] > row.destination.loadlimit.voucherMax)) {
      if (batchElementCheckResult.loadLimitsReached.indexOf('Maximum voucher balance exceeded') === -1) {
        batchElementCheckResult.loadLimitsReached.push('Maximum voucher balance exceeded')
      }
      batchElementCheckResult.validAmount = false
      batchElementCheckResult.allowDelete = true
    }

  }

  render() {

    const validTextStyle = {
      color: green[500]
    }

    const invalidTextStyle = {
      color: red[500],
      fontWeight: 'bold'
    }

    const { batchFileContent, operationType } = this.state

    const voucherMaxLimitsByVoucher = this.getVoucherLimitsByVoucher(batchFileContent, operationType)

    return (
      <div>
      <Card style={{padding:'40px'}}>
        <Typography className="batch-file-header" gutterBottom variant="h5">
          Batch content of <span className="batch-file-name">{ this.state.fileName }</span> { this.state.reference ? <Chip label={ this.state.reference } className="batch-file-header-chip"/> : null }
        </Typography>
        
        <Table>
          <TableBody>
            <TableRow>
              <TableCell>
                Total records
              </TableCell>
              <TableCell>
                { this.state.batchFileContent.length }
              </TableCell>
              <TableCell>
                <Radio
                  checked={this.state.batchElementsFilter === ''}
                  onChange={this.handleFilterChange}
                  value=""
                  color="default"
                  name="batchListFilter"
                />
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell>
                Valid cards
              </TableCell>
              <TableCell>
                <p style={ ((this.state.validCards > 0) ? validTextStyle : invalidTextStyle) } >
                  { this.state.validCards }
                </p>
              </TableCell>
              <TableCell>
                <Radio
                  checked={this.state.batchElementsFilter === 'validCard'}
                  onChange={this.handleFilterChange}
                  value="validCard"
                  color="default"
                  name="batchListFilter"
                />
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell>
                Invalid cards
              </TableCell>
              <TableCell>
                <p style= { (this.state.invalidCards > 0) ? invalidTextStyle : validTextStyle } >
                  { this.state.invalidCards }
                </p>
              </TableCell>
              <TableCell>
                <Radio
                  checked={this.state.batchElementsFilter === 'invalidCard'}
                  onChange={this.handleFilterChange}
                  value="invalidCard"
                  color="default"
                  name="batchListFilter"
                />
              </TableCell>
            </TableRow>
            { ['load','devalue'].includes(operationType) ?
              <TableRow>
                <TableCell>
                  Valid amounts
                </TableCell>
                <TableCell>
                  <p style= { (this.state.validAmounts > 0) ? validTextStyle : invalidTextStyle } >
                    { this.state.validAmounts }
                  </p>
                </TableCell>
                <TableCell>
                  <Radio
                    checked={this.state.batchElementsFilter === 'validAmount'}
                    onChange={this.handleFilterChange}
                    value="validAmount"
                    color="default"
                    name="batchListFilter"
                  />
                </TableCell>
              </TableRow> : null
            } 
            { ['load','devalue'].includes(operationType) ?
              <TableRow>
                <TableCell>
                  Invalid amounts
                </TableCell>
                <TableCell>
                  <p style= { (this.state.invalidAmounts > 0) ? invalidTextStyle : validTextStyle } >
                    { this.state.invalidAmounts }
                  </p>
                </TableCell>
                <TableCell>
                  <Radio
                    checked={this.state.batchElementsFilter === 'invalidAmount'}
                    onChange={this.handleFilterChange}
                    value="invalidAmount"
                    color="default"
                    name="batchListFilter"
                  />
                </TableCell>
              </TableRow> : null
            }
          </TableBody>
        </Table>
        <CardActions className="align-right">
          <Button onClick={ this.resetComponent.bind(this) } color="primary" style={{marginRight: '10px' }}>
            Cancel
          </Button>
          <Button className="flattbutton-login" color="primary"size="large" variant="contained" onClick={() => this.props.submitCallback()} disabled={this.state.submitDisabled || this.props.apiCallPending}>{this.props.operationType}</Button>
        </CardActions>
      </Card>
      <br />
      { (operationType === 'load')  && (!Config.hideFees) ?
        <Card style={{padding:'40px'}}>
          <Typography className="batch-file-header" gutterBottom variant="h5">
            Load Fee
          </Typography>
          <Typography gutterBottom variant="caption">
            By clicking apply button the same fee will be applied to all card in the batch across all the different KYC.
          </Typography>
          <Input name='batchFeeAmount' value={this.props.batchFeeAmount} onChange={this.props.handleInputChange} />
          <Button onClick={ (e) => this.applyBatchFee(e, this.props.batchFeeAmount) } color="primary" style={{marginLeft: '10px' }}>
              Apply
          </Button>
        </Card> : null
      }
      <br/>
      <Card style={{padding:'40px'}}>
        <CardContent style={this.props.theme.palette.tableWrapper}>
          <Table className="batch-file-table">
            <TableHead>
              <TableRow>
                <TableCell>#</TableCell>
                <TableCell>Identifier</TableCell>
                { ['transfer','load','devalue'].includes(operationType) ? <TableCell>Amount</TableCell> : null }
                { ['load'].includes(operationType) && (!Config.hideFees) ? <TableCell>Load Fee</TableCell> : null }
                <TableCell>{ ['devalue'].includes(operationType) ? 'Funds Available' : 'Current Balance'}</TableCell>
                { ['transfer','load','devalue'].includes(operationType) ? <TableCell>Balance after operation</TableCell> : null }
                { ['transfer'].includes(operationType) ? <TableCell>Destination</TableCell> : null }
              </TableRow>
            </TableHead>
            <TableBody>
              {batchFileContent.map((row,i) => {
                const batchElementCheckResult = this.checkBatchElement(row, operationType)
                this.validateTransferLimits(operationType, voucherMaxLimitsByVoucher, row, batchElementCheckResult)
                let display = true
                display = (
                  (this.state.batchElementsFilter === '')
                ||
                  (this.state.batchElementsFilter === 'validCard' && batchElementCheckResult.validCard)
                ||
                  (this.state.batchElementsFilter === 'invalidCard' && !batchElementCheckResult.validCard)
                ||
                  (this.state.batchElementsFilter === 'validAmount' && batchElementCheckResult.validAmount)
                ||
                  (this.state.batchElementsFilter === 'invalidAmount' && !batchElementCheckResult.validAmount)
                ) ? true : false
                const returnElement = display ? (
                  <TableRow key={i+1}>
                    <TableCell>{i+1}</TableCell>
                    <TableCell>
                      {row.identifier}
                      { (!batchElementCheckResult.validCard || batchElementCheckResult.allowDelete)? 
                        <BatchFileElementEditDialog
                          batchFileRowNumber={i}
                          batchFileElementValue={row.identifier}
                          batchFileElementUpdate={this.batchFileElementUpdate.bind(this)}
                          batchFileElementDelete={this.batchFileElementDelete.bind(this)}
                        /> : null
                      }
                      { (batchElementCheckResult.exists && batchElementCheckResult.validCard && batchElementCheckResult.indicateActive && row.source.active) ?
                        <Tooltip id="tooltip-fab" title="Card is active">
                            <span style={validTextStyle} className="card-status-indicator">A</span>
                        </Tooltip> : null
                      }
                      { (batchElementCheckResult.exists && batchElementCheckResult.indicateLoadLimit && batchElementCheckResult.loadLimitsReached && batchElementCheckResult.loadLimitsReached.length) ?
                        <Tooltip id="tooltip-fab" title={'Load limits reached: ' + batchElementCheckResult.loadLimitsReached.join(', ') }>
                            <span style={invalidTextStyle} className="card-status-indicator">L</span>
                        </Tooltip> : null
                      }
                      { (!batchElementCheckResult.exists) ?
                        <Tooltip id="tooltip-fab" title="Card does not exist">
                            <span style={invalidTextStyle} className="card-status-indicator">E</span>
                        </Tooltip> : null
                      }
                      { (batchElementCheckResult.exists && !batchElementCheckResult.validCard && batchElementCheckResult.indicateStopped && row.source.stopped) ?
                        <Tooltip id="tooltip-fab" title="Card is stopped">
                            <span style={invalidTextStyle} className="card-status-indicator">S</span>
                        </Tooltip> : null
                      }
                      { (batchElementCheckResult.exists && !batchElementCheckResult.validCard && batchElementCheckResult.indicateDestinationStopped && row.destination.stopped) ?
                        <Tooltip id="tooltip-fab" title="Destination card is stopped">
                            <span style={invalidTextStyle} className="card-status-indicator">S</span>
                        </Tooltip> : null
                      }
                      { (batchElementCheckResult.exists && !batchElementCheckResult.validCard && batchElementCheckResult.indicateRetired && row.source.retired) ?
                        <Tooltip id="tooltip-fab" title="Card is retired">
                            <span style={invalidTextStyle} className="card-status-indicator">R</span>
                        </Tooltip> : null
                      }
                      { (batchElementCheckResult.exists && !batchElementCheckResult.validCard && batchElementCheckResult.indicateDestinationRetired &&  row.destination.retired) ?
                        <Tooltip id="tooltip-fab" title="Destination card is retired">
                            <span style={invalidTextStyle} className="card-status-indicator">R</span>
                        </Tooltip> : null
                      }
                      { (batchElementCheckResult.exists && !batchElementCheckResult.validCard && batchElementCheckResult.indicateInactive && !!!row.source.active) ?
                        <Tooltip id="tooltip-fab" title="Card is inactive">
                            <span style={invalidTextStyle} className="card-status-indicator">I</span>
                        </Tooltip> : null
                      }
                      { (batchElementCheckResult.exists && !batchElementCheckResult.validCard && batchElementCheckResult.indicateDestinationInactive && !row.destination.active) ?
                        <Tooltip id="tooltip-fab" title="Destination card is inactive">
                            <span style={invalidTextStyle} className="card-status-indicator">I</span>
                        </Tooltip> : null
                      }
                      { (batchElementCheckResult.exists && !batchElementCheckResult.validCard && batchElementCheckResult.indicateKyc && !row.source.kyc) ?
                        <Tooltip id="tooltip-fab" title="Card is not KYC">
                            <span style={invalidTextStyle} className="card-status-indicator">K</span>
                        </Tooltip> : null
                      }
                      { (batchElementCheckResult.exists && !batchElementCheckResult.validCard && batchElementCheckResult.indicateDuplicate && (row.duplicate || row.source.duplicate)) ?
                        <Tooltip id="tooltip-fab" title="Card is duplicate in the file">
                            <span style={invalidTextStyle} className="card-status-indicator">D</span>
                        </Tooltip> : null
                      }
                      { (batchElementCheckResult.exists && !batchElementCheckResult.validCard && batchElementCheckResult.indicateDestinationExpired && row.destination.expired) ?
                        <Tooltip id="tooltip-fab" title="Destination card has expired">
                            <span style={invalidTextStyle} className="card-status-indicator">EX</span>
                        </Tooltip> : null
                      }
                      { (batchElementCheckResult.exists && !batchElementCheckResult.validCard && batchElementCheckResult.indicateDuplicate && batchElementCheckResult.sameVoucher) ?
                        <Tooltip id="tooltip-fab" title="Source card is the same as destination card">
                            <span style={invalidTextStyle} className="card-status-indicator">D</span>
                        </Tooltip> : null
                      }
                      { (batchElementCheckResult.exists && batchElementCheckResult.indicateInsufficientBalance) ?
                        <Tooltip id="tooltip-fab" title="Insufficient card balance">
                            <span style={invalidTextStyle} className="card-status-indicator">B</span>
                        </Tooltip> : null
                      }
                    </TableCell>
                    { ['transfer','load','devalue'].includes(operationType) ?
                      <TableCell>
                        <FormControl
                          disabled={!batchElementCheckResult.validCard}
                          error={batchElementCheckResult.validCard && !batchElementCheckResult.validAmount}
                        >
                          <Input id="name-error" value={row.amount || '0'} onChange={ (e) => this.handleBatchChangeCallback(e, i, 'amount') } />
                        </FormControl>
                      </TableCell> : null
                    }
                    { ['load'].includes(operationType) && (!Config.hideFees) ?
                      <TableCell>
                        <FormControl
                          error={batchElementCheckResult.validCard && !batchElementCheckResult.validAmount}
                        >
                          <Input value={row.batchFeeAmount} onChange={ (e) => this.handleBatchChangeCallback(e, i, 'batchFeeAmount') } />
                        </FormControl>
                      </TableCell> : null
                    }
                    <TableCell>
                      <CurrencyFormat
                        value={['devalue'].includes(operationType) ? row.source.fundsavailable : (['transfer'].includes(operationType)) ? row.source.fundsavailable : row.source.balance }
                        displayType={'text'}
                        thousandSeparator={true}
                        fixedDecimalScale={true}
                        decimalScale={2}
                      />
                    </TableCell>
                    { ['transfer','load','devalue'].includes(operationType) ?
                      <TableCell>
                        <CurrencyFormat
                          value={ batchElementCheckResult.resultBalance }
                          displayType={'text'}
                          thousandSeparator={true}
                          fixedDecimalScale={true}
                          decimalScale={2}
                          style={ batchElementCheckResult.validCard && !batchElementCheckResult.validAmount ? invalidTextStyle : {} }
                        />
                      </TableCell> : null
                    }
                    { ['transfer'].includes(operationType) ?
                      <TableCell>
                        {row.identifierTo}
                      </TableCell> : null
                    }
                  </TableRow>
                ) : null
                return returnElement
              })}
            </TableBody>
        </Table>
        </CardContent>
      </Card>
      </div>
    )
  }
}

BatchFile.propTypes = {
  BatchFileVerification: PropTypes.func
}

const mapStateToProps = (state, props) => {
  return {
    apiCallPending: !!(state.api.BATCH_LOAD && state.api.BATCH_LOAD > 0),
    batchFileVerificationStruct: {
      batchVerificationResult: (state.batchFileVerification.batchVerificationResult || []),
      batchFileRow: (state.batchFileVerification.batchFileRow || null),
      fileName: (state.batchFileVerification.fileName || '')
    }
  }
}

export default connect(
  mapStateToProps,
  { 
    batchFileVerification,
    batchFileVerificationErrorMessage
  }
)(withTheme(BatchFile))
