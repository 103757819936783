import React from 'react'
import BaseDateRangedReport from './BaseDateRangedReport'

const Fee = () => {
  return (
    <BaseDateRangedReport
      reportHeader = "Fee Report"
      reportDownloadName = "feeReport"
      apiReportName = "feeReport"
    />
  )
}

export default Fee
