import { apiCall } from './api'

export const CARD_SEARCH = 'CARD_SEARCH'

export function cardSearch(searchTerm, searchType, showExpired ) {
  const payload = apiCall('cardSearch', { searchTerm, searchType, showExpired })
  return {
    type: CARD_SEARCH,
    payload,
    meta: {
      searchTerm
    }
  }
}

export const GET_CARD_TAGS = 'GET_CARD_TAGS'

export function getCardTags() {
  const payload = apiCall('getCardTags', {})
  return {
    type: GET_CARD_TAGS,
    payload,
    meta: {}
  }
}

export const CARD_SEARCH_ERROR_MESSAGE = 'CARD_SEARCH_ERROR_MESSAGE'

export function cardSearchErrorMessage() {
  return {
    type: CARD_SEARCH_ERROR_MESSAGE
  }
}

export const CARD_SEARCH_RESET = 'CARD_SEARCH_RESET'

export function cardSearchReset () {
  return {
    type: CARD_SEARCH_RESET
  }
}

export const COMBINED_CARD_STATEMENT = 'COMBINED_CARD_STATEMENT'

export function getCombinedStatement(voucherNumbers) {
  const payload = apiCall('getCombinedStatement', { voucherNumbers })
  return {
    type: COMBINED_CARD_STATEMENT,
    payload,
    meta: {
    }
  }
}
export const COMBINED_CARD_STATEMENT_RESET = 'COMBINED_CARD_STATEMENT_RESET'

export function resetCombinedStatement () {
  return {
    type: COMBINED_CARD_STATEMENT_RESET
  }
}
