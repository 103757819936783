import React from 'react'
import red from '@material-ui/core/colors/red'

const Alert = (props) => {
  const { style = {} } = props
  return <div style={{
    fontColor: "#000000",
    backgroundColor: red[500],
    padding: "8px 15px",
    lineHeight: "30px",
    verticalAlign: "middle",
    color: "#ffffff",
    ...style
  }}>
    { props.children }
  </div>
}

export default Alert
