import { apiCall } from '../api'

export const UNSTOP_CARD = 'UNSTOP_CARD'

export function unstopCard(voucherNumber) {
  const payload = apiCall('unstopCard', { voucherNumber})
  return {
    type: UNSTOP_CARD,
    payload,
    meta: {
      voucherNumber
    }
  }
}

export const UNSTOP_CARD_ERROR_MESSAGE = 'UNSTOP_CARD_ERROR_MESSAGE'

export function unstopCardErrorMessage() {
  return {
    type: UNSTOP_CARD_ERROR_MESSAGE
  }
}

export const UNSTOP_CARD_CLEAR = 'UNSTOP_CARD_CLEAR'

export function unstopCardClear () {
  return {
    type: UNSTOP_CARD_CLEAR
  }
}
