import React, {Component} from 'react'
import {connect} from 'react-redux'
import {Redirect} from 'react-router'
import {rememberPassword, reset} from '../../action/forgotPassword'
import ForgotPasswordForm from '../../component/default/ForgotPasswordForm'
import ForgotPasswordConfirmation from '../../component/default/ForgotPasswordConfirmation'
import {red} from "@material-ui/core/colors/index";

const paperStyle = {
  width: '50%',
  position: 'absolute',
  top: '45%',
  left: '50%',
  padding: 20,
  marginLeft: '-120px',
  textAlign: 'center',
  display: 'flex',
  flexDirection: 'column',
  minHeight:'200px',
  justifyContent:'space-between',
  transform: 'translateY(-50%) translateX(-50%)',
  verticalAlign: 'middle'
}

const helperTextStyle = {
  color: red[500]
}

class ForgotPassword extends Component
{

  state = {
    account: ''
  }

  onInputChange = (event) => {
    const stateKey = event.target.getAttribute("name")
    this.setState({[stateKey]: event.target.value})
  }

  onKeyPress = (event) => {
    if (event.key === 'Enter') {
      this.onRemember()
    }
  }

  onRemember = () => {
    this.props.rememberPassword(this.state.account)
  }

  onCancel = () => {
    this.props.reset()
  }

  render() {
    if (this.props.token !== null) {
      return <Redirect to="/"/>
    }

    const disableSubmit = (this.state.account.length === 0) || this.props.submitting
    return (!this.props.successful) ?
      (
        <ForgotPasswordForm
        error={this.props.response}
        changed={this.onInputChange}
        submitted={this.onRemember}
        account={this.state.account}
        canceled={this.onCancel}
        canSubmit={disableSubmit}
        paperStyle={paperStyle}
        helperTextStyle={helperTextStyle} />
      ) :
      (
        <ForgotPasswordConfirmation
          email={this.props.email}
          accept={this.onCancel}
          paperStyle={paperStyle} />
      )
  }
}

const mapStateToProps = (state, props) => {
  return {
    token: state.auth.token,
    submitting: !!(state.api.REMEMBER_PASSWORD && state.api.REMEMBER_PASSWORD > 0),
    response: state.forgotPassword.errorMsg,
    email : state.forgotPassword.email,
    successful : state.forgotPassword.successful
  }
}

const mapDispatchToProps = {
  rememberPassword, reset
}

export default connect(mapStateToProps, mapDispatchToProps)(ForgotPassword)
