import { FAILED_TRANSACTION_SEARCH, FAILED_TRANSACTION_SEARCH_ERROR_MESSAGE, FAILED_TRANSACTION_SEARCH_CLEAR } from '../action/failedTransactionSearch'

const initialState = {
  errorMsg : '',
  searchResults: []
}

export default (state = initialState, action) => {
  const { type, payload } = action
  switch(type) {
    case FAILED_TRANSACTION_SEARCH_ERROR_MESSAGE:
      return {
        ...state,
        errorMsg: 'An error occurred'
      }
    case `${FAILED_TRANSACTION_SEARCH}_FULFILLED`:
      if (payload.resultCode === "0") {

        return {
          ...state,
          searchResults: JSON.parse(payload.resultText)
        }
      } else {
        return {
          ...state,
          errorMsg: payload.resultText
        }
      }
    case `${FAILED_TRANSACTION_SEARCH}_PENDING`:
      return {
        ...state,
        errorMsg: ''
      }
    case `${FAILED_TRANSACTION_SEARCH}_REJECTED`:
      return {
        ...state,
        errorMsg: 'Cannot connect to server'
      }
    case FAILED_TRANSACTION_SEARCH_CLEAR:
      return {
        initialState
      }
    default:
      return state
  }
}
