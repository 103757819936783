import { apiCall } from './api'

export const GET_FEE_TYPES = 'GET_FEE_TYPES'
export const getFeeTypes = token => ({
  type: GET_FEE_TYPES,
  payload: apiCall('feeTypes', {token}).then(feeTypes => {
    var feeTypeObj = {}
    feeTypes.feeTypes&&
    feeTypes.feeTypes.map(feeType => {
      feeTypeObj[feeType.ID] = {
        charge: feeType.CHARGE,
        id: feeType.ID,
        name: feeType.NAME
      }
      return null
    })
    return feeTypeObj
  })
})

export const INSERT_CARD_FEE = 'INSERT_CARD_FEE'
export const insertCardFee = insertCardFee => ({
  type: INSERT_CARD_FEE,
  payload: apiCall('insertCardFee', insertCardFee).then(result => result)
})

export const RESET_CARD_FEE_STATUS = 'RESET_CARD_FEE_STATUS'
export const resetCardFeeStatus = () => ({type: RESET_CARD_FEE_STATUS})
