import React from 'react'
import { connect } from 'react-redux'
import { Route, Redirect } from 'react-router'

// from PrivateRoute at https://reacttraining.com/react-router/web/example/auth-workflow
const PrivateRoute = ({ component: Component, token, userPerms, permissions, ...rest }) => (
  <Route
    {...rest}
    render={
      props => {
        // not logged in
        if (!token) {
          return <Redirect to="/login"/>
        }

        const requiredPerms = permissions || []
        for (let perm of requiredPerms) {
          let [ resource, action ] = perm.split(".")
          let actions = userPerms[resource] || []
          if (actions.length === 0 || actions.findIndex((a) => a === action) === -1) {
            // TODO a better looking component
            return <div>You are not authorized</div>
          }
        }

        return <Component {...props} />
      }
    }
  />
)

export default connect((state, props) => ({token: state.auth.token, userPerms: state.auth.perms}))(PrivateRoute)
