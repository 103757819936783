import { GET_CAMPAIGNS, GET_VIRTUAL_CAMPAIGNS } from '../action'

const FULFILLED = '_FULFILLED'

const initialState = {
	campaigns : []
}

export default (state = initialState, action) => {
	switch (action.type) {

	case `${GET_CAMPAIGNS}${FULFILLED}` :
		return { ...state, ...action.payload }
	case `${GET_VIRTUAL_CAMPAIGNS}${FULFILLED}` :
		return { ...state, ...action.payload }
	default:
		return state
	}
}
