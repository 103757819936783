import React from 'react'
import Select from '@material-ui/core/Select'
import FormControl from '@material-ui/core/FormControl'
import MenuItem from '@material-ui/core/MenuItem'
import InputLabel from '@material-ui/core/InputLabel'
import {withTheme} from '@material-ui/core/styles'

const CampaignSelect = props => <FormControl style={props.theme.palette.campaignSelect}>
  <InputLabel htmlFor="campaign">Campaign</InputLabel>
  <Select
    data-testid="campaignSelectInput"
    value = {props.campaign || ''}
    onChange = {props.handleInputChange}
    inputProps={{
      name: 'campaign',
      id: 'campaign',
    }}
  >{
      !props.disabledAll && 
    <MenuItem value={combineCampaigns(props.campaigns)}>
      <em>All</em>
    </MenuItem>
    }
    {
      props.campaigns.map(
        (campaignOption, key) => <MenuItem key={key} value={campaignOption.CampaignID}>{campaignOption.CampaignName}</MenuItem>
      )
    }
  </Select>
</FormControl>

export const combineCampaigns = campaigns => campaigns.reduce((finalValue, campaign, index) =>`${finalValue}${campaign.CampaignID}${index !== (campaigns.length-1) ? ',':''}`, '')

export default withTheme(CampaignSelect)
