import { apiCall } from './api' 

export const ACTIVATE_USER = 'ACTIVATE_USER'
export const activateUser = (token, userDetail) => (
  {
    type:ACTIVATE_USER,
    payload: apiCall('activateUser', {user:userDetail, token})
  }
)

export const RESET_ACTIVATE_USER_RESULT = 'RESET_ACTIVATE_USER_RESULT'
export const resetActivateUserResult = () => (
  {
    type:RESET_ACTIVATE_USER_RESULT
  }
)

export const USER_GETACTIVATION_INFO = 'USER_GETACTIVATION_INFO'
export const getActivationInfo = (uuid) => {
  const payload = apiCall('getActivationInfo',{userUUID:uuid})
  return {
    type: USER_GETACTIVATION_INFO,
    payload,
    meta: {
    }
  }
}

export const USER_GETACTIVATION_INFO_ERROR_MESSAGE = 'USER_GETACTIVATION_INFO_ERROR_MESSAGE'

export function activationInfoErrorMessage () {
  return {
    type: USER_GETACTIVATION_INFO_ERROR_MESSAGE
  }
}

export const USER_SAVE_PASSWORD = 'USER_SAVE_PASSWORD'
export const saveUserPassword = (token, userDetail) => (
  {
    type: USER_SAVE_PASSWORD,
    payload: apiCall('saveUserPassword', {user:userDetail, token})
  }
)

export const USER_CLEAR_ERROR_MESSAGE = 'USER_CLEAR_ERROR_MESSAGE'

export function userClearErrorMessage(userInfo) {
  return {
    type: USER_CLEAR_ERROR_MESSAGE
  }
}
