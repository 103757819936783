import { apiCall } from './api'

export const BATCH_TRANSFER = 'BATCH_TRANSFER'

export function batchTransfer(batchElements, identifierType, loadReference, fileName, batchTransferOptions) {
  const payload = apiCall('batchTransferFunds', {batchElements, identifierType, loadReference, fileName, batchTransferOptions})
  return {
    type: BATCH_TRANSFER,
    payload,
    meta: {
      batchElements,
      identifierType,
      loadReference,
      fileName,
      batchTransferOptions
    }
  }
}

export const BATCH_TRANSFER_ERROR_MESSAGE = 'BATCH_TRANSFER_ERROR_MESSAGE'

export function batchTransferErrorMessage (errorMessage = null) {
  return {
    type: BATCH_TRANSFER_ERROR_MESSAGE,
    payload: { resultText: errorMessage }
  }
}

export const BATCH_TRANSFER_RESET = 'BATCH_TRANSFER_RESET'

export function batchTransferReset () {
  return {
    type: BATCH_TRANSFER_RESET
  }
}
