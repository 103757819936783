import React from 'react'
import Wrapper from '../../component/default/layout/Wrapper'
import CardDetailsForm from '../../component/agoda/cardDetails/CardDetailsForm'

const CardDetails = (props) => {
  return (
    <div>
      <Wrapper>
        <CardDetailsForm voucherID={props.match.params.voucherID} reverse={props.reverse}/>
      </Wrapper>
    </div>
  )
}

export default CardDetails
