import React, { Component } from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import Dialog from '@material-ui/core/Dialog'
import Slide from '@material-ui/core/Slide'
import AppBar from '@material-ui/core/AppBar'
import Toolbar from '@material-ui/core/Toolbar'
import IconButton from '@material-ui/core/IconButton'
import Icon from '@material-ui/core/Icon'
import Typography from '@material-ui/core/Typography'
import Card from '@material-ui/core/Card'
import CardContent from '@material-ui/core/CardContent'
import Button from '@material-ui/core/Button'
import { failedTransactionSearch, failedTransactionSearchErrorMessage, failedTransactionSearchClear } from '../../../action/failedTransactionSearch'
import { FailedTransactionResults } from './FailedTransactionResults'
import dateFormat from "dateformat"
import { store } from '../../../store'
import DateFromDateTo from './DateFromDateTo'

function Transition(props) {
  return <Slide direction="up" {...props} />;
}

class FailedTransactions extends Component {

  constructor(props) {
    super(props)

    var today = new Date()
    var todaysDate = today.getFullYear() + '-' + (today.getMonth() + 1) + '-' + today.getDate()

    this.initialState = {
      voucherNumber: props.voucherNumber,
      startDate: dateFormat(new Date(todaysDate), "yyyy-mm-dd"),
      endDate: dateFormat(new Date(), "yyyy-mm-dd"),
      errorMsg: '',
      failedTransactionResults: []
    }
    this.state = {
      ...this.initialState
    }
  }



  handleInputChange = (event) => {
    const stateKey = event.target.name
    this.setState({ [stateKey]: event.target.value })
  }

  handleClick = () => {
    store.dispatch(failedTransactionSearchClear())
    this.props.failedTransactionSearch(this.props.voucherNumber, this.state.startDate, this.state.endDate)
  }

  componentDidMount()
  {
    this.setState(this.initialState)
    store.dispatch(failedTransactionSearchClear());
  }

  render() {

    const { submitting, errorMsg } = this.props
    const failedTransactionsCard = this.props.failedTransactionResults ? (
    <Card>
      <CardContent>
        <FailedTransactionResults failedTransactionResults={this.props.failedTransactionResults} />
      </CardContent>
    </Card>
    ) : (null)

    return (
      <Dialog
        fullScreen
        open={this.props.open}
        onClose={this.props.close}
        transition={Transition}
      >
        <AppBar style={{position:'relative'}}>
          <Toolbar>
            <Typography variant="h6" style={{flex:'1'}}>
              Failed Transactions
            </Typography>
            <IconButton color="inherit" onClick={this.props.close} aria-label="Close">
              <Icon>close</Icon>
            </IconButton>
          </Toolbar>
        </AppBar>
        
        <Card>
          <CardContent>
            <DateFromDateTo
              startDate={this.state.startDate}
              endDate={this.state.endDate}
              onChangeStartDate={this.handleInputChange}
              onChangeEndDate={this.handleInputChange}
            />
            <Button onClick={this.handleClick} disabled={submitting}>
              <Icon>search</Icon>
            </Button>
            {/*This will be swapped with a snackbar once the component has been commited */}
            <div>{errorMsg}</div>
          </CardContent>
        </Card>
        {failedTransactionsCard}
      </Dialog>
    )
    }
}

FailedTransactions.propTypes = {
  FailedTransactionSearch: PropTypes.func
}

const mapStateToProps = (state, props) => {
  return {
    submitting: !!(state.api.FAILED_TRANSACTION_SEARCH && state.api.FAILED_TRANSACTION_SEARCH > 0),
    errorMsg: state.failedTransactionSearch.errorMsg,
    failedTransactionResults: state.failedTransactionSearch.searchResults
  }
}

export default connect(
  mapStateToProps,
  { 
    failedTransactionSearch,
    failedTransactionSearchErrorMessage
  }
)(FailedTransactions)
