import { apiCall } from './api' 

export const SET_USER_PASSWORD_ERROR_MESSAGE = 'SET_PASSWORD_ERROR_MESSAGE'

export function setPasswordErrorMessage () {
  return {
    type: SET_USER_PASSWORD_ERROR_MESSAGE
  }
}


export const SET_USER_PASSWORD_RESULT = 'SET_USER_PASSWORD_RESULT'

export function setPasswordResult () {
  return {
    type: SET_USER_PASSWORD_RESULT
  }
}

export const SET_USER_PASSWORD = 'SET_USER_PASSWORD'
export function setUserPassword(userDetail) {  
  return {
    type: SET_USER_PASSWORD,
    payload: apiCall('savePassword', userDetail,false)
  }
}

