import { TOGGLE_MAGSTRIPE, TOGGLE_MAGSTRIPE_ERROR_MESSAGE, TOGGLE_MAGSTRIPE_CLEAR } from '../action/card/toggleMagstripe'

const initialState = {
  errorMsg : '',
  toggleMagstripeResult: false,
  toggleMagstripeApiCalled: false
}

export default (state = initialState, action) => {
  const { type, payload } = action
  switch(type) {
    case TOGGLE_MAGSTRIPE_ERROR_MESSAGE:
      return {
        ...state,
        errorMsg: 'An error occurred',
        toggleMagstripePending: false
      }
    case `${TOGGLE_MAGSTRIPE}_FULFILLED`:
      if (payload.resultCode === 0 || payload.resultCode === '0') {
        return {
          ...state,
          toggleMagstripeApiCalled: true,
          toggleMagstripeResult: !!payload.resultText,
          toggleMagstripePending: false
        }
      } else {
        return {
          ...state,
          errorMsg: payload.resultText,
          toggleMagstripePending: false
        }
      }
    case `${TOGGLE_MAGSTRIPE}_PENDING`:
      return {
        ...state,
        errorMsg: '',
        toggleMagstripePending: true
      }
    case `${TOGGLE_MAGSTRIPE}_REJECTED`:
      return {
        ...state,
        errorMsg: 'Cannot connect to server',
        toggleMagstripePending: false
      }
    case TOGGLE_MAGSTRIPE_CLEAR:
      return {
        ...initialState,
        toggleMagstripePending: false,
        toggleMagstripeApiCalled: false
      }
    default:
      return state
  }
}
