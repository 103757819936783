import { UNSTOP_CARD, UNSTOP_CARD_ERROR_MESSAGE, UNSTOP_CARD_CLEAR } from '../action/card/unstopCard'

const initialState = {
  errorMsg : '',
  unstopResult: false
}

export default (state = initialState, action) => {
  const { type, payload } = action
  switch(type) {
    case UNSTOP_CARD_ERROR_MESSAGE:
      return {
        ...state,
        errorMsg: 'An error occurred'
      }
    case `${UNSTOP_CARD}_FULFILLED`:
      if (payload.resultCode === "0") {
        return {
          ...state,
          unstopResult: payload.resultText
        }
      } else {
        return {
          ...state,
          errorMsg: payload.resultText
        }
      }
    case `${UNSTOP_CARD}_PENDING`:
      return {
        ...state,
        errorMsg: ''
      }
    case `${UNSTOP_CARD}_REJECTED`:
      return {
        ...state,
        errorMsg: 'Cannot connect to server'
      }
    case UNSTOP_CARD_CLEAR:
      return {
        initialState
      }
    default:
      return state
  }
}
