import { BATCH_TRANSFER, BATCH_TRANSFER_ERROR_MESSAGE } from '../action/batchTransfer'

const initialState = {
  errorMsg : '',
  batchUploadResult : false
}

export default (state = initialState, action) => {
  const { type, payload } = action
  switch(type) {
    case BATCH_TRANSFER_ERROR_MESSAGE:
      return {
        ...state,
        errorMsg: payload.resultText || 'An error occurred'
      }
    case `${BATCH_TRANSFER}_FULFILLED`:
      if (payload.resultCode === "0") {

        return {
          ...state,
          batchUploadResult: payload.resultText
        }
      } else {
        return {
          ...state,
          errorMsg: payload.resultText
        }
      }
    case `${BATCH_TRANSFER}_PENDING`:
      return {
        ...state,
        errorMsg: ''
      }
    case `${BATCH_TRANSFER}_REJECTED`:
      return {
        ...state,
        errorMsg: 'Cannot connect to server'
      }
    case `${BATCH_TRANSFER}_RESET`:
      return {
        ...initialState
      }

    default:
      return state
  }
}
