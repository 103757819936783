import { apiCall } from './api'
import Config from '../config'

export const USER_DELETE = 'USER_DELETE'

export function userDelete(id) {
  const payload = apiCall('deleteUser', {deleteUserID: id})
  return {
    type: USER_DELETE,
    payload,
    meta: {
      id
    }
  }
}

export const USER_SEARCH = 'USER_SEARCH'

export function userSearch(keyword) {
  const payload = apiCall('searchUser', {keyword})
  return {
    type: USER_SEARCH,
    payload,
    meta: {
      keyword
    }
  }
}

export const USER_UNLOCK = 'USER_UNLOCK'

export function userUnlock(id) {
  const payload = apiCall('unlockUser', {unlockUserID: id})
  return {
    type: USER_UNLOCK,
    payload,
    meta: {
      id
    }
  }
}

export const RESEND_ACTIVATION = 'RESEND_ACTIVATION'

export function resendActivation(id) {
  const payload = apiCall('resendActivation', {resendActivationUserID: id})
  return {
    type: RESEND_ACTIVATION,
    payload,
    meta: {
      id
    }
  }
}

export const RESET_OTP = 'RESET_OTP'

export function resetOTP({reset2FAUserID}) {
  const reset2FAOwnerID = Config.ownerID
  const payload = apiCall('reset2FAEnrolment', {reset2FAUserID, reset2FAOwnerID})
  return {
    type: RESET_OTP,
    payload,
    meta: {
      reset2FAUserID, 
      reset2FAOwnerID
    }
  }
}

export const RESET_SUCCESS = 'RESET_SUCCESS'

export function resetSuccess() {
  return {
    type: RESET_SUCCESS
  }
}

export const RESET_ERROR = 'RESET_ERROR'

export function resetError() {
  return {
    type: RESET_ERROR
  }
}
