import { apiCall } from "./api";

export const GET_FEES_FROM_TERMINAL_ID = 'GET_FEES_FROM_TERMINAL_ID'

export function getFeeFromTerminalID() {
  const payload = apiCall('getFee',{})
  return {
    type: GET_FEES_FROM_TERMINAL_ID,
    payload
  }
} 
