import {ACTIVATE_USER,USER_GETACTIVATION_INFO,USER_GETACTIVATION_INFO_ERROR_MESSAGE} from '../action/activateUser'

const initialState = { 
	isValid:false,
	loading:true,
  errorMsg:'',
  activationInfoResponse:null
}

export default (state = initialState, action) => {
  const {type, payload} = action
  switch (type) {
    case USER_GETACTIVATION_INFO_ERROR_MESSAGE:
      return {
        ...state,
        errorMsg: 'An error occurred',
        activationInfoResponse: ''
      }
    case `${USER_GETACTIVATION_INFO}_FULFILLED`:
      if (payload.resultCode === "0") {
        const activationData=JSON.parse(payload.resultText.userActivationData);
        var errorMessage = '';
        if (!activationData.ISVALID) {
          if (activationData.ISEXPIRED) {
            errorMessage ='Your activation is expired. Please request administrator to resend activation';
          }
          else if(activationData.ISPASSWORDSET){
            errorMessage ='Account already active. Please try <a href="/login">login</a> with your username and password';
          } else {
            errorMessage ='Activation invalid';
          }
        }
        return {
          ...state,
          errorMsg:errorMessage ,
          loading:false,
          activationInfoResponse: activationData
        }
      } else {
        return {
          ...state,
          errorMsg: payload.resultText,
          activationInfoResponse: null,
          loading:false          
        }
      }
    case `${USER_GETACTIVATION_INFO}_PENDING`:
      return {
        ...state,
        errorMsg: '',
        loading:true,
        activationInfoResponse: null
      }
    case `${USER_GETACTIVATION_INFO}_REJECTED`:
      return {
        ...state,
        errorMsg: 'Cannot connect to server',
        activationInfoResponse:null
      }

    case `${ACTIVATE_USER}_FULFILLED`:
      
      return {
        ...state,
        transactions: payload.statement? [
          ...payload.statement
        ] : []
      }

    default:
      return state
  }
}
