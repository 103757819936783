import { connectRouter } from 'connected-react-router'
import { combineReducers } from 'redux'
import cardSearch from './cardSearch'
import userSearch from './userSearch'
import cardDetails from './cardDetails'
import merchant from './merchant'
import currentUser from './currentUser'
import userAdmin from './userAdmin'
import auth from './auth'
import api from './api'
import cardorder from './cardorder'
import virtualCardOrder from './virtualCardOrder'
import cardFee from './cardFee'
import failedTransactionSearch from './failedTransactionSearch'
import batchLoad from './batchLoad'
import batchTransfer from './batchTransfer'
import devalueCard from './devalueCard'
import stopCard from './stopCard'
import unstopCard from './unstopCard'
import getStopReasons from './getStopReasons'
import retireCard from './retireCard'
import report from './report'
import reportInfo from './report'
import userCampaigns from './userCampaigns'
import chargebackReport from './chargebackReport'
import batchFileVerification from './batchFileVerification'
import batchLoads from './batchLoads'
import batchDevalue from './batchDevalue'
import setCardStatus from './setCardStatus'
import fees from './fees'
import activateUser from './activateUser'
import setPassword from './setPassword'
import campaign from './campaign'
import forgotPassword from './forgotPassword'
import resetPassword from './resetPassword'
import generalUserDetails from './generalUserDetails'
import reverseTransaction from './reverseTransaction'
import cardNote from './cardNote'
import toggleMagstripe from './toggleMagstripe'
import {createBrowserHistory as createHistory} from "history";
import changePin from './changePin'
import virtualCardOrders from './virtualCardOrdersBatchLoads'

const nodeEnv = process.env.NODE_ENV || 'development'

const theme = process.env.REACT_APP_THEME || 'default'

const browserHistory =
  nodeEnv === 'production' ?
    createHistory({basename : `/${theme}`}) :
    createHistory()

const appReducer = combineReducers({
  // add more reducers here
  cardSearch,
  cardDetails,
  currentUser,
  userAdmin,
  userSearch,
  auth,
  merchant,
  api,
  router: connectRouter (browserHistory),
  cardorder,
  virtualCardOrder,
  cardFee,
  failedTransactionSearch,
  devalueCard,
  stopCard,
  unstopCard,
  getStopReasons,

  /*
  Report reducer is used under different names throughout the code, defining them here both. Should be fixed in future.
   */
  report,
  reportInfo,

  userCampaigns,
  retireCard,
  chargebackReport,
  batchFileVerification,
  batchLoad,
  batchTransfer,
  batchLoads,
  batchDevalue,
  setCardStatus,
  fees,
  activateUser,
  setPassword,
  forgotPassword,
  resetPassword,
  campaign,
  generalUserDetails,
  reverseTransaction,
  cardNote,
  toggleMagstripe,
  changePin,
  virtualCardOrders,
})

const rootReducer = (state, action) => {
  if (action.type === 'LOGOUT') {
    state = undefined
  }

  return appReducer(state, action)
}

export { rootReducer, browserHistory }
