export function asMoney(currencySymbol, amountStr, decimals = 2, commaChunkSize = 3) {
  let amount = parseFloat(amountStr)
  let moneyStr = currencyNumber(Math.abs(amount), decimals, commaChunkSize)
  if (currencySymbol) {
    moneyStr = `${currencySymbol}${moneyStr}`
  }

  if (amount < 0) {
    moneyStr = `-${moneyStr}`
  }

  return moneyStr
}

export function numericOnly(text) {
  if (!text)  {
    text = ''
  }
  return text.replace(/[^0-9]/g, '')
}

function currencyNumber(amount, decimals, commaChunkSize) {
  let re = '\\d(?=(\\d{' + (commaChunkSize || 3) + '})+' + (decimals > 0 ? '\\.' : '$') + ')'
  return amount.toFixed(Math.max(0, ~~decimals)).replace(new RegExp(re, 'g'), '$&,')
}

export function stableSort(array, cmp) {
  const stabilizedThis = array.map((el, index) => [el, index])
  stabilizedThis.sort((a, b) => {
    const order = cmp(a[0], b[0])
    if (order !== 0) {
      return order
    }
    return a[1] - b[1]
  });
  return stabilizedThis.map(el => el[0])
}


export function countDecimals (value) {
  if (Math.floor(value) === parseFloat(value)) {
    return 0
  }

  return value.toString().split(".")[1].length || 0;
}
