import React, { Component, Fragment } from 'react'

import NavHeading from './NavHeading'

class NavItems extends Component {
  renderItems = subItems => {
    const { navigate } = this.props
    return subItems.map((route, index) => {
      return (
        <NavHeading key={index} index={index} route={route} navigate={navigate} />
      ) 
    })
  }

  render() {
    const { items } = this.props
    return <Fragment>{this.renderItems(items)}</Fragment>
  }
}

export default NavItems
