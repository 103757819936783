import React, { Component } from 'react'
import { store } from '../../../store'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import Card from '@material-ui/core/Card'
import CardContent from '@material-ui/core/CardContent'
import { batchLoads, batchLoadsErrorMessage, batchLoadsReset, batchLoadsResetError, batchLoadReject, batchLoadClose, batchLoadApprove, batchLoadRetry, batchLoadDetails, batchLoadDetailsReset } from '../../../action/batchLoads'
import { BatchLoadsResults } from './BatchLoadsResults'
import BatchLoadDetails from './BatchLoadDetails'
import Notification from '../../default/layout/Notification'
import dateFormat from "dateformat"
import {DATE_RANGE_EXCEEDED, START_DATE_AFTER_END_DATE} from "../../../action/apiResponseCodes";

const msPerDay = 86400000
const defaultDaysBack = 56
//The below limit is also set in the Client Portal API. Make sure to change both.
const maxDaysBack = 56

const dateFilterValidationErrorMessages = {
  [DATE_RANGE_EXCEEDED]: `Maximum search timespan is ${maxDaysBack} days`,
  [START_DATE_AFTER_END_DATE]: 'The date filter start date is greater than the end date'
}

class BatchLoadsForm extends Component {

  constructor(props) {
    super(props)
    const currentDate = new Date()
    this.initialState = {
      endDate: dateFormat(currentDate, "yyyy-mm-dd"),
      startDate: dateFormat(new Date(currentDate.setTime( currentDate.getTime() - (defaultDaysBack * msPerDay) )), "yyyy-mm-dd")
    }
    this.state = {
      ...this.initialState,
      batchLoadsFilter: 1
    }
  }

  componentDidMount() {
    this.getBatchLoads()
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.checkRefresh(prevProps, prevState)) {
      this.getBatchLoads()
    }
  }

  checkRefresh(prevProps, prevState) {
    if(this.state.batchLoadsFilter !== prevState.batchLoadsFilter) {
      //make sure dates are not left empty when changing filter type
      if(this.state.startDate === '') {
        this.setState({startDate: this.initialState.startDate})
      }
      if(this.state.endDate === '') {
        this.setState({endDate: this.initialState.endDate})
      }
      return true
    }
    if((this.state.startDate !== '' && this.state.startDate !== prevState.startDate) || (this.state.endDate !== '' && this.state.endDate !== prevState.endDate)) {
      return true
    }

    return false
  }

  handleInputChange = (event) => {
    const stateKey = event.target.name
    this.setState({ [stateKey]: event.target.value })
  }

  getBatchLoads() {
    this.resetComponent()
    if ([1,5].includes(this.state.batchLoadsFilter)) {
      this.props.batchLoads(this.state.batchLoadsFilter)
    } else {
      this.props.batchLoads(this.state.batchLoadsFilter, this.state.startDate, this.state.endDate)
    }
  }

  resetComponent() {
    store.dispatch(batchLoadsReset())
  }

  resetError() {
    store.dispatch(batchLoadsResetError())
  }

  resetBatchLoadDetails() {
    store.dispatch(batchLoadDetailsReset())
  }

  render () {
    let { errorMsg, errorCode } = this.props
    let dateFilterValidationError = false

    if (this.props.batchLoadRejectResult || this.props.batchLoadCloseResult) {
      this.getBatchLoads()
    }

    const batchLoadApproveResult = (this.props.batchLoadApproveResult.PROCESSING) ? (
      <Notification
        type={'success'}
        message={'Batch load scheduled for processing'}
        open={ true }
        closed={() => this.getBatchLoads()}
      />
    ) : (
      null
    )

    const batchLoadRetryResult = (this.props.batchLoadRetryResult.PROCESSING) ? (
      <Notification
        type={'success'}
        message={'Batch load scheduled for re-processing'}
        open={ true }
        closed={() => this.getBatchLoads()}
      />
    ) : (
      null
    )

    const batchLoadScheduleResult = (this.props.batchLoadApproveResult.PROCESSDATE) ? (
      <Notification
        type={'success'}
        message={'Load scheduled on ' + this.props.batchLoadApproveResult.PROCESSDATE}
        open={ true }
        closed={() => this.getBatchLoads()}
      />
    ) : (
      null
    )

    if([DATE_RANGE_EXCEEDED,START_DATE_AFTER_END_DATE].includes(errorCode)) {
      errorMsg = dateFilterValidationErrorMessages[errorCode]
      dateFilterValidationError = true
    }
    const batchLoadsError = errorMsg ? (
      <Notification type={'error'} message={ errorMsg } open={ true } closed={() => this.resetError()} />
    ) : (
      null
    )

    const responseCard = this.props.batchLoadsResults ? (
        <BatchLoadsResults
          batchLoadsResults = { this.props.batchLoadsResults }
          batchLoadReject = { this.props.batchLoadReject }
          batchLoadClose = { this.props.batchLoadClose }
          batchLoadApprove = { this.props.batchLoadApprove }
          batchLoadRetry = { this.props.batchLoadRetry }
          batchLoadDetails = { this.props.batchLoadDetails }
          batchLoadsFilter = { this.state.batchLoadsFilter }
          handleInputChange = { this.handleInputChange }
          startDate = {this.state.startDate}
          endDate = {this.state.endDate}
          dateFilterHidden = {[1,5].includes(this.state.batchLoadsFilter)}
          dateFilterValidationError = {dateFilterValidationError}
          currentUserId = { this.props.user.userId }
          batchLoadsRefresh = {this.getBatchLoads.bind(this)}
        />
    ) : (
      null
    )

    return (
      <React.Fragment>
        { batchLoadsError }
        { batchLoadApproveResult }
        { batchLoadRetryResult }
        { batchLoadScheduleResult }
        <BatchLoadDetails
          batchLoadDetailsResults = { this.props.batchLoadDetailsResult }
          batchLoadDetailsMetadata = { this.props.batchLoadDetailsMetadata }
          open = { this.props.batchLoadDetailsResult.length > 0 }
          close = { this.resetBatchLoadDetails }
        />
        <Card style={{padding:'20px'}}>
          <CardContent>
            { responseCard }
          </CardContent>
        </Card>
      </React.Fragment>
    )
  }
}

BatchLoadsForm.propTypes = {
  BatchLoads: PropTypes.func,
  batchLoadReject: PropTypes.func,
  batchLoadClose: PropTypes.func,
  batchLoadApprove: PropTypes.func,
  batchLoadRetry: PropTypes.func,
  batchLoadDetails: PropTypes.func
}

const mapStateToProps = (state, props) => {
  return {
    apiCallPending: !!(state.api.GET_BATCH_LOADS && state.api.GET_BATCH_LOADS > 0),
    errorMsg: state.batchLoads.errorMsg,
    errorCode: state.batchLoads.errorCode,
    batchLoadsResults: state.batchLoads.batchLoadsResults || [],
    batchLoadRejectResult: state.batchLoads.batchLoadRejectResult || false,
    batchLoadCloseResult: state.batchLoads.batchLoadCloseResult || false,
    batchLoadApproveResult: state.batchLoads.batchLoadApproveResult || {},
    batchLoadRetryResult: state.batchLoads.batchLoadRetryResult || {},
    batchLoadDetailsResult: state.batchLoads.batchLoadDetailsResult || [],
    batchLoadDetailsMetadata: state.batchLoads.batchLoadDetailsMetadata || {},
    user: state.auth.user
  }
}

export default connect(
  mapStateToProps,
  {
    batchLoads,
    batchLoadsErrorMessage,
    batchLoadReject,
    batchLoadClose,
    batchLoadApprove,
    batchLoadRetry,
    batchLoadDetails
  }
)(BatchLoadsForm)
