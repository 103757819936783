import React, {Component} from 'react'
import Wrapper from '../../component/default/layout/Wrapper'
import CardSearchForm from '../../component/agoda/cardSearch/CardSearchForm'

class CardSearch extends Component {
  render () {
    return (
      <div>
        <Wrapper>
          <CardSearchForm />
        </Wrapper>
      </div>
    )
  }
}

export default CardSearch
