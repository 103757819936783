import { connect } from 'react-redux'

const PrivateSection = (props) => {
  const requiredPerms = props.permissions || []
  for (let perm of requiredPerms) {
    let [ resource, action ] = perm.split(".")
    let actions = props.userPerms[resource] || []
    if (actions.length === 0 || actions.findIndex((a) => a === action) === -1) {
      return null
    }
  }

  return props.children
}

export default connect((state, props) => ({userPerms: state.auth.perms}))(PrivateSection)
