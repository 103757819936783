import { apiCall } from './api'
import { store } from '../store'

export const BATCH_FILE_VERIFICATION = 'BATCH_FILE_VERIFICATION'

export function batchFileVerification(batchElements, identifierType, batchFileRow, fileName, batchSizeLimit = 0, additionalValidation = [], reference) {
  const payload = apiCall('batchFileVerification', {batchElements, identifierType, batchSizeLimit, additionalValidation, fileName, reference})

  return {
    type: BATCH_FILE_VERIFICATION,
    payload,
    meta: {
      batchElements,
      identifierType,
      batchFileRow,
      fileName,
      batchSizeLimit,
      additionalValidation
    }
  }
}

export const BATCH_FILE_VERIFICATION_ERROR_MESSAGE = 'BATCH_FILE_VERIFICATION_ERROR_MESSAGE'

export function batchFileVerificationErrorMessage (errorMessage = null) {
  return {
    type: BATCH_FILE_VERIFICATION_ERROR_MESSAGE,
    payload: { resultText: errorMessage }
  }
}

export function verifyBatchFile(acceptedFiles, rejectedFiles, identifierType, batchSizeLimit = 0, additionalValidation = [], reference) {
  acceptedFiles.forEach(file => {
    const reader = new FileReader()
    reader.onload = () => {
      const fileContent = reader.result
      let batchElements = []
      // split lines into array
      const fileLines = fileContent.split(/(\r\n|\n|\r)/gm)
      fileLines.forEach(function(row){
        const columns = row.split(',')
        if (columns.length === 2) {
          if (columns[1].length > 10) {
            batchElements.push({
              identifier: columns[0],
              identifierTo: columns[1],
              amount: 0
            })
          } else {
            batchElements.push({
              identifier: columns[0],
              amount: columns[1]
            })
          }
        } else if (columns.length === 3) {
          batchElements.push({
            identifier: columns[0],
            identifierTo: columns[1],
            amount: columns[2]
          })
        } else if (columns[0].length > 10 && columns.length === 1) {
          batchElements.push({
            identifier: columns[0],
            amount: 0
          })
        }
      })
      if (batchElements.length === 0) {
        store.dispatch(batchFileVerificationErrorMessage('Invalid file content'))
        return
      }
      store.dispatch(batchFileVerification(batchElements, identifierType, null, file.name, batchSizeLimit, additionalValidation, reference))
    }
    reader.onabort = () => store.dispatch(batchFileVerificationErrorMessage('File upload aborted'))
    reader.onerror = () => store.dispatch(batchFileVerificationErrorMessage('File upload failed'))

    reader.readAsBinaryString(file)
  })
}

export const BATCH_FILE_VERIFICATION_RESET = 'BATCH_FILE_VERIFICATION_RESET'

export function batchFileVerificationReset () {
  return {
    type: BATCH_FILE_VERIFICATION_RESET
  }
}
