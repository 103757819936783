import { apiCall } from './api' 

export const BATCH_LOADS = 'BATCH_LOADS'

export function batchLoads(filter, startDate = null, endDate = null) {
  const payload = apiCall('getBatchLoads', {filter, startDate, endDate})
  return {
    type: BATCH_LOADS,
    payload
  }
}

export const BATCH_LOAD_REJECT = 'BATCH_LOAD_REJECT'

export function batchLoadReject(batchLoadFileID) {
  const payload = apiCall('rejectBatchLoad', {batchLoadFileID})
  return {
    type: BATCH_LOAD_REJECT,
    payload
  }
}

export const BATCH_LOAD_CLOSE = 'BATCH_LOAD_CLOSE'

export function batchLoadClose(batchLoadFileID, closedComments) {
  const payload = apiCall('closeBatchLoad', {batchLoadFileID, closedComments})
  return {
    type: BATCH_LOAD_CLOSE,
    payload
  }
}

export const BATCH_LOAD_UPDATE_CLOSED_COMMENTS = 'BATCH_LOAD_UPDATE_CLOSED_COMMENTS'

export function batchLoadUpdateClosedComments(batchLoadFileID, closedComments) {
  const payload = apiCall('updateClosedBatchLoadComments', {batchLoadFileID, closedComments})
  return {
    type: BATCH_LOAD_UPDATE_CLOSED_COMMENTS,
    payload
  }
}

export const BATCH_LOAD_APPROVE = 'BATCH_LOAD_APPROVE'

export function batchLoadApprove(batchLoadFileID, processDate) {
  const payload = apiCall('approveBatchLoad', {batchLoadFileID, processDate})
  return {
    type: BATCH_LOAD_APPROVE,
    payload
  }
}

export const BATCH_LOAD_RETRY = 'BATCH_LOAD_RETRY'

export function batchLoadRetry(batchLoadFileID) {
  const payload = apiCall('retryBatchLoad', {batchLoadFileID})
  return {
    type: BATCH_LOAD_RETRY,
    payload
  }
}

export const BATCH_LOAD_DETAILS = 'BATCH_LOAD_DETAILS'

export function batchLoadDetails(batchLoadFile) {
  const payload = apiCall('batchLoadDetails', {batchLoadFileID: batchLoadFile.BATCHLOADFILEID})
  return {
    type: BATCH_LOAD_DETAILS,
    payload,
    meta: {
      batchLoadFile
    }
  }
}

export const BATCH_LOAD_DETAILS_RESET = 'BATCH_LOAD_DETAILS_RESET'

export function batchLoadDetailsReset() {
  return {
    type: BATCH_LOAD_DETAILS_RESET
  }
}

export const DOWNLOAD_BATCH_LOAD_REPORT = 'DOWNLOAD_BATCH_LOAD_REPORT'

export function downloadBatchLoadReport(batchLoadFileID) {
  const payload = apiCall('downloadBatchLoadReport', {batchLoadFileID})
  return {
    type: DOWNLOAD_BATCH_LOAD_REPORT,
    payload
  }
}

export const DOWNLOAD_BATCH_LOAD_REPORT_RESET = 'DOWNLOAD_BATCH_LOAD_REPORT_RESET'

export function downloadBatchLoadReportReset() {
  return {
    type: DOWNLOAD_BATCH_LOAD_REPORT_RESET
  }
}

export const DOWNLOAD_BATCH_LOAD_FILE = 'DOWNLOAD_BATCH_LOAD_FILE'

export function downloadBatchLoadFile(batchLoadFileID) {
  const payload = apiCall('downloadBatchLoadFile', {batchLoadFileID})
  return {
    type: DOWNLOAD_BATCH_LOAD_FILE,
    payload
  }
}

export const DOWNLOAD_BATCH_LOAD_FILE_RESET = 'DOWNLOAD_BATCH_LOAD_FILE_RESET'

export function downloadBatchLoadFileReset() {
  return {
    type: DOWNLOAD_BATCH_LOAD_FILE_RESET
  }
}

export const BATCH_LOADS_ERROR_MESSAGE = 'BATCH_LOADS_ERROR_MESSAGE'

export function batchLoadsErrorMessage (errorMessage = null) {
  return {
    type: BATCH_LOADS_ERROR_MESSAGE,
    payload: { resultText: errorMessage }
  }
}

export const BATCH_LOADS_RESET = 'BATCH_LOADS_RESET'

export function batchLoadsReset () {
  return {
    type: BATCH_LOADS_RESET
  }
}

export const BATCH_LOAD_RESET_ERROR = 'BATCH_LOAD_RESET_ERROR'

export function batchLoadsResetError () {
  return {
    type: BATCH_LOAD_RESET_ERROR
  }
}
