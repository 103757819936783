import React from 'react'
import Table from '@material-ui/core/Table'
import TableHead from '@material-ui/core/TableHead'
import TableBody from '@material-ui/core/TableBody'
import TableRow from '@material-ui/core/TableRow'
import TableCell from '@material-ui/core/TableCell'
import Dialog from "@material-ui/core/Dialog"
import DialogTitle from "@material-ui/core/DialogTitle"
import DialogContent from "@material-ui/core/DialogContent"
import DialogActions from "@material-ui/core/DialogActions"
import Button from "@material-ui/core/Button"

const SettlementChargebacksResults = (props) => {
  const details =
    props.chargebacks.map((row,i) =>
         (
          <TableRow key={i}>
            <TableCell style={{textAlign: 'right'}}>{row.ChargebackAmount}</TableCell>
            <TableCell>{row.ChargebackCurrency}</TableCell>
            <TableCell style={{textAlign: 'right'}}>{row.ChargebackRequestAmount}</TableCell>
            <TableCell>{row.CardCurrency}</TableCell>
            <TableCell>{row.ChargebackRequestedDate}</TableCell>
            <TableCell>{row.ChargebackProcessedDate}</TableCell>
            <TableCell>{row.ChargebackDescription}</TableCell>
            <TableCell>{row.ChargebackNumber}</TableCell>
            <TableCell>{row.ChargebackRejected === 1 ? 'Rejected' : ''}</TableCell>
            <TableCell>{row.ChargebackRejectedDate}</TableCell>
            <TableCell>{row.ChargebackReversed === 1 ? 'Reversed' : ''}</TableCell>
            <TableCell>{row.ChargebackReversedDate}</TableCell>
            <TableCell>{row.SecondPresentmentStatus === 1 ? 'Received' : ''}</TableCell>
          </TableRow>
        )
    )

  return (
    <Dialog maxWidth open={props.isOpen} onClose={props.close} aria-labelledby="form-dialog-title">
      <DialogTitle id="form-dialog-title">Chargebacks</DialogTitle>
      <DialogContent>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Amount</TableCell>
              <TableCell>Currency</TableCell>
              <TableCell>Requested Amount (Card Currency)</TableCell>
              <TableCell>Card Currency</TableCell>
              <TableCell>Date Requested</TableCell>
              <TableCell>Date Processed</TableCell>
              <TableCell>Description</TableCell>
              <TableCell>Number</TableCell>
              <TableCell>Rejected</TableCell>
              <TableCell>Rejected Date</TableCell>
              <TableCell>Reversed</TableCell>
              <TableCell>Reversed Date</TableCell>
              <TableCell>Second Presentment Status</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {details}
          </TableBody>
        </Table>
      </DialogContent>
      <DialogActions>
        <Button onClick={props.close} color="primary">
          Close
        </Button>
      </DialogActions>
    </Dialog>

  )
}

export default SettlementChargebacksResults
