import React from 'react'
import { connect } from 'react-redux'
import { Redirect } from 'react-router'
import { logOut } from '../../action/auth'

const Logout = (props) => {
  props.logOut() 
  return <Redirect to="/login"/>
}

export default connect(null, { logOut })(Logout)
