import React, {Component} from 'react'
import Wrapper from '../../component/default/layout/Wrapper'
import BatchLoadsForm from '../../component/agoda/batchLoads/BatchLoadsForm'

class BatchLoads extends Component {
  render () {
    return (
      <div>
        <Wrapper>
          <BatchLoadsForm />
        </Wrapper>
      </div>
    )
  }
}

export default BatchLoads
