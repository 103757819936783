import React, { Component } from 'react'
import Wrapper from '../../../component/default/layout/Wrapper'
import { connect } from 'react-redux'
import { Button } from '@material-ui/core'
import {DailyReportHeader} from './DailyReportHeader'
import {getDailyReport} from "../../../action/report";
import download from 'downloadjs'
import Notification from '../../../component/default/layout/Notification'

class DailySettlementSummary extends Component{
  state = {
    date: '',
    showNotFound : false
  }

  onDateChangeHandler = e => {
    this.setState({date:e.target.value})
  }

  reportExists = (url) => {
    var http = new XMLHttpRequest();
    http.open('HEAD', url, false);
    http.send();
    return http.status !== 404;
  }

  onClickHandler = () => {
    this.props.loadReport(this.state.date, "summarySettlementReport" )
  }

  componentWillReceiveProps(nextProps) {
    if ((nextProps.url !== '') && ( typeof(nextProps.url) !== 'undefined' )) {
      if (this.reportExists(nextProps.url)){
        download(nextProps.url)
      } else {
        this.setState({showNotFound: true})
      }
    }
  }

  render () {
    return (
      <div>
        <Notification
          type={'error'}
          message={'Report not found'}
          open={this.state.showNotFound}
          closed={() => this.setState({showNotFound: false})}
        />

        <Wrapper>
          <DailyReportHeader
            name={"Summary Daily Settlement Report"}
            showDate={this.state.date}
            dateChanged={this.onDateChangeHandler}
            manualAction = {<Button className="flattbutton-login" color="primary" size="medium" variant="contained" onClick={this.onClickHandler} disabled={this.state.date === ''}>Download</Button>}
          />
        </Wrapper>
      </div>
    )
  }
}

const mapStateToProps = (state, props) => ({
  url: state.reportInfo.reportURL
})
const mapDispatchToProps = {
  loadReport: getDailyReport
}

export default connect( mapStateToProps, mapDispatchToProps)(DailySettlementSummary)

