import { apiCall } from './api'
import { store } from '../store'

export const SUBMIT_CARD_ORDER = 'SUBMIT_CARD_ORDER'
export const submitCardOrder = ({campaignId, quotationNumber, cardOrder, cardOrderFee, designid, fileName, plasticid
}) => {
  const payload = apiCall('batchCardOrder', {
    campaignId,
    quotationNumber,
    batchElements: constructBatchElement(cardOrder, cardOrderFee, designid, plasticid),
    fileName
  })
  return {
    type: SUBMIT_CARD_ORDER,
    payload,
    meta: {
      campaignId, quotationNumber, cardOrder, cardOrderFee, designid, fileName, plasticid
    }
  }
}

const constructBatchElement = (cardOrder, cardOrderFee, designid, plasticid) => cardOrder.map( card => {
  const singleCardOrderFee = (parseInt(card.cardOrderFee, 10) || 0) * 100;

  return ({
    title: '',
    name: card.name,
    surname: card.surname,
    recipientName: card.recipientName,
    address1: card.address1,
    address2: card.address2,
    address3: card.postalCode,
    address4: '',
    address5: '',
    email: '',
    plasticID: plasticid,
    cardDesignID: designid,
    cellPhoneNumber: card.recipientNumber,
    loadAmount: card.loadAmount * 100,
    feeAmount: singleCardOrderFee,
    sourceOfFunds: '',
    additionalData: card.customerReference
  })
})

export const RESET_SUCCESS = 'RESET_SUCCESS'

export const resetSuccess = () => ({type: RESET_SUCCESS})

export const RESET_CARD_ORDER = 'RESET_CARD_ORDER'
export const resetCardOrder = () => ({type: RESET_CARD_ORDER})

export const verifyCardOrderFile = (files, campaignID) => {

  const fileBlob = files[0]
  
  var reader = new FileReader()

  reader.onload = function() {

    const fileContent = reader.result
    let orderBatch = []

    let cardOrders = fileContent.split(/\r\n|\n/)

    cardOrders.forEach(function (row) {
      //Only process lines that aren't empty
      if (row.length > 0) {
        let cardOrder = row.split(',')
        console.log('cardOrder', cardOrder)
        let cardOrderObject = {
          "name": cardOrder[0],
          "surname": cardOrder[1],
          "customerReference": cardOrder[2],
          "loadAmount": parseFloat(cardOrder[3] ? cardOrder[3] : 0),
          "cardOrderFee": parseFloat(cardOrder[4] ? cardOrder[4] : 0),
          "recipientName": cardOrder[5].substring(0, 40),
          "recipientNumber": cardOrder[6],
          "address1": cardOrder[7].substring(0, 40),
          "address2": cardOrder[8].substring(0, 40),
          "postalCode": cardOrder[9].substring(0, 40),
        }

        orderBatch.push(cardOrderObject)
      }
    })

    store.dispatch(cardOrderVerification(orderBatch, campaignID))
  }

  reader.onabort = () => store.dispatch(cardOrderVerificationErrorMessage('File upload aborted'))
  reader.onerror = () => store.dispatch(cardOrderVerificationErrorMessage('File upload failed'))

  reader.readAsBinaryString(fileBlob) 
}

export const CARD_ORDER_VERIFICATION = 'CARD_ORDER_VERIFICATION'
export const cardOrderVerification = (cardOrders, campaignID) => {
  cardOrders = cardOrders.map((order) => {
    delete order.ERROR
    return order
  })
  return {
    type: CARD_ORDER_VERIFICATION,
    payload: apiCall('verifyCardOrder', {cardOrders, campaignID}),
    meta: {
      cardOrders,
      campaignID
    }
  }
}

export const CARD_ORDER_VERIFICATION_ERROR_MESSAGE = 'CARD_ORDER_VERIFICATION_ERROR_MESSAGE'

export const cardOrderVerificationErrorMessage = (errorMessage = null) => {
  return {
    type: CARD_ORDER_VERIFICATION_ERROR_MESSAGE,
    payload: {resultText : errorMessage}
  }
}
