import {ADD_USER, USER_INFO, USER_ADMIN_SAVE, RESET_USER_CREATED, DISMISS_USER_CREATED_ERROR,DISMISS_USER_CREATED_CONFIRMATION} from '../action/userAdmin'

const initialState = {
  errorMsg: '',
  user: {},
  created: false,
  confirmationOpen: false
}

export default (state = initialState, action) => {
  const {type, payload} = action
  switch (type) {
    case `${USER_INFO}_FULFILLED`:
      if (payload.resultCode === "0") {

        const groupId = (payload.groups) && (payload.groups[0]) && (payload.groups[0].id) ? payload.groups[0].id : null

        return {
          ...state,
          errorMsg: '',
          user: {...payload.user, groupId: groupId}
        }
      } else {
        return {
          ...state,
          errorMsg: payload.resultText
        }
      }
    case `${USER_INFO}_REJECTED`:
      return {
        ...state,
        errorMsg: 'Cannot connect to server'
      }
    case `${ADD_USER}_FULFILLED`:
      if (payload.resultCode === "0") {
        return {
          ...state,
          errorMsg: '',
          created: true
        }
      } else {
        return {
          ...state,
          errorMsg: payload.resultText
        }
      }
    case `${USER_ADMIN_SAVE}_FULFILLED`:
      if (payload.resultCode === "0") {
        return {
          ...state,
          user: action.meta.user,
          confirmationOpen: true,
          errorMsg: ''
        }
      } else {
        return {
          ...state,
          user: action.meta.user,
          errorMsg: payload.resultText
        }
      }
    case RESET_USER_CREATED:
      return {
        ...state,
        created: false
      }
    case DISMISS_USER_CREATED_ERROR:
      return {
        ...state,
        errorMsg: ''
      }
    case DISMISS_USER_CREATED_CONFIRMATION:
      return {
        ...state,
        confirmationOpen: false
      }
    default:
      return state
  }
}
