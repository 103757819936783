import Paper from '@material-ui/core/Paper'
import TextField from '@material-ui/core/TextField'
import Button from '@material-ui/core/Button'
import { withStyles } from '@material-ui/core/styles';
import {Link} from 'react-router-dom'
import Typography from '@material-ui/core/Typography'
import React from 'react'

const styles = theme => ({
  button: {
    margin: theme.spacing(),
  },
});

const ForgotPasswordForm = (props) => {
    const form = (!props.error) ?
      <React.Fragment>
        <Typography style={{marginTop: 20, textAlign: 'left' }} variant="subtitle1" gutterBottom>Please enter your username or e-mail address to reset your password .</Typography>
        <TextField
          name="account"
          label="Username or email"
          inputProps={{
            maxLength: 100
          }}
          fullWidth={true}
          onChange={props.changed}
          value={props.account}
          autoComplete="false"
          style={{borderBottom:'0.1px solid'}}
        />
        <div style={{marginTop: 20}}>
          <Link to='/login' style={{textDecoration: 'none'}}>
           <Button className={props.classes.button} size="large" variant="contained"
                   onClick={props.canceled}>Cancel</Button>
          </Link>
          <Button className={props.classes.button} size="large" variant="contained" color="primary"
                  onClick={props.submitted} disabled={props.canSubmit}>Next</Button>
        </div>
      </React.Fragment>
    :
      <React.Fragment>
        <Typography style={{marginTop: 40, textAlign: 'left' }} variant="subtitle1" gutterBottom>{props.error}</Typography>
        <Link to='/login' style={{textDecoration: 'none'}} >
          <Button className={props.classes.button} size="large" color="primary" variant="contained" onClick={props.canceled}>Accept</Button>
        </Link>
      </React.Fragment>

  return (
      <Paper style={props.paperStyle} zdepth={4}>
        {form}
      </Paper>
    )
}

export default withStyles(styles)(ForgotPasswordForm)
