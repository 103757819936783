import React, { Component } from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import { Card, CardContent } from '@material-ui/core'
import dateFormat from 'dateformat'
import download from 'downloadjs'

import {
  DateFilter,
  ForceRefresh,
  Header,
  ResultTable,
  StatusSelector,
} from '../../component/agoda/virtualCardOrdersBatchLoads'
import Wrapper from '../../component/default/layout/Wrapper'
import {
  fetchVirtualCardOrdersBatchLoads,
  approveVirtualCardOrdersBatchLoad,
  rejectVirtualCardOrdersBatchLoad,
  fetchVirtualCardOrdersBatchLoadDetails,
  downloadVirtualCardOrdersBatchLoadDetailsReport,
  setVirtualCardOrdersBatchLoadError,
  clearVirtualCardOrdersBatchLoadError,
} from '../../action/virtualCardOrdersBatchLoads'
import { calculateDifferenceInDays } from '../../util/misc'
import Notification from '../../component/default/layout/Notification'
import VirtualCardOrdersDetails from '../../component/agoda/virtualCardOrdersBatchLoads/VirtualCardOrdersDetails'
import b64toBlob from '../../util/b64toBlob'
import { FILTER_STATE } from '../../constants/virtualCardOrder'

export const msPerDay = 86400000
export const defaultDaysBack = 56
export const maxDaysBack = 56

export const DATE_RANGE_EXCEEDED = `Maximum search timespan is ${maxDaysBack} days`
export const APPROVAL_MESSAGE = 'The virtual card order has been approved successfully'
export const REJECTION_MESSAGE = 'The virtual card order has been rejected successfully'
export const NO_VIRTUAL_BATCH_LOAD_FOUND_ERROR_MESSAGE = 'No virtual batch load orders found'

const downloadExcelData = (excelBase64) => {
  const mimeType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
  const binaryExcelData = b64toBlob(excelBase64)
  const fileName = `virtual_card_orders_batch_load_details_report_${dateFormat(new Date(), 'yyyy-mm-dd')}.xlsx`
  download(binaryExcelData, fileName, mimeType)
}

export class VirtualCardOrdersBatchLoads extends Component {
  constructor(...args) {
    super(...args)

    const currentDate = new Date()
    this.state = {
      filter: 1,
      startDate: dateFormat(new Date(currentDate.getTime() - defaultDaysBack * msPerDay), 'yyyy-mm-dd'),
      endDate: dateFormat(currentDate, 'yyyy-mm-dd'),
      dateRangeExceeded: false,
      selectedVirtualCardOrdersBatchLoadId: null,
      visible: false,
    }
  }

  componentDidMount = () => {
    this.fetchVirtualCardOrdersBatchLoads()
  }

  componentDidUpdate = (prevProps, prevState) => {
    if (this.state.filter !== prevState.filter) {
      this.fetchVirtualCardOrdersBatchLoads()
    }
    if (this.props.virtualCardOrdersBatchLoadDetails !== prevProps.virtualCardOrdersBatchLoadDetails) {
      this.setState({ visible: true })
    }

    if (this.props.virtualCardOrdersBatchLoadDetailsReport && !prevProps.virtualCardOrdersBatchLoadDetailsReport) {
      downloadExcelData(this.props.virtualCardOrdersBatchLoadDetailsReport)
    }
  }

  fetchVirtualCardOrdersBatchLoads = () => {
    const { filter, startDate, endDate } = this.state
    this.props.fetchVirtualCardOrdersBatchLoads({
      filter,
      startDate,
      endDate,
    })
  }

  handleOnFilterStatusChange = (filter) => this.setState({ filter })

  handleOnDatePeriodChange = ({ startDate, endDate }) => {
    const dateRangeExceeded = calculateDifferenceInDays(startDate, endDate) > maxDaysBack
    this.setState({ startDate, endDate, dateRangeExceeded }, () => {
      if (calculateDifferenceInDays(startDate, endDate) > maxDaysBack) {
        this.props.setVirtualCardOrdersBatchLoadError(DATE_RANGE_EXCEEDED)
      } else {
        this.resetError()
        this.fetchVirtualCardOrdersBatchLoads()
      }
    })
  }

  resetError() {
    this.props.clearVirtualCardOrdersBatchLoadError()
  }

  render() {
    const { filter, startDate, endDate, dateRangeExceeded, visible, selectedVirtualCardOrdersBatchLoadId } = this.state

    const {
      virtualCardOrdersBatchLoads,
      virtualCardOrdersBatchLoadDetails,
      approvedSuccessfully,
      rejectedSuccessfully,
      currentUser,
      errorMsg,
      fetchVirtualCardOrdersBatchLoadDetails,
      approveVirtualCardOrdersBatchLoad,
      rejectVirtualCardOrdersBatchLoad,
      downloadVirtualCardOrdersBatchLoadDetailsReport,
    } = this.props

    return (
      <>
        <div data-testid="content">
          <Wrapper>
            <Card style={{ padding: '20px' }}>
              <CardContent>
                <Header />
                <StatusSelector value={filter} onChange={this.handleOnFilterStatusChange} />
                <ForceRefresh onClick={this.fetchVirtualCardOrdersBatchLoads} />
                <DateFilter
                  startDate={startDate}
                  endDate={endDate}
                  hasError={dateRangeExceeded}
                  onChange={this.handleOnDatePeriodChange}
                />
                <ResultTable
                  virtualCardOrdersBatchLoads={virtualCardOrdersBatchLoads}
                  showApprovedBy={filter === FILTER_STATE.APPROVED}
                  showRejectedBy={filter === FILTER_STATE.REJECTED}
                  onShowDetails={(virtualCardOrdersBatchLoadId) => {
                    this.setState({
                      selectedVirtualCardOrdersBatchLoadId: virtualCardOrdersBatchLoadId,
                    })
                    fetchVirtualCardOrdersBatchLoadDetails(virtualCardOrdersBatchLoadId)
                  }}
                  onApprove={approveVirtualCardOrdersBatchLoad}
                  onReject={rejectVirtualCardOrdersBatchLoad}
                  currentUser={currentUser}
                />
              </CardContent>
            </Card>
          </Wrapper>
        </div>
        {errorMsg && <Notification type="error" message={errorMsg} open closed={() => this.resetError()} />}
        {approvedSuccessfully && (
          <Notification
            type={'success'}
            message={APPROVAL_MESSAGE}
            open
            closed={() => this.fetchVirtualCardOrdersBatchLoads()}
          />
        )}
        {rejectedSuccessfully && (
          <Notification
            type={'success'}
            message={REJECTION_MESSAGE}
            open
            closed={() => this.fetchVirtualCardOrdersBatchLoads()}
          />
        )}
        {visible && (
          <VirtualCardOrdersDetails
            virtualCardsOrders={virtualCardOrdersBatchLoadDetails}
            onDownloadReport={() => {
              downloadVirtualCardOrdersBatchLoadDetailsReport(selectedVirtualCardOrdersBatchLoadId)
            }}
            onClose={() => this.setState({ visible: false })}
          />
        )}
      </>
    )
  }
}

VirtualCardOrdersBatchLoads.propTypes = {
  virtualCardOrdersBatchLoads: PropTypes.array,
  virtualCardOrdersBatchLoadDetails: PropTypes.array,
  virtualCardOrdersBatchLoadDetailsReport: PropTypes.string,
  errorMsg: PropTypes.string,
  approvedSuccessfully: PropTypes.bool,
  rejectedSuccessfully: PropTypes.bool,
  currentUser: PropTypes.object,

  fetchVirtualCardOrdersBatchLoads: PropTypes.func,
  approveVirtualCardOrdersBatchLoad: PropTypes.func,
  rejectVirtualCardOrdersBatchLoad: PropTypes.func,
  fetchVirtualCardOrdersBatchLoadDetails: PropTypes.func,
  downloadVirtualCardOrdersBatchLoadDetailsReport: PropTypes.func,

  setVirtualCardOrdersBatchLoadError: PropTypes.func,
  clearVirtualCardOrdersBatchLoadError: PropTypes.func,
}

const mapStateToProps = (state) => {
  return {
    virtualCardOrdersBatchLoads: state.virtualCardOrders.virtualCardOrdersBatchLoads,
    virtualCardOrdersBatchLoadDetails: state.virtualCardOrders.virtualCardOrdersBatchLoadDetails,
    virtualCardOrdersBatchLoadDetailsReport: state.virtualCardOrders.virtualCardOrdersBatchLoadDetailsReport,
    errorMsg: state.virtualCardOrders.errorMsg || '',
    approvedSuccessfully: state.virtualCardOrders.approvedSuccessfully,
    rejectedSuccessfully: state.virtualCardOrders.rejectedSuccessfully,
    currentUser: state.auth.user,
  }
}

const mapActionsToProps = {
  fetchVirtualCardOrdersBatchLoads,
  approveVirtualCardOrdersBatchLoad,
  rejectVirtualCardOrdersBatchLoad,
  fetchVirtualCardOrdersBatchLoadDetails,
  downloadVirtualCardOrdersBatchLoadDetailsReport,
  setVirtualCardOrdersBatchLoadError,
  clearVirtualCardOrdersBatchLoadError,
}

export default connect(mapStateToProps, mapActionsToProps)(VirtualCardOrdersBatchLoads)
