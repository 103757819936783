import React, { Component } from 'react'
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Popover from '@material-ui/core/Popover';
import Icon from '@material-ui/core/Icon';
import IconButton from '@material-ui/core/IconButton'

const styles = theme => ({
  typography: {
    margin: theme.spacing(2),
  },
});

class BatchLoadsStatusPopover extends Component {
  state = {
    anchorEl: null,
  };

  handleClick = event => {
    this.setState({
      anchorEl: event.currentTarget,
    });
  };

  handleClose = () => {
    this.setState({
      anchorEl: null,
    });
  };

  render() {
    const { classes, status } = this.props;
    const { anchorEl } = this.state;

    let statusIcon, disabled

    if (status) {
      statusIcon = (<Icon>check</Icon>)
      disabled = false
    } else {
      statusIcon = (<Icon>close</Icon>)
      disabled = true
    }

    const scheduledDate = (this.props.scheduledDate) ? true : false

    return (
      <div>
        <IconButton onClick={this.handleClick} disabled={ disabled } aria-label="Details" >
            {statusIcon}
        </IconButton>
        <Popover
          open={Boolean(anchorEl)}
          anchorEl={anchorEl}
          onClose={this.handleClose}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'center',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'center',
          }}
        >
          <Typography className={classes.typography}>
            {this.props.type} by: { this.props.firstName } { this.props.lastName }
            <br />
            {this.props.type} on: { this.props.statusDate }
            <br />
            {(scheduledDate) ? (
              ( "Scheduled on: " +this.props.scheduledDate)
            ) : null}
          </Typography>
        </Popover>
      </div>
    );
  }
}

BatchLoadsStatusPopover.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(BatchLoadsStatusPopover);