import React, { Component } from 'react'
import { ListItem, ListItemText, Collapse } from '@material-ui/core'
import { ExpandLess, ExpandMore} from '@material-ui/icons'
import NavChildren from './NavChildren'

class NavHeading extends Component {

  constructor(props) {
    super(props)
    this.initialState = {
      open: false
    }
    this.state = {
      ...this.initialState
    }
  }
  
  render() {
    const { route, index, navigate } = this.props
    
    const headerClasses = [
      `menuitem-pad-${route.depth}`,
      'menuitem-heading',
      'menutext'
    ]

    const handleHeadingClick = () => {
      this.setState({ open : !this.state.open })
      navigate(route.path)
    }

    const adaptedMenuHeaderItem = 
      <ListItem
        className={headerClasses.join(' ')}
        activeclassname="menuitem-active"
        button
        key={route.depth * 10 + index}
        onClick={ handleHeadingClick }
        data-testid={route.testid ?? null}
      >
        <ListItemText primary={route.displayName} disableTypography={true} />
        {route.children.length ? this.state.open ? <ExpandLess /> : <ExpandMore /> : null}
      </ListItem>

    return (
      <div>
        { adaptedMenuHeaderItem }
        <Collapse in={this.state.open} timeout="auto" unmountOnExit>
          <NavChildren key={index} route={route.children} navigate={navigate} />
        </Collapse>
      </div>
      
    )
  }
}

export default NavHeading
