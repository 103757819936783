import React from 'react'
import PropTypes from 'prop-types'
import { Icon, IconButton, TableCell, TableRow, Tooltip } from '@material-ui/core'

import BatchLoadsStatusPopover from '../batchLoads/BatchLoadsStatusPopover'
import { doSafeDateFormat } from '../../../util/misc'
import { BUTTON_TOOLTIP_DELAY } from '../../../constants/virtualCardOrder'

const isApprovedOrRejected = (virtualCardOrder) => virtualCardOrder.isApproved || virtualCardOrder.isRejected

const ResultTableRow = ({
  virtualCardOrder,
  onShowDetails,
  onApprove,
  onReject,
  approvable,
  showApprovedBy,
  showRejectedBy,
}) => {
  return (
    <TableRow>
      <TableCell>{virtualCardOrder.campaignName}</TableCell>
      <TableCell>{doSafeDateFormat(virtualCardOrder.uploadDate, 'yyyy-mm-dd HH:MM:ss')}</TableCell>
      <TableCell>
        {virtualCardOrder.uploadUserFirstName} {virtualCardOrder.uploadUserLastName}
      </TableCell>
      {showApprovedBy && (
        <>
          <TableCell>
            {virtualCardOrder.approvalUserFirstName} {virtualCardOrder.approvalUserLastName}
          </TableCell>
          <TableCell>{doSafeDateFormat(virtualCardOrder.approvalDate, 'yyyy-mm-dd HH:MM:ss')}</TableCell>
        </>
      )}
      {showRejectedBy && (
        <>
          <TableCell>
            {virtualCardOrder.rejectUserFirstName} {virtualCardOrder.rejectUserLastName}
          </TableCell>
          <TableCell>{doSafeDateFormat(virtualCardOrder.rejectDate, 'yyyy-mm-dd HH:MM:ss')}</TableCell>
        </>
      )}
      {!showApprovedBy && !showRejectedBy && (
        <TableCell>
          <BatchLoadsStatusPopover
            type="Approved"
            status={virtualCardOrder.isApproved}
            firstName={virtualCardOrder.approvalUserFirstName}
            lastName={virtualCardOrder.approvalUserLastName}
            statusDate={doSafeDateFormat(virtualCardOrder.approvalDate, 'yyyy-mm-dd HH:MM:ss')}
          />
        </TableCell>
      )}
      <TableCell>{virtualCardOrder.cardsCount}</TableCell>
      <TableCell>
        {!isApprovedOrRejected(virtualCardOrder) && approvable ? (
          <>
            <Tooltip title="Approve virtual card order" enterDelay={BUTTON_TOOLTIP_DELAY}>
              <span>
                <IconButton onClick={onApprove} aria-label="Approve" data-testid="vco-approve-button">
                  <Icon>check</Icon>
                </IconButton>
              </span>
            </Tooltip>
            <Tooltip title="Reject virtual card order" enterDelay={BUTTON_TOOLTIP_DELAY}>
              <span>
                <IconButton onClick={onReject} aria-label="Reject" data-testid="vco-reject-button">
                  <Icon>clear</Icon>
                </IconButton>
              </span>
            </Tooltip>
          </>
        ) : (
          <span style={{ marginLeft: '85.68px' }}></span>
        )}

        <Tooltip title="Show batch load details" enterDelay={BUTTON_TOOLTIP_DELAY}>
          <IconButton onClick={onShowDetails} aria-label="Details" data-testid="vco-show-details-button">
            <Icon>list</Icon>
          </IconButton>
        </Tooltip>
      </TableCell>
    </TableRow>
  )
}

ResultTableRow.propTypes = {
  virtualCardOrder: PropTypes.object,
  onShowDetails: PropTypes.func,
  onApprove: PropTypes.func,
  onReject: PropTypes.func,
  approvable: PropTypes.bool,
  showApprovedBy: PropTypes.bool,
  showRejectedBy: PropTypes.bool,
}

export default ResultTableRow
