import React from 'react'
import Wrapper from '../../../component/default/layout/Wrapper'
import CSVReport from '../../../component/agoda/reports/CSVReport/CSVReport'
import transform from '../../../component/agoda/reports/CSVReport/transform'

const CardholderStatement = () => <div>
  <Wrapper>
    <CSVReport 
      type="cardholderStatement" 
      reportHeader ="Cardholder Statement"
      transform = {
        { "TransactionAmount" : transform.centToMoney }
      }
    />
  </Wrapper>
</div>

export default CardholderStatement
