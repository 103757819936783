import React, { Component } from 'react'
import PropTypes from 'prop-types';
import { store } from '../../../store'
import { connect } from 'react-redux'
import Card from '@material-ui/core/Card'
import CardContent from '@material-ui/core/CardContent'
import CardActions from '@material-ui/core/CardActions'
import Typography from '@material-ui/core/Typography'
import Button from '@material-ui/core/Button'
import TextField from '@material-ui/core/TextField'
import InputLabel from '@material-ui/core/InputLabel'
import Select from '@material-ui/core/Select'
import Stepper from '@material-ui/core/Stepper'
import Step from '@material-ui/core/Step'
import StepLabel from '@material-ui/core/StepLabel'
import Dropzone from 'react-dropzone'
import Table from '@material-ui/core/Table'
import TableHead from '@material-ui/core/TableHead'
import TableBody from '@material-ui/core/TableBody'
import TableRow from '@material-ui/core/TableRow'
import TableCell from '@material-ui/core/TableCell'
import Collapse from '@material-ui/core/Collapse'
import Modal from '@material-ui/core/Modal'
import plusImage from '../../../images/agoda/dropzone.png'
import { withStyles } from '@material-ui/core/styles'
import {
  submitVirtualCardOrder,
  resetVirtualSuccess,
  getVirtualCampaigns,
  verifyVirtualCardOrderFile,
  resetVirtualCardOrder } from '../../../action'
import {
  virtualCardOrderVerification,
  virtualCardOrderVerificationErrorMessage
} from '../../../action/virtualCardOrder'
import DeleteIcon from '@material-ui/icons/Delete'
import SuccessModal from './SuccessModal'
// import { red } from '@material-ui/core/colors'
import Notification from "../../default/layout/Notification";
import batchSample from '../../../resources/order-virtual-card-sample.csv'
import DownloadSample from '../../../container/agoda/BatchSampleDownload'

import {getModalStyle, styles} from './CardOrderForm'

const initialSingleVirtualCardForm = {
  customerReference:'',
  cardLabel:'',
  notificationNumber: '',
  expiryDate: '',
  loadAmount: 0,
  tagName: ''
}

const initialState = {
  activeStep: 1,
  activeStep1: true,
  activeStep2: false,
  campaignId: 0,
  virtualCardOrderFile: 'Try dropping a file into this box, or click on it to select the file to upload.',
  virtualCardOrder: [],
  virtualCardOrderItems: '0',
  virtualCardOrderTotalValue: 0.00,
  virtualCardOrderSuccessFulItems: '0',
  fileName: '',
  singleVirtualCardForm: initialSingleVirtualCardForm
}
const initialErrors = {
  campaignId: '',
  quotationNumber: 'This field is required'
}

class CardVirtualCardOrderForm extends Component {
  constructor(...args) {
    super(...args)
    this.initialState = {
      values: initialState,
      errors: initialErrors,
      submitDisabled: true,
      singleVirtualCardOrderModalOpen: false
    }
    this.state = {
      ...this.initialState
    }

    this.verifyBatchVirtual = this.verifyBatchVirtual.bind(this)
  }

  componentWillUnmount = () => {
    store.dispatch(resetVirtualCardOrder())
  }

  onNext = (nextStep) => {
    let toggleStep1 = nextStep === 1;
    let toggleStep2 = nextStep === 2;
  
    const newState = {
      values: {
        ...this.state.values,
        activeStep: nextStep,
        activeStep1: toggleStep1,
        activeStep2: toggleStep2
      },
      errors: {
        ...this.state.errors,
      }
    }
    this.setState(newState)
  } 

  componentDidMount = () => {
    this.props.getVirtualCampaigns()
  }

  componentDidUpdate = (prevProps, prevState) => {
    if (prevProps.virtualCardOrder.complete !== this.props.virtualCardOrder.complete) {
      this.setState({...initialState})
    }
  }


  onSubmit = () => {
    const { virtualCardOrder, submitVirtualCardOrder } = this.props

    const validVirtualCardOrders = virtualCardOrder.virtualCardOrders.filter((order) => {
      return !("ERROR" in order)
    })

    submitVirtualCardOrder({
      campaignId : this.state.values.campaignId,
      virtualCardOrders: validVirtualCardOrders,
      fileName: this.state.values.fileName
    })
  }

  deleteVirtualCardOrder = (arrayIndex) => {
    const {virtualCardOrder} = this.props
    virtualCardOrder.virtualCardOrders.splice(arrayIndex, 1)

    store.dispatch(virtualCardOrderVerification(virtualCardOrder.virtualCardOrders, this.state.values.campaignId))

    this.forceUpdate()
  }

  updateOrderSubmitDisabled(newState) {
    const { values, errors } = newState
    let submitDisabled = false

    for (let field of Object.keys(values)) {
      // required fields check
      if (["campaignId"].includes(field)) {
        if (values[field] === '' || values[field] === 0 || errors[field] !== '') {
          submitDisabled = true
          break
        }
      }
    }

    newState.submitDisabled = submitDisabled
  }

  onSelectedCampaign = (event) => {
    const value = event.target.value
    const newState = {
      ...this.state,
      values: {
        ...this.state.values,
        campaignId: value
      },
      errors: {
        ...this.state.errors,
        campaignId: (value !== '0') ? '' : value
      }
    }
    this.updateOrderSubmitDisabled(newState)
    this.setState(newState)
    store.dispatch(virtualCardOrderVerification(this.props.virtualCardOrder.virtualCardOrders, value))
  }

  verifyBatchVirtual(acceptedFiles, rejectedFiles) {
    this.setState({...this.state, uploadTriggered: true})

    this.setState(
      {...this.state, activeStep: 1 },
      verifyVirtualCardOrderFile(acceptedFiles, this.state.values.campaignId)
    )

    const newState = {
      values: {
        ...this.state.values,
        virtualCardOrder: [...this.state.values.virtualCardOrder],
      }
    }
    this.setState(newState)
  }

  resetState = () => {
    this.props.resetVirtualSuccess()
    this.setState({...this.initialState})
  }

  handleSingleVirtualCardModalClose = () => this.setState({
    singleVirtualCardOrderModalOpen: false
  })
  handleSingleVirtualCardModalOpen = () => this.setState({
    singleVirtualCardOrderModalOpen: true
  })

  onSingleVirtualCardInputChange = e => this.setState(
    {values: {
      ...this.state.values,
      singleVirtualCardForm: {
        ...this.state.values.singleVirtualCardForm,
        [e.target.name]:e.target.value
      }
    }}
  )

  addSingleVirtualCardToCardOrder = () => {
    //this.state.values.singleVirtualCardForm
    const {virtualCardOrder} = this.props

    virtualCardOrder.virtualCardOrders.push(this.state.values.singleVirtualCardForm)

    this.setState({
      singleVirtualCardOrderModalOpen: false,
      singleVirtualCardForm: initialSingleVirtualCardForm
    })

    store.dispatch(virtualCardOrderVerification(virtualCardOrder.virtualCardOrders, this.state.values.campaignId))

    this.forceUpdate()
  }

  StepOne = () => {
    const { values, errors, submitDisabled } = this.state
    const { classes, virtualCardOrder } = this.props

    virtualCardOrder.virtualCardOrders = (virtualCardOrder.virtualCardOrders) ? virtualCardOrder.virtualCardOrders : []

    const successCount = virtualCardOrder.virtualCardOrders.filter((order) => {
      return !("ERROR" in order)
    })

    const initialLoadTotal = parseFloat(0)
    const loadTotal = virtualCardOrder.virtualCardOrders.reduce(
      (accumulator, currentValue) => parseFloat(accumulator) + parseFloat(currentValue.loadAmount),
      initialLoadTotal
    )

    return <Collapse in={values.activeStep1}>
      <Card style={{padding:'40px'}}>
        <Typography gutterBottom variant="subtitle1">
          Select a campaign and fill in virtual card order details
        </Typography>
        <CardContent>
          <InputLabel htmlFor="campaign-native-simple">Campaign</InputLabel><br />
          <Select
            native
            name="campaignId"
            data-testid="campaignSelect"
            value={values.campaignId} className="select"
            onChange={this.onSelectedCampaign}
            inputProps={{
              id: 'campaign-native-simple',
              'data-testid':'campaignSelect-input',
            }}
          >
            <option value={-1} disabled hidden>{errors.campaignId}</option>
            <option value={0}>Select a Campaign</option>
            {
              this.props.campaign.campaigns.map((campaign, key) => (
                <option key={campaign.CampaignID}
                  value={campaign.CampaignID}>
                    {campaign.CampaignName}
                </option>
                )
              )
            }
          </Select>
          <br /><br />
          { (this.state.values.fileName === '') ?
            <div>
              <InputLabel htmlFor="raised-button-file">Virtual Card Order File</InputLabel>
              <br />
              <br />

              <Dropzone onDrop={this.verifyBatchVirtual.bind(this)} multiple={false} className="dropzone" accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel">
                {({getRootProps, getInputProps}) => (
                  <section className="container">
                    <div  {...getRootProps({className: 'dropzone'})}>
                      <input name="dropzone" {...getInputProps({'data-testid': 'dropzone-input'})} />
                      <img className="dropzone-image" src={plusImage} alt="Try dropping a file into this box, or click on it to select the file to upload." />
                      <Typography component="p" className="dropzone-p">Try dropping a file into this box, or click on it to select the file to upload.</Typography>
                    </div>
                  </section>
                )}
              </Dropzone>
            </div> : null
          }
        </CardContent>
        { (this.state.values.fileName === '') ? <DownloadSample batchSample ={batchSample}/> : null}
      </Card><br />
      <Card style={{ padding: '40px' }}>
        <Typography gutterBottom variant="h5">Uploaded Card Order File ({successCount.length} successful / {virtualCardOrder.virtualCardOrders.length - successCount.length} failed), Total Load Amount S$ {loadTotal.toFixed(2)}</Typography>
        <Button data-testid="addCardBtn" color='primary' variant='contained' style={{ color: 'white' }} onClick={this.handleSingleVirtualCardModalOpen}>Add Virtual Card</Button>
        <CardContent className={classes.tableWrapper}>
          <Table className="cardOrderTable">
            <TableHead>
              <TableRow>
                <TableCell>Customer Reference</TableCell>
                <TableCell>Card Label</TableCell>
                <TableCell>Notification Number</TableCell>
                <TableCell>Expiry Date</TableCell>
                <TableCell>Load Amount</TableCell>
                <TableCell>Tag Name</TableCell>
                <TableCell>Tag Value</TableCell>
                <TableCell></TableCell>
                <TableCell></TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {virtualCardOrder.virtualCardOrders.map((singleVirtualCardOrder, arrKey) => {
                const orderKey = `order-${arrKey}`;
                return (
                  <TableRow key={orderKey}>
                    <TableCell>{singleVirtualCardOrder.customerReference}</TableCell>
                    <TableCell>{singleVirtualCardOrder.cardLabel}</TableCell>
                    <TableCell>{singleVirtualCardOrder.notificationNumber}</TableCell>
                    <TableCell>{singleVirtualCardOrder.expiryDate}</TableCell>
                    <TableCell>{singleVirtualCardOrder.loadAmount}</TableCell>
                    <TableCell>{singleVirtualCardOrder.tagName}</TableCell>
                    <TableCell>{singleVirtualCardOrder.tagValue}</TableCell>
                    <TableCell className={classes.tableWrapper}>{singleVirtualCardOrder.ERROR}</TableCell>
                    <TableCell><DeleteIcon data-testid="cvo-delete-button" onClick={() => this.deleteVirtualCardOrder(arrKey)} /></TableCell>
                  </TableRow>)
              }
              )
              }
            </TableBody>
          </Table>
        </CardContent>
        <CardActions className="align-right">
          <Button data-testid='sumbitLoadedCards' className="flattbutton-login" color="primary" size="large" variant="contained" onClick={() => this.onNext(2)} disabled={successCount.length === 0 || submitDisabled}>Next</Button>
        </CardActions>
      </Card><br />
    </Collapse>
  }

StepTwo = () => {
  const { values } = this.state
  const { virtualCardOrder } = this.props

  virtualCardOrder.virtualCardOrders = (virtualCardOrder.virtualCardOrders) ? virtualCardOrder.virtualCardOrders : []

  const successCount = virtualCardOrder.virtualCardOrders.filter((order) => {
    return !("ERROR" in order)
  })

  const initialLoadAmount = 0
  const totalLoadAmount = successCount.reduce((accumulator, singleCardOrder) =>
    parseFloat(accumulator) + parseFloat(singleCardOrder.loadAmount), initialLoadAmount
  )

  return <Collapse in={values.activeStep2}>
    <Card style={{padding:'40px'}}>
      <Typography gutterBottom variant="h5">Virtual Card Order Confirmation</Typography>
      <CardContent>
        <InputLabel htmlFor="raised-button-file">Campaign</InputLabel><br />
        <InputLabel htmlFor="raised-button-file">{values.campaignId}</InputLabel><br /><br />
        <InputLabel htmlFor="raised-button-file">Virtual Cards in Order</InputLabel><br />
        <InputLabel htmlFor="raised-button-file">{successCount.length}</InputLabel><br /><br />
        <InputLabel htmlFor="raised-button-file">Total Load Amount</InputLabel><br />
        <InputLabel htmlFor="raised-button-file">S$ {totalLoadAmount.toFixed(2)}</InputLabel><br /><br />
        <Typography component="p">If the above information is complete and correct, a virtual card order can be submitted by pressing the 'SUBMIT' button. Please note that delivery is finalized 24 hours after submission, after which an order cannot be cancelled.</Typography>
      </CardContent>
      <CardActions className="align-right">
        <Button onClick={()=>this.onNext(1)}>Back</Button>
        <Button data-testid="submitFinalBtn" className="flattbutton-login" color="primary"size="large" variant="contained" onClick={()=> this.onSubmit()} disabled={successCount.length === 0 || this.props.submitting}>Submit</Button>
      </CardActions>
    </Card>
  </Collapse>
}

AddSingleVirtualCardModal = () => {
  const { classes } = this.props
  const { values } = this.state
  return <Modal
    aria-labelledby="simple-modal-title"
    aria-describedby="simple-modal-description"
    open={this.state.singleVirtualCardOrderModalOpen}
    onClose={this.handleSingleVirtualCardModalClose}
  >
    <div style={getModalStyle()} className={classes.paper}>
      <Typography variant="h5" id="modal-title">
      Add a single Virtual Card
      </Typography>
      <TextField
        name="customerReference"
        label="Customer Reference"
        inputProps={{ maxLength: 50 }}
        fullWidth={true}
        onChange={this.onSingleVirtualCardInputChange}
        data-testid="customerReference-input"
        value={values.singleVirtualCardForm.customerReference}
      />
      <TextField
        name="cardLabel"
        label="Card Label"
        inputProps={{ maxLength: 50 }}
        fullWidth={true}
        onChange={this.onSingleVirtualCardInputChange}
        data-testid="label-input"
        value={values.singleVirtualCardForm.cardLabel}
      />
      <TextField
        name="notificationNumber"
        label="Notification Number"
        inputProps={{ maxLength: 50 }}
        fullWidth={true}
        onChange={this.onSingleVirtualCardInputChange}
        value={values.singleVirtualCardForm.notificationNumber}
      />
      <TextField
        name="expiryDate"
        label="Expiry Date"
        inputProps={{ maxLength: 50 }}
        fullWidth={true}
        onChange={this.onSingleVirtualCardInputChange}
        value={values.singleVirtualCardForm.expiryDate}
      />
      <TextField
        name="loadAmount"
        label="Load Amount"
        inputProps={{ maxLength: 10 }}
        fullWidth={true}
        onChange={this.onSingleVirtualCardInputChange}
        value={values.singleVirtualCardForm.loadAmount}
      />
      <TextField
        name="tagName"
        label="Tag Name"
        inputProps={{ maxLength: 356 }}
        fullWidth={true}
        onChange={this.onSingleVirtualCardInputChange}
        value={values.singleVirtualCardForm.tagName}
      />
      <TextField
        name="tagValue"
        label="Tag Value"
        inputProps={{ maxLength: 1791 }}
        fullWidth={true}
        onChange={this.onSingleVirtualCardInputChange}
        value={values.singleVirtualCardForm.tagValue}
      />

      <br /><br />
      <Button data-testid="submitCardBtn" onClick={this.addSingleVirtualCardToCardOrder} disabled={values.singleVirtualCardForm.customerReference === '' || values.singleVirtualCardForm.cardLabel === ''} variant='contained' color='primary' style={{color:'white'}}>Add Virtual Card Order</Button>
      <Button data-testid="cancelAddBtn" onClick={this.handleSingleVirtualCardModalClose} variant='contained' style={{color:'rgb(12,179,157)', backgroundColor:'white',float:'right'}}>Cancel</Button>
    </div>
  </Modal>
}

  VirtualCardOrderResult = () =>{
    const { classes, virtualCardOrder } = this.props
    const { resultCode, resultText } = virtualCardOrder
    return <Modal
      aria-labelledby="simple-modal-title"
      aria-describedby="simple-modal-description"
      open={parseInt(resultCode, 10) < 0}
    >
      <div style={getModalStyle()} className={classes.paper}>
        <Typography variant="h5" id="modal-title">
          Error
        </Typography>
        <Typography component="p">{resultCode}: {resultText}</Typography>
        <Button variant='contained' color='primary' onClick={this.onClearStateAndProps}>OK</Button>
      </div>
    </Modal>
  }

  onClearStateAndProps = () => {
    this.setState({values:initialState})
    this.props.resetVirtualCardOrder()
  }

  render() {
    const { values } = this.state
    const steps = ['Virtual Card Order Creation', 'Virtual Card Order Confirmation']

    return (
      <React.Fragment>
        <SuccessModal open={this.props.virtualCardOrder.complete} onClose={this.resetState} />
        <Card style={{padding:'40px'}}>
          <Typography gutterBottom variant="h5">
            Order Virtual Card
          </Typography>
          <CardContent>
            <Stepper activeStep={values.activeStep}>
              {steps.map((label, index) => {
                const stepKey = `step-${index}`; 
                return (
                  <Step key={stepKey}>
                    <StepLabel>{label}</StepLabel>
                  </Step>
                )
              })}
            </Stepper>
          </CardContent>
        </Card>
        <br />
        <this.StepOne/>
        <this.StepTwo/>
        <this.AddSingleVirtualCardModal/>
        <this.VirtualCardOrderResult/>

        { this.props.error ? (
          <Notification type={'error'} message={ this.props.errorMsg } open={ true } closed={() => this.onClearStateAndProps()}/>
        ) : (
          null
        ) }

      </React.Fragment>
    )
  }
}

CardVirtualCardOrderForm.propTypes = {
  submitting: PropTypes.bool.isRequired,
  error: PropTypes.bool.isRequired,
  errorMsg: PropTypes.string.isRequired,
  virtualCardOrder: PropTypes.shape({ 
    complete: PropTypes.bool.isRequired,
    virtualCardOrders: PropTypes.arrayOf(PropTypes.shape({
      customerReference: PropTypes.string.isRequired,
      cardLabel: PropTypes.string.isRequired,
      notificationNumber: PropTypes.string.isRequired,
      expiryDate: PropTypes.string.isRequired,
      loadAmount: PropTypes.number.isRequired,
      tagName: PropTypes.string.isRequired,
      tagValue: PropTypes.string.isRequired,
      ERROR: PropTypes.string, 
    })).isRequired,
    resultCode: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
    resultText: PropTypes.string.isRequired,
   }).isRequired,
   campaign: PropTypes.shape({
    campaigns: PropTypes.arrayOf(PropTypes.shape({
      CampaignID: PropTypes.number.isRequired,
      CampaignName: PropTypes.string.isRequired,
    })).isRequired,
  }).isRequired,
  classes: PropTypes.object.isRequired,
  virtualCardOrderVerification: PropTypes.func.isRequired,
  virtualCardOrderVerificationErrorMessage: PropTypes.func.isRequired,
  submitVirtualCardOrder: PropTypes.func.isRequired,
  resetVirtualSuccess: PropTypes.func.isRequired,
  getVirtualCampaigns: PropTypes.func.isRequired,
  resetVirtualCardOrder: PropTypes.func.isRequired,
};

const mapStateToProps = (state, props) => ({
  submitting: !!(state.api.SUBMIT_VIRTUAL_CARD_ORDER && state.api.SUBMIT_VIRTUAL_CARD_ORDER > 0),
  error: !!(state.virtualCardOrder.errorMsg && state.virtualCardOrder.errorMsg !== ''),
  errorMsg: state.virtualCardOrder.errorMsg,
  virtualCardOrder: state.virtualCardOrder,
  campaign: state.campaign,
})

export default connect(
  mapStateToProps,
  {
    virtualCardOrderVerification,
    virtualCardOrderVerificationErrorMessage,
    submitVirtualCardOrder,
    resetVirtualSuccess,
    getVirtualCampaigns,
    resetVirtualCardOrder
  }
)(withStyles(styles)(CardVirtualCardOrderForm))
