import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import AppBar from '@material-ui/core/AppBar'
import Typography from '@material-ui/core/Typography'
import Button from '@material-ui/core/Button'
import Icon from '@material-ui/core/Icon'
import { userDetails } from '../../../util/user'
class Header extends Component {

  render() {
    const isLoggedIn = (typeof this.props.token !== 'undefined' && this.props.token !== null)
    let userName = isLoggedIn ? userDetails(this.props.token).sub : ""

    const style = {
      flexWrap: {
        display:'flex',
        flexFlow:'row no-wrap',
        justifyContent:'space-between',
        alignItems:'flex-end'
      },
      navLeft: {
        display:'flex',
        flexFlow:'row no-wrap',
        alignItems:'center'
      },
      navRight: {
        display:'flex',
        flexFlow:'row no-wrap',
        alignItems:'center',
        marginBottom:'3px'
      },
      title: {
        fontSize:'24px',
        paddingLeft: '20px',
        paddingRight: '87px',
        color:'#eee',
        borderColor: '#eee',       
        borderRight: '1px solid',
      },
      button: {
        color:'#14a9e0'
      }
    }

    return (
      <AppBar className="appbar" position="fixed">
        <div style={style.flexWrap}>
          <div style={style.navLeft}>
            <Typography variant="h6" style={style.title}>
              {this.props.title || 'Client Portal'}
            </Typography>            
          </div>         
          
          <div style={style.navRight}>
            {isLoggedIn && <Button data-testid='loggedInUser' style={style.button}>Logged in as: {userName}</Button>}
            {isLoggedIn && <Button style={style.button} size='small' href={`${process.env.PUBLIC_URL}/user/detail`}><Icon>settings</Icon></Button>}
            {isLoggedIn && <Button style={style.button} size='small' href={`${process.env.PUBLIC_URL}/logout`}><Icon>exit_to_app</Icon></Button>}
          </div>
        </div>
      </AppBar>
    )
  }
}

const mapStateToProps = (state, props) => {
  return {
    token: state.auth.token
  }
}

Header.propTypes = {
  token: PropTypes.string,
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
};

export default  connect(mapStateToProps)(Header)
