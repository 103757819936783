import React, { Component } from 'react'
import { store } from '../../../store'
import { connect } from 'react-redux'
import Dropzone from 'react-dropzone'
import PropTypes from 'prop-types'
import Card from '@material-ui/core/Card'
import CardContent from '@material-ui/core/CardContent'
import Typography from '@material-ui/core/Typography'
import Input from '@material-ui/core/Input'
import InputLabel from '@material-ui/core/InputLabel'
import { FormControl, FormControlLabel } from '@material-ui/core'
import Select from '@material-ui/core/Select'
import Stepper from '@material-ui/core/Stepper'
import Step from '@material-ui/core/Step'
import StepLabel from '@material-ui/core/StepLabel'
import MenuItem from '@material-ui/core/MenuItem'
import { batchDevalue, batchDevalueErrorMessage, batchDevalueReset } from '../../../action/batchDevalue'
import { batchFileVerification, batchFileVerificationErrorMessage, verifyBatchFile, batchFileVerificationReset } from '../../../action/batchFileVerification'
import Notification from '../../default/layout/Notification'
import Switch from '@material-ui/core/Switch'
import BatchFile from '../batchFile/BatchFile'
import plusImage from '../../../images/agoda/dropzone.png'
import batchSample from '../../../resources/devalue-batch-sample.csv'
import DownloadSample from '../../../container/agoda/BatchSampleDownload'

const batchSizeLimit = 250


class BatchDevalueForm extends Component {

  constructor(props) {
    super(props)
    this.initialState = {
      activeStep: 0,
      batchFileContent: [],
      disabled: true,
      errorMsg: '',
      batchUploadResult: false,
      identifierType: 1,
      batchDescription: '',
      fileName: '',
      retire: false
    }
    this.state = {
      ...this.initialState
    }
  }

  componentDidMount() {
    this.setState(this.initialState)
    store.dispatch(batchDevalueReset())
     store.dispatch(batchFileVerificationReset())
  }

  componentWillReceiveProps(nextProps){
    if (nextProps.batchFileVerificationStruct.batchVerificationResult) {
      this.setState({
        batchFileContent : nextProps.batchFileVerificationStruct.batchVerificationResult,
        fileName: nextProps.batchFileVerificationStruct.fileName
      })
    }
  }

  handleInputChange = (event) => {
    const stateKey = event.target.name
    this.setState({ [stateKey]: event.target.value })
  }

  handleCheck = (event) => {
    const stateKey = event.target.name
    this.setState({ [stateKey]: event.target.checked })
  }

  verifyBatch(acceptedFiles, rejectedFiles) {
    this.setState({...this.state, activeStep: 1 },verifyBatchFile(acceptedFiles, rejectedFiles, this.state.identifierType, batchSizeLimit, ['duplicateCard']))
  }

  uploadBatch() {
    this.setState({
      ...this.state,
      activeStep: 2
    }, () => store.dispatch(batchDevalue(this.state.batchFileContent, this.state.identifierType, this.state.batchDescription, this.state.fileName, this.state.retire)))
  }

  resetComponent() {
    this.setState(this.initialState)
    store.dispatch(batchDevalueReset())
    store.dispatch(batchFileVerificationReset())
  }

  render () {
    const steps = ['Batch file upload', 'Batch verification', 'Batch submit']
    const { apiCallPending, errorMsg } = this.props

    const disabled = (apiCallPending || this.state.batchDescription.length === 0)

    const batchUploadResult = this.props.batchUploadResult ? (
      <Notification type={'success'} message={'Batch file uploaded successfully'} open={ true } closed={() => this.resetComponent()}/>
    ) : (
      null
    )

    const batchUploadError = errorMsg ? (
      <Notification type={'error'} message={ errorMsg } open={ true } closed={() => this.resetComponent()}/>
    ) : (
      null
    )

    return (
      <React.Fragment>
        { batchUploadError }
        { batchUploadResult }

        <Stepper activeStep={this.state.activeStep}>
          {steps.map((label, index) => {
            return (
              <Step key={label}>
                <StepLabel>{label}</StepLabel>
              </Step>  
            )
          })}
        </Stepper>
        <br />
        { this.state.activeStep === 0 ? (
          <Card>
            <CardContent>
              <Typography gutterBottom variant="h5" component="h2">
                Batch Card Devalue
              </Typography>
              <Typography gutterBottom variant="subtitle1">
                Upload a CSV file containing a list of card identifiers and amounts to devalue. 0 amount or no amount means devalue of the whole balance. (Maximum {batchSizeLimit} records)
              </Typography>
              <div className="batch-devalue-form-container"> 
                <FormControl className="form-input">
                  <InputLabel htmlFor="identifier-type">Identifier</InputLabel>
                  <Select
                    id="identifier-type"
                    value={this.state.identifierType}
                    onChange={this.handleInputChange}
                    input={<Input name="identifierType"/>}
                    fullWidth={true}
                  >
                    <MenuItem value={1}>PAN</MenuItem>
                    <MenuItem value={2}>TrackingNumber</MenuItem>
                  </Select>
                </FormControl>
                <FormControl className="form-input">
                  <InputLabel htmlFor="batch-description">Batch Description</InputLabel>
                  <Input
                    id="batch-description"
                    value={this.state.batchDescription}
                    name="batchDescription"
                    onChange={this.handleInputChange}
                  />
                </FormControl>
                <FormControl className="batch-form-switch">
                  <FormControlLabel
                    control={
                      <Switch
                        checked={this.state.retire}
                        onChange={this.handleCheck}
                        name="retire"
                        value="retire"
                        color="primary"
                      />
                    }
                    label="Retire batch cards"
                    classes={{label : 'form-input-label'}}
                  />
                </FormControl>
              </div>
              <br />
              
              <Dropzone className="dropzone" multiple={false} onDrop={this.verifyBatch.bind(this)} disabled={disabled} accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel">
                {({getRootProps, getInputProps}) => (
                  <section className="container">
                    <div {...getRootProps({className: 'dropzone'})}>
                      <input {...getInputProps()} />
                      <img className="dropzone-image" src={plusImage} alt="Try dropping a file into this box, or click on it to select the file to upload." />
                      <Typography component="p" className="dropzone-p">Try dropping a file into this box, or click on it to select the file to upload.</Typography>
                    </div>
                  </section>
                )}
              </Dropzone>
            </CardContent>
            <DownloadSample batchSample ={batchSample}/>
          </Card>
        ) : null }
        { (this.state.batchFileContent.length && this.state.activeStep === 1) ? (
            <BatchFile
              batchFileContent = { this.state.batchFileContent }
              submitCallback = {this.uploadBatch.bind(this)}
              resetCallback = {this.resetComponent.bind(this)}
              identifierType = {this.state.identifierType}
              fileName = {this.state.fileName}
              reference = {this.state.batchDescription}
              operationType = "devalue"
            />
          ): null
        }
      </React.Fragment>
    )
  }
}

BatchDevalueForm.propTypes = {
  BatchDevalue: PropTypes.func,
  BatchFileVerification: PropTypes.func
}

const mapStateToProps = (state, props) => {
  return {
    apiCallPending: !!(state.api.BATCH_DEVALUE && state.api.BATCH_DEVALUE > 0),
    errorMsg: (state.batchDevalue.errorMsg && state.batchDevalue.errorMsg.length) ? state.batchDevalue.errorMsg
      : state.batchFileVerification.errorMsg,  
    batchUploadResult: state.batchDevalue.batchUploadResult ? JSON.parse(state.batchDevalue.batchUploadResult) : false,
    batchFileVerificationStruct: {
      batchVerificationResult: (state.batchFileVerification.batchVerificationResult || []),
      batchFileRow: (state.batchFileVerification.batchFileRow || null),
      fileName: (state.batchFileVerification.fileName || '')
    }
  }
}

export default connect(
  mapStateToProps,
  { 
    batchDevalue,
    batchDevalueErrorMessage,
    batchFileVerification,
    batchFileVerificationErrorMessage
  }
)(BatchDevalueForm)
