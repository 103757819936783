import React from 'react'
import PropTypes from 'prop-types'
import TableRow from '@material-ui/core/TableRow'
import TableCell from '@material-ui/core/TableCell'
import CurrencyFormat from 'react-currency-format'
import { doSafeDateFormat } from '../../../util/misc'

const renderTags = (tagName) =>
  (tagName ?? '').split('|').map((tag) => (
    <div key={tag} style={{ marginBottom: 4 }}>
      {tag}
    </div>
  ))

const StyledTd = ({ children }) => <TableCell style={{ verticalAlign: 'top' }}>{children}</TableCell>
StyledTd.propTypes = {
  children: PropTypes.node,
}

const VirtualCardOrdersDetailsTableBody = ({ virtualCardsOrders }) => {
  return virtualCardsOrders.map(
    (
      {
        virtualCardOrderId,
        customerReference,
        cardLabel,
        notificationNumber,
        expiryDate,
        loadAmount,
        tagName,
        tagValue,
      },
      i
    ) => (
      <TableRow key={virtualCardOrderId}>
        <StyledTd>{customerReference}</StyledTd>
        <StyledTd>{cardLabel}</StyledTd>
        <StyledTd>{notificationNumber}</StyledTd>
        <StyledTd>{doSafeDateFormat(expiryDate)}</StyledTd>
        <StyledTd>
          <CurrencyFormat value={loadAmount} displayType={'text'} thousandSeparator={true} prefix={'$'} />
        </StyledTd>
        <TableCell>{renderTags(tagName)}</TableCell>
        <TableCell>{renderTags(tagValue)}</TableCell>
      </TableRow>
    )
  )
}

VirtualCardOrdersDetailsTableBody.propTypes = {
  virtualCardsOrders: PropTypes.array,
}

export default VirtualCardOrdersDetailsTableBody
