import React, {Component, Fragment} from 'react'
import {TablePagination, Typography} from '@material-ui/core'
import Table from '@material-ui/core/Table'
import TableHead from '@material-ui/core/TableHead'
import TableBody from '@material-ui/core/TableBody'
import TableRow from '@material-ui/core/TableRow'
import TableCell from '@material-ui/core/TableCell'
import DialogBox from '../../default/layout/DialogBox'
import IconButton from "@material-ui/core/IconButton"
import Tooltip from "@material-ui/core/Tooltip"
import Icon from "@material-ui/core/Icon"
import {getFieldName} from '../../../util/misc'
import Grid from "@material-ui/core/Grid"
import PictureAsPDF from '@material-ui/icons/PictureAsPdf'
import {PDFDownloadLink} from "@react-pdf/renderer"
import ChargebackReportPrintDetails from "./ChargebackReportPrintDetails"


const DialogBody = props => <Table>
  <TableHead>
    {props.headers.map(
      header=>
        <TableRow>
          <TableCell>{header}</TableCell>
          <TableCell>{props.data[getFieldName(header)]}</TableCell>
        </TableRow>)}
  </TableHead>
</Table>

const iconColStyle = {
  padding: 0
}

const AMOUNT = 'AMOUNT'
const WITH_CURRENCY = '_WITH_CURRENCY'

export class ChargebackReportTemplate extends Component{

  state = {
    dialogOpen : false,
    dialogDetail:{},
    dialogHeader:[],
    page: 0,
    rowsPerPage: 15
  }

  handleChangePage = (event, newPage) => {
    this.setState({page: newPage})
  }

  handleChangeRowsPerPage = (event) => {
    this.setState({rowsPerPage: parseInt(event.target.value, 15)}, {page: 0})
  }

  render(){
    return <Fragment>
      <DialogBox 
        open={this.state.dialogOpen}
        title={`${this.props.header || ''} Details`}
        onClick={()=>this.setState({dialogOpen: false})}
      >
        <DialogBody headers={this.state.dialogHeader} data={this.state.dialogDetail}/>
      </DialogBox>

      <Grid
        justify="space-between"
        container
      >
        <Grid item>
          <Typography className="table-header" variant="h6" gutterBottom>
            {this.props.header}
          </Typography>
        </Grid>
        <Grid item>
          <IconButton style={{verticalAlign: 'middle',cursor: 'pointer', marginRight:'5px'}} onClick={ () => {this.props.downloadExcel(false)}}>
            <Tooltip title="Excel report"><Icon color="primary">receipt</Icon></Tooltip>
          </IconButton>
          <IconButton style={{verticalAlign: 'middle',cursor: 'pointer', marginRight:'20px'}} onClick={ () => {this.props.downloadExcel(true)}}>
            <Tooltip title="Excel including all details"><Icon color="primary">receipt</Icon></Tooltip>
          </IconButton>
        </Grid>

        <Grid item style={{ padding: '20px', overflow: 'overlay' }}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell style={iconColStyle}></TableCell>
              <TableCell style={iconColStyle}></TableCell>
              {this.props.tableHeader && this.props.tableHeader.map(head =><TableCell style={{textAlign: getFieldName(head).includes(AMOUNT) ? 'right' : 'left'}}>{head}</TableCell>)}
            </TableRow>
          </TableHead>
          <TableBody>
            {
              this.props.tableData && this.props.tableData.length  > 0 ? this.props.tableData.slice(this.state.page * this.state.rowsPerPage, this.state.page * this.state.rowsPerPage + this.state.rowsPerPage).map( (data, key) =>
                <TableRow key={key}>
                  <TableCell style={iconColStyle}>
                    <Tooltip title="Details"><IconButton onClick={()=>this.setState({dialogOpen:true, dialogDetail:data, dialogHeader:this.props.detailKeys})}><Icon>description</Icon></IconButton></Tooltip>
                  </TableCell>
                  <TableCell style={iconColStyle}>
                    <PDFDownloadLink document={<ChargebackReportPrintDetails chargebackDetails={data}/>} fileName={"ChargebackDetails-trackingNumber-" + data['TRACKINGNUMBER'] + ".pdf"}>
                      <Tooltip title="Download PDF"><IconButton><PictureAsPDF/></IconButton></Tooltip>
                      {({ blob, url, loading, error }) => (loading ? 'Loading document...' : 'Download now')}
                    </PDFDownloadLink>
                  </TableCell>
                  {
                    this.props.tableHeader.map(getFieldName).map(columnName =>
                      <TableCell key={columnName} style={{textAlign: columnName.includes(AMOUNT) ? 'right' : 'left'}} >
                        {columnName && (columnName.includes(AMOUNT) ? data[columnName + WITH_CURRENCY] : data[columnName])}
                      </TableCell> )
                  }
                </TableRow> ) :
                <TableRow style={{background:'#fbfbfb'}}>
                  <TableCell style={{color:'grey'}} colSpan={this.props.header ? this.props.header.length : 1}>
                    No Result for {this.props.header}
                  </TableCell>
                </TableRow>
            }
          </TableBody>
        </Table>
        </Grid>
        <TablePagination
          rowsPerPageOptions={[this.state.rowsPerPage]}
          component={"div"}
          count={this.props.tableData ? this.props.tableData.length : 0}
          rowsPerPage={this.state.rowsPerPage}
          page={this.state.page}
          onChangePage={this.handleChangePage}
          onChangeRowsPerPage={this.handleChangeRowsPerPage}
        />
      </Grid>
    </Fragment>
  }

}
export default ChargebackReportTemplate
