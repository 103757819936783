import React from 'react'
import {Button, Icon, Typography} from '@material-ui/core'
import {withStyles, withTheme} from '@material-ui/core/styles'
import {connect} from 'react-redux'
import {chargebackReport} from '../../action/chargebackReport'
import ChargebackReportTemplate from '../../component/agoda/reports/ChargeBackReportTemplate'
import DateFromDateTo from '../../component/agoda/transactionDetails/DateFromDateTo'
import {threeMonthAgo, today} from '../../component/agoda/transactionDetails/CardTransactions'
import CampaignSelect, {combineCampaigns} from './reports/CampaignSelect'
import {getCampaigns} from '../../action'
import dateFormat from "dateformat"
import {getFieldName, s2ab} from '../../util/misc'
import XLSX from "xlsx"
import download from "downloadjs"
import Card from "@material-ui/core/Card"
import {array, arrayOf, number, shape, string} from "prop-types"

const styles = {
  button:{width:'60px', color:'white'},
  processed:{fontSize: '90%',color:'green'},
  unprocessed:{fontSize: '90%',color:'orange'},
  header:{padding:'20px'}
}

export class ChargebackReport extends React.Component {

  constructor(props){
    super(props)
    this.state = {
      startDate: dateFormat(new Date(threeMonthAgo), "yyyy-mm-dd"),
      endDate: dateFormat(new Date(today), "yyyy-mm-dd")
    }
  }

  componentDidMount() {
    this.props.getCampaigns()
  }

  componentDidUpdate = (prevProps) => {
    if (prevProps.campaigns !== this.props.campaigns) {
      this.setState({campaign: combineCampaigns(this.props.campaigns)})
    }
  }

  handleInputChange = (event, child) => this.setState({ [event.target.name]: event.target.value })

  downloadExcelData = (allColumns = false) => {
    const mimeType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
    const headers = allColumns ? this.props.chargebackReportData.COLUMNS : this.props.chargebackReportData.SUMMARYCOLUMNS
    const dataColumns = headers.map(header => getFieldName(header.NAME))
    const title = allColumns ? "Chargeback Details" : "Chargeback List"
    const data = this.props.chargebackReportData.DATA

    const reportFilename = allColumns ? 'Agoda_Portal_Chargeback_Details.xlsx' : 'Agoda_Portal_Chargeback_List.xlsx'
    const excelWorkbook = XLSX.utils.book_new()

    // put the whole report data into an array of arrays, with the first row the headers
    let excelData = []
    excelData.push(headers.map(header => header.NAME))

    if (data) {
      data.forEach(row => excelData.push(dataColumns.map(columnName => row[columnName])))
    }

    excelWorkbook.Props = {
      Title: "Chargeback Report",
      Author: "Tutuka",
      CreatedDate: new Date()
    }
    excelWorkbook.SheetNames.push(title)
    const excelSheet = XLSX.utils.aoa_to_sheet(excelData)
    excelSheet['!cols'] = headers.map(header => ({wch: header.EXCELWIDTH}))
    excelWorkbook.Sheets[title] = excelSheet

    const wbout = XLSX.write(excelWorkbook, {bookType: 'xlsx', type: 'binary'})
    download(new Blob([s2ab(wbout)],{type:"application/octet-stream"}), reportFilename, mimeType)
  }

  render() {
    const { classes } = this.props
    return (
      <Card style={{ padding:'20px'}}>
        <Typography style={styles.header} variant="h6" gutterBottom>
            Chargeback Report
        </Typography>
        <div style={{margin:'0 20px'}}>
          <DateFromDateTo
            startDate={this.state.startDate}
            endDate={this.state.endDate}
            onChangeStartDate={this.handleInputChange}
            onChangeEndDate={this.handleInputChange}
          />
          <CampaignSelect
            classes={classes}
            className={classes.select}
            campaigns={this.props.campaigns}
            campaign = {this.state.campaign}
            handleInputChange={this.handleInputChange}
          />
          <Button data-testid='searchChargeback' onClick={() => this.props.chargebackReport(this.props.token, this.state.startDate, this.state.endDate, this.state.campaign)}>
            <Icon>search</Icon>
          </Button>
        </div>
        {
          this.props.chargebackReportData?.VERSION &&
          <ChargebackReportTemplate
            header='Chargebacks'
            tableHeader={this.props.chargebackReportData.HTMLCOLUMNS}
            tableData={this.props.chargebackReportData.DATA}
            detailKeys={this.props.chargebackReportData.COLUMNS.map(column => column.NAME)}
            downloadExcel={this.downloadExcelData}
          />
        }
      </Card>
    )
  }
}

const mapStateToProps = (state) => ({
  token: state.auth.token,
  chargebackReportData: state.chargebackReport.chargebackReport,
  campaigns: state.campaign.campaigns
})

const mapDispatchToProps = {
  chargebackReport,
  getCampaigns
}

ChargebackReport.propTypes = {
  chargebackReportData: shape({
    'COLUMNS': arrayOf(shape({
      'NAME': string,
      'EXCELWIDTH': number
    })),
    'SUMMARYCOLUMNS': arrayOf(shape({
      'NAME': string,
      'EXCELWIDTH': number
    })),
    'HTMLCOLUMNS': array,
    'DATA': array
  })
}

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(withTheme(ChargebackReport)))
