import 'whatwg-fetch'
import {apiCall} from './api'

export const LOAD_REPORT = 'LOAD_REPORT'

export function loadReport(name, campaignIDs, reportDate) {
  return async function action(dispatch) {
    dispatch({type: `${LOAD_REPORT}_PENDING`})

    try {
      let payload = await doLoadReport(name, campaignIDs, reportDate)
      dispatch({type: `${LOAD_REPORT}_FULFILLED`, payload})
    } catch (e) {
      dispatch({type: `${LOAD_REPORT}_REJECTED`, payload: "", error: e})
    }
  }
}

async function doLoadReport(name, campaignIDs, reportDate) {
  let allReportInfos = await Promise.all(
    campaignIDs.map(campaignID => getReportInfo(name, campaignID, reportDate))
  )

  let allReports = await Promise.all(
    allReportInfos.map(reportInfo => getReportByInfo(reportInfo))
  )

  var reportURL = allReportInfos.length === 1 ? allReportInfos[0].reportURL : "";

  return {
    reportURL,
    reports: allReports
  }
}

function getReportInfo(name, campaignID, reportDate) {
  return apiCall('GetReport', {name, campaignID, reportDate})
    .then(reportInfo => {
      if (reportInfo.resultCode !== "0") {
        throw new Error(reportInfo.resultText)
      }
      return reportInfo
    });
}

function getReportByInfo(reportInfo) {
  return fetch(reportInfo.reportURL)
    .then(reportResponse => {
      if (!reportResponse.ok) {
        const error = (reportResponse.status === 404) ? 'Report not found' : 'Report download failed'
        throw new Error(error)
      }
      return reportResponse.text()
    });
}

export const GET_DATE_RANGE_REPORT = 'GET_DATE_RANGE_REPORT'

export const getDateRangeReport = ( reportName, reportArguments ) => {
  const payload = apiCall('getDateRangeReport', { reportName, reportArguments })
  return {
    type: GET_DATE_RANGE_REPORT,
    payload,
    meta: {
      reportArguments,
      reportName
    }
  }
}

export const GET_DATE_RANGE_LIMIT = 'GET_DATE_RANGE_LIMIT'

export const getDateRangeLimit = ( reportName ) => {
  const payload = apiCall('getDateRangeLimit', { reportName })
  return {
    type: GET_DATE_RANGE_LIMIT,
    payload,
    meta: {
      reportName
    }
  }
}

export const GET_MONTHLY_REPORT = 'GET_MONTHLY_REPORT'

export const getMonthlyReport = ( reportName, reportArguments ) => {
  const payload = apiCall('getMonthlyReport', { reportName, reportArguments })
  return {
    type: GET_MONTHLY_REPORT,
    payload,
    meta: {
      reportArguments,
      reportName
    }
  }
}

export const GET_DAILY_REPORT = 'GET_DAILY_REPORT'

export const getDailyReport = (reportDate, name) => {
  const payload = apiCall('getDailyReport', {reportDate, name})
  return {
    type: GET_DAILY_REPORT,
    payload,
    meta: {
      reportDate,
      name
    }
  }
}

export const GET_REPORT = 'GET_REPORT'

export const getReport = (name, campaignID, reportDate) => {
  const payload = apiCall('getReport', {name, campaignID, reportDate})
  return {
    type: GET_REPORT,
    payload,
    meta: {
      name,
      campaignID,
      reportDate
    }
  }
}
