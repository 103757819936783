import React, { Component } from 'react'
import { TableRow, TableCell, Tooltip } from '@material-ui/core'
import Icon from '@material-ui/core/Icon'
import { green, red } from '@material-ui/core/colors'
import { Link } from 'react-router-dom'
import CurrencyFormat from 'react-currency-format'


export class CardSearchResultsRow extends Component { 
  
  getStatus = () => {
    const validTextStyle = {
      color: green[500]
    }

    const invalidTextStyle = {
      color: red[500],
      fontWeight: 'bold'
    }

    const { searchResult: row } = this.props

    return (
      <div>
        {row.ACTIVATED ? (
          <Tooltip id="tooltip-fab" title="Card is Active">
            <span style={validTextStyle} className="card-status-indicator">
              A
            </span>
          </Tooltip>
        ) : (
          <Tooltip id="tooltip-fab" title="Card is Inactive">
            <span style={invalidTextStyle} className="card-status-indicator">
              I
            </span>
          </Tooltip>
        )}
        {row.EXPIRED ? (
          <Tooltip id="tooltip-fab" title="Card is Expired">
            <span style={invalidTextStyle} className="card-status-indicator">
              E
            </span>
          </Tooltip>
        ) : null}
        {row.RETIRED ? (
          <Tooltip id="tooltip-fab" title="Card is Retired">
            <span style={invalidTextStyle} className="card-status-indicator">
              R
            </span>
          </Tooltip>
        ) : null}
        {row.STOPPED ? (
          <Tooltip id="tooltip-fab" title="Card is Stopped">
            <span style={invalidTextStyle} className="card-status-indicator">
              S
            </span>
          </Tooltip>
        ) : null}
      </div>
    )
  }

  render() {
    const link = '/card/details/' + this.props.searchResult.VOUCHERID
    const status = this.getStatus()

    return (
      <TableRow>
        <TableCell>
          <Tooltip title={"Show Statement"}>
            <Link to={link} className="table-icon-link">
              <Icon>description</Icon>
            </Link>
          </Tooltip>
        </TableCell>
        <TableCell>{this.props.searchResult.CAMPAIGNNAME}</TableCell>
        <TableCell className='customer-reference'>{this.props.searchResult.CUSTOMERREFERENCE}</TableCell>
        <TableCell>{this.props.searchResult.CARDNUMBER}</TableCell>
        <TableCell className='tracking-number'>{this.props.searchResult.TRACKINGNUMBER}</TableCell>
        <TableCell >{this.props.searchResult.BOOKINGID}</TableCell>
        <TableCell>{this.props.searchResult.CARDEXPIRY}</TableCell>
        <TableCell>{this.props.searchResult.CARDCVV2}</TableCell>
        <TableCell>
          <CurrencyFormat
            value={this.props.searchResult.CARDBALANCE}
            displayType={'text'}
            thousandSeparator={true}
            prefix={'$'}
          />
        </TableCell>
        <TableCell>
          <CurrencyFormat
            value={this.props.searchResult.AVAILABLEFUNDS}
            displayType={'text'}
            thousandSeparator={true}
            prefix={'$'}
          />
        </TableCell>
        <TableCell>
          <CurrencyFormat
            value={this.props.searchResult.LOADEDAMOUNT}
            displayType={'text'}
            thousandSeparator={true}
            prefix={'$'}
          />
        </TableCell>
        <TableCell>
          <CurrencyFormat
            value={this.props.searchResult.AUTHORIZEDAMOUNT}
            displayType={'text'}
            thousandSeparator={true}
            prefix={'$'}
          />
        </TableCell>
        <TableCell>
          <CurrencyFormat
            value={this.props.searchResult.SETTLEDAMOUNT}
            displayType={'text'}
            thousandSeparator={true}
            prefix={'$'}
          />
        </TableCell>
        <TableCell>{this.props.searchResult.ISSUEDATE}</TableCell>
        <TableCell>{status}</TableCell>
      </TableRow>
    )
  }
}
