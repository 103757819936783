import { CHANGE_PIN, CHANGE_PIN_ERROR_MESSAGE, CHANGE_PIN_CLEAR } from '../action/card/changePin'

const initialState = {
  errorMsg : '',
  changePinResult: false,
  changePinSuccess: false
}

export default (state = initialState, action) => {
  const { type, payload } = action
  switch(type) {
    case CHANGE_PIN_ERROR_MESSAGE:
      return {
        ...state,
        errorMsg: 'An error occurred'
      }
    case `${CHANGE_PIN}_FULFILLED`:
      if (payload.resultCode === 0) {
        return {
          ...state,
          changePinResult: "Pin Change Successful",
          changePinSuccess: true
        }
      } else {
        return {
          ...state,
          errorMsg: payload.resultText || 'An error occurred',
          changePinSuccess: false
        }
      }
    case `${CHANGE_PIN}_PENDING`:
      return {
        ...state,
        errorMsg: '',
        changePinSuccess: false
      }
    case `${CHANGE_PIN}_REJECTED`:
      return {
        ...state,
        errorMsg: 'Cannot connect to server'
      }
    case CHANGE_PIN_CLEAR:
      return {
        initialState
      }
    default:
      return state
  }
}
