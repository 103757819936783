import { apiCall } from './api' 

export const MERCHANT_SIGNUP = 'MERCHANT_SIGNUP'

export function merchantSignUp(merchantInfo) {
  const payload = apiCall('merchantSignup', { ...merchantInfo })
  return {
    type: MERCHANT_SIGNUP,
    payload,
    meta: {
      merchantInfo
    }
  }
}

export const MERCHANT_CLEAR_ERROR_MESSAGE = 'MERCHANT_CLEAR_ERROR_MESSAGE'

export function merchantClearErrorMessage(merchantInfo) {
  return {
    type: MERCHANT_CLEAR_ERROR_MESSAGE
  }
}

export const MERCHANT_CLEAR_SIGN_UP_LIST = 'MERCHANT_CLEAR_SIGN_UP_LIST'

export function merchantClearSignUpList(position) {
  return {
    type: MERCHANT_CLEAR_SIGN_UP_LIST,
    position
  }
}
