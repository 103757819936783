import React from 'react'
import { withStyles } from '@material-ui/core/styles'
import { Drawer } from '@material-ui/core'

const styles = theme => ({
  docked: {
    position: 'relative'
  },
  paper: {
    height: 'calc(100% - 64px)',
    top: 64
  }
})

const DefaultDrawer = props => (
  <Drawer variant="permanent" className="drawerPaper" classes={props.classes}>
    {props.children}
  </Drawer>
)

export default withStyles(styles)(DefaultDrawer)
