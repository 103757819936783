import React from 'react'
import PropTypes from 'prop-types'
import { Icon, IconButton, Tooltip } from '@material-ui/core'

const buttonTooltipDelay = 800

const ForceRefresh = (props) => {
  const { onClick } = props

  return (
    <Tooltip
      title="Refresh results"
      enterDelay={buttonTooltipDelay}
      className="batch-loads-results-refresh"
    >
      <span>
        <IconButton
          onClick={onClick}
          aria-label="Refresh"
          data-testid="vco-refresh-button"
        >
          <Icon>autorenew</Icon>
        </IconButton>
      </span>
    </Tooltip>
  )
}

ForceRefresh.propTypes = {
  onClick: PropTypes.func,
}

export default ForceRefresh
