export default (statement) => {
	let csvContent = ''
	if (statement) {
		if (statement.COLUMNS) {
			csvContent += statement.COLUMNS.join(',') + "\r\n"
		}
		if (statement.DATA) {
			statement.DATA.forEach(function (item, index) {
				let row = item.join(',')
				csvContent += row + "\r\n"
			});
		}
	}
	return csvContent
}
