import {REMEMBER_PASSWORD, RESET_REMEMBER_PASSWORD} from '../action/forgotPassword'
import {USER_NOT_FOUND} from "../action/apiResponseCodes";

const initialState = {
  errorMsg: '',
  email : '',
  successful: false
}

export default (state = initialState, action) => {
  const {type, payload} = action
  switch (type) {
    case `${RESET_REMEMBER_PASSWORD}`:
    case `${REMEMBER_PASSWORD}_PENDING`:
      return {
        ...state,
        errorMsg: '',
        email: '',
        successful : false
      }
    case `${REMEMBER_PASSWORD}_FULFILLED`:
      if (payload.resultCode === "0") {
        return {
          ...state,
          errorMsg: '',
          email: payload.resultText,
          successful: true
        }
      } else if (payload.resultCode === USER_NOT_FOUND) {
        return {
          ...state,
          errorMsg: 'Thank you, if your user account is registered with us, you will receive an email on how to reset your password.',
          email: '',
          successful: false
        }
      } else {
        return {
          ...state,
          errorMsg: payload.resultText,
          email: '',
          successful: false
        }
      }
    default:
      return state
  }
}
