import { createStore, applyMiddleware } from 'redux'
import { routerMiddleware } from 'connected-react-router'
import promiseMiddleware from 'redux-promise-middleware'
import { persistStore, persistReducer } from 'redux-persist'
import storage from 'redux-persist/lib/storage/session'
import {rootReducer , browserHistory } from './reducer'
import thunk from 'redux-thunk'
import { composeWithDevTools } from 'redux-devtools-extension'

const nodeEnv = process.env.NODE_ENV || 'development'

const persistConfig = {
  key: 'root',
  blacklist: [
    'router',
    'setPassword',

    /*
      Excluding report reducer from storage as big reports exceed local storage limits.
      Since report reducer is defined under different names, excluding them both.
    */
    'report',
    'reportInfo'
  ],
  storage
}

const persistedReducer = persistReducer(persistConfig, rootReducer)

var middlewares = [
  routerMiddleware(browserHistory),
  promiseMiddleware,
  thunk
]

var compose

if (nodeEnv === 'development') {
  // because we don't want to include this in prod mode (npm run build) and also to avoid show loggin in test env
  // but import statement can be used only on top of file
  const { logger } = require('redux-logger')
  middlewares = [
    ...middlewares,
    logger
  ]
  compose = composeWithDevTools(applyMiddleware(...middlewares))
} else {
  compose = applyMiddleware(...middlewares)
}

const store = createStore(
  persistedReducer,
  compose
)
const persistor = persistStore(store)

export { 
  store,
  persistor,
  browserHistory
}
