import {apiCall} from './api'

export const GET_CURRENT_USER = 'GET_CURRENT_USER'
export const getUser = () => {
  const payload = apiCall('getUserByID', {})
  return {
    type: GET_CURRENT_USER,
    payload,
    meta: {}
  }
}

export const CURRENT_USER_SAVE = 'CURRENT_USER_SAVE'
export const saveUser = (userDetail) => (
  {
    type: CURRENT_USER_SAVE,
    payload: apiCall('saveUser', {
      user: userDetail
    })
    , meta: {
      user: userDetail
    }
  }
)

export const CHANGE_USER_PASSWORD = 'CHANGE_USER_PASSWORD'

export const changeUserPassword = (oldPassword, newPassword) => (
  {
    type: CHANGE_USER_PASSWORD,
    payload: apiCall('changeUserPassword', {
      oldPassword,
      newPassword
    }),
    meta: {
      oldPassword,
      newPassword
    }
  }
)

export const CHANGE_PASSWORD_RESULT_AS_DEFAULT = 'CHANGE_PASSWORD_RESULT_AS_DEFAULT'

export const setPasswordResultAsDefault = () => ({
  type: CHANGE_PASSWORD_RESULT_AS_DEFAULT
})

export const DISMISS_CURRENT_USER_ERROR = 'DISMISS_CURRENT_USER_ERROR'
export const dismissError = () => (
  {
    type: DISMISS_CURRENT_USER_ERROR
  }
)
