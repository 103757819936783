import React, { Fragment } from 'react'
import TextField from '@material-ui/core/TextField'

export default props => <Fragment>
  <TextField
    name="startDate"
    label="Start date"
    type="date"
    onChange={props.onChangeStartDate}
    value={props.startDate}
    disabled={props.disabled}
  />
  <span style={{padding:'20px'}}>-</span>
  <TextField
    name="endDate"
    label="End date"
    type="date"
    onChange={props.onChangeEndDate}
    value={props.endDate}
    disabled={props.disabled}
  />
</Fragment>
