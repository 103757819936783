import React, {Component} from 'react'
import Wrapper from '../../component/default/layout/Wrapper'
import BatchTransferForm from '../../component/agoda/batchTransfer/BatchTransferForm'

class BatchTransfer extends Component {
  render () {
    return (
      <div>
        <Wrapper>
          <BatchTransferForm />
        </Wrapper>
      </div>
    )
  }
}

export default BatchTransfer
