import axios from 'axios'


export const csvLinkToArrayObject = async (csvUrl) => {
  if(csvUrl){
    const result = await axios({
      url: csvUrl,
      method:'GET',
      responseType: 'text'
    })
    const rows = result.data.split('\n')
    const headers = rows[0]
    const headerColumns = headers.split(',')
    var resultArray = []
    for(let i = 1 ; i < rows.length ; i++){
      let row = rows[i]
      let rowColumns = row.split(',')
      resultArray.push(rowToObject(rowColumns,headerColumns))
    }
    return {headerColumns, resultArray}
  }
  return {
    headerColumns:[],
    resultArray:[]
  }
}

export const createCsvString = (reportData) => {
  let csvString = ''
  reportData.forEach((rowItem, rowIndex) => {
    let line = []
    rowItem.forEach((colItem, colIndex) => {
      let value = ''
      if ((typeof colItem !== 'undefined') && (colItem !== null) && colItem && colItem.replace ){
        value = colItem.replace(',', '')
      }
      line.push(value)
    })
    csvString += line.join(',')
    csvString += '\r\n'
  })
  return csvString
}

export const toCsvBlob = (rows) => {
  let csvString = createCsvString(rows)
  return new Blob([csvString], {type: 'data:application/csv'})
}

const rowToObject = (rowColumns, headerColumns) => headerColumns.reduce((returnObj, headerColumn, index) => ({
  ...returnObj,
  [headerColumn]:rowColumns[index]
}), {})
