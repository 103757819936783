import { apiCall } from '../api'

export const RETIRE_CARD = 'RETIRE_CARD'

export function retireCard(trackingNumber, activationData) {
  const payload = apiCall('retireCard', { trackingNumber, activationData})
  return {
    type: RETIRE_CARD,
    payload,
    meta: {
      trackingNumber,
      activationData
    }
  }
}

export const RETIRE_CARD_ERROR_MESSAGE = 'RETIRE_CARD_ERROR_MESSAGE'

export function retireCardErrorMessage() {
  return {
    type: RETIRE_CARD_ERROR_MESSAGE
  }
}

export const RETIRE_CARD_CLEAR = 'RETIRE_CARD_CLEAR'

export function retireCardClear () {
  return {
    type: RETIRE_CARD_CLEAR
  }
}