import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { store } from '../../../store'
import { connect } from 'react-redux'
import Card from '@material-ui/core/Card'
import CardContent from '@material-ui/core/CardContent'
import CardActions from '@material-ui/core/CardActions'
import Typography from '@material-ui/core/Typography'
import Button from '@material-ui/core/Button'
import TextField from '@material-ui/core/TextField'
import InputLabel from '@material-ui/core/InputLabel'
import Select from '@material-ui/core/Select'
import Stepper from '@material-ui/core/Stepper'
import Step from '@material-ui/core/Step'
import StepLabel from '@material-ui/core/StepLabel'
import Dropzone from 'react-dropzone'
import Table from '@material-ui/core/Table'
import TableHead from '@material-ui/core/TableHead'
import TableBody from '@material-ui/core/TableBody'
import TableRow from '@material-ui/core/TableRow'
import TableCell from '@material-ui/core/TableCell'
import Collapse from '@material-ui/core/Collapse'
import Modal from '@material-ui/core/Modal'
import plusImage from '../../../images/agoda/dropzone.png'
import { withStyles } from '@material-ui/core/styles'
import {
  getFeeFromTerminalID,
  submitCardOrder,
  resetSuccess,
  getCampaigns,
  verifyCardOrderFile,
  resetCardOrder } from '../../../action'
import {
  cardOrderVerification,
  cardOrderVerificationErrorMessage
} from '../../../action/cardOrder'
import DeleteIcon from '@material-ui/icons/Delete'
import SuccessModal from './SuccessModal'
import { red } from '@material-ui/core/colors'
import Notification from "../../default/layout/Notification";
import batchSample from '../../../resources/order-card-sample.csv'
import DownloadSample from '../../../container/agoda/BatchSampleDownload'

export function getModalStyle() {
  const top = 50
  const left = 50

  return {
    top: `${top}%`,
    left: `${left}%`,
    transform: `translate(-${top}%, -${left}%)`,
  }
}

export const styles = theme => ({
  paper: {
    position: 'absolute',
    width: theme.spacing(50),
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    padding: theme.spacing(4)
  },
  tableWrapper: {
    overflowX: 'auto',
  },
  errorMessage: {
    color : red
  }
})

const initialSingleCardForm = {
  name:'',
  surname:'',
  customerReference: '',
  loadAmount: 0,
  cardOrderFee: 0,
  recipientName: '',
  recipientNumber: '',
  address1: '',
  address2: '',
  postalCode: ''
}

const initialState = {
  activeStep: 1,
  activeStep1: true,
  activeStep2: false,
  campaignId: 0,
  quotationNumber: '',
  cardOrderFile: 'Try dropping a file into this box, or click on it to select the file to upload.',
  cardOrder: [],
  cardOrderFee: 0,
  cardOrderItems: '0',
  cardOrderTotalValue: 0.00,
  cardOrderSuccessFulItems: '0',
  designid: 1,
  fileName: '',
  plasticid: 1,
  singleCardForm: initialSingleCardForm
}
const initialErrors = {
  campaignId: '',
  quotationNumber: 'This field is required'
}

const CARD_ORDER_FEE_ID = 12

class CardOrderForm extends Component {
  constructor(props) {
    super(props)
    this.initialState = {
      values: initialState, 
      errors: initialErrors,
      submitDisabled: true,
      singleCardOrderModalOpen: false
    }
    this.state = {
      ...this.initialState
    }
    this.validateRegex = {
      quotationNumber: /^[^'"`^]{3,50}$/,
    }

    this.verifyBatch = this.verifyBatch.bind(this)
  }

  componentWillUnmount = () => {
    store.dispatch(resetCardOrder())
  }

  onNext = (nextStep) => {
    var toggleStep1, toggleStep2
    switch (nextStep) {
    case 2:
      toggleStep1 = false
      toggleStep2 = true
      break
    case 1:
      toggleStep1 = true
      toggleStep2 = false
      break
    default:
      toggleStep1 = true
      toggleStep2 = false
    }
    const newState = {
      values: {
        ...this.state.values,
        activeStep: nextStep,
        activeStep1: toggleStep1,
        activeStep2: toggleStep2
      },
      errors: {
        ...this.state.errors,
      }
    }
    this.setState(newState)
  }

  componentDidMount = () => {
    this.props.getFeeFromTerminalID()
    this.props.getCampaigns()
  }

  componentDidUpdate = (prevProps, prevState) => {
    if (prevProps.cardorder.complete !== this.props.cardorder.complete)
      this.setState({...initialState})

    if (prevProps.campaign.campaigns !== this.props.campaign.campaigns){
      this.setState({values:{
        ...this.state.values,
        campaignId: this.props.campaign.campaigns[0] ? this.props.campaign.campaigns[0].CampaignID : 0
      }})
    }
  }
  

  onSubmit = () => {
    const { cardorder, submitCardOrder } = this.props

    const validCardOrders = cardorder.cardOrders.filter((order) => {
      return !("ERROR" in order)
    })

    submitCardOrder({
      campaignId : this.state.values.campaignId,
      quotationNumber: this.state.values.quotationNumber,
      cardOrder: validCardOrders,
      designid: this.state.values.designid,
      fileName: this.state.values.fileName,
      plasticid: this.state.values.plasticid
    })
  }

  handleFeeChange = (feeIndex, value) => {
    const { cardorder } = this.props

    cardorder.cardOrders[feeIndex].cardOrderFee = value
    this.forceUpdate()
  }

  applyFee() {
    const { cardorder } = this.props
    cardorder.cardOrders.map((row) => {
      row.cardOrderFee = this.state.values.cardOrderFee
      return row
    })
    this.setState(this.state)
  }

  onInputChange = (event) => {
    const stateKey = event.target.getAttribute("name")
    const value = event.target.value
    const error = this.validateInput(stateKey, value)
    const newState = {
      values: {
        ...this.state.values,
        [stateKey]: value
      },
      errors: {
        ...this.state.errors,
        [stateKey]: error
      }
    }
    this.updateOrderSubmitDisabled(newState)
    this.setState(newState)
  }

  validateInput(key, value) {
    const regex = this.validateRegex[key]
    // assume all fields are required
    if (value === "") {
      return "This field is required"
    } else if (regex && !regex.test(value)) {
      return "Invalid input format"
    } else {
      return ""
    }
  }

  deleteCardOrder = (arrayIndex) => {
    const {cardorder} = this.props
    cardorder.cardOrders.splice(arrayIndex, 1)

    store.dispatch(cardOrderVerification(cardorder.cardOrders, this.state.values.campaignId))

    this.forceUpdate()
  }

  updateOrderSubmitDisabled(newState) {
    const { values, errors } = newState
    let submitDisabled = false

    for (let field of Object.keys(values)) {
      // required fields check
      if (["campaignId", "quotationNumber"].includes(field)) {
        if (values[field] === '' || errors[field] !== '') {
          submitDisabled = true
          break 
        }
      }
      if (["campaignId"].includes(field)) {
        if (values[field] === 0 ) {
          submitDisabled = true
          break
        }
      }
    }


    newState.submitDisabled = submitDisabled
  }

  onSelectedCampaign = (event) => {
    const value = event.target.value
    const newState = {
      ...this.state,
      values: {
        ...this.state.values,
        campaignId: value,
        cardOrderFee: this.setCardOrderFee(value)
      },
      errors: {
        ...this.state.errors,
        campaignId: (value !== '0') ? '' : value
      }
    }
    this.updateOrderSubmitDisabled(newState)
    this.setState(newState)
    store.dispatch(cardOrderVerification(this.props.cardorder.cardOrders, value))
  }

  setCardOrderFee = value => Object.keys( this.props.fees ).map( feeKey => this.props.fees[feeKey].CAMPAIGNID === value && this.props.fees[feeKey].TRANSACTIONFEETYPEID === CARD_ORDER_FEE_ID ? this.props.fees[feeKey].CAMPAIGNTRANSACTIONFEECHARGE : 0 ).filter( x => x !== null)[0]

  verifyBatch(acceptedFiles, rejectedFiles) {
    this.setState({...this.state, uploadTriggered: true})

    this.setState(
      {...this.state, activeStep: 1 },
      verifyCardOrderFile(acceptedFiles, this.state.values.campaignId)
    )

    const newState = {
      values: {
        ...this.state.values,
        cardOrder: [...this.state.values.cardOrder],
      }
    }
    this.setState(newState)
  }
  
  resetState = () => {
    this.props.resetSuccess()
    this.setState({...this.initialState})
  }

  handleSingleCardModalClose = () => this.setState({
    singleCardOrderModalOpen: false
  })
  handleSingleCardModalOpen = () => this.setState({
    singleCardOrderModalOpen: true
  })

  onSingleCardInputChange = e => this.setState(
    {values: {
      ...this.state.values, 
      singleCardForm:{
        ...this.state.values.singleCardForm,
        [e.target.name]:e.target.value
      }
    }}
  )

  onAddressFieldInputChange = e => {
    // Commas aren't allowed in address fields because of further PAN file format.
    e.target.value = e.target.value.replace(",", "");

    this.onSingleCardInputChange(e);
  }

  addSingleCardToCardOrder = () => {
    //this.state.values.singleCardForm
    const {cardorder} = this.props

    cardorder.cardOrders.push(this.state.values.singleCardForm)

    this.setState({
      singleCardOrderModalOpen: false,
      singleCardForm: initialSingleCardForm
    })

    store.dispatch(cardOrderVerification(cardorder.cardOrders, this.state.values.campaignId))

    this.forceUpdate()
  }

  StepOne = () => {
    const { values, errors, submitDisabled } = this.state
    const { classes, cardorder } = this.props

    cardorder.cardOrders = (cardorder.cardOrders) ? cardorder.cardOrders : []

    const successCount = cardorder.cardOrders.filter((order) => {
      return !("ERROR" in order)
    })

    const initialLoadTotal = parseFloat(0)
    const loadTotal = cardorder.cardOrders.reduce(
      (accumulator, currentValue) => parseFloat(accumulator) + parseFloat(currentValue.loadAmount),
      initialLoadTotal
    )

    return <Collapse in={values.activeStep1}>
      <Card style={{padding:'40px'}}>
        <Typography gutterBottom variant="subtitle1">
          Select a campaign and fill in card order details
        </Typography>
        <CardContent>
          <InputLabel htmlFor="campaign-native-simple">Campaign</InputLabel><br />
          <Select 
            native 
            name="campaignId"
            value={values.campaignId} className="select"
            onChange={this.onSelectedCampaign}
            inputProps={{
              id: 'campaign-native-simple'
            }}
          >
            <option value={-1} disabled hidden>{errors.campaignId}</option>
            <option value={0}>Select a Campaign</option>
            {
              this.props.campaign.campaigns.map(  (campaign, key) => <option key={campaign.CampaignID} value={campaign.CampaignID}>{campaign.CampaignName}</option>)
            }
          </Select>           
          <TextField 
            name="plasticid"
            label="Plastic Id" 
            placeholder="0" 
            inputProps={{ maxLength: 60 }}
            fullWidth={true}
            onChange={this.onInputChange}
            value={values.plasticid}
          />
          <TextField 
            name="designid"
            label="Design ID" 
            placeholder="number" 
            helperText={errors.designId}
            inputProps={{ maxLength: 60 }}
            fullWidth={true} 
            onChange={this.onInputChange}
            value={values.designid}
          />
          <TextField 
            name="quotationNumber"
            label="Quotation Number" 
            placeholder="3-50 characters" 
            helperText={errors.quotationNumber}
            inputProps={{ maxLength: 50 }}
            fullWidth={true} 
            onChange={this.onInputChange}
            value={values.quotationNumber}
          />
          <br /><br />
          <Typography gutterBottom variant="caption">
            The below fee amount will be applied. By clicking apply button the fee will be re-applied to all cards in the order
          </Typography>
          <br />
          <TextField 
            name="cardOrderFee"
            label="Card Order Fee" 
            placeholder=""
            inputProps={{ maxLength: 60 }}
            fullWidth={false}
            onChange={this.onInputChange}
            value={values.cardOrderFee}
          />
          <Button onClick={ (e) => this.applyFee() } color="primary" style={{marginLeft: '10px' }}>
              Apply
          </Button>
          <br /><br />
          { (this.state.values.fileName === '') ?
            <div>
              <InputLabel htmlFor="raised-button-file">Card Order File</InputLabel>
              <br />
              <br />
              
              <Dropzone onDrop={this.verifyBatch.bind(this)} multiple={false} className="dropzone" accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel">
                {({getRootProps, getInputProps}) => (
                  <section className="container">
                    <div {...getRootProps({className: 'dropzone'})}>
                      <input {...getInputProps()} />
                      <img className="dropzone-image" src={plusImage} alt="Try dropping a file into this box, or click on it to select the file to upload." />
                      <Typography component="p" className="dropzone-p">Try dropping a file into this box, or click on it to select the file to upload.</Typography>
                    </div>
                  </section>
                )}
              </Dropzone>
            </div> : null
          }
        </CardContent>
        { (this.state.values.fileName === '') ? <DownloadSample batchSample ={batchSample}/> : null}
      </Card><br />
      <Card style={{padding:'40px'}}>
        <Typography gutterBottom variant="h5">Uploaded Card Order File ({successCount.length} successful / {cardorder.cardOrders.length - successCount.length} failed), Total Load Amount S$ {loadTotal.toFixed(2)}</Typography>
        <Button color='primary' variant='contained' style={{color:'white'}} onClick={this.handleSingleCardModalOpen}>Add Card</Button>
        <CardContent className={classes.tableWrapper}>
          <Table className="cardOrderTable">
            <TableHead>
              <TableRow>
                <TableCell>Name</TableCell>
                <TableCell>Surname</TableCell>
                <TableCell>Customer Reference</TableCell>
                <TableCell>Load Amount</TableCell>
                <TableCell>Card Order Fee</TableCell>
                <TableCell>Recipient Name</TableCell>
                <TableCell>Recipient Number</TableCell>
                <TableCell>Address Line 1</TableCell>
                <TableCell>Address Line 2</TableCell>
                <TableCell>Postal Code</TableCell>
                <TableCell></TableCell>
                <TableCell></TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {cardorder.cardOrders.map((singleCardOrder, arrKey) =>
                <TableRow key={arrKey}>
                  <TableCell>{singleCardOrder.name}</TableCell>
                  <TableCell>{singleCardOrder.surname}</TableCell>
                  <TableCell>{singleCardOrder.customerReference}</TableCell>
                  <TableCell>{singleCardOrder.loadAmount}</TableCell>
                  <TableCell><TextField value={singleCardOrder.cardOrderFee} key={arrKey} onChange={ e => this.handleFeeChange(arrKey, e.target.value)}/></TableCell>
                  <TableCell>{singleCardOrder.recipientName}</TableCell>
                  <TableCell>{singleCardOrder.recipientNumber}</TableCell>
                  <TableCell>{singleCardOrder.address1}</TableCell>
                  <TableCell>{singleCardOrder.address2}</TableCell>
                  <TableCell>{singleCardOrder.postalCode}</TableCell>
                  <TableCell className={classes.tableWrapper}>{singleCardOrder.ERROR}</TableCell>
                  <TableCell><DeleteIcon onClick={() => this.deleteCardOrder(arrKey)}/></TableCell>
                </TableRow>
              )
              } 
            </TableBody>
          </Table>
        </CardContent>
        <CardActions className="align-right">
          <Button className="flattbutton-login" color="primary"size="large" variant="contained" onClick={() => this.onNext(2)} disabled={successCount.length === 0  || submitDisabled }>Next</Button>
        </CardActions>
      </Card><br />
    </Collapse>
  }

StepTwo = () => {
  const { values } = this.state
  const { cardorder } = this.props

  cardorder.cardOrders = (cardorder.cardOrders) ? cardorder.cardOrders : []

  const successCount = cardorder.cardOrders.filter((order) => {
    return !("ERROR" in order)
  })

  const initialOrderFee = 0
  const totalOrderFee = successCount.reduce((accumulator, singleCardOrder) =>
    parseFloat(accumulator) + parseFloat(singleCardOrder.cardOrderFee), initialOrderFee
  )

  const initialLoadAmount = 0
  const totalLoadAmount = successCount.reduce((accumulator, singleCardOrder) =>
    parseFloat(accumulator) + parseFloat(singleCardOrder.loadAmount), initialLoadAmount
  )

  return <Collapse in={values.activeStep2}>
    <Card style={{padding:'40px'}}>
      <Typography gutterBottom variant="h5">Card Order Confirmation</Typography>
      <CardContent>
        <InputLabel htmlFor="raised-button-file">Campaign</InputLabel><br />
        <InputLabel htmlFor="raised-button-file">{values.campaignId}</InputLabel><br /><br />
        <InputLabel htmlFor="raised-button-file">Plastic ID</InputLabel><br />
        <InputLabel htmlFor="raised-button-file">{values.plasticid}</InputLabel><br /><br />
        <InputLabel htmlFor="raised-button-file">Design ID</InputLabel><br />
        <InputLabel htmlFor="raised-button-file">{values.designid}</InputLabel><br /><br />
        <InputLabel htmlFor="raised-button-file">Quotation Number</InputLabel><br />
        <InputLabel htmlFor="raised-button-file">{values.quotationNumber}</InputLabel><br /><br />
        <InputLabel htmlFor="raised-button-file">Cards in Order</InputLabel><br />
        <InputLabel htmlFor="raised-button-file">{successCount.length}</InputLabel><br /><br />
        <InputLabel htmlFor="raised-button-file">Total Order Fee</InputLabel><br />
        <InputLabel htmlFor="raised-button-file">S$ {totalOrderFee.toFixed(2)}</InputLabel><br /><br />
        <InputLabel htmlFor="raised-button-file">Total Load Amount</InputLabel><br />
        <InputLabel htmlFor="raised-button-file">S$ {totalLoadAmount.toFixed(2)}</InputLabel><br /><br />
        <Typography component="p">If the above information is complete and correct, a card order can be submitted by pressing the 'SUBMIT' button. Please note that delivery is finalized 24 hours after submission, after which an order cannot be cancelled.</Typography>
      </CardContent>
      <CardActions className="align-right">
        <Button onClick={()=>this.onNext(1)}>Back</Button>
        <Button className="flattbutton-login" color="primary"size="large" variant="contained" onClick={()=> this.onSubmit()} disabled={successCount.length === 0 || this.props.submitting}>Submit</Button>
      </CardActions>
    </Card>
  </Collapse>
}

AddSingleCardModal = () => {
  const { classes } = this.props
  const { values } = this.state
  return <Modal
    aria-labelledby="simple-modal-title"
    aria-describedby="simple-modal-description"
    open={this.state.singleCardOrderModalOpen}
    onClose={this.handleSingleCardModalClose}
  >
    <div style={getModalStyle()} className={classes.paper}>
      <Typography variant="h5" id="modal-title">
      Add a single card
      </Typography>
      <TextField
        name="name"
        label="Name"
        inputProps={{ maxLength: 50 }}
        fullWidth={true}
        onChange={this.onSingleCardInputChange}
        value={values.singleCardForm.name}
      />
      <TextField
        name="surname"
        label="Surname"
        inputProps={{ maxLength: 50 }}
        fullWidth={true}
        onChange={this.onSingleCardInputChange}
        value={values.singleCardForm.surname}
      />
      <TextField
        name="customerReference"
        label="Customer Reference"
        inputProps={{ maxLength: 50 }}
        fullWidth={true}
        onChange={this.onSingleCardInputChange}
        value={values.singleCardForm.customerReference}
      />
      <TextField
        name="loadAmount"
        label="Load Amount"
        inputProps={{ maxLength: 10 }}
        fullWidth={true}
        onChange={this.onSingleCardInputChange}
        value={values.singleCardForm.loadAmount}
      />
      <TextField
        name="cardOrderFee"
        label="Card Order Fee"
        inputProps={{ maxLength: 10 }}
        fullWidth={true}
        onChange={this.onSingleCardInputChange}
        value={values.singleCardForm.cardOrderFee}
      />
      <TextField
        name="recipientName"
        label="Recipient Name"
        placeholder="3-60 characters"
        inputProps={{ maxLength: 40 }}
        fullWidth={true}
        onChange={this.onSingleCardInputChange}
        value={values.singleCardForm.recipientName}
      />
      <TextField
        name="recipientNumber"
        label="Recipient Contact Number"
        placeholder="Contact Number"
        inputProps={{ maxLength: 30 }}
        fullWidth={true}
        onChange={this.onSingleCardInputChange}
        value={values.singleCardForm.recipientNumber}
      />
      <TextField
        name="address1"
        label="Address Line 1"
        inputProps={{ maxLength: 40 }}
        fullWidth={true}
        onChange={this.onAddressFieldInputChange}
        value={values.singleCardForm.address1}
      />
      <TextField
        name="address2"
        label="Address Line 2"
        inputProps={{ maxLength: 40 }}
        fullWidth={true}
        onChange={this.onAddressFieldInputChange}
        value={values.singleCardForm.address2}
      />
      <TextField
        name="postalCode"
        label="Postal Code"
        inputProps={{ maxLength: 40 }}
        fullWidth={true}
        onChange={this.onAddressFieldInputChange}
        value={values.singleCardForm.postalCode}
      />
      <br /><br />
      <Button onClick={this.addSingleCardToCardOrder} disabled={values.singleCardForm.name === '' || values.singleCardForm.surname === ''} variant='contained' color='primary' style={{color:'white'}}>Add Card Order</Button>
      <Button onClick={this.handleSingleCardModalClose} variant='contained' style={{color:'rgb(12,179,157)',backgroundColor:'white',float:'right'}}>Cancel</Button>
    </div>
  </Modal>
}

  CardOrderResult = () =>{ 
    const { classes, cardorder } = this.props
    const { resultCode, resultText } = cardorder
    return <Modal
      aria-labelledby="simple-modal-title"
      aria-describedby="simple-modal-description"
      open={parseInt(resultCode, 10) < 0}
    >
      <div style={getModalStyle()} className={classes.paper}>
        <Typography variant="h5" id="modal-title">
          Error
        </Typography>
        <Typography component="p">{resultCode}: {resultText}</Typography>
        <Button variant='contained' color='primary' onClick={this.onClearStateAndProps}>OK</Button>
      </div>
    </Modal>
  }

  onClearStateAndProps = () => {
    this.setState({values:initialState})
    this.props.resetCardOrder()
  }

  render() {
    const { values } = this.state
    const steps = ['Card Order Creation', 'Card Order Confirmation']

    return (
      <React.Fragment>
        <SuccessModal open={this.props.cardorder.complete} onClose={this.resetState} />
        <Card style={{padding:'40px'}}>
          <Typography gutterBottom variant="h5">
            Order Card
          </Typography>
          <CardContent>
            <Stepper activeStep={values.activeStep}>
              {steps.map((label, index) => {
                return (
                  <Step key={index}>
                    <StepLabel>{label}</StepLabel>
                  </Step>  
                )
              })}
            </Stepper>
          </CardContent>
        </Card>
        <br />
        <this.StepOne/>
        <this.StepTwo/>
        <this.AddSingleCardModal/>
        <this.CardOrderResult/>

        { this.props.error ? (
          <Notification type={'error'} message={ this.props.errorMsg } open={ true } closed={() => this.onClearStateAndProps()}/>
        ) : (
          null
        ) }

      </React.Fragment>
    )
  }
}

CardOrderForm.propTypes = {
  getFeeFromTerminalID: PropTypes.func.isRequired,
  getCampaigns: PropTypes.func.isRequired,
  submitCardOrder: PropTypes.func.isRequired,
  resetSuccess: PropTypes.func.isRequired,
  resetCardOrder: PropTypes.func.isRequired,
  cardOrderVerification: PropTypes.func.isRequired,
  cardOrderVerificationErrorMessage: PropTypes.func.isRequired,
  fees: PropTypes.object.isRequired,
  cardorder: PropTypes.shape({
    complete: PropTypes.bool.isRequired,
    cardOrders: PropTypes.array.isRequired,
    resultCode: PropTypes.number,
    resultText: PropTypes.string,
    errorMsg: PropTypes.string,
  }).isRequired,
  campaign: PropTypes.shape({
    campaigns: PropTypes.array.isRequired,
  }).isRequired,
  classes: PropTypes.object.isRequired,
  submitting: PropTypes.bool.isRequired,
  error: PropTypes.bool.isRequired,
  errorMsg: PropTypes.string,
};

const mapStateToProps = (state, props) => ({
  submitting: !!(state.api.SUBMIT_CARD_ORDER && state.api.SUBMIT_CARD_ORDER > 0),
  error: !!(state.cardorder.errorMsg && state.cardorder.errorMsg !== ''),
  errorMsg: state.cardorder.errorMsg,
  fees: state.fees,
  cardorder: state.cardorder,
  campaign: state.campaign,
})

export default connect(
  mapStateToProps,
  { 
    cardOrderVerification,
    cardOrderVerificationErrorMessage,
    getFeeFromTerminalID,
    submitCardOrder,
    resetSuccess,
    getCampaigns,
    resetCardOrder
  }
)(withStyles(styles)(CardOrderForm))


