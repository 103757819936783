import {TOKEN_EXPIRED} from "../action/apiResponseCodes"

const initialState = {
  pending: false
}
const getInProgressCalls = (state, action) => state[action] ? state[action] : 0

export default (state = initialState, action) => {
  const { type } = action
  
  switch(type) {
    case 'API_RESET':
      return {
        ...state,
        redirect: ''
      }
    default:
      if (type.indexOf("_PENDING") > -1) {
        const actionType = type.replace(/_PENDING/g, '')
        const inProgressCalls = getInProgressCalls(state, actionType) 
        return {
          ...state,
          [actionType] : inProgressCalls + 1,
          pending: true
        }
      } else if (type.indexOf("_FULFILLED") > -1 || type.indexOf("_REJECTED") > -1) {
        const actionType = type.replace(/_FULFILLED|_REJECTED/g, '')
        const inProgressCalls = getInProgressCalls(state, actionType) 

        if(action.payload.resultCode === TOKEN_EXPIRED) {
          state.redirect = '/login'
        } else {
          state.redirect = ''
        }

        return {
          ...state,
          [actionType] : (inProgressCalls > 0 ? inProgressCalls - 1 : 0),
          pending: false
        }
      }
  }
  return state
}
